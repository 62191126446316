.section-margin,
.section-margin2 {
  padding-top: 60px;
  padding-bottom: 60px;
}
address,
body,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-weight: 400;
  font-style: normal;
}
.below-banner ul li,
.child-menu,
ol,
ul {
  list-style: none;
}
#shadow1,
#shadow5 {
  width: 96px;
  height: 96px;
}
.ads-batch-div1,
.branding-process-text1,
.branding-why-text1,
.domain-name-div,
.nav-tab-text,
.org-mart-what-img,
.ourteam-what-we-do-text,
.performance-div3-sec2,
.performance-sem-div2,
.question-creative-div,
.question-div,
.ruby-div,
.security-slider-img,
.social-question-text {
  align-self: center;
  align-self: center;
}
.waviy,
.waviy-sec {
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
}
@font-face {
  font-family: arial;
  src: url("../../public/assest/font/Barlow/Barlow-Regular.ttf");
  font-style: normal;
}
@font-face {
  font-family: arial-bold;
  src: url("../../public/assest/font/Barlow/Barlow-Bold.ttf");
  font-style: normal;
}
/* @font-face {
  font-family: arial;
  src: url("../../public/assest/font/new-font/titilliumweb-regular.ttf");
  font-style: normal;
}
@font-face {
  font-family: arial-bold;
  src: url("../../public/assest/font/new-font/titilliumweb-bold.ttf");
  font-style: normal;
} */
/* @font-face {
  font-family: arial;
  src: url("../../public/assest/font/arial/ARIAL.woff");
  font-style: normal;
}
@font-face {
  font-family: arial-bold;
  src: url("../../public/assest/font/arial/ARIALBD.woff");
  font-style: normal;
} */
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #0b76b4;
}
:root {
  --primary-color: #436fe8;
  --primary-bg-color: #436fe8;
  --text-gray-light: #495057;
  --bg-white: #ffffff;
  --bg-black: #000;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
:after,
:before {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
  display: initial;
}
caption,
th {
  text-align: left;
}
q:after,
q:before,
ul.ruby-menu > li:hover > a:only-child:after,
ul.ruby-menu > li > a:only-child:after,
ul.ruby-menu > li > ul > li > a:only-child:after,
ul.ruby-menu > li > ul > li > ul > li > a:only-child:after,
ul.ruby-menu > li > ul > li > ul > li > ul > li > a:only-child:after {
  content: "";
}
.modal-content,
.pt-domain-price table,
.table-bordered,
abbr,
acronym {
  border: 0;
}
.breadcrumbs,
.mobile-none,
.nav-tabs10,
.quality-cart,
article,
aside,
div.ruby-c-inline2 span.ruby-c-date2,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
.one-box-form,
.user-block {
  position: relative;
  display: flex;
}
.section-margin2 {
  background-color: #dce9ef;
}
button:focus,
input:focus {
  outline: transparent 0;
  box-shadow: none;
}
#pt-footer .pt-footer-style-1 .widget:first-child,
.hsc ul,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
ul {
  margin-bottom: 0;
}
body,
html {
  max-width: 100%;
  overflow-x: hidden;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
a,
a:hover {
  text-decoration: none;
  outline: transparent 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #436fe8;
}
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
body {
  font-size: 16px;
  width: 100% !important;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
}
.new-header.desktop .menu-level-1-child.right-side-menu {
  text-transform: none;
}
.new-header.desktop .user-block {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  -webkit-box-align: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
}
.al-video,
.ani-girl-div,
.banner-new,
.branding-consulting-banner,
.new-header.desktop .personal-banking,
.new-header.desktop .user-block .personal-banking-wrapper,
.organic-marketing-banner,
.performance-banner,
.price,
.profile-button,
.question-creative-section-img,
.sub-banner-img,
.subscript,
.video-section,
.waviy2,
.why-choose-us-img,
div.ruby-wrapper > ul,
ul.ruby-menu > li > ul > li,
ul.ruby-menu > li > ul > li > ul > li {
  position: relative;
}
.one-box-form {
  width: 100%;
}
.one-box-form .login-inp {
  width: 100%;
  height: 35px;
  border-radius: 15px;
  background: 0 0;
  border: 1px solid var(--primary-color);
  padding-left: 10px;
  font-family: apice;
}
.login-btn {
  height: 35px;
  line-height: 1;
  background: var(--primary-bg-color);
  padding: 0 40px;
  border-radius: 15px;
  font-size: 16px;
  letter-spacing: 1px;
  position: relative;
  left: -25px;
  border: none;
  color: #fff;
}
.nu {
  background: #fff;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
  border-radius: 15px;
  height: 50px;
  line-height: 25px !important;
  font-weight: 500;
  padding: 8px 20px;
  font-size: 14px;
  font-family: apice;
  position: relative;
  top: 4px;
}
.link-area-bn,
.link-area-yb {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.nu > a {
  color: var(--primary-color);
}
.nav-link > a,
.since-2020 {
  color: var(--bg-white);
  font-size: 13px;
}
.about-us > .drop-arrow,
.b2b-industrial-div,
.case-padding-none,
.nav-link,
.navbar-brand,
.padding-none {
  padding: 0;
}
.navbar-brand > img {
  height: 54px;
  margin: 7px 10px;
}
.hsc img,
.hsc ul li {
  margin-right: 0;
}
.menu-inner {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  -webkit-box-align: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
}
.case-studies-div,
.domain-cart,
.flex-cart-div,
.icon-top-ul,
.menu-inner1,
.number > div,
.second-menu-ul,
.term-flex-div {
  display: flex;
}
.menu-inner1 {
  background-color: var(--bg-white);
  margin: 0 auto;
  padding: 11px 0;
  text-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
}
.iso-logo {
  padding: 4px 0;
  width: 37px;
  border-radius: 34px;
  margin-left: 192px;
}
.since-2020 {
  padding: 14px 0;
  font-family: inherit;
  margin-left: 308px;
}
.nav-link > a,
.teams-link {
  font-family: arial;
}
.iso-class,
.numbers {
  display: flex;
  justify-content: space-around;
}
.nav-tabs .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: var(--bg-white);
  border-color: none;
}
.first-tabs > li > a {
  color: var(--primary-bg-color);
  border-right: 1px solid var(--primary-bg-color);
  padding: 0 32px;
  font-weight: 500;
  font-size: 15px;
}
.header1 {
  transform: translateY(-45%);
  position: fixed;
  transition: 1s;
  box-shadow: 0 0 6px 0 #6c757d;
  min-width: 100%;
  z-index: 999;
}
.header1:hover {
  transform: translateY(0);
}
.menuicon-mobile {
  color: var(--primary-bg-color);
  background-color: #fff;
  border-radius: 5px;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  float: right;
}
.back-ground-color,
.branding-sem-consulting-what,
.footer-back,
.hf,
.one-box:hover,
.second-menu {
  background-color: #436fe8;
}
.navbar-light .navbar-toggler {
  color: var(--primary-color);
  border-color: var(--primary-color);
}
.b2b-border2,
.email-redeff-div,
.hsc,
.hsc ul li,
.icon-top-ul > li,
.ruby {
  border-right: 1px solid #ccc;
}
.teams-link:hover {
  text-decoration: underline;
}
.nav-item {
  position: relative;
  max-width: 100%;
  padding: 0;
}
.nav-link.active > a {
  color: #fed501;
  font-family: arial;
  font-size: 13px;
  padding: 28px 0;
}
.nav-link > a {
  font-weight: 500;
  padding: 28px 0;
}
.nav-link > a:hover {
  color: #fed501;
  transition: 1s;
}
.text-align-div,
.text-rig {
  text-align: right;
}
.below-banner,
.branding-service-text2,
.career-dont-see-div,
.career-second-div,
.fboy,
.fcoy,
.pt-button2,
.pt-comparison-table .table td,
.pt-comparison-table .table th,
.readmore-button,
.result-text,
.security-blue-div {
  text-align: center;
}
.section-top {
  padding-top: 67px;
}
.icon-top-ul > li > a > img {
  height: 30px;
}
.icon-top-ul {
  justify-content: flex-end;
  height: 30px;
}
.action-black-div-text,
.footer-div-text {
  font-family: arial;
  height: 237px;
  align-items: center;
}
.icon-top-ul > li {
  padding: 0 7px;
}
.hsc ul li a {
  color: var(--bg-black);
}
.hsc ul li {
  display: inline-block;
}
.dno {
  padding: 6px 0;
}
.second-menu-ul > li {
  float: left;
  font-size: 14px;
  padding: 0 10px;
  text-transform: uppercase;
  border-right: 1px solid #bbb;
}
.second-menu {
  padding: 0 40px;
}
.second-menu-ul > li > a {
  display: block;
  line-height: 28px;
  color: #fff;
  text-decoration: none;
  font-family: arial;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 12px;
  padding: 0;
}
.below-banner > ul {
  display: inline-flex;
}
.below-banner ul li {
  padding: 0 !important;
  margin: 0;
}
.below-banner ul li a {
  color: #fff;
  text-decoration: none;
}
.link-area-yb {
  padding: 6px 43px;
  position: relative;
}
.link-area-bn {
  padding: 6px 50px;
  margin-left: -40px;
}
.pt-copyright-footer,
.pt-footer-style-1 {
  background: var(--primary-color);
}
.link-area-bn a {
  color: #a21b21 !important;
  font-weight: 500;
}
.link-area-bn::after,
.link-area-bn::before,
.link-area-yb::after,
.link-area-yb::before {
  content: "";
  position: absolute;
  top: 0;
  height: 40px;
  width: 24px;
}
#pt-footer {
  background: #0b76b4;
  display: inline-block;
  width: 100%;
  
}
.pt-footer-style-1 {
  color: #fff;
}
.footer-title,
.widget ul.menu li a {
  color: var(--bg-white);
}
.pt-footer-top {
  padding: 60px 0 !important;
}
.action-blue-div-buttom,
.action-white-div-buttom,
.action-yellow-div-buttom {
  padding: 5px 23px;
  transition: 0.5s ease-out;
}
.footer-title2 {
  color: var(--primary-bg-color);
}
footer#pt-footer .widget .footer-title {
  margin-bottom: 15px;
  padding: 0;
  font-size: 22px;
  font-family: arial-bold;
  text-transform: capitalize;
  position: relative;
  line-height: 34px;
}
footer#pt-footer .widget .footer-title2 {
  margin-bottom: 15px;
  padding: 0;
  font-size: 22px;
  font-family: arial-bold;
  position: relative;
  line-height: 34px;
}
.widget ul.menu li a {
  position: relative;
  border: none;
  padding: 3px 0 3px 00px;
  display: inline-block;
  width: 100%;
}
footer#pt-footer .pt-footer-style-1 .widget ul li a,
footer#pt-footer .pt-footer-style-1 .widget ul.menu li a {
  color: var(--bg-white);
  line-height: 25px;
  /* font-family: arial; */font-family: 'Opens',sans-serif;
}
footer#pt-footer .pt-footer-style-1 .widget ul li a span,
footer#pt-footer .pt-footer-style-1 .widget ul.menu li a {
  margin-left: 8px;
}
.hov li:first-child,
.hov-drop li:first-child,
.mt50,
div.ruby-grid > div.ruby-row div.ruby-row:first-of-type [class^="ruby-col"] {
  margin-top: 0;
}
.fboy,
.fcoy {
  margin-top: 35px;
}
.menu-toper ul {
  margin-bottom: 0;
  display: flex;
}
.menu-toper ul li {
  display: inline-block;
  margin-right: 10px;
}
.fcoy ul li a img {
  width: 135px;
  margin: 10px;
  padding: 0 10px;
  border-right: 1px solid #495057;
}
.fboy ul li a img {
  width: 101px;
  margin: 9px;
}
.footer-icon-img {
  margin-top: 39px;
}
.footer-icon-img2 {
  margin-top: 43px;
}
#pt-footer .pt-footer-style-1 .pt-footer-logo {
  height: 79px;
  margin-bottom: 13px;
  margin-top: 36px;
  width: 240px;
  border-radius: 5px;
}
.grank-title {
  line-height: 25px;
  margin-bottom: 38px;
  font-family: arial;
}
footer#pt-footer .pt-copyright-footer .pt-copyright a:hover {
  color: #fff;
  text-decoration: underline;
}
footer#pt-footer .pt-copyright-footer .pt-copyright a {
  color: #fff;
  font-family: arial;
}
footer#pt-footer .pt-copyright-footer .pt-copyright {
  text-align: left;
  display: inline-block;
  width: 100%;
}
footer#pt-footer .pt-copyright-footer {
  padding: 23px 0;
}
.footer-back2 {
  background-image: linear-gradient(to left, #0080ff2d, #ffffffe0);
}
.footerimg-div,
.performance-footerimg-div {
  position: relative;
  margin-top: 50px;
}
.footer-div-text {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 80%;
  top: 40%;
  left: 50%;
  background-color: #000;
  background-image: url("../../public/assest/photos/contact-us.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-size: 38px;
  display: flex;
  color: #fed501;
  justify-content: space-around;
  border-radius: 10px;
  font-weight: 800;
}
.action-black-div-buttom,
.footer-div-buttom,
.performance-footer-div-buttom {
  background-color: #fed501;
  color: #000;
  font-family: arial;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 23px;
  border-radius: 5px;
  background: linear-gradient(to left, #ffc107 50%, #000 50%) right;
  background-size: 200%;
  transition: 0.5s ease-out;
}
.action-black-div-buttom:hover,
.footer-div-buttom:hover,
.performance-footer-div-buttom:hover {
  background-position: left;
  color: #ffc107;
  border: 1px solid #ffc107;
}
.action-black-div-text,
.action-white-div-text {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-size: 30px;
  border-radius: 10px;
  font-weight: 800;
}
.action-black-div-text {
  width: 100%;
  background-color: #000;
  background-image: url("../../public/assest/photos/contact-us.png");
  display: flex;
  color: #fed501;
  justify-content: space-around;
}
.action-blue-div-text,
.action-white-div-text {
  align-items: center;
  font-family: arial;
  width: 100%;
  height: 237px;
  display: flex;
}
.action-white-div-text {
  background-color: #fff;
  background-image: url("../../public/assest/photos/white-action-back.png");
  color: var(--primary-bg-color);
  justify-content: space-around;
}
.action-white-div-buttom {
  background-color: var(--primary-bg-color);
  color: #fff;
  font-family: arial;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  background: linear-gradient(to left, #436fe8 50%, #fff 50%) right;
  background-size: 200%;
}
.action-white-div-buttom:hover,
.web-dev-action-yellow-div-buttom:hover {
  background-position: left;
  color: #436fe8;
  border: 1px solid #436fe8;
}
.action-blue-div-text {
  background-image: url("../../public/assest/photos/blue-action-back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-size: 38px;
  color: #fff;
  justify-content: space-around;
  border-radius: 10px;
  font-weight: 800;
}
.action-blue-div-buttom {
  background-color: #fed501;
  color: #436fe8;
  font-family: arial;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  background: linear-gradient(to left, #fff 50%, #436fe8 50%) right;
  background-size: 200%;
}
.action-blue-div-buttom:hover {
  background-position: left;
  color: #fff;
  border: 1px solid #fff;
}
.action-yellow-div-text > p > span,
.web-dev-action-yellow-div-text > p > span {
  font-size: 16px;
  font-family: arial-bold;
}
.action-yellow-div-text {
  width: 100%;
  font-family: arial;
  height: 237px;
  background-image: url("../../public/assest/photos/yellow-action-back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  align-items: center;
  font-size: 38px;
  display: flex;
  color: #000;
  justify-content: space-around;
  border-radius: 10px;
  font-weight: 800;
}
.action-yellow-div-buttom {
  background-color: #fed501;
  color: #fed501;
  font-family: arial;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  background: linear-gradient(to left, #000 50%, #fed501 50%) right;
  background-size: 200%;
}
.action-yellow-div-buttom:hover {
  background-position: left;
  color: #000;
  border: 1px solid #000;
}
.pt-footer-social ul {
  margin: 0;
  padding: 0;
  float: right;
}
.pt-footer-social ul li {
  list-style: none;
  float: left;
  border-radius: 20px;
  background-color: #fff;
  margin-right: 11px;
}
.pt-footer-social ul li:hover {
  transform: scale(1.2);
  transition: 1s;
}
.pt-footer-social ul li a {
  background: var(--bg-white);
  color: var(--primary-color);
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  display: inline-block;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.pt-contact > li > a > i,
.pt-contact > li > i {
  margin-top: 8px;
  margin-right: 10px;
}
.pt-contact > li {
  margin-bottom: 15px;
  display: flex;
}
.pt-contact > li > span {
  margin-left: 3px;
  font-family: arial;
  line-height: 26px;
  font-size: 15px;
}
.footer-white-bg-padding {
  padding: 0 50px;
}
.regman,
.regman2,
.regman3 {
  padding: 6px 20px;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  display: inline-block;
  font-size: 17px;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 50%);
  margin-bottom: 40px;
}
#order1 {
  order: 1;
  order: 2;
}
#order2 {
  order: 2;
  order: 1;
}
.pt-section-title-1.text-center {
  padding: 20px 15px;
  margin-bottom: 0;
}
.pt-section-title-1 .pt-section-title {
  font-size: 48px;
  line-height: 56px;
  z-index: 1;
  position: relative;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 30px;
  text-align: center;
}
.imp-word {
  color: #a21b21;
  font-weight: 500;
}
.pt-button,
.regman,
.regman2,
.regman3 {
  position: relative;
  color: var(--bg-white);
}
.regman {
  background: #46769b;
}
.regman2 {
  background: #5e8cad;
}
.regman3 {
  background: #75a2bf;
}
.pt-button,
.pt-button2 {
  font-family: var(--title-fonts);
  border: none;
}
.pt-button {
  width: auto;
  background: var(--primary-color);
  background-size: 200% auto;
  font-size: 16px;
  padding: 12px 45px;
  line-height: 2;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-top: 50px;
}
.pt-button2,
.pt-button3 {
  width: auto;
  font-size: 16px;
  padding: 6px 45px;
  line-height: 2;
  vertical-align: middle;
  display: inline-block;
  margin-top: 50px;
  position: relative;
  font-weight: 700;
  overflow: hidden;
}
.pt-button2 {
  background: 0 0/200% auto #fed501;
  border-radius: 5px;
}
.pt-button3 {
  background: 0 0/200% auto #0062cc;
  font-family: arial-bold;
  border: none;
  border-radius: 5px;
}
.pt-button3:hover {
  background-color: #ffc107;
  transition: 1s;
}
.elementor-icon-list-item {
  margin-bottom: 10px;
}
.elementor-icon-list-text {
  font-size: 18px;
  font-weight: 600;
  color: #0b76b4;
  padding-bottom: 20px;
  position: relative;
}
.elementor-icon-list-text2::before,
.elementor-icon-list-text::before {
  content: "";
  position: absolute;
  top: 13%;
  width: 10px;
  background-color: #fed501;
  height: 10px;
  left: -20px;
  border-radius: 20px;
}
.elementor-icon-list-text3 {
  color: #495057;
  font-size: 21px;
  padding-bottom: 20px;
  font-family: arial;
  line-height: 34px;
  position: relative;
}
.elementor-icon-list-text3::before {
  content: "";
  position: absolute;
  top: 13%;
  width: 10px;
  background-color: var(--primary-bg-color);
  height: 10px;
  left: -20px;
  border-radius: 20px;
}
.elementor-icon-list-text2 {
  font-size: 17px;
  color: var(--primary-color);
  padding-bottom: 20px;
  font-family: arial-bold;
  line-height: 30px;
  position: relative;
}
.elementor-icon-list-text4 {
  font-size: 26px;
  font-weight: 600;
  color: #000;
  font-family: arial-bold;
  padding-bottom: 26px;
}
.elementor-icon-list-p,
.elementor-icon-list-p1 {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 35px;
}
.nav-tab-text {
  padding: 127px 80px;
}
.nav-section-content {
  background-color: #f1f1f1;
  position: relative;
}
.nav-section-content2 {
  background-color: #fed501;
  position: relative;
}
.elementor-icon-list-p {
  text-align: right;
}
.elementor-icon-list-p1 {
  text-align: left;
}
.elementor-icon-list-p2 {
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  margin-bottom: 35px;
}
.benefit-header,
.benefit-header1 {
  font-size: 25px;
  margin-bottom: 8px;
}
.benefit-header {
  text-align: right;
  color: var(--primary-color);
}
.benefit-header1 {
  text-align: left;
  color: var(--primary-color);
}
.pt-section-title,
.pt-section-title2 {
  font-size: 46px;
  line-height: 56px;
  z-index: 1;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 30px;
  margin-right: 66px;
  position: relative;
}
.elementor-icon-list-img {
  width: 80%;
  margin-top: 10%;
}
.eyn .pt-section-title {
  font-size: 40px;
  line-height: 45px;
  color: var(--primary-color);
  margin-bottom: 0;
  text-align: left;
}
.pt-section-title {
  text-align: right;
}
.pt-section-title2 {
  text-align: left;
}
.btn-link,
.btn-link:hover {
  color: var(--bg-black);
  text-decoration: none;
  font-weight: 600;
  font-size: 19px;
}
.btn-link {
  font-family: arial;
  display: flex;
  justify-content: space-between;
}
.btn-link:hover {
  border: 1px solid transparent;
}
.search-box-form2 > .login-btn2,
.search-box-form > .login-btn {
  line-height: 1;
  color: var(--primary-color) !important;
  letter-spacing: 1px;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0;
}
.card-header {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 28px 15px;
}
.card {
  margin-bottom: 38px;
  border-radius: 00px;
  border: none;
  box-shadow: 0 0 15px 0 #ccc;
}
.accordion > .card > .card-header {
  border-radius: 9px;
  margin-bottom: 0;
}
.menu-inner3 {
  width: 100%;
  text-align: center;
  display: block;
}
.nav-mobile > li {
  margin: 1px 0;
  color: #0b76b4;
  text-transform: uppercase;
  padding: 10px 0;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  border-bottom: 1px solid var(--primary-color);
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0;
  font-family: arial;
  background: #fff;
  color: #6c757de0;
  border: none;
}
.card-body-span {
  border-top: 1px solid #ccc;
  font-size: 18px;
  font-family: arial;
  padding: 41px 7px;
  line-height: 36px;
  margin: 0 20px;
}
.elementor-icon-list-img2 {
  width: 100%;
  max-width: 300px;
  float: left;
  margin-top: 16%;
  margin-bottom: 10%;
}
.carousel-item {
  height: 520px;
  position: relative;
}
.slide-heading,
.slide-heading2 {
  position: absolute;
  left: 190px;
  color: var(--bg-white);
}
.carousel-item > img {
  height: 100%;
}
.slide-heading {
  top: 40px;
  font-size: 51px;
}
.slide-heading2 {
  bottom: 98px;
  width: 40%;
  font-size: 18px;
  padding: 0;
}
.slide-heading2 > h4 {
  margin-bottom: 20px;
}
.slide-heading > span {
  font-weight: 700;
}
.search-box-form2 {
  width: 100%;
  display: flex;
}
.search-box-form2 > .login-btn2 {
  height: 48px;
  padding: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 17px;
  border: none;
  width: 95px;
  position: relative;
  left: 0;
  background: var(--bg-white);
}
.search-box-form2 .login-inp2 {
  width: 100%;
  height: 48px;
  background: var(--bg-white);
  border: 1px solid var(--bg-white);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 40px;
}
.search-box-form {
  width: 100%;
  display: flex;
  margin-left: 90px;
}
.search-box-form > .login-btn {
  height: 29px;
  padding: 0;
  border-radius: 15px;
  font-size: 10px;
  position: relative;
  top: -3px;
  left: 42px;
  background: 0 0;
}
.search-box-form .login-inp {
  width: 65%;
  height: 30px;
  border-radius: 25px;
  background: 0 0;
  border: 1px solid var(--primary-color);
  padding-left: 37px;
  font-size: 15px;
  font-family: arial;
  padding-bottom: 0;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table-striped {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.pt-comparison-table .table-striped thead th {
  border-radius: 10px;
}
.cell-tag {
  border-top-right-radius: 10px;
  color: #fff;
  position: relative;
  border-top-left-radius: 10px;
  letter-spacing: 1px;
  padding: 10px 0;
  font-weight: 500;
  top: -20px;
  background: #e67213;
  text-align: center;
}
.header-div .cell-tag {
  position: absolute;
  top: -34px;
  width: 21%;
}
.cell-tag:after {
  top: 100%;
  left: 50%;
  border: 15px solid rgba(255, 75, 54, 0);
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #e67213;
  margin-left: -15px;
  transition: 0.5s ease-in-out;
}
.cell-price {
  font-size: 35px;
}
.cell-price sup {
  font-size: 25px;
}
.cell-price sub {
  position: relative;
  left: -30px;
  font-size: 14px;
}
.table tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.table tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table-striped td,
.table-striped th {
  vertical-align: middle !important;
}
.fa-check {
  color: green;
  font-weight: 550;
}
.cell-tag-bottom {
  position: absolute;
  left: 39%;
  bottom: 1%;
}
.cell-tag-bottom a,
.cell-tag-bottom2 a,
.cell-tag-bottom3 a {
  background: #e67213;
  color: #fff;
  padding: 7px 20px;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
}
.cell-tag-bottom2 {
  position: absolute;
  left: 60.5%;
  bottom: 1%;
}
.cell-tag-bottom3 {
  position: absolute;
  left: 83%;
  bottom: 1%;
}
.child-menu,
.child-menu2 {
  position: absolute;
  top: 85%;
  left: 0;
  background: #fff;
}
.child-menu {
  margin: 0;
  border-radius: 10px 0 0 10px;
  padding: 9px 0 63px;
  box-shadow: 0 0 4px 0 #ccc;
  z-index: 10;
  color: #000;
  text-transform: capitalize;
  opacity: 1;
  cursor: pointer;
  visibility: hidden;
  transform: translateY(30px);
  transition: 0.3s ease-in;
}
.child-menu2 > li > a,
.child-menu > li > a {
  font-family: Barlow, sans-serif;
  font-size: 15px;
  padding: 8px 20px;
  display: block;
  color: #666;
  white-space: nowrap;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.child-menu2 > a > li,
.child-menu > a > li {
  width: 140px;
  padding: 6px 5px;
  font-weight: 100;
  text-align: center;
  font-family: arial;
  font-size: 13px;
}
.child-menu > a > li:hover,
.child-submenu > li:hover,
.parent-submenu:hover {
  transition: 0.5s;
  background-color: #436fe8;
  color: #fff;
}
.child-menu2 > li > a:hover,
.child-menu > li > a:hover,
.child-thirdmenu2 > li > a:hover,
.child-thirdmenu > li > a:hover {
  color: #2e4589;
  text-decoration: none;
  background: #f5f4f4;
}
.parent-menu:hover .child-menu,
.parent-menu:hover .child-menu2,
.parent-submenu:hover .child-submenu,
.parent-thirdmenu:hover .child-thirdmenu,
.parent-thirdmenu:hover .child-thirdmenu2 {
  visibility: visible;
  -webkit-transform: translateY(6px);
  -ms-transform: translateY(6px);
  transform: translateY(6px);
}
.child-menu2,
.child-submenu {
  list-style: none;
  margin: 0;
  text-transform: capitalize;
  z-index: 10;
  color: #000;
  opacity: 1;
  visibility: hidden;
  transform: translateY(30px);
  transition: 0.3s ease-in;
  cursor: pointer;
  box-shadow: 0 0 4px 0 #ccc;
}
.child-menu2 {
  border-radius: 10px;
  padding: 8px 0 9px;
}
.parent-menu,
.parent-submenu,
.parent-thirdmenu {
  display: inline-block;
  position: relative;
}
.child-menu2 > a > li:hover,
.child-thirdmenu2 > a > li:hover,
.child-thirdmenu > a > li:hover {
  background-color: #436fe8;
  color: #fff;
}
.parent-menu:hover .about-us > .drop-arrow {
  transform: rotate(180deg);
  transition: 1s;
}
.parent-submenu {
  display: flex;
  justify-content: center;
  padding: 5px 0;
}
.child-submenu {
  padding: 10px 0 112px;
  border-radius: 0;
  position: absolute;
  top: -50%;
  left: 100%;
  background: #e9ecef;
}
.child-submenu > li > a,
.child-thirdmenu2 > li > a,
.child-thirdmenu > li > a {
  font-family: Barlow, sans-serif;
  font-size: 15px;
  padding: 8px 20px;
  display: block;
  white-space: nowrap;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.child-submenu > li,
.child-thirdmenu2 > a > li,
.child-thirdmenu > a > li {
  width: 120px;
  text-align: center;
  font-size: 12px;
  font-weight: 100;
  font-family: arial;
  text-transform: capitalize;
}
.child-thirdmenu,
.child-thirdmenu2 {
  list-style: none;
  margin: 0;
  border-radius: 0 10px 10px 0;
  padding: 9px 0;
  position: absolute;
  left: 100%;
  border-top: 1px solid #dee2e6;
  background: #dee2e6;
  z-index: 10;
  color: #000;
  opacity: 1;
  transform: translateY(30px);
  transition: 0.3s ease-in;
  visibility: hidden;
}
.child-submenu > li {
  padding: 0;
}
.child-submenu > li > a:hover {
  color: #fff !important;
  text-decoration: none;
  background: var(--primary-bg-color);
}
.child-thirdmenu {
  top: -140%;
  cursor: pointer;
}
.child-thirdmenu2 > a > li,
.child-thirdmenu > a > li {
  padding: 6px 0;
}
.child-thirdmenu2 > li:hover,
.child-thirdmenu > li:hover {
  font-weight: 600;
}
.child-thirdmenu2 {
  box-shadow: 0 0 4px 0 #ccc;
  top: -41%;
}
.nav-link2,
.slider-div-team,
.slider-div2 {
  background-size: 100% 100%;
}
.nav-link10,
.nav-link10.active {
  border: none;
  font-size: 13px;
  text-align: start;
  padding: 11px 9px;
  color: #666;
  width: 100%;
}
.b2c-link > span {
  padding: 0;
  font-size: 10px;
}
.nav-link10.active {
  background: #ccc;
}
.nav-link10 {
  background-color: #efefef;
}
.ruby2 > img,
div.ruby-grid img,
ruby > img {
  position: relative;
  max-width: 100%;
  height: auto;
  display: block;
}
div.ruby-c-inline span.ruby-c-date a {
  color: #444;
  font-size: 11px;
}
span.ruby-c-content {
  padding: 11px 1px;
}
.ruby img {
  border-radius: 14px;
  padding: 10px;
  width: 100%;
}
div.ruby-c-inline,
div.ruby-c-inline2 {
  display: block;
  position: relative;
  height: 14px;
  margin: 7px 0;
}
span.ruby-c-category,
span.ruby-c-category a,
span.ruby-c-category2,
span.ruby-c-category2 a {
  font-size: 11px;
  margin: 9px 0;
  color: #444;
}
div.ruby-c-inline2 span.ruby-c-date2 a {
  color: #474343;
  font-size: 12px;
  font-weight: 700;
}
span.ruby-c-title,
span.ruby-c-title a,
span.ruby-c-title2,
span.ruby-c-title2 a {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 20px;
  margin-top: -5px;
  color: #222;
}
span.ruby-c-content2 {
  display: block;
  top: 7px;
  font-size: 12px;
  line-height: 18px;
  text-align: justify;
  padding: 11px 1px;
}
.ruby2 img {
  border-radius: 0;
  padding-top: 20px;
  padding-right: 10px;
  width: 100%;
}
.slick-slider .slick-next,
.slick-slider .slick-prev,
.slider2.slick-slider .slick-next,
.slider2.slick-slider .slick-prev {
  line-height: 0;
  position: absolute;
  height: 20px;
  border: none;
  color: #0062cc;
  top: 50%;
  padding: 0;
  display: block;
  z-index: 1;
  cursor: pointer;
  outline: 0;
  font-size: 0;
}
.ruby2 {
  border-right: 1px solid #ccc;
  display: flex;
}
.ruby-div {
  margin-top: 18px;
  margin-bottom: 45px;
}
.slick-slider .slick-prev,
.slider2.slick-slider .slick-prev {
  width: 20px;
  transform: translate(150%, -50%);
}
.slick-slider .slick-next,
.slider2.slick-slider .slick-next {
  width: 30px;
  transform: translate(-150%, -50%);
}
.slick-slider .slick-next:focus,
.slick-slider .slick-next:hover,
.slick-slider .slick-prev:focus,
.slick-slider .slick-prev:hover,
.slider2.slick-slider .slick-next:focus,
.slider2.slick-slider .slick-next:hover,
.slider2.slick-slider .slick-prev:focus,
.slider2.slick-slider .slick-prev:hover {
  color: #0062cc;
  outline: 0;
}
.slider-div {
  height: 200px;
  position: relative;
  outline: 0;
}
.slider-div > .slider-img-absolute1 {
  width: 100%;
  border-radius: 27px;
  padding: 0;
  height: 100%;
}
.slider-div2 {
  height: 210px;
  background-image: url("../../public/assest/photos/background-blue-slider.png");
  background-repeat: no-repeat;
  position: relative;
  border-radius: 17px;
  text-align: center;
  border-right: 4px solid #fff;
  outline: 0;
}
.slider-img2 {
  font-size: 14px;
  position: absolute;
  color: #fff;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  line-height: 20px;
  font-family: arial-bold;
  text-transform: uppercase;
}
.b2b-slider-div2 > .slider-img-absolute1,
.slider-div2 > .slider-img-absolute1 {
  width: 100%;
  padding: 0;
  height: 100%;
}
.slider-img-absolute-11 {
  position: absolute;
  top: 41%;
  font-size: 17px;
  width: 150px;
  color: #436fe8;
  transition: 0.3s;
  left: 23%;
}
.slider-div:hover .slider-img-absolute-11 {
  position: absolute;
  top: 41%;
  font-size: 19px;
  color: #fff;
  transition: 0.3s;
  left: 23%;
  transform: scale(1.2);
}
.slider-div:hover .slider-img-absolute-12,
.slider-img-absolute-12 {
  left: 17%;
  top: 41%;
  color: #fff;
  position: absolute;
  transition: 0.3s;
  font-size: 19px;
}
.slider-img-absolute-12 {
  width: 150px;
}
.slider-div:hover .slider-img-absolute-12 {
  transform: scale(1.2);
}
.slider-img-absolute-13 {
  position: absolute;
  top: 41%;
  width: 150px;
  font-size: 19px;
  color: #fff;
  transition: 0.3s;
  left: 31%;
}
.slider-div:hover .slider-img-absolute-13 {
  position: absolute;
  top: 41%;
  font-size: 19px;
  color: #fff;
  transition: 0.3s;
  left: 31%;
  transform: scale(1.2);
}
.slider-img-absolute-14 {
  width: 150px;
  position: absolute;
  top: 41%;
  font-size: 17px;
  color: #436fe8;
  transition: 0.3s;
  left: 35%;
}
.slider-div:hover .slider-img-absolute-14 {
  position: absolute;
  top: 41%;
  font-size: 19px;
  color: #fff;
  transition: 0.3s;
  left: 35%;
  transform: scale(1.2);
}
.slider-img-absolute-9 {
  position: absolute;
  top: 50%;
  transition: 0.3s;
  transform: translate(-50%, -50%);
  width: 150px;
  left: 50%;
}
.slider-div:hover .slider-img-absolute-9 {
  position: absolute;
  top: 50%;
  transition: 0.3s;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slider-heading-top::after,
.slider-heading::after,
.slider2.slider-heading::after {
  position: absolute;
  width: 68px;
  top: 134%;
  border-radius: 20px;
  height: 4px;
  background-color: #fed501;
  content: "";
  text-align: center;
}
.slider-heading-top {
  position: absolute;
  text-align: center;
  left: 43%;
  font-family: arial-bold;
  bottom: 9%;
  color: #436fe8;
  font-size: 29px;
  text-transform: uppercase;
  font-weight: 700;
}
.slider-heading-top::after {
  left: 31%;
}
.slider-heading {
  text-align: center;
  color: #436fe8;
  font-size: 32px;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 75px;
  font-family: arial-bold;
  margin-top: 50px;
}
.slider-heading::after,
.slider2.slider-heading::after {
  right: 48%;
}
.slick-slider .slick-next:before,
.slick-slider .slick-prev:before {
  font-family: slick;
  font-size: 33px;
  line-height: 1;
  opacity: 0.75;
  color: rgba(0, 123, 255, 0.5);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slider2.slider-div {
  height: 250px;
  background-color: #fff;
  outline: 0;
}
.slider2.slider-div > img {
  width: 100%;
  padding: 12px;
  height: 100%;
  border-radius: 25px;
}
.slider2.slider-heading {
  text-align: center;
  color: #436fe8;
  font-size: 34px;
  font-weight: 700;
  position: relative;
  margin-bottom: 50px;
  margin-top: 50px;
}
.slider2.slick-slider .slick-next:before,
.slider2.slick-slider .slick-prev:before {
  font-family: slick;
  font-size: 45px;
  line-height: 1;
  opacity: 0.75;
  color: #343a40;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.section-margin {
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
}
.box-right,
.section-margin-selecter2,
.why-choose-us-texting-div {
  margin-top: 50px;
}
.background-blue-content {
  position: absolute;
  top: 31%;
  left: 10%;
}
.background-blue-imgs {
  width: 100%;
  height: 23vh;
  text-align: center;
}
.background-blue-imgs::after {
  content: "";
  position: absolute;
  border: 1px solid #000;
}
.ourteam-what-we-do-text {
  position: relative;
  text-align: center;
}
.ourteam-what-we-do-text > .typewriter > h3 {
  font-family: montessat;
  color: #fff;
  font-size: 80px;
  position: relative;
  line-height: 77px;
  text-shadow: 5px 3px #343434;
}
.ourteam-what-we-do-text > .typewriter > h3 > span {
  display: block;
  font-size: 142px;
  line-height: 126px;
}
.ourteam-what-we-do-text > .typewriter > h3 > .do {
  display: block;
  font-size: 157px;
  line-height: 126px;
}
.ourteam-what-we-do-text::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 69px;
  border-radius: 12px;
  width: 9px;
  background-color: var(--primary-bg-color);
  height: 100%;
}
.slider-div-team {
  height: 530px;
  border-radius: 7px;
  width: 100%;
  min-height: 400px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  outline: 0;
  cursor: pointer;
  background-image: url("../../public/assest/photos/gallery/gallery3-small.jpg");
  background-repeat: no-repeat;
}
.slider-team-text {
  min-height: 350px;
}
.why-choose-us-texting {
  position: absolute;
  top: 36%;
  transform: translate(0, -50%);
  left: 67%;
  padding: 50px;
}
.methodo-img1,
.methodo-img2,
.methodo-img3,
.methodo-img4,
.methodo-img5,
.our-teams-div,
.our-teams-div > img {
  position: relative;
}
.why-choose-us-texting > h2 {
  color: #fff;
  font-size: 19px;
  line-height: 32px;
  margin-bottom: 31px;
  position: relative;
  font-family: arial;
}
.why-choose-us-texting > h2::before {
  content: "";
  width: 8px;
  height: 100%;
  border-radius: 20px;
  top: 0;
  left: -7%;
  position: absolute;
  background-color: #ffc107;
}
.why-choose-us-texting-button:hover {
  background-color: var(--primary-bg-color);
  transition: 1s;
}
.why-choose-us-texting-button {
  background-color: #fed501;
  padding: 13px 41px;
  border-radius: 5px;
}
.why-choose-us-texting-button > a > span {
  color: #000;
  margin-top: 20px;
  font-weight: 500;
}
.our-teams-div {
  width: 352px;
}
.our-teams-div > img {
  width: 120%;
  z-index: 1;
}
.our-teams-div > h1 {
  position: absolute;
  bottom: 30%;
  left: 31%;
  font-size: 30px;
  z-index: 2;
  font-weight: 700;
}
.methodo-img1 {
  width: 59%;
  margin: auto;
}
.methodo-img2 {
  width: 48%;
  margin: auto;
}
.methodo-img3 {
  width: 40%;
  margin: auto;
}
.methodo-img4 {
  width: 31%;
  margin: auto;
}
.methodo-img5 {
  width: 22%;
  margin: auto;
}
.Client-testinomial-buttom,
.slider-heading1,
.slider-heading2 {
  position: relative;
  margin-top: 50px;
}
.methodo-img-div {
  display: grid;
}
.methodo-texting {
  position: relative;
  padding: 85px 35px;
}
.methodo-texting > h3 {
  font-size: 18px;
  color: #436fe8;
  font-family: arial-bold;
  margin: 10px 0;
  position: relative;
}
.methodo-texting > h3::before {
  content: "";
  position: absolute;
  top: 25%;
  left: -3.2%;
  width: 10px;
  height: 10px;
  background-color: var(--primary-bg-color);
  border-radius: 20px;
}
.methodo-texting > p {
  font-size: 14px;
  font-family: arial;
  line-height: 22px;
  color: #6c757d;
}
.nav-item2 {
  width: 219px;
  text-align: center;
}
.nav-link2,
.nav-link2.active {
  border: none;
  background-repeat: no-repeat;
  text-transform: uppercase;
  font-size: 16px;
  height: 66px;
  background-color: #436fe8;
  width: 100%;
  font-family: arial-bold;
}
.nav-tabs2 {
  display: flex;
  justify-content: center;
  background-color: #436fe8;
  align-items: center;
}
#gears,
#title,
.Client-testinomial-buttom {
  display: inline-block;
  vertical-align: middle;
}
.nav-link2 {
  color: #fff;
  text-align: center;
}
.nav-link2.active {
  background-image: url("../../public/assest/photos/yellow-back-img3.png");
  background-size: 107% 100%;
  color: #000;
}
.slider-heading-text::after,
.slider-heading1::after {
  position: absolute;
  text-align: left;
  border-radius: 20px;
  content: "";
}
.our-team-text > h1 {
  padding: 50px 0;
  color: #436fe8;
}
.our-team-text > p {
  font-size: 19px;
  color: #1a416b;
  font-weight: 600;
}
.slider-heading1 {
  text-align: left;
  color: #436fe8;
  font-size: 28px;
  font-weight: 700;
  font-family: arial-bold;
  margin-bottom: 50px;
}
.slider-heading2 {
  text-align: left;
  font-family: arial;
  color: #436fe8;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 25px;
}
.slider-heading1::after {
  width: 68px;
  top: 127%;
  left: 10%;
  height: 4px;
  background-color: #fed501;
}
.slider-heading-text {
  position: relative;
  font-size: 18px;
  font-family: arial-bold;
  color: #6c757d;
}
.slider-heading-text::after {
  width: 63px;
  left: 0;
  height: 3px;
  background-color: #436fe87d;
  bottom: -15px;
}
.Client-testinomial-buttom {
  width: auto;
  background: #ffc107;
  color: var(--bg-white);
  font-family: arial;
  font-size: 15px;
  font-weight: 700;
  padding: 12px 45px;
  line-height: 2;
  border: none;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 00px;
  background: linear-gradient(to left, #ffc107 50%, #0062cc 50%) right;
  background-size: 200%;
  transition: 0.5s ease-out;
}
.Client-testinomial-buttom:hover,
.email-banner-div1 > h3 > a:hover,
.email-banner-div > h3 > a:hover,
.explore-plans h3 a:hover,
.security-banner-div > h3 > a:hover,
.security-org-banner-div > h3 > a:hover {
  background-position: left;
  color: #fff;
}
.client-test-images {
  margin-bottom: 29px;
  text-align: start;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 9px 0 #ccc;
}
.client-test-images > a > p {
  padding: 10px;
  font-size: 13px;
  font-family: arial-bold;
  text-transform: capitalize;
  font-weight: 500;
  color: #6c757d;
}
.banner-new-div2 > h3 > a,
.banner-new-div > h3 > a,
.branding-comminication-banner-new-div > h3 > a,
.organic-marketing-banner-new-div > h3 > a {
  padding: 11px 40px;
  transition: 0.5s ease-out;
}
.client-testimonial-texture {
  background-image: url(../../public/assest/photos/client-testimonial-texture.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0;
}
.client-test-div {
  height: 8vh;
}
.nav-link-img {
  width: 45px;
  height: 70px;
}
.banner-new-div {
  position: absolute;
  top: 10%;
  text-align: end;
  right: 7%;
}
.banner-new-div > h1 {
  font-family: arial-bold;
  line-height: 27px;
  font-size: 17px;
}
.banner-new-div > h4 {
  font-size: 41px;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 418px;
  min-height: 120px;
  line-height: 56px;
  font-family: arial-bold;
}
.banner-new-div > h2 {
  font-size: 17px;
  line-height: 26px;
  margin: 14px 0;
  font-family: arial-bold;
}
.banner-new-div2 > h3,
.banner-new-div > h3 {
  font-size: 13px;
  line-height: 26px;
  margin: 29px 1px;
  font-family: arial;
}
.banner-new-div > h3 > a {
  background-color: #000;
  border-radius: 4px;
  background: linear-gradient(to left, #000 50%, #fff 50%) right;
  background-size: 200%;
  color: #fff;
}
.b2b-banner-div > h3 > a:hover,
.b2c-banner-div > h3 > a:hover,
.banner-new-div2 > h3 > a:hover,
.banner-new-div > h3 > a:hover,
.branding-banner-new-div > h3 > a:hover,
.branding-cunsulting-banner-new-div > h3 > a:hover,
.creative-banner-div > h3 > a:hover,
.creative-marketing-banner-div > h3 > a:hover,
.hosting-banner h3 a:hover,
.organic-marketing-banner-new-div > h3 > a:hover,
.social-banner-div > h3 > a:hover,
.web-develop-banner-div > h3 > a:hover {
  background-position: left;
  color: #000;
}
.banner-new-div2 {
  position: absolute;
  top: 10%;
  text-align: start;
  left: 100px;
}
.banner-new-div2 > h4 {
  font-size: 41px;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 418px;
  min-height: 120px;
  line-height: 56px;
  color: #fff;
  font-family: arial-bold;
}
.banner-new-div2 > h2 {
  font-size: 17px;
  line-height: 26px;
  margin: 14px 0;
  color: #fff;
  font-family: arial-bold;
}
.banner-new-div2 > h3 > a {
  background-color: #000;
  border-radius: 4px;
  color: #fff;
  background: linear-gradient(to left, #fed501 50%, #fff 50%) right;
  background-size: 200%;
}
.selecter-div {
  border: 1px solid #436fe8;
  border-radius: 18px;
  color: #436fe8;
  width: 290px;
  margin: 0 21px;
  padding: 3px 12px;
  font-family: arial;
}
.section-margin-selecter {
  margin-top: 33px;
  margin-bottom: 0;
  position: relative;
}
.result-img,
.selecter-section,
.total-pay-img {
  display: flex;
  justify-content: center;
}
.selecter-section > h3 {
  font-size: 30px;
  font-weight: 700;
  color: #436fe8;
  font-family: arial;
}
.testinomial-heading {
  padding-bottom: 80px;
  color: #fff;
  font-family: arial-bold;
  font-size: 35px;
  text-align: left;
  position: relative;
}
.b2b-testinomial-heading::after,
.testinomial-heading::after {
  border-radius: 10px;
  content: "";
  position: absolute;
  top: 48%;
  left: 4%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 5px;
  background-color: #fed501;
}
.b2b-testinomial-section-name6::before,
.testinomial-section-name2::before,
.testinomial-section-name3::before,
.testinomial-section-name4::before,
.testinomial-section-name5::before,
.testinomial-section-name6::before,
.testinomial-section-name::before {
  top: 10px;
  left: 10px;
  width: 60px;
  height: 57px;
  border-radius: 5px;
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
}
.testimonial-section-margin {
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  background-image: url("../../public/assest/photos/backgroundimage-blue.png");
  background-size: 100% 100%;
  padding-top: 80px;
  padding-bottom: 100px;
}
.testimonial-slider-div {
  position: relative;
  outline: 0;
  min-height: 450px;
}
.testimonial-slider-text {
  text-align: left;
  color: #fff;
  font-family: arial;
  font-size: 16px;
  padding: 27px 35px;
}
/* .testinomial-section-name::before {
  background-image: url("../../public/assest/photos/review-logo/techmech.png");
}
.testinomial-section-name2::before {
  background-image: url("../../public/assest/photos/review-logo/ameenji.png");
}
.testinomial-section-name3::before {
  background-image: url("../../public/assest/photos/background-grey.jpeg");
}
.testinomial-section-name4::before {
  background-image: url("../../public/assest/photos/review-logo/shantiniketan.png");
}
.testinomial-section-name5::before {
  background-image: url("../../public/assest/photos/review-logo/aqua-biotech.png");
} */
/* .b2b-testinomial-section-name6::before,
.testinomial-section-name6::before {
  background-image: url("../../public/assest/photos/review-logo/snvp-school.png");
} */
/* .b2b-testinomial-section-name7::before,
.testinomial-section-name7::before {
  position: absolute;
  top: 30%;
  left: 10px;
  width: 60px;
  height: 57px;
  border-radius: 5px;
  content: "";
  background-image: url("../../public/assest/photos/review-logo/ace-cons.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.testinomial-section-name8::before,
.testinomial-section-name9::before {
  top: 20%;
  left: 10px;
  width: 60px;
  height: 57px;
  border-radius: 5px;
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
}
.testinomial-section-name8::before {
  background-image: url("../../public/assest/photos/review-logo/esennarlogo.png");
}
.testinomial-section-name9::before {
  background-image: url("../../public/assest/photos/review-logo/candeur-landmark.png");
}
.testinomial-section-name10::before {
  position: absolute;
  top: 10%;
  left: 10px;
  width: 60px;
  height: 57px;
  border-radius: 5px;
  content: "";
  background-image: url("../../public/assest/photos/review-logo/ecolife_logo.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.b2b-testinomial-section-name11::before,
.testinomial-section-name11::before {
  position: absolute;
  top: 30%;
  left: 10px;
  width: 60px;
  height: 57px;
  border-radius: 5px;
  content: "";
  background-image: url("../../public/assest/photos/review-logo/bharadwaj-packaging.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}
.b2b-testinomial-section-name12::before,
.testinomial-section-name12::before {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 60px;
  height: 57px;
  border-radius: 5px;
  content: "";
  background-image: url("../../public/assest/photos/review-logo/rrstone.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.testinomial-section-name13::before {
  position: absolute;
  top: 25%;
  left: 10px;
  width: 60px;
  height: 57px;
  border-radius: 5px;
  content: "";
  background-image: url("../../public/assest/photos/review-logo/tsg-audio-nnovations.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.b2b-testinomial-section-name14::before,
.testinomial-section-name14::before {
  position: absolute;
  top: 30%;
  left: 10px;
  width: 60px;
  height: 57px;
  border-radius: 5px;
  content: "";
  background-image: url("../../public/assest/photos/client-icon/dirak.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.testinomial-section-name15::before,
.testinomial-section-name16::before {
  top: 20px;
  left: 10px;
  height: 57px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  border-radius: 5px;
  content: "";
  width: 60px;
}
.testinomial-section-name15::before {
  background-image: url("../../public/assest/photos/review-logo/ravoos.png");
}
.testinomial-section-name16::before {
  background-image: url("../../public/assest/photos/review-logo/rajapushpa.png");
}
.b2b-testinomial-section-name17::before,
.testinomial-section-name17::before {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 60px;
  height: 57px;
  border-radius: 5px;
  content: "";
  background-image: url("../../public/assest/photos/review-logo/primex-plastics.jpg");
  background-size: contain;
  background-repeat: no-repeat;
} */
.testinomial-section-img {
  width: 70%;
  height: auto;
  border-radius: 5px;
  text-align: center;
  /* margin-top: 18px; */
  position: relative;
}
.testimg {
  position: relative;
  z-index: 9999999;
}
.testimg:after {
  position: absolute;
  content: "";
  top: -15px;
  left: 0px;
  width: 374px;
  height: 374px;
  border-radius: 5px;
  background: 0 0;
  border: 2px solid #fed501;
  z-index: -1;
}
.paymeny-amount-img {
  width: 20px;
}
.payment-amoun {
  font-size: 17px;
  font-family: "arial-bold";
  color: #09757a;
  margin-left: 5px;
  margin-right: 5px;
}
.main-slider-custome-button-next:hover,
.main-slider-custome-button-prev:hover,
.security-slider-custome-button-next:hover,
.security-slider-custome-button-prev:hover,
.slider-custome-button-next:hover,
.slider-custome-button-prev:hover {
  background-color: #fff;
  transition: 0.6s;
}
.testinomial-section-name,
.testinomial-section-name10,
.testinomial-section-name11,
.testinomial-section-name12,
.testinomial-section-name13,
.testinomial-section-name14,
.testinomial-section-name15,
.testinomial-section-name16,
.testinomial-section-name17,
.testinomial-section-name2,
.testinomial-section-name3,
.testinomial-section-name4,
.testinomial-section-name5,
.testinomial-section-name6,
.testinomial-section-name7,
.testinomial-section-name8,
.testinomial-section-name9 {
  border-radius: 5px;
  background-color: #fed501;
  padding: 20px 31px 20px 36px;
  transform: translate(-11%, -150%);
  float: right;
  transition: 1s;
}
.carousel-control-next,
.carousel-control-prev {
  bottom: 5%;
  z-index: 1;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s;
  position: absolute;
}
.testinomial-section-name10 > h3,
.testinomial-section-name11 > h3,
.testinomial-section-name12 > h3,
.testinomial-section-name13 > h3,
.testinomial-section-name14 > h3,
.testinomial-section-name15 > h3,
.testinomial-section-name16 > h3,
.testinomial-section-name17 > h3,
.testinomial-section-name2 > h3,
.testinomial-section-name3 > h3,
.testinomial-section-name4 > h3,
.testinomial-section-name5 > h3,
.testinomial-section-name6 > h3,
.testinomial-section-name7 > h3,
.testinomial-section-name8 > h3,
.testinomial-section-name9 > h3,
.testinomial-section-name > h3 {
  font-size: 16px;color: #0c36a9;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 3px;font-weight: 600;text-transform: uppercase;
}
.b2b-testinomial-section-name10 > h2,
.b2b-testinomial-section-name11 > h2,
.b2b-testinomial-section-name12 > h2,
.b2b-testinomial-section-name13 > h2,
.b2b-testinomial-section-name14 > h2,
.b2b-testinomial-section-name15 > h2,
.b2b-testinomial-section-name16 > h2,
.b2b-testinomial-section-name17 > h2,
.b2b-testinomial-section-name2 > h2,
.b2b-testinomial-section-name3 > h2,
.b2b-testinomial-section-name4 > h2,
.b2b-testinomial-section-name5 > h2,
.b2b-testinomial-section-name6 > h2,
.b2b-testinomial-section-name7 > h2,
.b2b-testinomial-section-name8 > h2,
.b2b-testinomial-section-name9 > h2,
.b2b-testinomial-section-name > h2,
.testinomial-section-name10 > h2,
.testinomial-section-name11 > h2,
.testinomial-section-name12 > h2,
.testinomial-section-name13 > h2,
.testinomial-section-name14 > h2,
.testinomial-section-name15 > h2,
.testinomial-section-name16 > h2,
.testinomial-section-name17 > h2,
.testinomial-section-name2 > h2,
.testinomial-section-name3 > h2,
.testinomial-section-name4 > h2,
.testinomial-section-name5 > h2,
.testinomial-section-name6 > h2,
.testinomial-section-name7 > h2,
.testinomial-section-name8 > h2,
.testinomial-section-name9 > h2,
.testinomial-section-name > h2 {
  font-size: 14px;font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #333;
}
.testinomial-section-img:hover .b2b-testinomial-section-name,
.testinomial-section-img:hover .testinomial-section-name,
.testinomial-section-img:hover .testinomial-section-name10,
.testinomial-section-img:hover .testinomial-section-name11,
.testinomial-section-img:hover .testinomial-section-name12,
.testinomial-section-img:hover .testinomial-section-name13,
.testinomial-section-img:hover .testinomial-section-name14,
.testinomial-section-img:hover .testinomial-section-name15,
.testinomial-section-img:hover .testinomial-section-name16,
.testinomial-section-img:hover .testinomial-section-name17,
.testinomial-section-img:hover .testinomial-section-name2,
.testinomial-section-img:hover .testinomial-section-name3,
.testinomial-section-img:hover .testinomial-section-name4,
.testinomial-section-img:hover .testinomial-section-name5,
.testinomial-section-img:hover .testinomial-section-name6,
.testinomial-section-img:hover .testinomial-section-name7,
.testinomial-section-img:hover .testinomial-section-name8,
.testinomial-section-img:hover .testinomial-section-name9 {
  transform: translate(0, -150%);
}
.carousel-control-prev {
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.carousel-control-next {
  right: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.branding-consulting-process-div,
.branding-why-div,
.branding-why-img::after,
.branding-why-img::before,
.flip-card-front2,
.flip-card-front3 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.accordion-arrow {
  color: #ffc107;
}
.readmore-up {
  color: #212529;
  box-shadow: 0 0 22px 1px #ccc;
  border-radius: 14px;
  width: 58px;
}
.branding-process-img,
.branding-why-img,
.methodology-small-img,
.why-choose-us-img,
div.ruby-grid > div.ruby-row div.ruby-col-12 {
  width: 100%;
}
.slider-custome-button-next,
.slider-custome-button-prev {
  background-color: #f8f9faa3;
  color: #0062cc;
  width: 40px;
  padding: 5px;
  height: 40px;
  text-align: center;
  bottom: 12%;
}
.slider-custome-button-prev {
  position: absolute;
  display: block;
  border-radius: 3px;
  left: 5%;
  z-index: 2;
  cursor: pointer;
}
.slider-custome-button-next {
  position: absolute;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  left: 10%;
}
.slider-custome-button-next2,
.slider-custome-button-prev2 {
  bottom: 45%;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  position: absolute;
  color: #000;
}
.yahoo-img {
  width: 120%;
}
.slider-custome-button-prev2 {
  z-index: 2;
  left: 5%;
}
.slider-custome-button-next2 {
  right: 3%;
}
.slider-custome-button-next2:hover,
.slider-custome-button-prev2:hover {
  transition: 0.6s;
}
.header-what-we-do {
  text-align: center;
  font-family: arial-bold;
  font-size: 40px;
  padding-top: 50px;
  padding-bottom: 20px;
  color: #436fe8;
}
.passage-what-we-do {
  text-align: center;
  font-size: 25px;
  font-family: arial;
  color: #495057d4;
  font-weight: 700;
  padding-bottom: 10px;
  padding-top: 50px;
  letter-spacing: 2px;
}
.passage-methodology2,
.passage-what-we-do2 {
  color: #6c757d;
  font-family: arial;
  text-align: center;
}
.passage-what-we-do2 {
  font-size: 16px;
  padding: 10px 0;
  line-height: 32px;
}
.passage-methodology2 {
  font-size: 20px;
  padding-top: 60px;
  line-height: 44px;
  padding-bottom: 10px;
}
.heading-methodology2::after {
  content: "";
  position: absolute;
  bottom: 16%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 302px;
  border-radius: 10px;
  height: 5px;
  background-color: #000;
}
.heading-methodology2 {
  color: var(--primary-bg-color);
  font-size: 43px;
  text-align: center;
  text-transform: uppercase;
  padding: 47px 0;
  font-family: arial-bold;
  position: relative;
}
.header-div-why-choose-us {
  display: flex;
  transform: translateY(-50%);
  justify-content: center;
}
.header-why-choose-us {
  background-color: #fed501;
  padding: 20px 200px;
  text-transform: uppercase;
  border-radius: 25px;
  font-size: 30px;
  transform: translateY(-19%);
  font-family: arial-bold;
  box-shadow: 0 0 8px 0 #adb5bd;
  font-weight: 700;
}
.combined-img {
  width: 100px;
  margin-left: 50px;
}
.toggle-icon {
  text-align: left;
  margin-top: 18px;
  margin-bottom: 12px;
  margin-left: 17px;
}
.toggle-icon > a > img,
div.ruby-grid > div.ruby-row div.ruby-col-6 {
  width: 50%;
}
.accordian-summery {
  background-color: #fff;
  border: none;
}
.accordion-anchor-btn {
  margin-left: 10px;
}
.toggle-media-div {
  margin-left: 15px;
}
.toggle-media-icon {
  color: #0062cc;
  padding: 4px;
  background-color: #ffc107;
  border-radius: 50px;
  margin-left: 7px;
}
.toggle-media-copyright > h2 {
  font-size: 12px;
  text-align: left;
  padding: 10px 15px;
  color: #0062cc;
  line-height: 21px;
}
.branding-banner {
  position: relative;
  border-top: 0.5px solid #adb5bd;
  background-color: #436fe8;
}
.background-color,
.background-color4,
.background-color8 {
  background-color: #95cde1d1;
  color: #fff;
  padding: 9px 3px;
  opacity: 0.8;
  animation: 2s infinite animated;
  position: absolute;
  cursor: pointer;
}
.branding-banner-girl-img {
  position: absolute;
  top: -3%;
  right: 3%;
  max-width: 59%;
}
.branding-banner-new-div {
  position: absolute;
  top: 15%;
  text-align: start;
  left: 11%;
}
.branding-banner-new-div > h1 {
  font-size: 20px;line-height: 30px;
  padding-top: 20px;
  color: #fff;
  font-family: arial;
}
.branding-banner-new-div > h4 {
  font-size: 49px;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 493px;
  color: #e9ecef;
  min-height: 120px;
  line-height: 56px;
  font-family: arial-bold;
}
.b2c-banner-div > h3,
.branding-banner-new-div > h3,
.branding-comminication-banner-new-div > h3,
.branding-cunsulting-banner-new-div > h3,
.creative-marketing-banner-div > h3,
.email-banner-div1 > h3,
.email-banner-div > h3,
.organic-marketing-banner-new-div > h3,
.security-banner-div > h3,
.security-org-banner-div > h3,
.social-banner-div > h3,
.web-develop-banner-div > h3 {
  font-size: 16px;
  line-height: 26px;
  margin: 29px 1px;
  font-family: arial;
}
.branding-banner-new-div > h3 > a,
.branding-cunsulting-banner-new-div > h3 > a,
.creative-banner-div > h3 > a,
.creative-marketing-banner-div > h3 > a {
  padding: 11px 40px;
  border-radius: 23px;
  background: linear-gradient(to left, #fed501 50%, #fff 50%) right;
  color: #000;
  background-size: 200%;
  transition: 0.5s ease-out;
}
.standing-out-div > h4 {
  font-size: 25px;
  letter-spacing: 0;
  line-height: 35px;
  color: #436fe8;
  font-family: arial;
  text-align: center;
}
.standing-out-div > h5 {
  color: #424242;
  font-family: arial;
  font-size: 44px;
  text-align: center;
  font-weight: 700;
}
.ads-batch-div1,
.branding-process-text1,
.branding-why-text1 {
  text-align: center;
}
.branding-icon1 {
  position: absolute;
  top: 59%;
  right: 46%;
}
.branding-icon1 > img {
  animation: 3s infinite heart-beats;
  width: 48%;
  border-radius: 70%;
  background-color: #fff;
  border: 1px solid #fff;
}
.background-color8 {
  border: none;
  top: 51%;
  right: 8%;
  text-align: center;
  border-radius: 50px;
  width: 86px;
  height: 87px;
}
.branding-icon8 > img {
  width: 88%;
  border-radius: 59%;
  border: 2px solid #fff;
}
.background-color4 {
  border: none;
  top: 2%;
  right: 1%;
  text-align: center;
  border-radius: 50px;
  width: 71px;
  height: 71px;
}
.branding-icon4 > img {
  width: 80%;
  border-radius: 59%;
  border: 2px solid #fff;
}
.background-color {
  border: none;
  top: 16%;
  right: 9%;
  text-align: center;
  border-radius: 50px;
  width: 78px;
  height: 74px;
}
.branding-icon2 > img {
  width: 78%;
  border-radius: 59%;
  border: 2px solid #fff;
}
.branding-icon3 {
  position: absolute;
  top: 41%;
  right: -5%;
}
.branding-icon3 > img {
  animation: 2s infinite heart-beats;
  width: 36%;
  border-radius: 59%;
  border: 2px solid #fff;
}
.branding-icon7 {
  position: absolute;
  top: 46%;
  right: 37%;
}
.branding-icon7 > img {
  animation: 2s infinite heart-beats;
  width: 50%;
  border-radius: 59%;
  border: 2px solid #fff;
}
.branding-consulting-process-img > img,
.branding-process-img > img,
.branding-why-img > img {
  width: 100%;
  position: relative;
}
@keyframes animated {
  0% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes heart-beats {
  0% {
    transform: scale(1);
    background-color: #000;
  }
  7% {
    transform: scale(1.2);
  }
  12% {
    transform: scale(1.1);
  }
  20% {
    transform: scale(1.3);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.99);
  }
}
.branding-why-div {
  background-image: url("../../public/assest/photos/branding/branding-why-banner.png");
  /* height: 470px; */padding-bottom: 100px;
}
.branding-why-img::before {
  content: "";
  position: absolute;
  background-image: url("../../public/assest/photos/branding/branding-yellow-back.png");
  top: -10%;
  width: 552px;
  height: 397px;
  left: 8%;
}
.branding-why-img::after {
  content: "";
  position: absolute;
  background-image: url("../../public/assest/photos/branding/branding-yellow-path.png");
  bottom: -5%;
  left: 0.5%;
  width: 50px;
  height: 50px;
}
.branding-why-img > img {
  box-shadow: 0 0 10px 1px #6c757d;
}
.branding-why-text,
.standard-step-p {
  text-align: end;
}
.brndng-p{color: #fff;font-family: 'arial';text-align: center;}
.brndng-p p{font-size: 22px;
  margin-bottom: 30px;
  text-transform: capitalize;
  line-height: 35px;}
.branding-why-text > h4 {
  color: #fed501;
  font-family: arial-bold;
  padding-bottom: 38px;
  font-size: 35px;
  text-transform: uppercase;
}
.branding-why-text > h2 {
  font-size: 19px;
  padding-bottom: 26px;
  font-family: arial;
  padding-left: 60px;
  font-weight: 500;
  line-height: 29px;
  color: #fff;
}
.branding-process-div {
  min-height: 650px;
}
.branding-process-text {
  text-align: start;
  padding-top: 48px;
}
.branding-process-text > h4 {
  color: #37474f;
  font-family: arial-bold;
  font-size: 33px;
  padding-top: 30px;
}
.cart-Quatity-div {
  border: 1px solid #000;
  width: 135px;
  background-color: #eceff8;
}
.branding-process-text > h2 {
  color: #37474f;
  font-family: arial-bold;
  font-size: 44px;
  padding-bottom: 20px;
  text-transform: uppercase;
}
.branding-process-text > h3 {
  color: #37474f;
  font-family: arial-bold;
  font-size: 28px;
  padding-bottom: 10px;
  text-transform: uppercase;
}
.branding-process-text > p {
  font-size: 20px;
  padding-bottom: 26px;
  font-family: arial;
  font-weight: 500;
  padding-right: 50px;
  line-height: 32px;
  color: #436fe8;
}
.branding-service-div {
  background-color: rgba(0, 123, 255, 0.25);
}
.branding-service-text1 {
  display: flex;
  margin-bottom: 123px;
  justify-content: space-evenly;
  background-color: #436fe8;
  height: 400px;
}
.branding-service-text2 > h4 {
  color: #436fe8;
  padding-top: 41px;
  font-size: 33px;
  font-family: arial-bold;
}
.branding-service-text2 > h2 {
  color: #436fe8;
  padding-bottom: 150px;
  font-size: 36px;
  text-transform: uppercase;
  font-family: arial-bold;
}
.branding-service1 {
  width: 270px;
}
.flip-card {
  background-color: transparent;
  width: 300px;
  height: 450px;
  transform: translateY(-20%);
  perspective: 1000px;
}
.creative-flip-card:focus,
.flip-card:focus {
  outline: 0;
}
.creative-flip-card:focus .flip-card-inner,
.creative-flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner,
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.creative-flip-card-front3,
.flip-card-back,
.flip-card-front2,
.flip-card-front3 {
  position: absolute;
  width: 100%;
  height: 100%;
}
.flip-card-front2,
.flip-card-front3 {
  border-radius: 15px;
  color: #fff;
  z-index: 2;
  background-color: #ffc107;
}
.creative-flip-card-front3 > img,
.flip-card-front2 > img,
.flip-card-front3 > img {
  width: 90%;
  margin-top: 50px;
  border-radius: 0 50px;
}
.flip-card-front2 > h4,
.flip-card-front3 > h4 {
  font-family: arial-bold;
  font-size: 20px;
  margin-top: 37px;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #000;
}
.creative-flip-card-front3 > h3,
.flip-card-front2 > h3,
.flip-card-front3 > h3 {
  position: absolute;
  bottom: 12%;
  color: #000;
  font-family: arial-bold;
  box-shadow: 0 0 4px 0 #ccc;
  text-transform: uppercase;
  font-size: 13px;
  transform: translate(-50%, -50%);
  left: 50%;
  border-radius: 15px;
  padding: 10px 37px;
  background-color: #fed501;
}
.branding-quick-link,
.branding-quick-link > p {
  text-align: center;
  color: #303e45;
  font-family: arial-bold;
}
.branding-quick-link {
  line-height: 39px;
  font-size: 36px;
  text-transform: uppercase;
  margin-top: 40px;
}
.branding-quick-link > p {
  font-size: 24px;
  margin-bottom: 40px;
}
.branding-cunsulting-banner-new-div {
  position: absolute;
  top: 8%;
  text-align: start;
  left: 8%;
}
.branding-cunsulting-banner-new-div > h1 {
  position: relative;
  font-size: 7vh;
  color: #252839;
  -webkit-text-stroke: 0.3vw #383d52;
  text-transform: uppercase;
}
.performance-sem-banner-div > h1,
.performance-smm-banner-div > h1 {
  -webkit-text-stroke: 0.3vw transparent;
  -webkit-text-stroke: 0.3vw transparent;
}
.branding-cunsulting-banner-new-div > h1::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  color: #fff;
  -webkit-text-stroke: 0vw #383d52;
  overflow: hidden;
  animation: 6s linear infinite animate;
}
.branding-comminication-banner-new-div > h2,
.branding-cunsulting-banner-new-div > h2 {
  font-size: 21px;
  color: #6c757d;
  line-height: 56px;
  font-family: arial-bold;
}
.branding-consulting-banner > h4 {
  position: absolute;
  bottom: 5%;
  left: 50%;
  font-size: 23px;
  letter-spacing: 0;
  line-height: 40px;
  width: 1500px;
  color: #fff;
  font-family: arial;
  transform: translate(-50%, -50%);
  text-align: center;
}
.branding-consulting-banner > h5,
.branding-consulting-banner > h5 > p {
  color: #fed501;
  font-family: arial;
  text-align: center;
  font-weight: 700;
  position: absolute;
  transform: translate(-50%, -50%);
}
.branding-consulting-banner > h5 {
  bottom: 22%;
  left: 36%;
  font-size: 44px;
}
.branding-consulting-banner > h5 > p {
  bottom: 23%;
  left: 50%;
  font-size: 28px;
  line-height: 60px;
}
.branding-consulting-process-div {
  background-image: url("../../public/assest/photos/branding/branding-process-back-img.png");
  height: 550px;
}
.branding-consulting-process-text1 {
  width: 100%;
  max-width: 400px;
}
.branding-consulting-process-img {
  width: 100%;
  max-width: 500px;
  margin-bottom: 50px;
  margin-top: 50px;
}
.branding-consulting-process-text {
  text-align: end;
  padding-right: 130px;
}
.branding-consulting-process-text > h4 {
  color: #37474f;
  font-family: arial-bold;
  font-size: 33px;
  padding-top: 90px;
}
.branding-consulting-process-text > h2 {
  color: #37474f;
  font-family: arial-bold;
  font-size: 45px;
  text-transform: uppercase;
  padding-bottom: 20px;
}
.branding-consulting-process-text > p {
  font-size: 18px;
  padding-bottom: 26px;
  font-family: arial;
  font-weight: 500;
  line-height: 32px;
  color: #436fe8;
}
.branding-pro-img {
  text-align: center;
  margin: auto;
  padding: 26px 0;
}
.branding-consulting-what,
.searchArea2 button:hover,
.yellow-section {
  background-color: #fed501;
}
.branding-consulting-what-heading {
  padding: 35px 100px;
}
.branding-consulting-what-heading > h4 {
  color: #436fe8;
  font-size: 37px;
  font-family: arial-bold;
}
.branding-consulting-what-heading > h2 {
  color: #436fe8;
  font-size: 60px;
  font-family: arial-bold;
  text-transform: capitalize;
}
.branding-consulting-what-text {
  overflow-y: scroll;
  direction: rtl;
  height: 450px;
  margin-left: 60px;
  margin-bottom: 54px;
  padding: 20px 45px;
}
.branding-consulting-what-text > h2,
.branding-consulting-what-text > p {
  font-size: 19px;
  font-family: arial;
  margin-bottom: 24px;
  position: relative;
  line-height: 27px;
}

.branding-consulting-what-text > p > span{
  font-weight: 600;font-size: 18px;
}

.performance-div2 > h4,
.performance-div2 > p {
  margin-bottom: 20px;
  font-family: arial-bold;
}
.branding-consulting-what-text > h2::before,
.branding-consulting-what-text > p::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #0062cc;
  top: 13%;
  border-radius: 24px;
  position: absolute;
  cursor: pointer;
  left: -4%;
}
.branding-consulting-what-text::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}
.branding-consulting-what-text::-webkit-scrollbar-track {
  background-color: #ccc;
  border-radius: 10px;
  cursor: pointer;
}
.branding-consulting-what-text::-webkit-scrollbar-thumb {
  background: var(--primary-bg-color);
  border-radius: 10px;
  cursor: pointer;
}
.branding-consulting-what-text::-webkit-scrollbar-thumb:hover {
  background: var(--primary-bg-color);
  cursor: pointer;
}
.branding-comminication-banner-new-div {
  position: absolute;
  top: 8%;
  text-align: end;
  right: 8%;
}
.branding-comminication-banner-new-div > h1 {
  font-size: 44px;
  font-weight: 700;
  text-transform: uppercase;
  color: #6c757d;
  min-height: 123px;
  line-height: 60px;
  font-family: arial-bold;
}
.branding-comminication-banner-new-div > h3 > a {
  border-radius: 23px;
  background: linear-gradient(to left, #fed501 50%, #fff 50%) right;
  color: #000;
  background-size: 200%;
  border: 2px solid #ffc107;
}
.branding-comminication-banner-new-div > h3 > a:hover {
  background-position: left;
  color: #000;
  border: 2px solid #ffc107;
}
.performance-banner-div {
  position: absolute;
  top: 16%;
  text-align: start;
  left: 8%;
}
.performance-banner-div > h1 {
  color: #343a40;
  font-size: 47px;
  font-family: arial-bold;
  text-transform: uppercase;
  line-height: 62px;
}
.performance-banner-div > h2,
.performance-sem-banner-div > h2,
.performance-smm-banner-div > h2 {
  font-size: 21px;
  color: #000;
  line-height: 56px;
  font-family: arial-bold;
}
.career-dont-see-div > h2 > span,
.ideas-red {
  color: red;
}
.performance-sem-subdiv,
.performance-smm-subdiv,
.performance-subdiv {
  position: relative;
  margin-bottom: 150px;
}
.performance-subdiv > div {
  position: absolute;
  top: 15%;
  transform: translate(-50%, -50%);
  left: 50%;
  background-color: #e9ecef;
  width: 90%;
  border-radius: 25px;
  height: 100%;
  min-height: 250px;
  padding: 40px;
}
.domain-extention-plan-div:hover,
.org-sem-slider:hover,
.performance-sem-subdiv > div:hover,
.performance-smm-subdiv > div:hover,
.performance-subdiv > div:hover,
.rhombus69:hover,
.security-circle-div:hover,
.slider-div7:hover {
  background-color: #fed501;
  transition: 1s;
}
.performance-subdiv > div > h4 {
  font-size: 25px;
  font-family: arial-bold;
}
.performance-subdiv > div > p {
  font-size: 17px;
  font-family: arial;
  line-height: 27px;
  margin-top: 10px;
}
.performance-div2 > h4 {
  line-height: 52px;
  color: #436fe8;
  font-size: 34px;
  text-transform: uppercase;
}
.performance-div2 > p {
  font-size: 17px;font-family: 'arial';
  line-height: 27px;
  color: #333;
}
.performance-div2 {
  padding: 50px 100px;
}
.FAQs-heading-section,
.domain-second-section,
.performance-div3,
.web-dev-blue-section {
  background-color: var(--primary-bg-color);
}
.performance-div3-sec1 > h2 {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-family: arial-bold;
  padding-top: 50px;
}
.performance-div3-sec1-img {
  width: 70%;
  float: right;
}
#rhombus {
  position: relative;
  left: -23px;
  top: 53px;
  border-radius: 10px;
  width: 250px;
  height: 250px;
  background: #f8f9fa;
  box-shadow: 0 0 14px 0 #6c757d;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
#rhombus2,
#rhombus3 {
  transform: rotate(45deg);
  position: relative;
  width: 250px;
  border-radius: 10px;
  height: 250px;
  box-shadow: 0 0 14px 0 #6c757d;
  background: #f8f9fa;
}
#rhombus2:hover,
#rhombus3:hover,
#rhombus4:hover,
#rhombus:hover {
  background-color: #fed501;
  transition: 0.6s;
}
#rhombus2 {
  left: -74px;
  transform: rotate(45deg);
}
#rhombus3 {
  left: 30px;
  transform: rotate(45deg);
}
#rhombus4 {
  position: relative;
  left: -23px;
  top: -51px;
  width: 250px;
  border-radius: 10px;
  height: 250px;
  background: #f8f9fa;
  box-shadow: 0 0 14px 0 #6c757d;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
#rhombus2 > div,
#rhombus3 > div,
#rhombus4 > div,
#rhombus > div {
  transform: rotate(-45deg);
  text-align: center;
  position: relative;
}
#rhombus4 > div,
#rhombus > div {
  top: 60px;
}
#rhombus2 > div,
#rhombus3 > div {
  top: 45px;
}
#rhombus2 > div > h3,
#rhombus3 > div > h3,
#rhombus4 > div > h3,
#rhombus > div > h3 {
  font-size: 18px;
  margin-bottom: 10px;
  font-family: arial-bold;
}
#rhombus2 > div > p,
#rhombus3 > div > p,
#rhombus4 > div > p,
#rhombus > div > p,
.web-blue-div-white > p,
.web-blue-div1 > p,
.web-blue-div2 > p {
  font-size: 17px;
  font-family: arial;
}
.slider-div7 {
  background-color: #f8f9fa;
  margin: 20px 60px;
  padding: 58px 40px;
  height: 100%;
  min-height: 329px;
  width: 75%;
  border-radius: 20px;
  box-shadow: 0 0 17px 0 #adb5bd;
  text-align: center;
  cursor: pointer;
}
.slider-div7 > div {
  display: flex;
  margin-bottom: 50px;
  justify-content: center;
}
.organic-marketing-banner-new-div > div > .p2,
.organic-marketing-banner-new-div > div > p {
  margin-bottom: 20px;
  text-align: start;
  font-family: arial;
  font-size: 15px;
  background-color: #fff;
}
.slider-div7 > div img {
  width: 107px;
}
.slider-div77-text > h4 {
  color: #436fe8;
  text-align: center;
  font-family: arial-bold;
  margin-top: 60px;
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.slider-div77-text > h2 {
  text-align: center;
  font-size: 19px;
  margin: 10px 0;
  font-family: arial;
}
.slider-div77-text > p {
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
  font-family: arial;
}
.slider-div7 > h4 {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 30px;
  text-transform: capitalize;
  font-family: arial-bold;
}
.searchArea .inputDiv,
.searchArea button,
.searchArea input,
.searchArea2 .inputDiv,
.searchArea2 button,
.searchArea2 input {
  height: 40px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
}
.searchArea button {
  float: right;
  margin-left: -25px;
  border: 1px solid #436fe8;
  background-color: #436fe8;
  padding: 0 40px;
  border-radius: 25px;
  color: #fff;
  font-family: arial-bold;
  position: relative;
  z-index: 4;
}
.searchArea .inputDiv {
  overflow: hidden;
  border: 2px solid #436fe8;
  border-radius: 25px 0 0 25px;
}
.searchArea input {
  position: relative;
  border: 0;
  z-index: 2;
  padding: 6px 16px;
  width: 400px;
  height: 40px;
}
.performance-footer-div-text {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 50%;
  top: 40%;
  left: 50%;
  font-family: arial;
  padding: 40px;
  height: 250px;
  background-color: #f8f9fa;
  display: grid;
  align-items: center;
  border: 1px solid #436fe8;
  color: #6c757d;
  justify-content: space-around;
  border-radius: 20px;
}
.performance-footer-div-text > h4 {
  font-size: 30px;
  text-align: center;
  font-family: arial;
}
.h-max {
  height: 80vh;
  max-width: 650px;
  margin: 0 auto;
}
.rhombus69 {
  position: absolute;
  border-radius: 10px;
  margin: -10px auto;
  padding: 65px 0;
  text-align: center;
  width: 100%;
  max-width: 250px;
  background: #f8f9fa;
  box-shadow: 0 0 14px 0 #6c757d;
}
.ads-batch,
.organic-market-service-div {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.rho1 {
  top: 0;
  left: 48%;
  transform: translateX(-50%) rotate(45deg);
}
.rho2 {
  top: 51%;
  left: 0;
  transform: translateY(-50%) rotate(45deg);
}
.rho3 {
  bottom: 0;
  left: 49%;
  transform: translateX(-50%) rotate(45deg);
}
.rho4 {
  top: 53%;
  right: 20px;
  transform: translateY(-50%) rotate(45deg);
}
.rotate {
  transform: rotate(-45deg);
  min-height: 110px;
}
.rotate > h3 {
  font-size: 18px;
  font-family: arial-bold;
}
.rotate > p {
  font-size: 14px;
  font-size: "arial";
}
.performance-sem-banner-div {
  position: absolute;
  top: 16%;
  text-align: start;
  right: 5%;
}
.performance-sem-banner-div > h1 {
  position: relative;
  font-size: 9vh;
  color: #000;
  text-align: end;
  font-family: arial-bold;
}
#gear10,
#gear13,
#gear14,
#gear15,
#gear3,
#gear9,
#gears-static,
#level,
#shadow10,
#shadow13,
#shadow14,
#shadow15,
#shadow3,
.performance-sem-subdiv > div,
.performance-smm-banner-div,
.performance-smm-subdiv > div {
  position: absolute;
}
.performance-sem-subdiv > div,
.performance-smm-subdiv > div {
  top: 15%;
  transform: translate(-50%, -50%);
  left: 50%;
  background-color: #e9ecef;
  width: 90%;
  text-align: center;
  border-radius: 25px;
  height: 100%;
  min-height: 250px;
  padding: 40px;
}
.performance-sem-div2 > img {
  width: 70%;
}
.ads-batch {
  background-color: #fed501;
  background-image: url("../../public/assest/photos/performance-marketing/ads-batch-back.png");
}
.ads-batch-div1 > h4 {
  font-size: 38px;
  font-family: arial-bold;
  padding: 40px 0;
}
.ads-batch-div1 > p {
  font-size: 20px;
  font-family: arial;
}
.ads-batch-div2 {
  text-align: center;
  padding: 20px;
}
.ads-batch-div2 > img {
  padding-top: 34px;
}
.ads-batch-div2 > h4 {
  font-size: 22px;
  font-family: arial-bold;
  margin-top: 4px;
  margin-bottom: 30px;
}
.branding-sem-consulting-what-heading > h4 {
  color: #fed501;
  font-size: 35px;
  font-family: arial-bold;
}
.branding-sem-consulting-what-heading > h2 {
  color: #fed501;
  font-size: 60px;
  font-family: arial-bold;
  text-transform: capitalize;
}
.branding-sem-consulting-what-text > h2,
.branding-sem-consulting-what-text > p {
  font-size: 19px;
  font-family: arial;
  margin-bottom: 24px;
  position: relative;
  color: #fff;
  line-height: 27px;
}
.branding-sem-consulting-what-text > p > span {
  font-size: 18px;
}

.branding-sem-consulting-what-heading {
  padding: 40px 100px;
}
.branding-sem-consulting-what-text {
  overflow-y: scroll;
  /* direction: rtl; */
  height: 450px;
  margin-left: 60px;
  margin-bottom: 40px;
  padding: 20px 45px;
}
.branding-sem-consulting-what-text > h2::before,
.branding-sem-consulting-what-text > p::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #fed501;
  top: 13%;
  border-radius: 24px;
  position: absolute;
  cursor: pointer;
  left: -4%;
}
.branding-sem-consulting-what-text::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}
.branding-sem-consulting-what-text::-webkit-scrollbar-track {
  background-color: #ddd;
  border-radius: 10px;
  cursor: pointer;
}
.branding-sem-consulting-what-text::-webkit-scrollbar-thumb {
  background: #fed501;
  border-radius: 10px;
  cursor: pointer;
}
.branding-sem-consulting-what-text::-webkit-scrollbar-thumb:hover {
  background: #fed501;
  cursor: pointer;
}
.performance-smm-banner-div {
  top: 31%;
  text-align: start;
  left: 24%;
  transform: translate(-50%, -50%);
}
.performance-smm-banner-div > h1 {
  text-align: end;
  position: relative;
  font-size: 9vh;
  color: #f8f9fa;
  text-shadow: 1px;
  text-align: start;
  font-family: arial-bold;
}
.performance-sem-subdiv > div > h4,
.performance-smm-subdiv > div > h4 {
  font-size: 20px;
  text-align: center;
  font-family: arial-bold;
  margin-top: 13px;
}
.smm-ads-batch-div2 {
  text-align: center;
  background-color: #f8f9fa;
  margin: 30px;
  border-radius: 15px;
  min-height: 350px;
}
.smm-ads-batch-div2:hover {
  background-color: #436fe8;
  transition: 1s;
  color: #fff;
}
.smm-ads-batch-div2 > h4 {
  font-family: arial-bold;
  font-size: 16px;
  padding: 22px 0;
  text-transform: capitalize;
}
.smm-ads-batch-div2 > p {
  font-size: 15px;
  line-height: 24px;
  background: #fff;
  min-height: 250px;
  color: #000;
  border-radius: 12px;
  font-family: arial;
  padding: 15px 20px;
  margin: 0 20px 10px;
}
.performance-smm-subdiv > div > img,
div.ruby-grid > div.ruby-row div.ruby-col-9 {
  width: 75%;
}
.typewriter h3 {
  font-size: 41px;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 418px;
  min-height: 120px;
  line-height: 56px;
  color: #000;
  font-family: monospace;
  overflow: hidden;
  white-space: nowrap;
}
.organic-marketing-banner-new-div > h2,
.pink,
.question-div > h2,
.question-div > h4,
.videos-text > h4,
.voilet {
  font-family: arial-bold;
}
@keyframes typing {
  to {
    width: 100%;
  }
  from {
    width: 0%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}
#level {
  width: 100%;
  height: 1px;
  top: 50%;
}
#content {
  text-align: center;
  margin-top: -257px;
}
#gears {
  width: 478px;
  height: 655px;
  position: relative;
}
#gears-static {
  background: url(../../public/assest/photos/branding/our-process-img.png) -363px -903px
    no-repeat;
  width: 329px;
  height: 602px;
  bottom: 5px;
  right: 0;
  opacity: 0.4;
}
#title {
  color: #9eb7b5;
  width: 43%;
}
#title h1 {
  font-family: PTSansNarrowBold, sans-serif;
  font-size: 3.6em;
  text-shadow: rgba(0, 0, 0, 0.36) 7px 7px 10px;
}
#title p {
  font-family: sans-serif;
  font-size: 1.2em;
  line-height: 148%;
  text-shadow: rgba(0, 0, 0, 0.36) 1px 1px 0;
}
.shadow {
  -webkit-box-shadow: 4px 7px 25px 10px rgba(43, 36, 0, 0.36);
  -moz-box-shadow: 4px 7px 25px 10px rgba(43, 36, 0, 0.36);
  box-shadow: 4px 7px 25px 10px rgba(43, 36, 0, 0.36);
}
#gear15 {
  background: url(../../public/assest/photos/branding/our-process-img.png) 0 -993px
    no-repeat;
  width: 321px;
  height: 321px;
  left: 45px;
  top: 179px;
  -webkit-animation: 24s linear infinite rotate-back;
  -moz-animation: 24s linear infinite rotate-back;
  -ms-animation: rotate-back 24000ms linear infinite;
  animation: 24s linear infinite rotate-back;
}
#shadow15 {
  width: 306px;
  height: 306px;
  -webkit-border-radius: 153px;
  -moz-border-radius: 153px;
  border-radius: 153px;
  left: 52px;
  top: 186px;
}
#gear14 {
  background: url(../../public/assest/photos/branding/our-process-img.png) 0 -856px
    no-repeat;
  width: 87px;
  height: 87px;
  left: 162px;
  top: 296px;
}
#shadow14 {
  width: 70px;
  height: 70px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  left: 171px;
  top: 304px;
}
#gear13 {
  background: url(../../public/assest/photos/branding/our-process-img.png) 0 -744px
    no-repeat;
  width: 62px;
  height: 62px;
  left: 174px;
  top: 309px;
  -webkit-animation: 8s linear infinite rotate;
  -moz-animation: 8s linear infinite rotate;
  -ms-animation: rotate 8000ms linear infinite;
  animation: 8s linear infinite rotate;
}
#shadow13 {
  width: 36px;
  height: 36px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  left: 187px;
  top: 322px;
}
#gear10 {
  background: url(../../public/assest/photos/branding/our-process-img.png) 0 -184px
    no-repeat;
  width: 122px;
  height: 122px;
  left: 175px;
  top: 0;
  -webkit-animation: 8s linear infinite rotate-back;
  -moz-animation: 8s linear infinite rotate-back;
  -ms-animation: rotate-back 8000ms linear infinite;
  animation: 8s linear infinite rotate-back;
}
#shadow10 {
  width: 86px;
  height: 86px;
  -webkit-border-radius: 43px;
  -moz-border-radius: 43px;
  border-radius: 43px;
  left: 193px;
  top: 18px;
}
#gear3 {
  background: url(../../public/assest/photos/branding/our-process-img.png) 0 -1493px
    no-repeat;
  width: 85px;
  height: 84px;
  left: 194px;
  top: 19px;
  -webkit-animation: 10s linear infinite rotate;
  -moz-animation: 10s linear infinite rotate;
  -ms-animation: rotate 10000ms linear infinite;
  animation: 10s linear infinite rotate;
}
#shadow3 {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  left: 206px;
  top: 31px;
}
#gear9 {
  background: url(../../public/assest/photos/branding/our-process-img.png) -371px -280px
    no-repeat;
  width: 234px;
  height: 234px;
  left: 197px;
  top: 96px;
  -webkit-animation: 12s linear infinite rotate;
  -moz-animation: 12s linear infinite rotate;
  -ms-animation: rotate 12000ms linear infinite;
  animation: 12s linear infinite rotate;
}
#gear6,
#gear7 {
  animation: 10s linear infinite rotate-back;
  -moz-animation: 10s linear infinite rotate-back;
  -ms-animation: rotate-back 10000ms linear infinite;
  position: absolute;
}
#shadow9 {
  width: 200px;
  height: 200px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  position: absolute;
  left: 214px;
  top: 113px;
}
#gear7 {
  background: url(../../public/assest/photos/branding/our-process-img.png) -371px
    0 no-repeat;
  width: 108px;
  height: 108px;
  left: 260px;
  top: 159px;
  animation: 10s linear infinite rotate-back;
}
#shadow7 {
  width: 76px;
  height: 76px;
  -webkit-border-radius: 38px;
  -moz-border-radius: 38px;
  border-radius: 38px;
  position: absolute;
  left: 276px;
  top: 175px;
}
#gear6 {
  background: url(../../public/assest/photos/branding/our-process-img.png) 0 -1931px
    no-repeat;
  width: 134px;
  height: 134px;
  left: 305px;
  bottom: 212px;
  animation: 10s linear infinite rotate-back;
}
#shadow6 {
  width: 98px;
  height: 98px;
  -webkit-border-radius: 49px;
  -moz-border-radius: 49px;
  border-radius: 49px;
  position: absolute;
  left: 323px;
  bottom: 230px;
}
#gear4 {
  background: url(../../public/assest/photos/branding/our-process-img.png) 0 -1627px
    no-repeat;
  width: 69px;
  height: 69px;
  position: absolute;
  left: 337px;
  bottom: 245px;
  -webkit-animation: 10s linear infinite rotate-back;
  -moz-animation: 10s linear infinite rotate-back;
  -ms-animation: rotate-back 10000ms linear infinite;
  animation: 10s linear infinite rotate-back;
}
#gear12 {
  background: url(../../public/assest/photos/branding/our-process-img.png) 0 -530px
    no-repeat;
  width: 164px;
  height: 164px;
  position: absolute;
  left: 208px;
  bottom: 85px;
  -webkit-animation: 8s linear infinite rotate;
  -moz-animation: 8s linear infinite rotate;
  -ms-animation: rotate 8000ms linear infinite;
  animation: 8s linear infinite rotate;
}
#shadow12 {
  width: 124px;
  height: 124px;
  -webkit-border-radius: 62px;
  -moz-border-radius: 62px;
  border-radius: 62px;
  position: absolute;
  left: 225px;
  bottom: 107px;
}
#gear11 {
  background: url(../../public/assest/photos/branding/our-process-img.png) 0 -356px
    no-repeat;
  width: 125px;
  height: 124px;
  position: absolute;
  left: 228px;
  bottom: 105px;
  -webkit-animation: 10s linear infinite rotate-back;
  -moz-animation: 10s linear infinite rotate-back;
  -ms-animation: rotate-back 10000ms linear infinite;
  animation: 10s linear infinite rotate-back;
}
#shadow11 {
  width: 88px;
  height: 88px;
  -webkit-border-radius: 44px;
  -moz-border-radius: 44px;
  border-radius: 44px;
  position: absolute;
  left: 247px;
  bottom: 123px;
}
#gear8 {
  background: url(../../public/assest/photos/branding/our-process-img.png) -371px -158px
    no-repeat;
  width: 72px;
  height: 72px;
  position: absolute;
  left: 254px;
  bottom: 131px;
  -webkit-animation: 6s linear infinite rotate;
  -moz-animation: 6s linear infinite rotate;
  -ms-animation: rotate 6000ms linear infinite;
  animation: 6s linear infinite rotate;
}
#shadow8 {
  width: 42px;
  height: 42px;
  -webkit-border-radius: 21px;
  -moz-border-radius: 21px;
  border-radius: 21px;
  position: absolute;
  left: 269px;
  bottom: 146px;
}
#gear1 {
  background: url(../../public/assest/photos/branding/our-process-img.png)
    no-repeat;
  width: 135px;
  height: 134px;
  position: absolute;
  left: 83px;
  bottom: 111px;
  -webkit-animation: 10s linear infinite rotate-back;
  -moz-animation: 10s linear infinite rotate-back;
  -ms-animation: rotate-back 10000ms linear infinite;
  animation: 10s linear infinite rotate-back;
}
#shadow1 {
  -webkit-border-radius: 48px;
  -moz-border-radius: 48px;
  border-radius: 48px;
  position: absolute;
  left: 103px;
  bottom: 130px;
}
#gear5 {
  background: url(../../public/assest/photos/branding/our-process-img.png) 0 -1746px
    no-repeat;
  width: 134px;
  height: 135px;
  position: absolute;
  left: 22px;
  top: 108px;
  -webkit-animation: 10s linear infinite alternate rotate;
  -moz-animation: 10s linear infinite alternate rotate;
  -ms-animation: rotate 10000ms linear infinite alternate;
  animation: 10s linear infinite alternate rotate;
}
#shadow5 {
  -webkit-border-radius: 48px;
  -moz-border-radius: 48px;
  border-radius: 48px;
  position: absolute;
  left: 41px;
  top: 127px;
}
#gear2 {
  background: url(../../public/assest/photos/branding/our-process-img.png) 0 -1364px
    no-repeat;
  width: 80px;
  height: 79px;
  position: absolute;
  left: 49px;
  top: 136px;
  -webkit-animation: 10s linear infinite alternate rotate-back;
  -moz-animation: 10s linear infinite alternate rotate-back;
  -ms-animation: rotate-back 10000ms linear infinite alternate;
  animation: 10s linear infinite alternate rotate-back;
}
#weight {
  background: url(../../public/assest/photos/branding/our-process-img.png) -371px -564px
    no-repeat;
  width: 34px;
  height: 92px;
  position: absolute;
  left: 1px;
  bottom: 0;
  -webkit-animation: 10s linear infinite alternate up;
  -moz-animation: 10s linear infinite alternate up;
  -ms-animation: up 10000ms linear infinite alternate;
  animation: 10s linear infinite alternate up;
}
#shadowweight {
  width: 10px;
  height: 80px;
  position: absolute;
  left: 12px;
  bottom: 0;
  -webkit-animation: 10s linear infinite alternate up;
  -moz-animation: 10s linear infinite alternate up;
  -ms-animation: up 10000ms linear infinite alternate;
  animation: 10s linear infinite alternate up;
}
#chain-circle {
  background: url(../../public/assest/photos/branding/our-process-img.png) -371px -706px
    no-repeat;
  width: 146px;
  height: 147px;
  position: absolute;
  left: 17px;
  top: 102px;
  -webkit-animation: 10s linear infinite alternate rotate;
  -moz-animation: 10s linear infinite alternate rotate;
  -ms-animation: rotate 10000ms linear infinite alternate;
  animation: 10s linear infinite alternate rotate;
}
#chain {
  width: 1px;
  height: 380px;
  border-left: 2px dotted #c8d94a;
  position: absolute;
  left: 17px;
  top: 175px;
  opacity: 0.7;
  -webkit-animation: 10s linear infinite alternate collapse;
  -moz-animation: 10s linear infinite alternate collapse;
  -ms-animation: collapse 10000ms linear infinite alternate;
  animation: 10s linear infinite alternate collapse;
}
@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes rotate {
  from {
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes rotate {
  from {
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-back {
  from {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-moz-keyframes rotate-back {
  from {
    -moz-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -moz-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes rotate-back {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-ms-keyframes rotate-back {
  from {
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -ms-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-o-keyframes rotate-back {
  from {
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes up {
  from {
    bottom: 0;
  }
  to {
    bottom: 340px;
  }
}
@-moz-keyframes up {
  from {
    bottom: 0;
  }
  to {
    bottom: 340px;
  }
}
@-webkit-keyframes up {
  from {
    bottom: 0;
  }
  to {
    bottom: 340px;
  }
}
@-ms-keyframes up {
  from {
    bottom: 0;
  }
  to {
    bottom: 340px;
  }
}
@-o-keyframes up {
  from {
    bottom: 0;
  }
  to {
    bottom: 340px;
  }
}
@keyframes collapse {
  from {
    height: 387px;
  }
  to {
    height: 48px;
  }
}
@-moz-keyframes collapse {
  from {
    height: 387px;
  }
  to {
    height: 48px;
  }
}
@-webkit-keyframes collapse {
  from {
    height: 387px;
  }
  to {
    height: 48px;
  }
}
@-ms-keyframes collapse {
  from {
    height: 387px;
  }
  to {
    height: 48px;
  }
}
@-o-keyframes collapse {
  from {
    height: 387px;
  }
  to {
    height: 48px;
  }
}
.organic-marketing-banner-new-div {
  position: absolute;
  top: 0;
  text-align: start;
  right: 0;
}
.organic-marketing-banner-new-div > img {
  position: relative;
  top: 0;
  right: 0;
  height: 550px;
}
.organic-marketing-banner-new-div > h1 {
  position: absolute;
  top: 11%;
  left: 113px;
  color: #343a40;
  font-size: 47px;
  font-family: arial-bold;
  text-transform: uppercase;
  line-height: 62px;
}
@keyframes animate {
  0%,
  10%,
  100% {
    width: 0;
  }
  70%,
  90% {
    width: 100%;
  }
}
.organic-marketing-banner-new-div > h2 {
  font-size: 21px;
  top: 38%;
  left: 113px;
  position: absolute;
  color: #37474f;
  line-height: 56px;
}
.organic-marketing-banner-new-div > h3 > a {
  position: absolute;
  top: 49%;
  left: 113px;
  border-radius: 23px;
  background: linear-gradient(to left, #436fe8 50%, #fff 50%) right;
  color: #fff;
  background-size: 200%;
}
.organic-marketing-banner-new-div > div {
  position: absolute;
  bottom: 0;
  right: 12px;
}
.organic-marketing-banner-new-div > .div2-p2 {
  position: absolute;
  bottom: 22%;
  right: 12px;
}
.organic-marketing-banner-new-div > div > p {
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 200px;
  padding: 20px 15px;
  box-shadow: 0 0 10px 0 #6c757d;
}
.organic-marketing-banner-new-div > div > .p2 {
  border-radius: 5px;
  width: 278px;
  padding: 20px 23px;
}
.voilet {
  color: #664dab;
}
.pink {
  color: #d44cc6;
}
.question-div > h4 {
  color: #436fe8;
  font-size: 37px;
  margin-bottom: 50px;
  text-transform: uppercase;
  position: relative;
}
.question-div > h4::after {
  position: absolute;
  content: "";
  bottom: -41%;
  left: 0;
  background-color: #fed501;
  border-radius: 10px;
  width: 100px;
  height: 5px;
}
.question-div > h2 {
  font-size: 18px;font-family: 'arial';
  line-height: 30px;
  padding-right: 150px;
  color: #6c757d;
}
.question-creative-section,
.question-section {
  margin: 50px 0;
}
.shakex {
  animation: 4s infinite shakeX;
}
@keyframes shakeX {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
.org-mark-sem-text > h4::after,
.organic-market-service-div > h4,
.organic-market-service-div > img,
.videos-text {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
}
.oragnic-marketing-video {
  width: 100%;
  background-color: #0062cc;
}
.videos-text {
  top: 50%;
  text-align: center;
}
.videos-text > h4 {
  color: #fed501;
  margin-bottom: 50px;
  font-size: 41px;
}
.videos-text > p {
  font-size: 20px;
  font-family: arial;
  line-height: 37px;
  color: #fff;
}
.organic-market-service-div {
  background-image: url("../../public/assest/photos/background-blue-slider.png");
  min-height: 350px;
  position: relative;
  z-index: 1;
}
.org-mart-what-sect,
.org-mart-what-we-do {
  background-image: url("../../public/assest/photos/organicmarketing/org-mart-what-we-img.png");
}
.video-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(67, 111, 232, 0.8);
  width: 100%;
  height: 100%;
}
.organic-market-service-div > img {
  top: 50%;
  width: 34%;
}
.organic-market-service-div > h4 {
  top: 80%;
  font-family: arial-bold;
  color: #fff;
  font-size: 17px;
  text-align: center;
}
.organic-market-service-text {
  text-align: center;
  font-size: 17px;
  font-family: arial;
  padding: 1px 20px;
  color: #436fe8;
  min-height: 138px;
  line-height: 27px;
  position: relative;
}
.organic-market-service-text::before {
  z-index: 0;
  border-radius: 20px;
  content: "";
  position: absolute;
  top: -98%;
  left: 0;
  border: 2px solid #ffc107;
  width: 100%;
  height: 290px;
}
.organic-market-service-section {
  padding: 50px 0;
  background-color: #436fe826;
}
.org-mark-sem-text > h4 {
  color: #436fe8;
  text-align: center;
  font-family: arial-bold;
  margin-top: 60px;
  margin-bottom: 56px;
  font-size: 37px;
  position: relative;
  text-transform: uppercase;
}
.org-mark-sem-text > h2,
.org-mark-sem-text > p {
  font-size: 22px;
  color: #436fe8;
  font-family: arial;
  text-align: center;
}
.org-mark-sem-text > h4::after {
  content: "";
  bottom: -23%;
  border-radius: 10px;
  width: 100px;
  height: 5px;
  background-color: #fed501;
}
.org-mart-what-img::before,
.org-mart-what-we-do2 > h4::before,
.org-mart-what-we-do > h4::before {
  bottom: 25%;
  width: 120px;
  height: 5px;
}
.org-mark-sem-text > h2 {
  margin: 3px 0;
  line-height: 31px;
}
.org-mark-sem-text > p {
  margin: 0;
}
.org-sem-slider {
  background-color: #e9ecef;
  margin: 20px 60px;
  padding: 30px 12px;
  height: 100%;
  min-height: 329px;
  width: 75%;
  border-radius: 20px;
  box-shadow: 0 0 17px 0 #adb5bd;
  text-align: center;
  cursor: pointer;
}
.org-sem-slider > h4 {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  text-transform: capitalize;
  font-family: arial-bold;
}
.org-sem-slider > p {
  padding: 20px 12px;
  font-family: arial;
  margin-top: 22px;
  border-radius: 19px;
  min-height: 220px;
  background-color: #fff;
}
.org-sem-slider > p:hover {
  background-color: #ffee95;
}
.org-mart-what-img::before,
.org-mart-what-we-do2 > h4::before,
.org-mart-what-we-do2 > p::before,
.org-mart-what-we-do > h4::before,
.org-mart-what-we-do > p::before {
  position: absolute;
  border-radius: 20px;
  background-color: #ffc107;
  content: "";
  left: 0;bottom: 20px;
}
.organic-market-sem-service-section {
  padding: 50px 0;
  background-color: #436fe826;
  margin-top: 50px;
}
.org-mart-what-we-do,
.org-mart-what-we-do2 {
  height: 800px;
  padding: 50px 110px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.org-mart-what-we-do2 > h4,
.org-mart-what-we-do > h4 {
  font-size: 20px;
  font-family: arial-bold;
  padding: 40px 0;text-transform: uppercase;line-height: 35px;
  position: relative;
  color: #fff;
}
.org-mart-what-we-do2 > h2,
.org-mart-what-we-do > h2 {
  font-size: 21px;
  font-family: arial;
  color: #fff;
  line-height: 35px;
  padding-bottom: 41px;
}
.org-mart-what-we-do2 > p,
.org-mart-what-we-do > p {
  font-size: 18px;
  font-family: arial;
  line-height: 35px;
  margin-bottom: 15px;
  color: #fff;
  position: relative;
  padding: 15px 20px;
}
.org-mart-what-we-do2 > p::before,
.org-mart-what-we-do > p::before {
  top: 17%;
  width: 10px;
  height: 10px;
}
.org-mart-what-img {
  position: relative;
}
.org-mart-what-sect {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 800px;
}
.creative-banner {
  position: relative;
  background-color: #f0f0f0;
}
.creative-banner-div,
.creative-bulb-banner-div,
.domain-banner-div {
  position: absolute;
  top: 27%;
  text-align: end;
  right: 100px;
}
.creative-banner-div > h3 {
  font-size: 16px;
  line-height: 26px;
  margin: 61px 1px;
  font-family: arial;
}
.creative-banner-div > h1 {
  font-family: arial-bold;
  font-size: 39px;
  color: #fff;
  margin-bottom: 20px;
}
.creative-banner-div > h2 {
  font-family: arial-bold;
  font-size: 19px;
  color: #fff;
}
.creative-bulb-banner-div > h4 {
  font-family: arial-bold;
  font-size: 64px;
  color: #436fe8;
  line-height: 85px;
  text-transform: uppercase;
}
.creative-bulb-banner-div > h2 {
  font-family: arial-bold;
  font-size: 64px;
  line-height: 77px;
  text-transform: uppercase;
  color: #495057;
}
.creative-bulb-banner-div p{width: 50%;
  float: right;
  margin-top: 20px;
  line-height: 28px;
}
.creative-blue-div > h4,
.question-creative-div > h4 {
  font-size: 44px;
  font-family: arial-bold;
  position: relative;
  text-transform: uppercase;
}
.creative-blue-div > h3,
.question-creative-div > h3 {
  font-size: 30px;color: #436fe8;margin-bottom: 10px;
  font-family: arial-bold;
  position: relative;
  text-transform: uppercase;
}
.creative-blue-section {
  background-image: url("../../public/assest/photos/creative-content/blue-back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 60px;
}
.creative-blue-div > h4 {
  color: #fff;
  text-align: start;
  padding-top: 60px;
  margin-bottom: 60px;
  line-height: 60px;
  padding-left: 120px;
}
.creative-blue-div > h4 > span,
.creative-header > span,
.question-creative-div > h4 > span {
  color: #fed501;
}
.question-creative-div > h4 {
  color: #436fe8;
  margin-bottom: 30px;
}
.question-creative-div > h2 {
  font-size: 20px;
  font-family: arial;
  line-height: 30px;
  padding-right: 150px;
  color: #6c757d;
}
.question-creative-img {
  position: absolute;
  left: 0;
  bottom: 8%;
}
.flip-card-back,
.flip-card-inner {
  border-radius: 15px;
  position: relative;
}
.creative-flip-card {
  background-color: transparent;
  width: 100%;
  height: 400px;
  perspective: 1000px;
}
.flip-card-inner {
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.creat-img1 {
  color: #fff;
  z-index: 2;
  background-image: url(../../public/assest/photos/creative-content/creative-flip1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.creat-img2 {
  color: #fff;
  z-index: 2;
  background-image: url(../../public/assest/photos/creative-content/creative-flip2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.creat-img3 {
  color: #fff;
  z-index: 2;
  background-image: url(../../public/assest/photos/creative-content/creative-flip3.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.creat-img4 {
  color: #fff;
  z-index: 2;
  background-image: url(../../public/assest/photos/creative-content/creative-flip4.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.creat-img5 {
  color: #fff;
  z-index: 2;
  background-image: url(../../public/assest/photos/creative-content/creative-flip5.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.creat-img6 {
  color: #fff;
  z-index: 2;
  background-image: url(../../public/assest/photos/creative-content/creative-flip6.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.creat-img7 {
  color: #fff;
  z-index: 2;
  background-image: url(../../public/assest/photos/creative-content/creative-flip7.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.creat-img8 {
  color: #fff;
  z-index: 2;
  background-image: url(../../public/assest/photos/creative-content/creative-flip8.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.creative-flip-card-front3 > h4 {
  font-family: arial-bold;
  font-size: 25px;
  margin-top: 37px;
  line-height: 26px;
  bottom: 40px;
  transform: translateX(-50%);
  left: 50%;
  text-transform: capitalize;
  position: absolute;
  color: #fff;
}
.flip-card-back {
  background: #fff;
  color: #000;
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.creative-header2,
.hid-box {
  font-family: arial;
  color: #6c757d;
}
.box,
.box4 {
  margin: 20px 60px;
  border-radius: 7px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
}
.flip-card-back::after {
  position: absolute;
  content: "";
  bottom: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 66px;
  height: 5px;
  background-color: #fed501;
}
.box,
.box4,
.box4 > .hid-box3,
.box > .hid-box2 {
  position: relative;
}
.box,
.box4,
.section2-web-develop-header::before,
.social-question-div,
.web-develop-header::before {
  background-color: var(--primary-bg-color);
}
.flip-card-back > h3 {
  font-size: 16px;
  font-family: arial;
  text-align: center;
  font-weight: 600;
  line-height: 26px;
  padding: 0 50px;
  letter-spacing: 1px;
  color: #495057;
}
.box {
  padding: 30px 0;
  min-height: 329px;
  width: 75%;
  height: 500px;
  box-shadow: 0 0 17px 0 #adb5bd;
}
.box4 {
  padding: 0;
  min-height: 349px;
  width: 82%;
  height: 474px;
}
.hid-box,
.hid-box4 {
  transition: 0.3s ease-out;
  height: 100%;
  position: absolute;
  width: 100%;
}
.box > .hid-box2 > img {
  margin: auto;
  width: 37%;
}
.box4 > .hid-box3 > h1,
.box > .hid-box2 > h4 {
  color: #fff;
  font-size: 18px;
  font-family: arial-bold;
  margin-top: 20px;
  line-height: 23px;
}
.box > .hid-box2 {
  top: 20%;
  transition: 0.3s ease-out;
}
.box4 > .hid-box3 > img {
  margin: auto;
  width: 103%;
}
.box4 > .hid-box3 {
  top: 0;
  transition: 0.3s ease-out;
}
.hid-box {
  top: 100%;
  background-image: url("../../public/assest/photos/creative-content/creative-icon-back-white.png");
  background-size: 102% 54%;
  padding: 50px 21px;
  background-repeat: no-repeat;
  font-size: 14px;
}
.hid-box4 {
  top: 100%;
  background-image: url("../../public/assest/photos/gallery/asset.png");
  background-size: 104% 51%;
  background-repeat: no-repeat;
  padding-top: 150px;
  font-family: arial-bold;
  color: #fff;
  font-size: 30px;
}
.back-blue,
.back-grey,
.creative-what-we {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.box4:hover > .hid-box4,
.box:hover > .hid-box {
  top: 50%;
}
.box:hover > .hid-box2 {
  top: 5%;
}
.box4:hover > .hid-box3 {
  top: 0;
}
.creative-header {
  text-align: center;
  text-transform: uppercase;
  margin: 54px 0;
  line-height: 63px;
  font-family: arial-bold;
  color: #436fe8;
  font-size: 42px;
}
.creative-header2 {
  text-align: center;
  line-height: 38px;
  margin-bottom: 40px;
  font-size: 20px;
}
.organ .creative-header2 {
  text-align: left;margin: 50px 0px;
  line-height: 32px;
  font-size: 18px;
}
.creative-header23,
.creative-header24 {
  text-transform: capitalize;
  margin: 54px 0;
  line-height: 46px;
  font-family: arial;
  color: #6c757d;
  font-size: 16px;
}
.creative-header23 {
  text-align: right;
}
.creative-header24 {
  text-align: left;
}
.social-header,
.social-question-header {
  text-transform: uppercase;
  font-family: arial-bold;
  color: #436fe8;
}
.back-grey {
  background-image: url("../../public/assest/photos/creative-content/back-grey.png");
}
.back-blue,
.creative-what-we {
  background-image: url("../../public/assest/photos/creative-content/blue-back.png");
}
.back-yellow {
  background-image: url("../../public/assest/photos/creative-content/back-yellow.png");
  background-size: 100% 200%;
  background-repeat: no-repeat;
}
.social-media-what,
.social-question-text {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.creative-marketing-banner-div {
  position: absolute;
  top: 23%;
  text-align: start;
  left: 100px;
}
.creative-marketing-banner-div > h1 {
  font-family: arial-bold;
  font-size: 39px;
  color: #000;
  margin-bottom: 20px;
}
.creative-marketing-banner-div > h2 {
  font-family: arial-bold;
  font-size: 19px;
  color: #000;
}
.security-banner-div,
.security-org-banner-div,
.social-banner-div,
.web-develop-banner-div {
  position: absolute;
  top: 27%;
  text-align: start;
  left: 100px;
}
.social-banner-div > h1 {
  font-family: arial-bold;
  font-size: 38px;
  color: #fff;
  margin-bottom: 20px;
}
.social-banner-div > h2 {
  font-family: arial;
  font-size: 28px;
  color: #fff;
}
.b2b-banner-div > h3 > a,
.social-banner-div > h3 > a,
.web-develop-banner-div > h3 > a {
  padding: 16px 40px;
  border-radius: 6px;
  background: linear-gradient(to left, #fed501 50%, #fff 50%) right;
  color: #000;
  font-size: 14px;
  background-size: 200%;
  transition: 0.5s ease-out;
}
.social-banner-img {
  position: absolute;
  top: 0;
  right: 100px;
}
.social-header {
  text-align: center;
  margin: 22px 0;
  line-height: 58px;
  font-size: 37px;
}
.social-question-div {
  border-top-right-radius: 65px;
  border-bottom-right-radius: 65px;
  box-shadow: 7px 0 12px 0 #6c757d;
  height: 850px;
  position: relative;
  z-index: 3;
}
.social-question-div > img {
  position: absolute;
  top: 19%;
  left: 50px;
  transform: translate(-50%, -50%);
  width: 82%;
}
.social-question-text {
  background-image: url("../../public/assest/photos/social-media/grey-back.png");
  padding: 60px;
  z-index: 1;
}
.social-question-header {
  text-align: left;
  margin: 3px 0;
  line-height: 63px;
  font-size: 34px;
}
.icon-slider > h5,
.social-header2,
.social-question-header2 {
  font-family: arial;
  color: #6c757d;
}
.social-question-header2 {
  text-align: left;
  text-transform: capitalize;
  margin: 42px 0;
  line-height: 30px;
  font-size: 15px;
}
.social-header2 {
  text-align: center;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 17px;margin: 0px 50px;margin-bottom: 20px;
}
.section2-web-develop-header,
.web-develop-header {
  margin: 40px 0;
  text-transform: uppercase;
  position: relative;
}
.icon-slider,
.icon-slider2 {
  padding: 62px 0;
}
.icon-slider > img {
  margin: auto;
}
.icon-slider2 > img {
  margin: auto;
  width: 50%;
}
.icon-slider > h5 {
  text-align: center;
  font-size: 18px;
  padding-top: 10px;
}
.challenge-sec,
.shared-title-sec,
.slider-icon-div,
.what-we-offer-div,
.what-we-offer-div2 {
  padding: 50px 0;
}
.center-slider .slick-center,
.center-slider
  .slick-slide[aria-hidden="true"]:not([tabindex="-1"])
  + .slick-cloned[aria-hidden="true"] {
  transform: scale(1.5);
}
.web-dev-blue-heading::before,
.web-develop-header::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.social-media-what {
  background-image: url("../../public/assest/photos/social-media/yellow-back.png");
}
.web-develop-banner-div > h1 {
  font-family: arial-bold;
  font-size: 54px;
  color: #fff;
  margin-bottom: 13px;
}
.web-develop-banner-div > h3 {font-family: arial;line-height: 33px;font-size: 23px;
  color: #fff;margin-bottom: 10px;}
.web-develop-banner-div > h2 {
  font-family: arial;
  font-size: 29px;
  color: #fff;
}
.web-dev-action-yellow-div-buttom {
  background-color: #fed501;
  color: #fff;
  font-family: arial;
  font-size: 14px;
  letter-spacing: 2px;
  padding: 10px 37px;
  text-transform: uppercase;
  border-radius: 5px;
  background: linear-gradient(to left, #436fe8 50%, #fff 50%) right;
  background-size: 200%;
  transition: 0.5s ease-out;
}
.domain-extention-FAQs-section,
.domain-plan-div,
.domain-plan-section,
.security-blue-section {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.web-develop-header {
  text-align: center;
  line-height: 63px;
  font-family: arial-bold;
  color: #495057;
  font-size: 37px;
}
.web-develop-header::before {
  top: 100%;
  width: 72px;
  border-radius: 25px;
  height: 4px;
}
.web-dev-action-yellow-div-text {
  width: 100%;
  font-family: arial;
  height: 237px;
  background-color: #fed501;
  align-items: center;
  font-size: 38px;
  display: flex;
  color: #000;
  justify-content: space-around;
  border-radius: 10px;
  font-weight: 800;
}
.web-blue-div-white,
.web-blue-div1 {
  padding: 57px 31px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  min-height: 350px;
  box-shadow: 0 0 9px 0 #6c757d;
}
.section2-web-develop-header {
  text-align: left;
  line-height: 38px;
  font-family: arial-bold;
  color: #495057;
  font-size: 28px;
}
.section2-web-develop-header::before {
  content: "";
  position: absolute;
  top: 110%;
  left: 0;
  width: 72px;
  border-radius: 25px;
  height: 4px;
}
.section2-web-develop-passage {
  text-align: left;
  margin: 40px 20px;
  position: relative;
}
.section2-web-develop-passage > p {
  text-align: left;
  margin: 20px 0px;
  line-height: 28px;
  font-family: arial;
  color: #495057;
  font-size: 17px;
  position: relative;
}
.section2-web-develop-passage > p > span{font-weight: 600;font-size: 16px;}
.section2-web-develop-image {margin-top: 100px;}
@media(min-width:100px) and (max-width: 767px){
  .section2-web-develop-image {margin-top: 0px;}
}
.domain-banner-div > h4,
.domain-banner-div > h5 {
  color: #fff;
  line-height: 61px;
  font-family: arial;
}
.web-section2 {
  margin: 60px 0;
}
.web-dev-blue-div {
  margin: 40px 0;
  border-radius: 15px;
}
.web-blue-div1 {
  text-align: center;
  background-color: #fed501;
}
.web-blue-div-white > h4,
.web-blue-div1 > h4,
.web-blue-div2 > h4 {
  font-size: 22px;
  line-height: 31px;
  padding: 20px 0;
  font-family: arial-bold;
}
.web-blue-div2 {
  text-align: center;
  background-color: #fed501;
  padding: 57px 31px;
  border-radius: 15px;
  position: relative;
  left: -22px;
  min-height: 350px;
  box-shadow: 0 0 9px 0 #6c757d;
}
.web-blue-div-white {
  text-align: center;
  background-color: #fff;
  position: relative;
  left: -11px;
}
.web-dev-blue-heading {
  text-align: center;
  color: #fff;
  font-size: 35px;
  text-transform: uppercase;
  padding: 40px 0 80px;
  position: relative;
  font-family: arial-bold;
}
.web-dev-blue-heading::before {
  top: 59%;
  border-radius: 15px;
  width: 100px;
  height: 5px;
  background-color: #fed501;
}
.b2b-testinomial-section-name10::before,
.b2b-testinomial-section-name13::before,
.b2b-testinomial-section-name15::before,
.b2b-testinomial-section-name16::before,
.b2b-testinomial-section-name2::before,
.b2b-testinomial-section-name3::before,
.b2b-testinomial-section-name4::before,
.b2b-testinomial-section-name5::before,
.b2b-testinomial-section-name8::before,
.b2b-testinomial-section-name9::before,
.b2b-testinomial-section-name::before {
  top: 10px;
  left: 10px;
  width: 60px;
  height: 57px;
  background-size: contain;
  content: "";
}
.web-text-slider {
  background-color: #fed501;
  margin: 11px 60px;
  text-align: center;
  height: 100%;
  min-height: 480px;
  box-shadow: 0 0 11px 0 #adb5bd;
  border-radius: 33px;
}
.web-text-slider:hover {
  background-color: #e9ecef;
  transition: 0.6s;
}
.mt60,
.slider-margin-top {
  margin-top: 60px;
}
.web-slider-div {
  background-image: url("../../public/assest/photos/web-development/back-lines.png");
  background-repeat: no-repeat;
  background-position: center;
}
.web-text-slider-img {
  margin: auto;
  padding: 50px 0;
  width: 32%;
}
.web-text-slider > h4 {
  font-family: arial-bold;
  font-size: 21px;
  padding: 20px 0;
}
.web-text-slider > p {
  font-family: arial;
  font-size: 15px;
  padding: 0 20px;
}
.domain-banner-div > h4 {
  font-size: 30px;
}
.domain-banner-div > h5 {
  font-size: 18px;
}
.domain-banner-div > h1 {
  font-size: 67px;
  color: #fed501;
  font-family: arial-bold;
  line-height: 61px;
}
.searchArea2 {
  max-width: 400px;
  float: right;
  margin-left: 200px;
}
.searchArea2 button {
  float: right;
  margin-left: -25px;
  border: 1px solid #fed501;
  background-color: #fed501;
  padding: 0 40px;
  border-radius: 8px;
  color: #000;
  font-family: arial-bold;
  position: relative;
  box-shadow: 0 5px 6px 0 #495057;
  z-index: 4;
}
.searchArea2 .inputDiv {
  overflow: hidden;
  border: 2px solid #fff;
  border-radius: 8px;
  box-shadow: 0 5px 6px 0 #495057;
}
.searchArea2 input {
  position: relative;
  border: 0;
  z-index: 2;
  padding: 6px 16px;
  width: 365px;
  height: 40px;
}
.domain-second-div {
  background-color: #fff;
  align-items: center;
  height: auto;
  justify-content: space-around;
  display: flex;
  border-radius: 20px;
  margin: 33px 26px;
  padding: 20px 21px;
}
.domain-second-div > div > img {
  max-width: 423px;
  padding: 28px;
}
.domain-second-div > div > h3 {
  font-size: 25px;
  font-family: arial-bold;
  margin: 14px 0;
}
.domain-second-div > div > h4 {
  font-size: 14px;
  margin: 14px 0;
  font-family: arial;
  line-height: 24px;
}
.domain-second-heading > h4 {
  text-align: center;
  font-size: 30px;
  margin-bottom: 25px;
  font-family: arial-bold;
  text-transform: uppercase;
  color: #fff;
  margin-top: 60px;
}
.center-slider2 .slick-center,
.center-slider2
  .slick-slide[aria-hidden="true"]:not([tabindex="-1"])
  + .slick-cloned[aria-hidden="true"] {
  transform: scale(1.2);
  background-color: var(--primary-bg-color);
  color: #fff;
  border-radius: 25px;
}
.center-slider2 .slick-center {
  background-color: var(--primary-bg-color);
  color: #fff;
  border-radius: 25px;
}
.domain-slider-div {
  text-align: center;
  height: 344px;
  padding: 70px 0;
}
.domain-slider-div > h {
  font-size: 26px;
  padding: 20px 0;
  font-family: arial-bold;
}
.domain-slider-div > p {
  font-size: 15px;
  padding: 20px 0;
  line-height: 28px;
  font-family: arial;
}
.domain-slider-heading {
  text-align: center;
  font-size: 36px;
  margin: 62px 0;
  color: #436fe8;
  font-family: arial-bold;
  text-transform: uppercase;
}
.domain-plan-section {
  background-image: url("../../public/assest/photos/domain/domain-back-img2.png");
  padding: 40px 0;
}
.domain-plan-div {
  background-image: url("../../public/assest/photos/domain/domain-plan-back-img.png");
  height: 500px;
  text-align: center;
  padding: 40px;
}
.domain-plan-div > h2 {
  font-size: 24px;
  font-family: arial-bold;
  padding: 10px 0;
  text-transform: capitalize;
}
.domain-plan-div > h3 {
  position: relative;
  top: 68px;
}
.domain-plan-div > h3 > span {
  background-color: #fed501;
  font-family: arial-bold;
  font-size: 19px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px 20px;
  box-shadow: 2px 3px 7px 0 #6c757d;
  border-radius: 5px;
}
.security-banner-div > h3 > a,
.security-org-banner-div > h3 > a {
  background: linear-gradient(to left, #fed501 50%, #436fe8 50%) right;
}
.domain-plan-div > h4 {
  color: #436fe8;
  font-family: arial;
  font-size: 29px;
  font-weight: 700;
}
.domain-plan-div > h4 > span {
  font-size: 19px;
  color: #6c757d;
}
.domain-plan-div > h5 {
  font-size: 17px;
  font-family: arial-bold;
  padding: 20px;
}
.domain-plan-div > p {
  font-size: 16px;
  color: #495057;
  font-family: arial;
  padding-top: 67px;
  padding-left: 10px;
  padding-right: 10px;
}
.circular-percentage-section,
.domain-extention-plan-section,
.meet-core-div {
  background-color: var(--primary-bg-color);
  padding: 40px 0;
}
.domain-extention-slider-heading {
  text-align: center;
  font-size: 40px;
  margin: 30px 0;
  text-transform: uppercase;
  color: #fff;
  font-family: arial-bold;
}
.domain-extention-slider-passage {
  text-align: center;
  font-size: 23px;
  margin: 0 0 30px;
  color: #fff;
  font-family: arial;
}
.domain-extention-plan-div {
  height: 500px;
  text-align: center;
  box-shadow: 0 0 3px 0 #adb5bd;
  padding: 40px;
  background-color: #fff;
}
.extension-div1 {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.extension-div2 {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.domain-extention-plan-div > h2 {
  font-size: 32px;
  text-transform: uppercase;
  padding: 20px 0;
  font-family: arial-bold;
}
.domain-extention-plan-div > h3 {
  position: relative;
  top: 23px;
}
.domain-extention-plan-div > h3 > span {
  font-size: 15px;
  font-family: arial;
  box-shadow: 0 0 12px 1px #ccc;
  padding: 11px 35px;
  cursor: pointer;
}
.domain-extention-plan-div > h3 > span:hover {
  background-color: #fff;
  color: #000;
  transition: 1s;
}
.domain-extention-plan-div > h4 {
  font-size: 30px;
  font-family: arial;
  padding: 50px 0;
}
.domain-extention-plan-div > h4 > span {
  font-size: 18px;
  font-family: arial;
}
.domain-extention-plan-div > h5,
.domain-extention-plan-div > p {
  font-size: 14px;
  padding: 20px 0;
  font-family: arial;
}
.FAQs-heading-section h4,
.yellow-section-div > h2,
.yellow-section-div > h3,
.yellow-section-div > h5 {
  text-transform: uppercase;
  font-family: arial-bold;
}
.domain-extention-FAQs-section {
  background-image: url(../../public/assest/photos/domain/domain-back-img.png);
  padding: 80px 0;
}
.FAQs-heading-section h4 {
  color: #fff;
  text-align: center;
  font-size: 29px;
  padding: 40px 0;
}
.yellow-section-div {
  padding: 40px;
  background-color: #f7e276;
  margin: 40px 0;
  z-index: 0;
  border-radius: 15px;
}
.yellow-section-div:hover {
  transform: scale(1.1);
  transition: 1s;
  background-color: #fff;
  color: var(--primary-bg-color);
  z-index: 1;
}
.yellow-section-div > h5 {
  font-size: 31px;
}
.yellow-section-div > h2 {
  font-size: 22px;
  text-decoration-line: line-through;
}
.yellow-section-div > h3 {
  font-size: 31px;
  padding-top: 24px;
}
.yellow-section-div > h3 > span {
  font-size: 19px;
  text-transform: lowercase;
  font-family: arial;
  color: #495057;
}
.email-banner-div1 > h3 > a,
.email-banner-div > h3 > a,
.security-banner-div > h3 > a,
.security-org-banner-div > h3 > a {
  padding: 16px 40px;
  font-size: 14px;
  transition: 0.5s ease-out;
}
.yellow-section-div > h4 {
  font-size: 16px;
  text-transform: lowercase;
  text-align: center;
  font-family: arial;
}
.yellow-section-div > p {
  font-size: 16px;
  text-transform: capitalize;
  font-family: arial;
}
.yellow-section-heading {
  text-align: center;
  font-family: arial-bold;
  font-size: 33px;
  line-height: 47px;
  padding-top: 71px;
  text-transform: uppercase;
}
.yellow-transfer-section-div {
  text-align: center;
  background-color: #fff;
  padding: 0 50px;
  border-radius: 25px;
  margin: 50px 0;
  height: 289px;
}
.yellow-transfer-section-div:hover {
  transform: scale(1.1);
  transition: 1s;
  background-color: #ffed8d;
}
.yellow-transfer-section-div > img {
  height: 100px;
  margin: 22px 0;
}
.yellow-transfer-section-div > h4 {
  font-size: 23px;
  font-family: arial-bold;
  padding-bottom: 20px;
}
.yellow-transfer-section-div > p {
  font-size: 15px;
  font-family: arial;
}
.domain-name-div {
  background-color: #f2f2f2;
  text-align: center;
  margin: 5px;
  box-shadow: 13px 9px 18px 0 #ccc;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 15px;
  padding-top: 150px;
  height: 490px;
}
.domain-name-div:hover {
  background-color: var(--primary-bg-color);
  color: #fff;
  transition: 1s;
}
.domain-name-div > h4 {
  font-size: 20px;
  font-family: arial-bold;
  padding: 20px 0;
}
.security-banner-div > h1 {
  font-family: arial-bold;
  line-height: 50px;
  font-size: 45px;
  color: var(--primary-bg-color);
  margin-bottom: 20px;
}
.security-banner-div > h2,
.security-org-banner-div > h2 {
  font-family: arial;
  font-size: 20px;
  color: #003b77;
  line-height: 29px;
}
.security-banner-div > h3 > a {
  border-radius: 6px;
  color: #000;
  box-shadow: 0 0 9px 0 #adb5bd;
  background-size: 200%;
}
.security-circle-div {
  text-align: center;
  background-color: #e9ecef;
  height: 350px;
  border-radius: 200px;
  margin: 50px 0;
  box-shadow: 0 0 9px 0 #ccc;
  padding: 24px;
}
.security-circle-div > div {
  margin: auto;
  border: 2px dotted #000;
  padding: 25px;
  border-radius: 80px;
  width: 100px;
}
.security-circle-div > h4 {
  font-size: 23px;
  font-family: arial-bold;
  padding-top: 20px;
  color: #495057;
}
.security-circle-div > p {
  font-size: 17px;
  font-family: arial;
  padding: 11px 0;
  color: #495057;
}
.security-circle-heading {
  text-align: center;
  font-size: 35px;
  font-family: arial-bold;
  padding: 40px 0;
  text-transform: uppercase;
  color: var(--primary-bg-color);
}
.security-blue-section {
  background-image: url("../../public/assest/photos/security/blue-back.png");
  padding: 70px 0;
}
.security-slider-custome-button-next,
.security-slider-custome-button-prev {
  background-color: #f8f9faa3;
  color: #0062cc;
  width: 40px;
  bottom: 28%;
  position: absolute;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  padding: 5px;
  height: 40px;
  text-align: center;
}
.security-blue-div > h4 {
  font-size: 35px;
  padding: 20px 0;
  text-transform: uppercase;
  font-family: arial-bold;
  color: #ffff;
}
.security-blue-div > p {
  color: #fff;
  font-family: arial;
  padding: 0 150px;
  font-size: 20px;
}
.security-slider-custome-button-prev {
  left: 5%;
  z-index: 2;
}
.security-slider-custome-button-next {
  left: 12%;
}
.backword-arrow,
.forword-arrow,
.main-backword-arrow,
.main-forword-arrow {
  width: 50px;
  height: 50px;
}
.security-testimonial-slider-h1 {
  text-align: left;
  color: var(--primary-bg-color);
  font-family: arial-bold;
  font-size: 33px;
  padding: 27px 35px;
}
.security-testimonial-slider-text {
  text-align: left;
  color: #003b77;
  font-family: arial;
  font-size: 22px;
  padding: 0 35px;
}
.security-section-margin {
  background-image: url("../../public/assest/photos/security/security-back-banner.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: rgb(0 123 255 / 9%);
}
.standardssl-section,
.wildcard-lock-section {
  background-image: url("../../public/assest/photos/security/back-blue-square.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.security-slider-img {
  text-align: center;
}
.security-slider-img > img {
  margin: auto;
  padding: 50px;
  width: 92%;
}
.security-slider-heading {
  font-family: arial-bold;
  font-size: 30px;
  padding: 0 33px;
  text-transform: uppercase;
  margin-top: 150px;
}
.security-standard-blue-div {
  text-align: center;
  padding: 100px 40px;
}
.security-standard-blue-div > h4 {
  font-size: 36px;
  color: #436fe8;
  text-transform: uppercase;
  font-family: arial-bold;
  padding: 20px 0;
}
.security-standard-blue-div > p {
  font-size: 23px;
  font-family: arial;
  line-height: 41px;
}
.standard-step-img > img {
  width: 80%;
}
.standard-step1 {
  background-color: rgb(167 210 255 / 50%);
  margin-bottom: 12px;
  font-family: arial;
  font-size: 17px;
  border-left: 10px solid #436fe8;
  width: 729px;
  text-align: end;
  float: right;
  height: 119px;
  padding: 29px 90px 23px 23px;
  position: relative;
  left: 375px;
}
.standard-step2,
.standard-step3 {
  width: 800px;
  height: 119px;
  background-color: rgb(167 210 255 / 50%);
  margin-bottom: 12px;
  font-family: arial;
  font-size: 17px;
  border-left: 10px solid #436fe8;
  text-align: end;
  position: relative;
  float: right;
}
.standard-step2 {
  padding: 29px 86px 23px 23px;
  left: 310px;
}
.standard-step3 {
  padding: 29px 59px 23px 23px;
  left: 213px;
}
.standard-step4 {
  background-color: rgb(167 210 255 / 50%);
  margin-bottom: 12px;
  font-family: arial;
  font-size: 17px;
  border-left: 10px solid #436fe8;
  width: 670px;
  text-align: end;
  float: right;
  height: 110px;
  padding: 29px 51px 23px 23px;
  position: relative;
  left: 136px;
}
.standard-step-section {
  padding: 40px 0;
}
.wildcard-lock-heading {
  font-family: arial-bold;
  font-size: 37px;
  padding: 0 33px;
  line-height: 51px;
  margin-top: 80px;
}
.wildcard-lock-text {
  text-align: left;
  color: #003b77;
  font-family: arial;
  font-size: 26px;
  line-height: 45px;
  padding: 35px;
}
.wildcard-lock-section {
  padding: 100px 0;
}
.wildcard-lock-FAQs-heading {
  text-align: center;
  font-size: 40px;
  font-family: arial-bold;
  padding: 40px 0;
  color: #fff;
}
.email-banner-div,
.email-banner-div1 {
  text-align: start;
  left: 83px;
  position: absolute;
}
.security-org-banner-div > h1 {
  font-family: arial-bold;
  line-height: 48px;
  font-size: 37px;
  color: var(--primary-bg-color);
  margin-bottom: 20px;
}
.security-org-banner-div > h3 > a {
  border-radius: 6px;
  color: #000;
  background-size: 200%;
}
.email-banner-div {
  top: 16%;
}
.email-banner-div > h5 {
  font-family: arial-bold;
  line-height: 51px;
  font-size: 36px;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.email-banner-div > h1 {
  font-family: arial-bold;
  line-height: 20px;
  font-size: 20px;
  color: #000;
  margin-bottom: 11px;
}
.email-banner-div > h2 {
  font-family: arial;
  font-size: 20px;
  color: #003b77;
  line-height: 38px;
}
.email-banner-div1 > h3 > a,
.email-banner-div > h3 > a {
  border-radius: 6px;
  background: linear-gradient(to left, #fed501 50%, #436fe8 50%) right;
  color: #000;
  background-size: 200%;
  box-shadow: 0 0 9px 0 #adb5bd;
}
.email-banner-div1 {
  top: 25%;
}
.email-banner-div1 > h1 {
  font-family: arial-bold;
  line-height: 54px;
  font-size: 45px;
  color: #fff;
  margin-bottom: 20px;
}
.email-sub-logo-div h6,
.email-sub-logo-div4 h6 {
  margin: auto auto 40px;
  cursor: pointer;
  width: 200px;
}
.email-banner-div1 > h2 {
  font-family: arial;
  font-size: 20px;
  color: #fff;
  line-height: 38px;
}
.email-second-section {
  background-image: url("../../public/assest/photos/email/email-second-section-banner.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 60px;
  padding-left: 50px;
  padding-right: 50px;
}
.email-second-div {
  display: flex;
  padding: 20px 17px;
}
.email-second-div2 > div > img,
.email-second-div > div > img {
  width: 50px;
  margin: 0 4px;
}
.email-second-div > div > h4 {
  color: #436fe8;
  padding: 8px 5px;
  font-family: arial-bold;
  font-size: 18px;
}
.email-second-div > div > p {
  line-height: 20px;
  color: #495057;
  padding: 0 6px;
  font-family: arial;
  font-size: 15px;
}
.email-second-main-div {
  background-color: #fff;
  box-shadow: 0 0 10px 0 #6c757d;
  padding: 21px;
  margin: 31px 20px;
  border-radius: 44px 0 44px 44px;
}
.email-second-div2 > div > h2 {
  color: #436fe8;
  padding: 8px 0;
  font-family: arial-bold;
  font-size: 19px;
}
.email-second-div2 {
  padding: 14px 17px;
}
.email-second-main-div > p {
  font-size: 18px;
  font-family: arial;
  line-height: 35px;
  color: #495057;
  padding: 58px 21px;
}
.email-second-heading {
  font-family: arial-bold;
  color: #fff;
  font-size: 21px;
  padding: 0 50px;
}
.email-table-section {
  background-image: url("../../public/assest/photos/email/white-back5.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 100px;
}
.email-table-row {
  padding-top: 50px;
  padding-bottom: 100px;
}
.email-section {
  background-image: url("../../public/assest/photos/email/white-and-blue-img-back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.email-ani-email-heading {
  color: #fed501;
  font-size: 30px;
  line-height: 42px;
  padding: 0 37px;
  text-transform: uppercase;
  font-family: arial-bold;
}
.email-ani-email-h1 {
  color: #fff;
  font-size: 23px;
  font-family: arial-bold;
  padding: 25px 36px;
}
.email-ani-email-text {
  color: #fff;
  font-size: 21px;
  font-family: arial;
  padding: 0 36px;
  line-height: 40px;
}
.ani-girl-img1 {
  position: absolute;
  top: -24px;
  width: 112px;
  left: 17px;
  animation: 4s ease-out infinite moveUp3;
}
.ani-girl-img2 {
  position: absolute;
  top: 10%;
  width: 109px;
  left: 69px;
  animation: 2s ease-out infinite moveUp2;
}
.ani-girl-img3 {
  position: absolute;
  top: 24%;
  left: 112px;
  width: 102px;
  animation: 3s ease-out infinite moveUp;
}
@keyframes moveUp {
  from {
    top: 40%;
  }
  to {
    top: 20%;
  }
}
@keyframes moveUp2 {
  from {
    top: 40%;
  }
  to {
    top: 10%;
  }
}
@keyframes moveUp3 {
  from {
    top: 40%;
  }
  to {
    top: -10%;
  }
}
.google-workspace-div {
  padding: 0 4vw;
}
.email-sub-logo-div,
.email-sub-logo-div4 {
  padding: 18px 15px;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 2px 9px 1px #436fe863;
}
.email-sub-logo-div {
  text-align: center;
  height: 642px;
  border-radius: 18px;
  border: 3px solid #fff;
}
.email-sub-logo-div:hover {
  transform: scale(1.02);
  box-shadow: 0 0 15px #b7d3f3;
  transition: 0.6s;
}
.email-sub-logo-div h5,
.email-sub-logo-div4 h5 {
  font-size: 21px;
  font-family: arial-bold;
  color: #3f51b5;
  text-transform: uppercase;
  padding: 20px 0;
}
.email-sub-logo-div h4 {
  font-size: 51px;
  color: #436fe8;
  font-family: arial-bold;
}
.email-sub-logo-div h4 span {
  font-size: 19px;
  padding: 0 5px;
  color: #607d8b;
  font-family: cursive;
}
.email-sub-logo-div2 h4 {
  background-color: #fff;
  color: #436fe8;
  font-size: 52px;
  font-family: arial-bold;
  padding: 10px 0;
  text-align: center;
  text-transform: capitalize;
}
.email-sub-logo-div h3,
.email-sub-logo-div4 h3 {
  text-decoration: line-through;
  color: #6c757d;
  padding: 11px 0 0;
  font-size: 19px;
}
.email-sub-logo-div h2 {
  color: #f86d20;
  font-size: 15px;
  padding-bottom: 15px;
}
.email-sub-logo-div h6 {
  border-radius: 0;
  border: 2px solid #0062cc;
  font-family: arial-bold;
  font-size: 17px;
  background-color: #bedbfb5c;
  color: var(--primary-bg-color);
  padding: 18px 20px;
  text-transform: capitalize;
}
.email-sub-logo-div h6:hover {
  background-color: #436fe8;
  color: #fff;
  transition: 1s;
  border: 2px solid #436fe8;
}
.email-sub-logo-div p,
.email-sub-logo-div4 p {
  font-size: 14px;
  padding: 6px 0;
  text-align: left;
  text-transform: capitalize;
  line-height: 22px;
  display: flex;
  color: #6c757d;
  font-family: arial;
}
.email-sub-logo-div2 > h5 {
  background-color: #436fe824;
  color: #436fe8;
  font-size: 20px;
  font-family: arial-bold;
  text-transform: uppercase;
  text-align: center;
  padding: 20px 0;
  border-radius: 9px;
  box-shadow: 0 0 2px 1px #436fe84f;
}
.email-sub-logo-div > p > img {
  height: 22px;
  padding-right: 10px;
}
.email-sub-logo-div4 {
  text-align: center;
  height: 630px;
  border-radius: 18px;
  border: 3px solid #fff;
}
.email-sub-logo-div4:hover {
  transform: translate3d(0, -10px, 0);
  box-shadow: 0 0 15px #b7d3f3;
  transition: 0.6s;
}
.email-sub-logo-div4 h4 {
  font-size: 28px;
  color: #436fe8;
  font-family: arial-bold;
}
.email-sub-logo-div4 h4 span {
  font-size: 19px;
  padding: 0 5px;
  text-transform: lowercase;
  color: #607d8b;
  font-family: cursive;
}
.email-sub-logo-div4 h2 {
  color: #607d8b;
  font-size: 15px;
  padding-bottom: 15px;
}
.email-sub-logo-div4 h6 {
  border-radius: 0;
  border: 2px solid #0062cc;
  font-family: arial-bold;
  font-size: 17px;
  background-color: #bedbfb5c;
  color: #0062cc;
  padding: 18px 20px;
  text-transform: capitalize;
}
.email-sub-logo-div4 h6:hover,
.email-sub-logo-div4:hover h6,
.email-sub-logo-div:hover h6 {
  background-color: #436fe8;
  color: #fff;
  transition: 0.5s;
  border: 2px solid #436fe8;
}
.explore-plans h3 a,
.hosting-banner h3 a {
  padding: 7px 30px;
  border-radius: 23px;
  transition: 0.5s ease-out;
}
.email-small-button {
  background: #00bcd4;
  text-transform: capitalize;
  font-size: 15px;
  border-radius: 22px;
  padding: 10px 17px;
  font-family: arial;
  color: #fff;
  margin: 12px 13px;
  cursor: pointer;
}
.email-logo-icon,
.terms-policy-align {
  display: flex;
  justify-content: space-evenly;
}
.hosting-banner {
  height: 100%;
  width: 100%;
  margin: 15px 0;
}
.hosting-banner h3 a {
  color: #fff;
  background: linear-gradient(to left, #436fe8 50%, #fed501 50%) right;
  background-size: 200%;
}
.hosting-banner h3 {
  font-size: 16px;
  line-height: 26px;
  margin: 29px 1px;
  font-family: arial;
  letter-spacing: 0.5px;
}
.explore-plans h2,
.whychoose-sec .slick-slide .b2b-section-second,
.whychoose-sec .slick-slide .slider-div4,
.whychoose-sec .slick-slide .slider-div5,
.whychoose-sec .slick-slide .slider-div6,
.whychoose-sec .slick-slide .slider-div9 {
  margin-right: 50px;
}
.exp-hosting h1,
.exp-hosting h2 {
  font-family: arial-bold;
  line-height: 43px;
  text-transform: uppercase;
}
.exp-hosting {
  padding: 120px 0 0 120px;
}
.exp-hosting h2 {
  margin-bottom: 20px;
  font-size: 32px;
}
.exp-hosting h1 {
  font-size: 29px;
  margin-bottom: 10px;
  font-weight: 400;
}
.exp-hosting p,
.fea {
  margin-bottom: 30px;
}
.b2b-banner-div > h3,
.explore-plans h2,
.explore-plans h3,
.fea > h2,
.shared-title-p,
.titleh2 > h2,
.titleh2w2 > h2,
.titleh2w > h2 {
  font-family: arial;
}
.exp-hosting p {
  font-size: 16px;
  line-height: 26px;
}
.explore-plans {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fed501;
}
.features-sec,
.features-sec2 {
  padding: 60px 0;
  background-position: bottom;
  position: relative;
}
.b2b-slider-div2,
.login-paper-div,
.performax-end-div,
.price-box,
.sign-section {
  background-size: 100% 100%;
}
.explore-plans h2 {
  font-size: 26px;
  font-weight: 400;
}
.explore-plans h3 {
  font-size: 16px;
  line-height: 26px;
  margin: 20px 0;
  letter-spacing: 0.5px;
}
.explore-plans h3 a {
  color: #000;
  background: linear-gradient(to left, transparent 50%, #436fe8 50%) right;
  background-size: 200%;
  border: 1px solid #000;
}
.features-sec {
  background-image: url("../../public/assest/photos/hosting/bottom-bg.png");
  background-repeat: no-repeat;
}
.fea > h2 {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  display: block;
  color: #436fe8;
  font-size: 35px;
  font-weight: 600;
}
.content-box h3,
.content-box p,
.icon-box svg {
  color: #444;
  transition: 0.3s ease-in;
}
.fea > h2::after,
.titleh2 > h2::after,
.titleh2w2 > h2::after {
  background-color: #fed501;
  content: "";
  width: 60px;
  height: 3px;
  position: absolute;
  left: 0;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}
.one-box {
  padding: 50px 20px;
  background-color: #fbfbfb;
  margin: 20px 0;
  border: 1px solid #fbfbfb;
  border-radius: 10px;
  box-shadow: rgb(100 100 111 / 20%) 0 2px 5px 0;
  display: inline-flex;
  min-height: 200px;
  transition: 0.3s ease-in;
  cursor: pointer;
}
.slider-div4,
.slider-div5,
.slider-div9 {
  position: relative;
  background-color: #f5f5f5;
  text-align: center;
}
.op,
.op2 {
  min-height: 550px;
}
.op,
.op2,
.op3 {
  transition: 0.3s;
  height: 100%;
  opacity: 0.5;
}
.icon-box {
  margin-right: 20px;
}
.icon-box svg {
  font-size: 50px;
  margin-top: 10px;
}
.content-box h3 {
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
}
.content-box p {
  font-size: 14px;
  line-height: 20px;
}
.drop-new-span:hover,
.main li:hover .drop-new-span,
.main-drop li:hover .drop-new-span,
.one-box:hover h3,
.one-box:hover p,
.one-box:hover svg,
.whychoose-sec .slick-slide .slider-div9 .op:hover .slider-img9,
.whychoose-sec .slick-slide .slider-div9 .op:hover p,
ul.ruby-menu > li.ruby-active-menu-item:hover > a,
ul.ruby-menu > li.ruby-active-menu-item > a:hover,
ul.ruby-menu > li:hover > a,
ul.ruby-menu > li > a:hover {
  color: #fff;
}
.titleh2 > h2 {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  display: block;
  color: #436fe8;
  font-weight: 600;
}
.titleh2w > h2 {
  position: relative;
  text-align: center;
  display: block;
  font-weight: 600;
  color: #fff;
}
.titleh2w > h2::after {
  background-color: #fed501;
  content: "";
  width: 60px;
  height: 3px;
  position: absolute;
  left: 0;
  bottom: 24px;
  right: 0;
  margin: 0 auto;
}
.titleh2w > h1 {
  color: #fff;
  font-size: 12px;
  text-align: center;
}
.titleh2w2 > h2 {
  position: relative;
  text-align: center;
  display: block;
  margin-bottom: 75px;
  font-weight: 600;
  color: #fff;
}
.shared-title-p {
  text-align: center;
  font-size: 20px;
  line-height: 43px;
  margin: 40px 0;
}
.slider-img4,
.slider-img9 {
  margin: 10px 0;
  color: #000;
  font-weight: 400;
}
.whychoose-sec {
  padding-bottom: 30px;
}
.slider-div5 {
  padding: none;
}
.op {
  padding: 150px 0;
}
.op2 {
  padding: 50px 0;
}
.slider-img4 {
  font-size: 20px;
}
.slider-img9 {
  font-size: 25px;
}
.slider-img5 {
  font-size: 20px;
  font-family: arial-bold;
  margin: 20px 0;
  color: #000;
}
.b2b-section-second svg,
.slider-div4 svg,
.slider-div5 svg,
.slider-div6 svg,
.slider-div9 svg {
  font-size: 100px;
  color: #000;
}
.b2b-section-second p,
.slider-div4 p,
.slider-div5 p {
  color: #000;
  padding: 0 40px;
  font-size: 14px;
  line-height: 22px;
}
.email-sub-head {
  text-align: center;
  font-size: 27px;
  font-family: arial-bold;
  text-transform: uppercase;
  padding: 50px 0;
}
.slider-div9 p {
  color: #000;
  padding: 0 40px;
  font-size: 16px;
  line-height: 25px;
}
.slider-div4 img,
.slider-div9 img {
  width: 100px;
  margin: 0 auto;
}
.b2b-section-second:hover .op3,
.slider-div4:hover .op,
.slider-div5:hover .op,
.slider-div6:hover .op,
.slider-div6:hover .op2 {
  opacity: 1;
  background-color: #fed501;
  padding: none;
}
.slider-div9:hover .op {
  opacity: 1;
  background-color: var(--primary-bg-color);
  padding: none;
  color: #fff;
}
.slider-div5 img,
.slider-div6 img {
  width: 140px;
  margin: 0 auto;
}
.slider-div6 {
  text-align: center;
  border: 0.5px solid #ccc;
  padding: 0;
}
.b2b-border1,
.b2b-border3 {
  border-bottom: 1px solid #ccc;
}
.slider-div6 p {
  color: #000;
  padding: 28px 40px;
  font-size: 17px;
  line-height: 30px;
}
.whychoose-sec .slick-slide > div {
  margin: 0 10px;
}
.whychoose-sec .slick-list {
  margin: 0 -10px;
}
.bestprice-sec {
  background-image: url("../../public/assest/photos/hosting/blue-bg.png");
  padding: 60px 0;
  background-repeat: round;
}
.titleh2w p {
  color: #fff;
  margin-top: 30px;
  text-align: center;
  letter-spacing: 0;
  font-weight: 400;
}
.op3 {
  padding: 50px 0;
}
.b2b-banner-div {
  position: absolute;
  top: 20%;
  text-align: end;
  right: 100px;
}
.b2b-banner-div > h3 {
  font-size: 16px;
  line-height: 26px;
  margin: 25px 1px;
}
.b2b-banner-div > h1,
.b2c-banner-div > h1 {
  margin-bottom: 20px;
}
.b2b-banner-div > h1 {
  font-family: arial-bold;
  font-size: 56px;
  color: #000;
}
.b2b-banner-div > h2,
.b2c-banner-div > h1 {
  font-family: arial-bold;
  font-size: 43px;
  color: #000;
  line-height: 62px;
  text-transform: uppercase;
}
.b2b-banner-div > .b2b-1 {
  font-family: arial;
  font-size: 23px;
  line-height: 40px;
  color: #000;
  margin-bottom: 20px;
}
.b2c-banner-div {
  position: absolute;
  top: 16%;
  text-align: start;
  left: 173px;
}
.b2c-banner-div > h3 > a {
  padding: 16px 40px;
  border-radius: 6px;
  background: linear-gradient(to left, #0b76b4 50%, #fff 50%) right;
  color: #fff;
  font-size: 14px;
  background-size: 200%;
  transition: 0.5s ease-out;
}
.b2b-section-second {
  position: relative;
  background-color: #fff;
  text-align: center;
  padding: 0;
}
.b2b-section-second img {
  width: 80px;
  margin: 0 auto;
}
.b2b-slider-img5 {
  font-size: 20px;
  font-family: arial;
  margin: 20px 0;
  color: #000;
}
.b2b-border1 {
  border-right: 1px solid #ccc;
}
.b2b-border3 {
  border-right: none;
}
.b2b-industrial {
  height: 100%;
  width: 100%;
  position: relative;
}
.b2b-industrial-text {
  position: absolute;
  bottom: 0;
  padding: 20px 0;
  font-family: arial;
  width: 100%;
  text-align: center;
  font-size: 25px;
  background-color: #fed501;
  left: 0;
  visibility: hidden;
}
.b2b-industrial-div:hover > .b2b-industrial-text,
.profile-button:hover .profile-div {
  visibility: visible;
  transition: 1s;
}
.b2b-industrial:hover {
  transition: 1s;
  opacity: 0.5;
}
.b2b-our-client-section {
  background-color: #d0dbf9;
  margin: 40px 0;
  padding: 20px 0;
}
.margin-top-bott {
  margin: 40px 0;
}
.b2b-testimonial-section-margin {
  margin-top: 0;
  margin-bottom: 40px;
  position: relative;
  background-image: url("../../public/assest/photos/background-img-yellow.png");
  background-size: 100% 100%;
  padding-top: 80px;
  padding-bottom: 100px;
}
.b2b-testimonial-slider-text {
  text-align: left;
  color: #000;
  font-family: arial;
  line-height: 29px;
  font-size: 16px;
  padding: 27px 35px;
}
.b2b-testinomial-heading {
  padding-bottom: 80px;
  color: #000;
  font-family: arial-bold;
  font-size: 35px;
  text-align: left;
  position: relative;
}
.b2b-testinomial-section-name,
.b2b-testinomial-section-name10,
.b2b-testinomial-section-name11,
.b2b-testinomial-section-name12,
.b2b-testinomial-section-name13,
.b2b-testinomial-section-name14,
.b2b-testinomial-section-name15,
.b2b-testinomial-section-name16,
.b2b-testinomial-section-name17,
.b2b-testinomial-section-name2,
.b2b-testinomial-section-name3,
.b2b-testinomial-section-name4,
.b2b-testinomial-section-name5,
.b2b-testinomial-section-name6,
.b2b-testinomial-section-name7,
.b2b-testinomial-section-name8,
.b2b-testinomial-section-name9 {
  border-radius: 5px;
  background-color: #000;
  padding: 20px 31px 20px 87px;
  transform: translate(-11%, -150%);
  float: right;
  transition: 1s;
}
.b2b-slider-img2,
.case-banner-div,
.case-sub-banner-div,
.case-sub-banner-div2,
.slider-team-text,
.sub-banner-div {
  transform: translate(-50%, -50%);
}
.b2b-testinomial-section-name10 > h3,
.b2b-testinomial-section-name11 > h3,
.b2b-testinomial-section-name12 > h3,
.b2b-testinomial-section-name13 > h3,
.b2b-testinomial-section-name14 > h3,
.b2b-testinomial-section-name15 > h3,
.b2b-testinomial-section-name16 > h3,
.b2b-testinomial-section-name17 > h3,
.b2b-testinomial-section-name2 > h3,
.b2b-testinomial-section-name3 > h3,
.b2b-testinomial-section-name4 > h3,
.b2b-testinomial-section-name5 > h3,
.b2b-testinomial-section-name6 > h3,
.b2b-testinomial-section-name7 > h3,
.b2b-testinomial-section-name8 > h3,
.b2b-testinomial-section-name9 > h3,
.b2b-testinomial-section-name > h3 {
  font-size: 16px;
  font-family: arial;
  color: #fff;
  margin-bottom: 3px;
}
.testimonial-comma {
  width: 70px;
}
.b2b-testinomial-section-name::before {
  position: absolute;
  border-radius: 5px;
  background-image: url("../../public/assest/photos/review-logo/techmech.png");
  background-repeat: no-repeat;
}
.b2b-testinomial-section-name2::before {
  position: absolute;
  border-radius: 5px;
  background-image: url("../../public/assest/photos/review-logo/ameenji.png");
  background-repeat: no-repeat;
}
.b2b-testinomial-section-name3::before {
  position: absolute;
  border-radius: 5px;
  background-image: url("../../public/assest/photos/background-grey.jpeg");
  background-repeat: no-repeat;
}
.b2b-testinomial-section-name4::before {
  position: absolute;
  border-radius: 5px;
  background-image: url("../../public/assest/photos/review-logo/shantiniketan.png");
  background-repeat: no-repeat;
}
.b2b-testinomial-section-name5::before {
  position: absolute;
  border-radius: 5px;
  background-image: url("../../public/assest/photos/review-logo/aqua-biotech.png");
  background-repeat: no-repeat;
}
.b2b-testinomial-section-name8::before {
  position: absolute;
  border-radius: 5px;
  background-image: url("../../public/assest/photos/review-logo/esennarlogo.png");
  background-repeat: no-repeat;
}
.b2b-testinomial-section-name9::before {
  position: absolute;
  border-radius: 5px;
  background-image: url("../../public/assest/photos/review-logo/candeur-landmark.png");
  background-repeat: no-repeat;
}
.b2b-testinomial-section-name10::before {
  position: absolute;
  border-radius: 5px;
  background-image: url("../../public/assest/photos/review-logo/ecolife_logo.png");
  background-repeat: no-repeat;
}
.b2b-testinomial-section-name13::before {
  position: absolute;
  border-radius: 5px;
  background-image: url("../../public/assest/photos/review-logo/tsg-audio-nnovations.png");
  background-repeat: no-repeat;
}
.b2b-testinomial-section-name15::before {
  position: absolute;
  border-radius: 5px;
  background-image: url("../../public/assest/photos/review-logo/ravoos.png");
  background-repeat: no-repeat;
}
.b2b-testinomial-section-name16::before {
  position: absolute;
  border-radius: 5px;
  background-image: url("../../public/assest/photos/review-logo/rajapushpa.png");
  background-repeat: no-repeat;
}
.b2b-slider-div2 {
  height: 210px;
  background-image: url("../../public/assest/photos/background-yellow-slider.png");
  background-repeat: no-repeat;
  position: relative;
  border-radius: 17px;
  text-align: center;
  border-right: 4px solid #fff;
  color: #000;
  outline: 0;
}
.b2b-slider-img2 {
  font-size: 14px;
  position: absolute;
  color: #000;
  left: 50%;
  top: 50%;
  line-height: 20px;
  font-family: arial-bold;
  text-transform: uppercase;
}
.blog-banner-div {
  position: absolute;
  top: 24%;
  text-align: end;
  left: 173px;
}
.blog-banner-div > h2 {
  font-family: arial-bold;
  font-size: 111px;
  line-height: 62px;
  padding-bottom: 21px;
  border-bottom: 6px solid #fed501;
  border-radius: 5px;
  color: #000;
  position: relative;
}
.blog-images-section2 > div,
.blog-images-section > div {
  margin-bottom: 25px;
  border-radius: 7px;
  box-shadow: 0 0 10px 0 #ccc;
  width: 100%;
  overflow: hidden;
}
.blog-images-section {
  padding: 40px 80px;
}
.blog-images-section > div > img {
  width: 100%;
  transition: 0.6s linear;
  height: 300px;
  overflow: hidden;
}
.blog-images-section2 > div > h1,
.blog-images-section > h4 {
  padding: 20px 0;
}
.blog-images-section2 > div > img:hover,
.blog-images-section > div > img:hover {
  transform: scale(1.2);
}
.blog-images-section2 > div {
  margin-top: 20px;
  height: 550px;
}
.blog-images-section2 > div > img {
  width: 100%;
  transition: 0.6s linear;
  height: 100%;
  overflow: hidden;
  object-fit: fill;
}
.blog-images-section2 > h5,
.blog-images-section > h5 {
  color: #6c757d;
  font-family: arial;
  line-height: 26px;
  font-size: 17px;
}
.blog-images-section2 > h4 {
  color: #0062cc;
  font-family: arial-bold;
  line-height: 80px;
  font-size: 23px;
}
.blog-images-section > a > span > h2 {
  font-size: 15px;
  padding: 13px 24px;
  font-family: arial-bold;
  margin: 20px 0;
  width: 180px;
  border-radius: 15px;
  border: 2px solid #adb5bd;
}
.blog-images-section > a > span > h2:hover {
  background-color: #fed501;
  border: 2px solid #ffc107;
  transition: 1s;
}
.blog-images-section2 > p {
  color: #fff;
  line-height: 33px;
  font-family: arial-bold;
  padding: 21px 50px;
  margin: 20px 0;
  background-color: #436fe8;
}
.blog-images-section2 > h1 {
  color: #fff;
  line-height: 33px;
  font-family: arial-bold;
  padding: 21px 50px;
  font-size: 18px;
  margin: 20px 0;
  background-color: #436fe8;
}
.blog-section {
  padding: 100px 0;
  border-bottom: 34px solid #fed501;
}
.career-img-banner {
  height: auto;
}
.career-banner-div,
.meet-our-team-banner-div {
  position: absolute;
  top: 43%;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
}
.career-banner-div > h3 {
  font-size: 22px;
  line-height: 39px;
  margin: 22px 1px;
  color: #fff;
  font-family: arial;
}
.career-banner-div > h2 {
  color: #fff;
  font-family: arial-bold;
  font-size: 50px;
}
.career-yellow-div {
  display: flex;
  margin-top: -50px;
  justify-content: center;
}
.career-yellow-div > h2 {
  font-size: 22px;
  line-height: 39px;
  text-align: center;
  padding: 9px 0;
  border-radius: 50px;
  box-shadow: 0 0 3px 0 #adb5bd;
  margin: 22px 1px;
  background-color: #fed501;
  width: 302px;
  color: #495057;
  font-family: arial;
}
.career-second-section {
  padding: 80px 0;
}
.career-second-div > h4 {
  font-size: 28px;
  position: relative;
  font-family: arial;
  padding-top: 30px;
}
.career-at-g-rank-section {
  background-color: #e8e8e8;
  padding: 40px 0;
}
.career-at-g-rank-div > div {
  margin: 13px 0;
  height: 180px;
  padding: 30px 25px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 #ccc;
  border-left: 20px solid #fed501;
}
.career-at-g-rank-div > div:hover {
  background-color: var(--primary-bg-color);
  color: #fff;
  transition: 0.6s;
}
.career-at-g-rank-div > div > h4 {
  font-size: 18px;
  padding: 20px 0;
  font-family: arial-bold;
}
.career-at-g-rank-div > div > h2 {
  font-size: 16px;
  padding: 0;
  font-family: arial;
}
.career-at-g-rank-heading {
  text-align: center;
  padding: 20px 0;
}
.career-at-g-rank-heading > h4 {
  padding: 20px 0;
  text-transform: uppercase;
  font-size: 33px;
  font-family: arial-bold;
}
.career-at-g-rank-heading > h2 {
  font-size: 25px;
  font-family: arial;
}
.career-dont-see-div h4 {
  font-size: 43px;
  font-family: arial-bold;
}
.career-dont-see-div h2 {
  font-size: 24px;
  padding: 15px 0;
  font-family: arial;
  letter-spacing: 1px;
}
.case-banner-div > h2,
.case-sub-banner-div2 > h2,
.case-sub-banner-div > h2 {
  line-height: 62px;
  padding-bottom: 21px;
  position: relative;
  border-radius: 5px;
}
.career-dont-see-section {
  padding: 66px 0;
  background-color: #fed50166;
  border-bottom: 20px solid #fed501;
}
.case-banner-div {
  position: absolute;
  top: 50%;
  left: 50%;
}
.case-banner-div > h2 {
  font-family: arial-bold;
  font-size: 35px;
  border-bottom: 5px solid #fed501;
  color: #000;
  line-height: 45px;
  text-transform: uppercase;
}
.case-studies-div > a > h4 {
  text-transform: uppercase;
  color: #436fe8;
  font-size: 40px;
  padding-left: 20px;
}
.case-sub-banner-div {
  position: absolute;
  top: 90%;
  left: 25%;
}
.case-sub-banner-div > h2 {
  font-family: arial-bold;
  font-size: 24px;
}
.case-sub-banner-div2 > h2::before,
.case-sub-banner-div > h2::before,
.what-we-offer-div p::before {
  content: "";
  position: absolute;
  top: 32%;
  left: -43px;
  width: 15px;
  border-radius: 50%;
  height: 15px;
  background-color: var(--primary-bg-color);
}
.case-sub-banner-div2 {
  position: absolute;
  top: 90%;
  left: 75%;
  display: flex;
  justify-content: space-evenly;
}
.case-sub-banner-div2 > h2 {
  font-family: arial-bold;
  font-size: 25px;
}
.case-div-new-partner {
  text-align: center;
  margin: 30px 0px;
}

.case-div-new-partner > a > img {
  width: 70%;
}

.case-div-new {
  position: relative;
  background-image: linear-gradient(
      0deg,
      rgba(48, 28, 22, 0.7),
      rgba(48, 28, 22, 0.7)
    ),
    url("../../public/assest/photos/case-studies/case-studies-img1.png");

  min-height: 181px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  margin: 30px 0px;
}
.case-div-new2 {
  position: relative;
  background-image: linear-gradient(
      0deg,
      rgba(48, 28, 22, 0.7),
      rgba(48, 28, 22, 0.7)
    ),
    url("../../public/assest/photos/case-studies/case-studies-img2.png");

  min-height: 181px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 30px 0px;
  border-radius: 5px;
}
.case-div-new3 {
  position: relative;
  background-image: linear-gradient(
      0deg,
      rgba(48, 28, 22, 0.7),
      rgba(48, 28, 22, 0.7)
    ),
    url("../../public/assest/photos/case-studies/case-studies-img3.png");

  min-height: 181px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  margin: 30px 0px;
}
.case-div-new4 {
  position: relative;
  background-image: linear-gradient(
      0deg,
      rgba(48, 28, 22, 0.7),
      rgba(48, 28, 22, 0.7)
    ),
    url("../../public/assest/photos/case-studies/case-studies-img4.png");

  min-height: 181px;
  background-size: cover;
  border-radius: 5px;
  background-repeat: no-repeat;
  margin: 30px 0px;
}
.case-div-new5 {
  position: relative;
  background-image: linear-gradient(
      0deg,
      rgba(48, 28, 22, 0.7),
      rgba(48, 28, 22, 0.7)
    ),
    url("../../public/assest/photos/case-studies/case-studies-img5.png");

  min-height: 181px;
  background-size: cover;
  border-radius: 5px;
  background-repeat: no-repeat;
  margin: 30px 0px;
}
.case-div-new6 {
  position: relative;
  background-image: linear-gradient(
      0deg,
      rgba(48, 28, 22, 0.7),
      rgba(48, 28, 22, 0.7)
    ),
    url("../../public/assest/photos/case-studies/case-studies-img6.png");

  min-height: 181px;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 30px 0px;
}
.case-div-new7 {
  position: relative;
  background-image: linear-gradient(
      0deg,
      rgba(48, 28, 22, 0.7),
      rgba(48, 28, 22, 0.7)
    ),
    url("../../public/assest/photos/case-studies/jayabheri-banner.png");

  min-height: 181px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  margin: 30px 0px;
}
.case-div-new8 {
  position: relative;
  background-image: linear-gradient(
      0deg,
      rgba(48, 28, 22, 0.7),
      rgba(48, 28, 22, 0.7)
    ),
    url("../../public/assest/photos/case-studies/case-studies-img8.png");

  min-height: 181px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  margin: 30px 0px;
}
.case-div-new9 {
  position: relative;
  background-image: linear-gradient(
      0deg,
      rgba(48, 28, 22, 0.7),
      rgba(48, 28, 22, 0.7)
    ),
    url("../../public/assest/photos/case-studies/case-studies-img9.png");

  min-height: 181px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  margin: 30px 0px;
}

.case-div-new-partner > h4,
.case-div-new2-partner > h4,
.case-div-new3-partner > h4,
.case-div-new4-partner > h4,
.case-div-new5-partner > h4,
.case-div-new6-partner > h4,
.case-div-new > h4,
.case-div-new2 > h4,
.case-div-new3 > h4,
.case-div-new4 > h4,
.case-div-new5 > h4,
.case-div-new6 > h4,
.case-div-new7 > h4,
.case-div-new8 > h4,
.case-div-new9 > h4 {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  font-family: "arial-bold";
  transform: translate(-50%, -50%);
  width: 100%;
  color: #fff;
}
.sub-banner-div {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
}
.sub-banner-div > h1 {
  font-family: arial-bold;
  font-size: 96px;
}
.sub-banner-div > h2 {
  margin: auto;
  padding-left: 20px;
  font-size: 53px;
  font-family: arial-bold;
}
.what-we-offer-div p,
.what-we-offer-div2 p {
  padding: 17px 0;
  font-family: arial-bold;
  position: relative;
}
.what-we-offer-div p {
  font-size: 17px;
}
.what-we-offer-div2 p {
  font-size: 25px;
}
.performax-end-div {
  background-image: url("../../public/assest/photos/grey-back-performax.png");
  background-repeat: no-repeat;
}
.result-img > img {
  padding: 50px 0;
  height: 550px;
}
.result-text-section {
  padding: 50px 0;
  border-bottom: 20px solid #fed501;
}
.result-text h1 {
  width: 72%;
  margin: auto;
  font-size: 18px;
  line-height: 36px;
  padding: 20px 0;
  font-family: arial-bold;
}
.pt-list-info li,
div.ruby-menu-demo-cs-1 table {
  line-height: 20px;
}
.result-text h2 {
  font-size: 76px;
  color: #436fe8;
  font-family: arial-bold;
}
.result-text h3 {
  font-size: 20px;
  font-family: arial-bold;
}
.login-sign-div {
  margin-left: 20px;
  display: flex;
  background-color: #094bff52;
  padding: 2px 0;
  border-radius: 5px;
}
.log-in-head {
  font-size: 17px;
  font-family: arial;
  padding: 3px 20px;
}
.sign-section {
  background-image: url("../../public/assest/photos/client-testimonial-texture.png");
  background-repeat: no-repeat;
}
.sign-in-text {
  color: var(--primary-bg-color);
  font-size: 28px;
  font-family: arial-bold;
}
.sign-img {
  width: 80%;
  padding: 44px 0;
}
.login-paper-div {
  background-image: url("../../public/assest/photos/loging-back-img.png");
  background-repeat: no-repeat;
  background-color: #0062cc;
}
.addtocard-head {
  font-size: 15px;
  padding: 4px 16px;
  font-family: arial;
  color: #0430ab;
}
.sign-up-head,
.sign-up-head2 {
  font-size: 14px;
  font-family: arial;
  text-transform: capitalize;
  padding: 0 16px;
  margin: 1px 7px;
}
.sign-up-head {
  color: #fff;
  border-radius: 4px;
  background-color: #436fe8;
  border: 1px solid #436fe8;
}
.sign-up-head2 {
  color: #fff;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #000;
}
.security-table-section {
  padding: 40px;
}
.table-section {
  padding: 60px;
}
.pt-comparison-table .table td,
.pt-comparison-table .table th {
  padding: 10px 5px;
}
.pt-comparison-table .table-bordered thead td,
.pt-comparison-table .table-bordered thead th {
  border-color: var(--grey-color);
}
.pt-plan-img img {
  height: 45px;
}
.pt-comparison-table .pt-button {
  white-space: nowrap;
}
.pt-comparison-table .table-striped thead {
  color: #436fe8;
  background-color: #f7f9f9;
}
.pt-comparison-table .table-striped thead th .cell-inner span {
  font-weight: 500;
}
.pt-comparison-table .table-striped tbody tr,
.pt-domain-price .table td {
  background: #f7f9f9;
}
.pt-comparison-table .table-striped thead th {
  border: 1px solid #d3d3d3;
}
.pt-comparison-table .table td.cta {
  position: relative;
  text-align: center;
}
.pt-comparison-table .cell-tag-bottom {
  position: absolute;
  left: 30%;
  top: 90%;
}
.pt-comparison-table .cell-tag {
  background: #fed501;
  color: #000;
}
.pt-comparison-table .cell-tag:after {
  border-top-color: #fed501;
}
.table-p {
  padding: 35px 0;
  text-align: center;
  margin-top: 41px;
  color: #fff;
  font-size: 24px;
}
.pt-comparison-table .cell-tag-bottom a {
  background: #fed501;
  color: #436fe8;
  font-weight: 700;
}
.pt-comparison-table .cell-inner img,
.table-add-to-cart-div > h4 > div > img {
  width: 15px;
}
.price-box .pt-price-title {
  text-transform: uppercase;
  font-size: 16px;
  color: #555;
  font-weight: 600;
}
.price-box .pt-list-info {
  margin: 0;
  padding: 0;
}
.price-box .price {
  color: #436fe8;
  font-size: 35px;
  text-align: right;
  padding-right: 25px;
}
.pricing-section {
  background-color: #fffcdf;
}
.price-box {
  background-image: url("../../public/assest/photos/table-img/40.png");
  background-repeat: no-repeat;
  padding: 40px 70px;
}
.pt-list-info li {
  font-size: 14px;
  color: #555;
}
.price-box .pt-price-duration {
  text-align: right;
  padding-right: 25px;
  color: #555;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.pl0,
div.ruby-grid
  > div.ruby-row
  > [class^="ruby-col"]
  [class^="ruby-col"]:first-of-type {
  padding-left: 0;
}
.pr0 {
  padding-right: 0;
}
.pt-pricebox-info {
  padding-top: 20px;
}
.mt40 {
  margin-top: 40px;
}
.mt80 {
  margin-top: 80px;
}
.titleh2w h2 {
  color: #fff;
  padding: 40px 0;
  font-family: arial-bold;
  text-align: center;
  letter-spacing: 0;
  font-weight: 400;
}
.b2b-message-div {
  color: #333;
  font-family: tahoma;
  font-size: 3rem;
  font-weight: 100;
  line-height: 1.5;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  width: 550px;
}
.hov,
.hov-drop {
  line-height: 40px;
}
.b2b-message-div span {
  font-size: 40px;
  margin-left: 40px;
}
.b2b-message {
  color: #fed501;
  display: block;
  font-weight: 400;
  font-size: 44px;
  overflow: hidden;
  position: absolute;
  padding-left: 0.5rem;
  animation: 5s ease-in-out infinite openclose;
}
.b2b-message-h2 {
  font-size: 50px;
  font-family: arial-bold;
  text-transform: uppercase;
  color: #436fe8;
}
.b2b-message-p,
.total-payment > h2 > span {
  font-size: 17px;
}
.b2b-message-p {
  font-family: arial;
  padding: 66px 0;
}
.word1,
.word2,
.word3 {
  font-family: arial-bold;
}
@keyframes openclose {
  0%,
  33%,
  35% {
    top: 0.2rem;
    width: 0;
  }
  5% {
    width: 0;
  }
  15% {
    width: 450px;
  }
  30% {
    top: 0.2rem;
    width: 450px;
  }
  38% {
    top: -4.5rem;
  }
  48%,
  62% {
    top: -4.5rem;
    width: 450px;
  }
  66% {
    top: -4.5rem;
    width: 0;
    text-indent: 0;
  }
  71%,
  98% {
    top: -9rem;
    width: 0;
    text-indent: 5px;
  }
  86%,
  95% {
    top: -9rem;
    width: 450px;
  }
  100% {
    top: 0;
    width: 0;
    text-indent: 0;
  }
}
.circular-percentage-h1 {
  text-align: center;
  font-size: 22px;
  font-family: arial;
  color: #fff;
}
.main-slider-custome-button-next,
.main-slider-custome-button-prev {
  background-color: #f8f9faa3;
  color: #0062cc;
  width: 40px;
  bottom: 45%;
  padding: 5px;
  height: 40px;
  position: absolute;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}
.desktop-none,
.hov23,
.hov24,
ul.ruby-menu > li.ruby-menu-social > a > span {
  display: none;
}
.main-slider-custome-button-prev {
  display: block;
  right: 14%;
  z-index: 2;
}
.main-slider-custome-button-next {
  display: block;
  right: 10%;
}
.dashboard-div,
.dashboard1-div,
.dashboard2-div,
.dashboard3-div {
  margin: 0 10px;
  padding: 11px 10px;
  font-size: 3px;
  border-radius: 10px;
  color: #fff;
}
.secured-logo {
  height: 54px;
}
.dashboard-div {
  background-color: #f59053;
}
.dashboard-div > h1,
.dashboard1-div > h1,
.dashboard2-div > h1,
.dashboard3-div > h1 {
  font-family: arial;
  font-size: 18px;
}
.dashboard-div > h2,
.dashboard1-div > h2,
.dashboard2-div > h2,
.dashboard3-div > h2 {
  font-family: arial;
  font-size: 20px;
  padding-top: 15px;
}
.dashboard1-div {
  background-color: #85a3f5;
}
.dashboard2-div {
  background-color: #6bc17f;
}
.dashboard3-div {
  background-color: #17a2b8;
}
.your-cart-container {
  padding: 0 5vw;
}
.cart-count,
.cart-count2 {
  background-color: red;
  color: #fff;
  box-shadow: 1px 1px 1px 0 #6c757d;
  padding: 3px 6px;
  position: absolute;
  font-size: 11px;
  top: -17%;
  border-radius: 50px;
}
.total-payment {
  background: #f1f4f6;
  border-radius: 7px;
  margin-bottom: 32px;
  padding: 27px;
}
.total-payment h2 {
  font-size: 26px;
  font-family: arial-bold;
}
.total-price {
  float: right;
  font-family: arial;
  font-size: 20px;
  color: #09757a;
}
.profile-button-img,
.total-cart > div > h4 > img,
.total-price > img {
  width: 20px;
}
.total-payment h3 {
  text-align: center;
  font-size: 14px;
  font-family: arial-bold;
  text-decoration: underline;
}
.total-payment p {
  font-family: arial;
  padding: 12px 0;
  font-size: 15px;
}
.total-payment h5 {
  font-size: 17px;
  padding: 14px 31px;
  font-family: arial-bold;
  display: flex;
}
.ready-to-pay,
.total-payment h4 {
  font-size: 18px;
  text-align: center;
}
.total-payment h4 {
  font-family: arial-bold;
  padding: 14px;
}
.total-payment > h4 > img {
  width: 18px;
}
.total-payment > h5 > pre {
  font-size: 15px;
  font-family: arial;
  padding: 0 7px;
}
.ready-to-pay {
  width: 100%;
  background-color: #000;
  color: #fff;
  font-family: arial-bold;
  padding: 10px;
}
.total-pay-img img {
  height: 23px;
  width: 47px;
  border-radius: 5px;
  object-fit: contain;
  margin: 0 3px;
  box-shadow: 0 0 3px 0 #ccc;
}
.total-cart {
  display: flex;
  padding: 34px 20px;
  position: relative;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
}
.total-cart > div > h1 {
  font-size: 22px;
  font-family: arial-bold;
  text-transform: capitalize;
  padding: 10px 0;
}
.total-cart > div > p {
  font-size: 14px;
  font-family: arial;
}
.total-cart > div > h2,
.total-cart > div > h4 {
  font-size: 17px;
  font-family: arial-bold;
  color: #09757a;
}
.total-cart > div > img {
  padding: 0 20px;
  height: 61px;
}
.total-cart-button {
  width: 41px;
  border: none;
  font-size: 21px;
  background-color: #eceff8;
}
.cart-img-div {
  text-align: center;
  width: 132px;
  margin-right: 20px;
  background-color: #f8f9fa;
  padding-top: 40px;
}
.cart-Quatity-h6 {
  padding: 0;
  font-family: arial;
  color: #666;
  text-transform: capitalize;
}
.total-cart-span {
  width: 39px;
  font-family: arial;
  font-size: 18px;
  margin: 0;
  color: #6c757d;
  padding: 5px 14px;
}
.total-cart > div > h2 {
  float: right;
  text-align: end;
  width: 140px;
  right: 24px;
  bottom: 21%;
  display: flex;
  position: absolute;
}
.total-cart > div > h2 > img {
  height: 20px;
}
.total-cart > div > h4 {
  padding: 15px 0;
}
.total-cart-header {
  padding: 19px 0;
}
.total-cart-header h1 {
  font-size: 20px;
  text-transform: capitalize;
  color: #074b94;
  padding-bottom: 13px;
  font-family: arial;
}
.cart-icon {
  margin: 0 8px;
}
.total-cart-select {
  width: 116px;
  padding: 5px;
  text-align: center;
  height: 34px;
  margin-left: 10px;
  margin-top: 19px;
  background-color: #fff;
  box-shadow: 0 0 0 0 #dee2e6;
  font-family: arial;
  font-size: 15px;
  color: #666;
  border: 1px solid #495057;
}
.meet-our-core-team-section,
.meet-our-core-team-section2,
.meet-our-core-team-section3 {
  height: 680px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.button-dis {
  position: absolute;
  cursor: pointer;
  top: 5%;
  right: 10px;
}
.rechart-div {
  padding: 50px 0;
  margin: 10px 0;
  border-right: 1px solid #ccc;
}
.meet-our-festive-moment3,
.meet-our-team {
  padding: 40px 0;
  float: right;
}
.line-chart {
  width: 100px;
}
.meet-our-team {
  text-align: left;
  font-size: 21px;
  font-family: arial-bold;
  color: #fff;
  text-decoration: underline;
}
.meet-our-team-banner-div > h3 {
  background-color: #ffffff85;
  border-radius: 10px;
  color: #212529;
  font-family: arial;
  font-size: 19px;
  line-height: 39px;
  margin: 4px 1px;
  padding: 10px 60px;
}
.meet-our-team-banner-div > h2 {
  color: #436fe8;
  font-family: arial-bold;
  font-size: 61px;
  padding: 10px 30px;
  text-transform: uppercase;
  border-radius: 10px;
  background-color: #ffffff85;
  text-shadow: 3px 4px #fff;
}
.meet-our-core-team-section {
  background-image: url("../../public/assest/photos/our-team/meet-our-team-banner-white.png");
}
.meet-our-core-team-section2 {
  background-image: url("../../public/assest/photos/our-team/meet-our-team-banner-yellow.png");
}
.meet-our-core-team-section3 {
  background-image: url("../../public/assest/photos/our-team/background-blue.png");
}
.meet-our-festive-moment > h2 {
  font-family: arial-bold;
  color: #fff;
  padding: 20px 0;
  font-size: 33px;
}
.meet-our-festive-moment > h3 {
  font-family: arial;
  color: #fff;
  padding: 20px 0;
  line-height: 36px;
  font-size: 21px;
}
.meet-our-festive-moment2 > h2 {
  font-family: arial-bold;
  color: #000;
  padding: 20px 0;
  font-size: 33px;
}
.meet-our-festive-moment2 > h3 {
  font-family: arial;
  color: #000;
  padding: 20px 0;
  line-height: 36px;
  font-size: 21px;
}
.meet-our-festive-moment2 > a > span {
  font-size: 21px;
  float: left;
  font-family: arial-bold;
  color: #000;
  text-decoration: underline;
  padding: 0;
}
.meet-our-festive-moment3,
.meet-our-festive-moment > a > span {
  font-size: 21px;
  color: #fff;
  text-decoration: underline;
  font-family: arial-bold;
}
.meet-our-festive-moment > a > span {
  float: left;
  padding: 0;
}
.festive-img-div {
  width: 87%;
  height: 353px;
  position: relative;
  margin-bottom: 20px;
  float: right;
  border: 3px solid #efefef;
  margin-top: 0;
  border-radius: 7px;
}
.festive-img-div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  float: right;
  border-radius: 7px;
}
.festive-img-div2 img,
.festive-img-div3 img {
  border: 3px solid #efefef;
  position: relative;
  object-fit: cover;
}
.festive-img-div > h5 {
  background-color: #efefef;
  text-align: left;
  color: #436fe8;
  font-size: 25px;
  text-transform: capitalize;
  padding: 11px 15px;
  font-family: arial-bold;
}
.festive-img-div2 img {
  width: 111%;
  height: 163px;
  float: left;
  bottom: -22px;
  margin-top: 2px;
  padding: 0;
  left: -42px;
  border-radius: 6px;
}
.festive-img-div3 img {
  width: 83%;
  height: 154px;
  box-shadow: 0 0 10px 0 #6c757d;
  bottom: 17px;
  border-radius: 5px;
  float: right;
  left: 0;
}
.festive-img-div4 img {
  width: 87%;
  height: 169px;
  object-fit: cover;
  border: 3px solid #efefef;
  position: relative;
  margin-bottom: 20px;
  float: left;
  left: -27px;
  top: -18px;
  border-radius: 7px;
}
.meet-our-festive-moment,
.meet-our-festive-moment2 {
  padding: 20px;
}
.meet-our-team2 {
  float: left;
}
.slider-div-team2 {
  height: 530px;
  border-radius: 7px;
  width: 100%;
  min-height: 400px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  outline: 0;
  cursor: pointer;
  background-image: url("../../public/assest/photos/gallery/gallery3-small2.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: 1s;
}
.slider-team-text {
  width: 90%;
  height: 100%;
  border-radius: 15px;
  position: absolute;
  top: 110%;
  left: 50%;
  display: none;
  transition: 1s;
}
.animate-charcter,
.waviy span,
.waviy-sec span,
.waviy2 span {
  display: inline-block;
  text-transform: uppercase;
}
.slider-div-team2:hover .slider-team-text,
.slider-div-team:hover .slider-team-text {
  display: block;
  transition: 1s;
}
.table-head {
  font-size: 16px;
  width: 100%;
  background-color: #f0f8ff;
  padding: 20px 10px;
}
.table-add-to-cart-div > h3 {
  color: #000;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 8px;
  font-family: arial-bold;
  background-color: #fed501;
  text-align: center;
  width: 73%;
  padding: 9px 0;
  margin: auto;
}
.table-add-to-cart-div > h4,
.table-cell-tag2 {
  text-align: center;
  width: 270px;
  background-color: #f8f9fa;
  font-family: arial-bold;
}
.table-add-to-cart-div > h4 {
  border-radius: 18px;
  padding: 11px 0;
  color: #436fe8;
  border: 1px solid #ccc;
  font-size: 30px;
}
.table-cell-tag2 {
  border-radius: 18px;
  padding: 20px 0;
  color: #6c757d;
  border: 1px solid #ccc;
  font-size: 15px;
}
.table-cell-tag,
.table-cell-tagsecu {
  letter-spacing: 1px;
  padding: 10px 0;
  font-weight: 500;
  margin: auto;
  background: #fed501;
  position: relative;
  color: #000;
  text-align: center;
}
.table-add-to-cart-div > h4 > span,
ul.ruby-menu > li.ruby-menu-mega-blog > div > ul.ruby-menu-mega-blog-nav > li {
  font-size: 14px;
}
.table-cell-tag {
  border-top-right-radius: 10px;
  font-size: 18px;
  width: 90%;
  border-top-left-radius: 10px;
}
.table-cell-tag:after,
.table-cell-tagsecu:after {
  top: 100%;
  left: 50%;
  border: 15px solid rgba(255, 75, 54, 0);
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #fed501;
  margin-left: -15px;
  transition: 0.5s ease-in-out;
}
.table-cell-tagsecu {
  border-top-right-radius: 10px;
  font-size: 14px;
  width: 94%;
  border-top-left-radius: 10px;
}
.table-static-div {
  margin-top: 42px;
}
.contact-address,
.contact-us-form,
.contact-us-form-mobile {
  margin: 20px 40px;
}
.security-padding {
  padding-bottom: 60px;
}
.secu-add-to-cart-butt {
  width: 60% !important;
}
.secu-table-text {
  border-radius: 18px;
  text-align: center;
  padding: 10px 0;
  color: #6c757d;
  font-family: arial-bold;
  font-size: 14px;
  background-color: #f8f9fa;
}
.animate-charcter {
  background-image: linear-gradient(
    -225deg,
    #f8f9fa 0,
    #fff 29%,
    #fed501 67%,
    #fff800 100%
  );
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: 2s linear infinite textclip;
  font-size: 190px;
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.waviy-sec {
  position: relative;
  font-size: 52px;
}
.waviy-sec span {
  font-family: arial-bold;
  position: relative;
  color: #436fe8;
  animation: 1s infinite waviy;
  animation-delay: calc(0.1s * var(--i));
}
.waviy {
  position: relative;
  font-size: 60px;
}
.hov32:hover .hov23,
.hov34:hover .hov24,
.hov35:hover .hov25 {
  position: absolute;
  background: #fff;
}
.waviy span {
  font-family: arial-bold;
  position: relative;
  color: #fff;
  animation: 1s infinite waviy;
  animation-delay: calc(0.1s * var(--i));
}
@keyframes waviy {
  0%,
  100%,
  40% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}
.waviy2 span {
  position: relative;
  font-size: 50px;
  color: #fff;
  animation: 2s infinite flips;
  animation-delay: calc(0.2s * var(--i));
}
@keyframes flips {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}
.contact-us-section {
  background-color: rgba(0, 123, 255, 0.25);
  padding: 70px 0;
}
.contact-us-contain {
  background-color: #fff;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  padding: 52px 0;
}
.contact-us-form h1 {
  font-size: 24px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  padding: 9px 0;
  font-family: arial-bold;
}
.contact-us-form h1.apnow {
  color: #436fe8;
  font-size: 40px;
  font-family: 'arial-bold';
  margin-bottom: 40px;
  letter-spacing: 1px;
}
.contact-us-form .sdupload{position: relative;width: 100%;
  float: left;height: 80px;
  font-size: 15px;
  color: #939393;border: none;
  /* border: 1px solid #a3a3a3; */
  padding: 18px;}
.contact-us-form .sdupload:after {
  position: absolute;
  content: "Upload CV";
  color: #436fe8;
  right: 40px;
  top: 30px;
  font-size: 17px;
  font-family: inherit;
}
.sdupload::-webkit-file-upload-button{padding: 10px 10px;background: #436fe8;
  font-size: 15px; margin-right: 25px;color: #fff; cursor: pointer;border: none;
  border-radius: 5px;}

.sdupload::-ms-browse{padding: 10px 10px; background: #436fe8; border:none; 
  border-radius: 5px; font-size: 17px; margin-right: 25px;color: #fff; cursor: pointer;}
.career-form-section{background-color: rgba(0,123,255,.05);padding: 50px 0px;}

.main li,
.main-drop li {
  text-align: start;
  list-style: none;
}
.contact-us-form p,
.contact-us-form-mobile p {
  font-size: 17px;
  color: #495057;
  line-height: 28px;
  padding: 14px 0;
  font-family: arial;
}
.contact-us-form h3,
.contact-us-form-mobile h3 {
  font-size: 15px;
  padding-top: 10px;
  color: #99a0a7;
}
.contact-us-form input,
.contact-us-form-mobile input {
  width: 100%;
  height: 44px;
  padding: 20px;
  border: none;
  box-shadow: 0 0 6px 0 #dee2e6;
  margin: 5px 0;
  border-radius: 4px;
  background-color: #fff;
}
.contact-us-form textarea,
.contact-us-form-mobile textarea {
  width: 100%;
  padding: 20px;
  border: none;
  box-shadow: 0 0 6px 0 #dee2e6;
  margin: 5px 0;
  border-radius: 4px;
  background-color: #fff;
}
.hov23 p,
.hov25 p {
  border-bottom: 1px solid #ccc;
}
.contact-us-form-mobile > form > h4 > button,
.contact-us-form > form > h4 > button {
  width: 100%;
  border: none;
  background-color: #436fe8;
  color: #fff;
  padding: 11px 0;
  border-radius: 6px;
  margin: 10px 0;
  text-transform: capitalize;
  font-family: arial-bold;
  font-size: 20px;
}
.contact-us-form-mobile > form > h4 > button:hover,
.contact-us-form > form > h4 > button:hover {
  background-color: rgba(0, 123, 255, 0.25);
  transition: 0.6s;
  color: #436fe8;
}
.contact-us-form-mobile h1 {
  font-size: 35px;
  color: #436fe8;
  padding: 0;
  font-family: arial-bold;
}
.hosting-breadcumbs {
  display: flex;
  justify-content: center;
  padding-top: 140px;
}
.contact-address {
  text-align: center;
  box-shadow: 0 0 4px 0 #dee2e6;
}
.contact-address p {
  color: #6c757d;
  padding: 20px 0;
  line-height: 28px;
  font-family: arial;
  font-size: 16px;
}
.contact-address h1 {
  font-size: 24px;
  font-family: arial-bold;
  color: #436fe8;
}
.contact-address h2 {
  font-size: 19px;
  font-family: arial;
  padding: 12px 0;
}
.contact-img {
  padding: 0 40px;
}
.gallery-block-img {
  height: 165px;
  padding: 3px 4px;
  object-fit: cover;
}
.gallery-block-img2 {
  height: 332px;
  padding: 4px;
  object-fit: cover;
}
.gallery-block-img3 {
  height: 491px;
  padding: 2px 18px;
  object-fit: cover;
}
.gallery-block-section {
  padding: 65px 0;
}
.main,
.main-drop {
  position: absolute;
  z-index: 1;
  visibility: hidden;
}
.hov,
.hov-drop,
div.ruby-menu-demo-header {
  z-index: 2;
  position: relative;
}
.hov-drop:hover .main-drop,
.hov:hover .main {
  visibility: visible;
}
.main li {
  background: #f8f9fa;
  position: relative;
  width: 91px;
  left: -10px;
  color: #436fe8;
  padding: 7px;
  box-shadow: 0 0 1px 0 #adb5bd;
  font-size: 12px;
  line-height: 18px;
  transition: 0.5s ease-in-out;
}
.hov23 p,
.hov24 p,
.hov25 p {
  color: #495057;
  font-family: arial;
  font-weight: 500;
  text-transform: capitalize;
}
.main :hover,
.main-drop li:hover {
  background-color: var(--primary-color);
  color: #fff;
  transition: 0.6s;
}
.hov li:nth-child(odd),
.hov-drop li:nth-child(odd) {
  transform-origin: top;
  transform: perspective(350px) rotateX(-90deg);
}
.hov li:nth-child(2n),
.hov-drop li:nth-child(2n) {
  margin-top: -65px;
  transform-origin: bottom;
  transform: perspective(350px) rotateX(90deg);
}
.hov-drop:hover li:nth-child(odd),
.hov:hover li:nth-child(odd) {
  transform-origin: top;
  transform: perspective(350px) rotateX(0);
  margin-top: 0;
}
.hov-drop:hover li:nth-child(2n),
.hov:hover li:nth-child(2n) {
  transform-origin: bottom;
  transform: perspective(350px) rotateX(0);
  margin-top: 0;
}
.hov {
  width: 91px;
  color: #fff;
  font-size: 13px;
  font-family: Helvetica;
  font-weight: 700;
  text-align: center;
  list-style: none;
}
.hov32:hover .hov23 {
  color: #000;
  top: 6px;
  display: block;
  left: 91px;
  width: 91px;
}
.hov23 p {
  height: 28px;
  padding: 4px 8px;
}
.hov34:hover .hov24 {
  color: #000;
  top: 0;
  display: block;
  left: 91px;
  -webkit-transform: translateY(6px);
  -ms-transform: translateY(6px);
  transform: translateY(6px);
  width: 91px;
}
.hov24 p {
  height: 32px;
  padding: 4px 10px;
}
.hov35:hover .hov25 {
  color: #000;
  top: 26px;
  display: block;
  left: 91px;
  width: 91px;
  transform: translateY(6px);
}
.hov25 p {
  height: 38px;
  line-height: 15px;
  padding: 6px;
}
.hov25 {
  display: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.teams-section-new {
  padding: 18px 6vw;
}
.teams-heading-div1 {
  text-align: center;
  background-image: url("../../public/assest/photos/termsandcondi.jpg");
  background-size: 100% 100%;
  height: 300px;
}
.teams-heading-div1 > h5 {
  font-family: arial-bold;
  color: #436fe8;
  text-transform: uppercase;
  padding-top: 111px;
  font-size: 36px;
  text-shadow: 0 4px #007bff3b;
}
.tearm-condition-div h1 {
  padding: 40px 0;
  font-family: arial-bold;
  font-size: 31px;
  color: #0062cc;
}
.tearm-condition-div h4 {
  font-family: arial-bold;
  padding-top: 22px;
  text-transform: uppercase;
  padding-bottom: 4px;
  color: #2451cc;
  font-size: 19px;
}
.tearm-condition-div h5 {
  font-size: 21px;
  line-height: 34px;
  font-family: arial-bold;
  padding: 13px 0;
}
.tearm-condition-div p {
  font-family: arial;
  font-size: 16px;
  color: #175089;
  line-height: 29px;
}
.tearm-condition-div div {
  font-family: arial;
  font-size: 16px;
  padding-left: 20px;
  padding-bottom: 8px;
  position: relative;
}
.teams-div2 p {
  font-family: arial;
  font-size: 16px;
  line-height: 23px;
  padding-top: 6px;
  padding-left: 0;
}
.number span,
.profile-div > button,
.profile-div > h1,
.profile-div > h5,
.thankyou-div h1 {
  font-family: arial-bold;
}
.teams-section {
  padding: 20px 4vw;
}
.term-flex-div > p {
  padding-right: 3px;
}
.number {
  margin-right: 10px;
  margin-left: 10px;
}
.hov-drop,
input.cmn-toggle-on + label:after,
input.cmn-toggle-round:checked + label:after {
  margin-left: 20px;
}
.number .counter {
  font-size: 3.9em;
  font-family: arial;
  margin: 3px 0;
  color: #fff;
}
.number > div > h4 {
  font-size: 24px;
  font-family: arial;
  color: #fff;
  margin: 44px 0 0 7px;
}
.number span {
  font-size: 45px;
  color: #fed501;
  display: block;
  text-transform: capitalize;
}
.main-drop li {
  background: #fff;
  position: relative;
  width: 225px;
  left: -20px;
  color: #436fe8;
  padding: 7px;
  box-shadow: 0 0 1px 0 #436fe8;
  font-size: 22px;
  line-height: 18px;
  transition: 0.5s ease-in-out;
}
.drop-new-span {
  color: #37474f;
  text-transform: capitalize;
  font-family: arial;
  font-size: 12px;
  font-weight: 100;
  text-align: center;
}
.hov-drop {
  width: 227px;
  font-size: 13px;
  font-family: Helvetica;
  font-weight: 700;
  padding: 0 20px;
  border-radius: 27px;
  border: 2px solid #436fe8;
  text-align: left;
  list-style: none;
}
.hov-drop:hover .drop-arrow {
  rotate: 180deg;
  transition: 0.6s;
}
.drop-arrow {
  float: right;
  margin-top: 9px;
}
.al-video-div {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -49%);
  left: 50%;
}
.al-video-button {
  background-color: #ffffff73;
  margin: 0 5px;
  border: 1px solid #fff;
  color: #fff;
}
.al-video-button:hover {
  background-color: #fff;
  transition: 0.6s;
  color: var(--primary-color);
}
.profile-div {
  position: absolute;
  text-align: center;
  top: 24px;
  background-color: #000000c7;
  right: 0;
  visibility: hidden;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 1;
}
.profile-div > h5 {
  font-size: 12px;
  padding: 3px;
}
.profile-div > h4 {
  font-size: 14px;
  padding: 5px;
}
.profile-div > h1 {
  font-size: 20px;
  width: 100%;
  color: #000;
}
.profile-div > button {
  border-top: 2px solid #fff;
  font-size: 14px;
  width: 92px;
  padding: 0;
  height: 26px;
  border-radius: 5px;
  border: #ffdead;
  color: #000;
}
.profile-div > button:hover {
  background-color: #bfd7f7;
}
.org-breadcru {
  top: 71%;
  left: 130px;
  position: absolute;
}
.thankyou-div {
  text-align: center;
  padding-top: 150px;
  height: 550px;
}
.thankyou-div h1 {
  color: #163277;
  font-size: 28px;
  line-height: 38px;
  padding: 10px 0;
  text-transform: capitalize;
}
.enquire-button span,
.enquire-button span:hover {
  padding: 9px 16px;
  text-transform: uppercase;
  text-decoration: none;
}
.thankyou-div h2 {
  font-size: 20px;
  line-height: 46px;
  font-family: arial;
  margin-bottom: 20px;
}
.enquire-button {
  position: fixed;
  border-radius: 0;
  right: -5px;
  bottom: 50%;
  z-index: 400;
  transform: translateY(50px);
  rotate: -90deg;
}
.enquire-button span {
  transition: color 0.2s ease-in-out;
  color: #fff;
  background-color: var(--primary-bg-color);
  border-radius: 3px;
  border: 1px solid var(--primary-color);
  cursor: pointer;
}
.enquire-button span:hover {
  transition: 0.5s ease-out;
  color: var(--primary-color);
  background: linear-gradient(to left, #fff 50%, #436fed 50%) right;
  background-size: 200%;
  border-radius: 3px;
  border: 1px solid #436fed;
}
.notification-badge {
  background-color: red; /* Badge color */
  color: white;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  top: 0;
  right: -10px;
  font-size: 12px;
  padding: 2px;
}
.contact-select-div {
  color: #6c757d;
  width: 100%;
  height: 44px;
  font-family: arial;
  padding: 0 18px;
  border: none;
  box-shadow: 0 0 6px 0 #dee2e6;
  margin: 5px 0;
  border-radius: 4px;
  background-color: #fff;
}
.contact-select-div option {
  height: 40px;
}
.overflow-div {
  display: flex;
  height: auto;
  justify-content: center;
  overflow-x: auto;
}
div.ruby-menu-demo-header {
  left: 0;
  top: 0;
  height: auto;
  padding: 0;
  margin: 0;
}
div.ruby-menu-demo-header-bg div.ruby-menu-demo-download > span,
div.ruby-menu-demo-header-bg div.ruby-menu-demo-download > span > span {
  color: #efefef;
}
div.ruby-menu-demo-description {
  position: relative;
  width: 980px;
  height: 160px;
  margin: 0 auto;
  padding: 0;
}
div.ruby-menu-demo-description img {
  max-width: 200px;
  float: left;
  margin: 50px 20px 0 0;
}
span.ruby-menu-demo-highlight {
  color: #333;
}
div.ruby-menu-demo-bg-dark,
div.ruby-menu-demo-bg-light {
  width: 980px;
  height: auto;
  position: relative;
  background: #fff;
  margin: 0 auto;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}
div.ruby-menu-demo-bg-dark {
  background: efefef;
}
div.ruby-grid > div.ruby-row::after,
div.ruby-menu-demo-bg-dark-c:after,
div.ruby-menu-demo-bg-light-c:after {
  content: "";
  display: table;
  clear: both;
}
div.ruby-menu-demo-cr-1,
div.ruby-menu-demo-cs-1,
div.ruby-menu-demo-cv-1 {
  float: left;
  width: 33%;
}
div.ruby-menu-demo-cv-2 {
  float: left;
  width: 67%;
}
div.ruby-menu-demo-cv-3 {
  float: left;
  width: 48%;
}
div.ruby-menu-demo-cv-4 {
  float: left;
  width: 52%;
}
div.ruby-menu-demo-cr-2,
div.ruby-menu-demo-cs-2 {
  float: left;
  width: 66%;
}
div.ruby-menu-demo-cf-1,
div.ruby-menu-demo-cf-2,
div.ruby-menu-demo-cf-3,
div.ruby-menu-demo-cf-4 {
  float: left;
  width: 25%;
  text-align: center;
}
.delete-icon-cart,
img.iphone7,
ul.ruby-menu > li.ruby-menu-right {
  float: right;
}
div.ruby-menu-demo-f-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto;
  padding: 22px;
  font-size: 30px;
  background: #efefef;
  color: #aaa;
}
div.ruby-menu-demo-f-title {
  width: 100%;
  height: 30px;
  margin: 20px auto 0;
  font-weight: 700;
  font-size: 18px;
}
div.ruby-menu-demo-f-desc {
  font-size: 14px;
  line-height: 24px;
  color: #555;
  padding: 15px;
}
div.ruby-menu-demo-cs-1 table td,
div.ruby-menu-demo-cs-1 table tr {
  padding: 0;
  font-weight: 700;
}
div.ruby-menu-demo-cs-1 table td:last-child {
  padding-top: 5px;
}
h2.ruby-menu-demo-slogan-1,
h3.ruby-menu-demo-slogan-1 {
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  color: #b1b1b1;
  margin: 0;
}
h1.ruby-menu-demo-slogan-2,
h3.ruby-menu-demo-slogan-2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: none;
  color: #222;
  margin: 0;
}
p.ruby-menu-demo-slogan-description,
p.ruby-menu-demo-slogan-description-2 {
  font-size: 16px;
  line-height: 24px;
  color: #444;
  margin: 35px 0;
}
p.ruby-menu-demo-slogan-description-2 {
  color: #666;
  font-weight: 600;
  margin: -10px 0;
}
span.ruby-menu-demo-code {
  color: #888;
  text-decoration: underline;
}
td.ruby-menu-demo-theme-td-1 {
  max-width: 30px;
  text-align: left;
  padding: 6px;
}
td.ruby-menu-demo-theme-td-2 {
  min-width: 120px;
  text-align: left;
  padding: 6px;
}
td.ruby-menu-demo-theme-td-1:hover {
  cursor: pointer;
}
.ruby-menu-demo-circle {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 4px solid #222;
  display: inline-block;
  margin: 0;
}
.ruby-menu-demo-color-1 {
  background: #1aad86;
}
.ruby-menu-demo-color-2 {
  background: #0c67c2;
}
.ruby-menu-demo-color-3 {
  background: #ce0661;
}
.ruby-menu-demo-color-4 {
  background: #ea7500;
}
.ruby-menu-demo-color-5 {
  background: #74ba27;
}
.ruby-menu-demo-color-6 {
  background: #ff3264;
}
.ruby-menu-demo-color-7 {
  background: #fad814;
}
.ruby-menu-demo-color-14,
.ruby-menu-demo-color-8 {
  background: #ccc;
}
.ruby-menu-demo-color-9 {
  background: #111;
  border-color: #ccc;
}
.ruby-menu-demo-color-10 {
  background: #036de2;
  border-color: #489efd;
}
.ruby-menu-demo-color-11 {
  background: #f85252;
  border-color: #444;
}
.ruby-menu-demo-color-12 {
  background: #ca79d0;
  border-color: #f2def3;
}
.ruby-menu-demo-color-13 {
  background: #efefef;
  border-color: #222;
}
div.ruby-menu-demo-download {
  position: absolute;
  width: auto;
  top: 55px;
  right: 0;
}
div.ruby-menu-demo-download .fa {
  font-size: 20px;
  margin-right: 10px;
}
div.ruby-menu-demo-download > span {
  font-size: 16px;
  display: block;
  margin: 10px 26px;
}
div.ruby-menu-demo-download > span > span {
  font-size: 20px;
  color: #555;
  font-weight: 700;
}
button.ruby-btn {
  background: #19ad86;
  border: 2px solid #19ad86;
  color: #fff;
  border-radius: 0;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
button.ruby-btn:hover {
  background: #fff;
  color: #19ad86;
}
.switch {
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
}
.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input.cmn-toggle-round + label {
  padding: 2px;
  margin-top: -10px;
  width: 40px;
  height: 20px;
  background-color: #ddd;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
}
input.cmn-toggle-round + label:after,
input.cmn-toggle-round + label:before {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
input.cmn-toggle-round + label:before {
  right: 1px;
  background-color: #f1f1f1;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round + label:after {
  width: 18px;
  background-color: #fff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -webkit-transition: margin 0.4s;
  -moz-transition: margin 0.4s;
  -o-transition: margin 0.4s;
  transition: margin 0.4s;
}
input.cmn-toggle-on + label:before,
input.cmn-toggle-round:checked + label:before {
  background-color: #8ce196;
}
input.cmn-toggle-on:checked + label:before {
  background-color: #f1f1f1;
}
input.cmn-toggle-on:checked + label:after {
  margin-left: 0;
}
img.iphone7 {
  max-width: 350px;
  margin-right: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  body {
    overflow-x: hidden;
  }
  div.ruby-menu-demo-description {
    position: relative;
    width: 760px;
    height: 160px;
    margin: 0 auto;
    padding: 0;
  }
  div.ruby-menu-demo-description img {
    max-width: 200px;
    float: left;
    margin: 50px 20px 0 0;
  }
  div.ruby-menu-demo-bg-dark,
  div.ruby-menu-demo-bg-light {
    width: 760px;
    font-size: 14px;
  }
  div.ruby-menu-demo-cs-1 {
    width: 27%;
  }
  div.ruby-menu-demo-cs-2 {
    width: 73%;
  }
  td.ruby-menu-demo-theme-td-1 {
    max-width: 27px;
    padding: 7px;
  }
  td.ruby-menu-demo-theme-td-2 {
    min-width: 100px;
    padding: 7px;
  }
  .ruby-menu-demo-circle {
    width: 22px;
    height: 22px;
    border: 3px solid #222;
  }
  img.iphone7 {
    max-width: 320px;
    float: right;
    margin-right: 20px;
  }
}
div.ruby-wrapper,
div.ruby-wrapper.ruby-vertical {
  background: #f9f9f9;
  color: #222;
}
ul.ruby-menu > li.ruby-active-menu-item > a {
  color: #222;
}
ul.ruby-menu.ruby-menu-dividers > li:hover > a,
ul.ruby-menu.ruby-menu-dividers > li > a:hover {
  border-left: 1px solid #222;
}
ul.ruby-menu.ruby-menu-dividers > li.ruby-active-menu-item > a {
  border-left: 1px solid #efefef;
}
ul.ruby-menu.ruby-vertical.ruby-menu-dividers > li.ruby-active-menu-item > a,
ul.ruby-menu.ruby-vertical.ruby-menu-dividers > li > a {
  border-left: none;
  border-right: none;
}
ul.ruby-menu.ruby-vertical.ruby-menu-dividers
  > li.ruby-active-menu-item:hover
  > a,
ul.ruby-menu.ruby-vertical.ruby-menu-dividers
  > li.ruby-active-menu-item
  > a:hover {
  border-bottom: 1px solid #222;
  border-left: none;
}
@media (min-width: 768px) {
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:after,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:before {
    background-color: #222;
  }
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:hover {
    color: #333;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li.ruby-active-menu-item
    > a:after,
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li:hover
    > a:after {
    border-color: transparent #fff transparent transparent;
  }
}
@media (max-width: 767px) {
  div.ruby-menu-demo-header {
    width: 100%;
    height: auto;
  }
  div.ruby-menu-demo-description {
    width: 100%;
    margin: 0 auto 220px;
    padding: 10px;
    text-align: center;
  }
  div.ruby-menu-demo-description img {
    float: none;
    margin: 90px 0 0;
  }
  div.ruby-menu-demo-download {
    position: relative;
    width: auto;
    top: 25px;
    right: auto;
    left: auto;
  }
  div.ruby-menu-demo-download > span {
    font-size: 20px;
    margin: 25px auto;
  }
  div.ruby-menu-demo-download > span > span {
    font-size: 26px;
  }
  button.ruby-btn {
    padding: 14px 25px;
    font-size: 16px;
    text-transform: uppercase;
  }
  div.ruby-menu-demo-bg-light.ruby-menu-demo-switches {
    display: none;
  }
  div.ruby-menu-demo-bg-dark,
  div.ruby-menu-demo-bg-light {
    width: 100%;
    font-size: 16px;
    padding: 25px;
    margin-bottom: -100px;
  }
  div.ruby-menu-demo-cr-1,
  div.ruby-menu-demo-cv-1,
  div.ruby-menu-demo-cv-2,
  div.ruby-menu-demo-cv-3,
  div.ruby-menu-demo-cv-4 {
    width: 100%;
  }
  div.ruby-menu-demo-cr-2 {
    width: 100%;
    margin-top: 40px;
  }
  div.ruby-menu-demo-cf-1,
  div.ruby-menu-demo-cf-2,
  div.ruby-menu-demo-cf-3,
  div.ruby-menu-demo-cf-4 {
    float: left;
    width: 50%;
    text-align: center;
  }
  div.ruby-menu-demo-f-desc {
    margin-bottom: 30px;
  }
  img.iphone7 {
    max-width: 300px;
    float: none;
    margin-right: 0 auto;
  }
  ul.ruby-menu > li > a,
  ul.ruby-menu > li > ul > li > ul > li > ul > li > a {
    background: #efefef;
  }
  ul.ruby-menu > li.ruby-active-menu-item > a:hover,
  ul.ruby-menu > li:hover > a,
  ul.ruby-menu > li > a:hover {
    background: #ccc;
    color: #111;
  }
  ul.ruby-menu > li.ruby-active-menu-item > a {
    background: #ccc;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li
    > a,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a,
  ul.ruby-menu > li > ul > li > a,
  ul.ruby-menu > li > ul > li > ul > li > a,
  ul.ruby-menu > li > ul > li > ul > li > ul > li > a {
    background: #d1d1d1;
  }
  ul.ruby-menu > li > ul > li:hover > a,
  ul.ruby-menu > li > ul > li > a:hover,
  ul.ruby-menu > li > ul > li > ul > li:hover > a,
  ul.ruby-menu > li > ul > li > ul > li > a:hover,
  ul.ruby-menu > li > ul > li > ul > li > ul > li:hover > a,
  ul.ruby-menu > li > ul > li > ul > li > ul > li > a:hover {
    background: #d9d9d9;
    color: #111;
  }
  ul.ruby-menu > li > ul > li > ul > li > a {
    background: #e5e5e5;
  }
  ul.ruby-menu > li > ul > li > ul > li:hover > a,
  ul.ruby-menu > li > ul > li > ul > li > a:hover {
    background: #e1e1e1;
  }
  ul.ruby-menu > li > ul > li > ul > li > ul > li:hover > a,
  ul.ruby-menu > li > ul > li > ul > li > ul > li > a:hover {
    background: #fff;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li.ruby-active-menu-item
    > a {
    background: #bcbcbc;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li:hover
    > a,
  ul.ruby-menu
    > li.ruby-menu-mega-shop
    > div
    > ul
    > li.ruby-active-menu-item
    > a,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li:hover > a,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:hover {
    background: #d9d9d9;
  }
  span.ruby-dropdown-toggle:after {
    color: #fff;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li
    > a:before,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:before,
  ul.ruby-menu > li > a:before,
  ul.ruby-menu > li > ul > li > a:before,
  ul.ruby-menu > li > ul > li > ul > li > a:before,
  ul.ruby-menu > li > ul > li > ul > li > ul > li > a:before {
    background: #333;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li
    > a:before,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:before,
  ul.ruby-menu > li > ul > li > a:before {
    background: #555;
  }
  ul.ruby-menu > li > ul > li > ul > li > a:before {
    background: #777;
  }
  ul.ruby-menu > li > ul > li > ul > li > ul > li > a:before {
    background: #999;
  }
  .c-hamburger span {
    background: #222;
  }
  .c-hamburger span::after,
  .c-hamburger span::before {
    background-color: #222;
  }
  .c-hamburger--htx {
    background-color: #efefef;
    color: #222;
  }
  .c-hamburger--htx.is-active {
    background-color: #ddd;
  }
  ul.ruby-menu > li.ruby-menu-mega-blog > div,
  ul.ruby-menu > li.ruby-menu-mega-shop > div,
  ul.ruby-menu > li.ruby-menu-mega > div,
  ul.ruby-menu > li > ul,
  ul.ruby-menu > li > ul > li > ul,
  ul.ruby-menu > li > ul > li > ul > li > ul {
    border-top: none;
  }
  ul.ruby-menu.ruby-menu-dividers > li,
  ul.ruby-menu.ruby-menu-dividers > li.ruby-menu-social > a,
  ul.ruby-menu.ruby-menu-dividers > li:hover > a,
  ul.ruby-menu.ruby-menu-dividers > li > a,
  ul.ruby-menu.ruby-menu-dividers > li > a:hover {
    border: none;
  }
}
span.ruby-dropdown-toggle,
span.ruby-dropdown-toggle:after {
  transition: transform 0.3s;
}
@media (min-width: 767px) {
  ul.ruby-menu > li > a:after,
  ul.ruby-menu > li > ul > li > a:after,
  ul.ruby-menu > li > ul > li > ul > li > a:after,
  ul.ruby-menu > li > ul > li > ul > li > ul > li > a:after {
    transition: 0.3s;
  }
  ul.ruby-menu > li > div,
  ul.ruby-menu > li > ul {
    transform: translateY(25px);
    transition: 0.3s;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog:hover
    > div
    > ul.ruby-menu-mega-blog-nav
    > li.ruby-active-menu-item
    > div,
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li:hover
    > div,
  ul.ruby-menu
    > li.ruby-menu-mega-shop:hover
    > div
    > ul
    > li.ruby-active-menu-item
    > div,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li:hover > div,
  ul.ruby-menu > li:hover > div,
  ul.ruby-menu > li:hover > ul {
    transform: translateY(0);
  }
  ul.ruby-menu.ruby-vertical > li > div,
  ul.ruby-menu.ruby-vertical > li > ul,
  ul.ruby-menu > li > ul > li > ul,
  ul.ruby-menu > li > ul > li > ul > li > ul,
  ul.ruby-menu > li > ul > li > ul > li > ul > li > ul {
    transform: translateX(25px);
    transition: 0.3s;
  }
  ul.ruby-menu.ruby-vertical
    > li.ruby-menu-mega-blog:hover
    > div
    > ul.ruby-menu-mega-blog-nav
    > li.ruby-active-menu-item
    > div,
  ul.ruby-menu.ruby-vertical
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li:hover
    > div,
  ul.ruby-menu.ruby-vertical
    > li.ruby-menu-mega-shop:hover
    > div
    > ul
    > li.ruby-active-menu-item
    > div,
  ul.ruby-menu.ruby-vertical
    > li.ruby-menu-mega-shop
    > div
    > ul
    > li:hover
    > div,
  ul.ruby-menu.ruby-vertical > li:hover > div,
  ul.ruby-menu.ruby-vertical > li:hover > ul,
  ul.ruby-menu > li > ul > li.ruby-open-to-left:hover > ul,
  ul.ruby-menu > li > ul > li:hover > ul,
  ul.ruby-menu > li > ul > li > ul > li.ruby-open-to-left:hover > ul,
  ul.ruby-menu > li > ul > li > ul > li:hover > ul,
  ul.ruby-menu > li > ul > li > ul > li > ul > li.ruby-open-to-left:hover > ul,
  ul.ruby-menu > li > ul > li > ul > li > ul > li:hover > ul {
    transform: translateX(0);
  }
  ul.ruby-menu > li > ul > li.ruby-open-to-left > ul,
  ul.ruby-menu > li > ul > li > ul > li.ruby-open-to-left > ul,
  ul.ruby-menu > li > ul > li > ul > li > ul > li.ruby-open-to-left > ul {
    transform: translateX(-25px);
    transition: 0.3s;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li
    > div,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > div {
    transform: translateY(-50px);
    transition: 0.5s;
  }
  ul.ruby-menu.ruby-vertical
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li
    > div,
  ul.ruby-menu.ruby-vertical > li.ruby-menu-mega-shop > div > ul > li > div {
    transform: translateX(50px);
    transition: 0.5s;
  }
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:after,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:before {
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}
*,
:after,
:before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
div.ruby-wrapper {
  position: relative;
  height: 25px;
  background: 0 0;
}
div.ruby-wrapper ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.ruby-menu > li {
  float: left;
  font-size: 14px;
}
ul.ruby-menu > li > a {
  display: block;
  line-height: 25px;
  padding: 0 25px 0 15px;
  color: #fff;
  text-decoration: none;
  border-right: 1px solid #ccc;
  font-size: 12px;font-family: 'Opens',sans-serif;
  /* font-family: arial; */
  text-transform: uppercase;
}
ul.ruby-menu > li > a:only-child {
  padding: 0 15px;
}
ul.ruby-menu > li.ruby-active-menu-item:hover > a,
ul.ruby-menu > li.ruby-active-menu-item > a:hover,
ul.ruby-menu > li:hover > a,
ul.ruby-menu > li > a:hover {
  background: 0 0;
}
ul.ruby-menu > li.ruby-active-menu-item > a {
  background: #17a47d;
}
ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > div ul > li .fa,
ul.ruby-menu > li.ruby-menu-mega > div ul li .fa,
ul.ruby-menu > li > a .fa,
ul.ruby-menu > li > ul > li > a .fa,
ul.ruby-menu > li > ul > li > ul > li > a .fa,
ul.ruby-menu > li > ul > li > ul > li > ul > li > a .fa {
  position: relative;
  width: 24px;
}
ul.ruby-menu > li > a > img {
  max-width: 16px;
  display: block;
}
ul.ruby-menu > li.ruby-menu-social > a {
  padding: 0 8px;
  width: 30px;
  font-size: 16px;
}
ul.ruby-menu.ruby-menu-dividers > li.ruby-menu-social.ruby-menu-search > a,
ul.ruby-menu > li.ruby-menu-social.ruby-menu-search > a {
  transition: 0.5s;
  max-height: 50px;
  width: 30px;
  transition-delay: 0.3s;
}
ul.ruby-menu.ruby-menu-dividers
  > li.ruby-menu-social.ruby-menu-search
  > a:hover,
ul.ruby-menu > li.ruby-menu-social.ruby-menu-search > a:hover {
  width: 120px;
}
ul.ruby-menu.ruby-menu-dividers
  > li.ruby-menu-social.ruby-menu-search
  > a:hover
  > span
  input,
ul.ruby-menu > li.ruby-menu-social.ruby-menu-search > a:hover > span input {
  transform: scaleY(1);
  max-width: 80px;
}
ul.ruby-menu.ruby-menu-dividers > li.ruby-menu-social.ruby-menu-search > a span,
ul.ruby-menu > li.ruby-menu-social.ruby-menu-search > a span {
  display: inline;
}
ul.ruby-menu.ruby-menu-dividers
  > li.ruby-menu-social.ruby-menu-search
  > a
  > span
  input,
ul.ruby-menu > li.ruby-menu-social.ruby-menu-search > a > span input {
  position: absolute;
  width: 80px;
  height: 50px;
  line-height: 50px;
  margin: 0;
  background: 0 0;
  color: #efefef;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 0;
  transform: scaleY(0);
  transition: 0.5s 0.3s;
}
ul.ruby-menu > li.ruby-menu-social.ruby-menu-search > a > span input:focus {
  outline: 0;
  color: #efefef;
}
ul.ruby-menu
  > li.ruby-menu-social.ruby-menu-search
  > a
  > span
  input::-webkit-input-placeholder {
  color: #efefef;
}
ul.ruby-menu.ruby-menu-dividers > li.ruby-menu-social > a,
ul.ruby-menu.ruby-menu-dividers > li > a {
  border-left: 1px solid #111;
  border-right: 1px solid #666;
}
ul.ruby-menu.ruby-menu-dividers > li.ruby-menu-social:last-child > a,
ul.ruby-menu.ruby-menu-dividers > li:first-child > a {
  border-left: none;
}
ul.ruby-menu.ruby-vertical.ruby-menu-dividers > li > a {
  border: none;
  border-top: 1px solid #111;
  border-bottom: 1px solid #666;
}
ul.ruby-menu.ruby-vertical.ruby-menu-dividers > li:first-child > a {
  border-top: none;
}
ul.ruby-menu.ruby-menu-dividers > li:hover > a,
ul.ruby-menu.ruby-menu-dividers > li > a:hover {
  border-right: 1px solid #19ad86;
}
ul.ruby-menu.ruby-vertical.ruby-menu-dividers > li:hover > a,
ul.ruby-menu.ruby-vertical.ruby-menu-dividers > li > a:hover {
  border-bottom: 1px solid #19ad86;
}
ul.ruby-menu.ruby-menu-dividers > li.ruby-active-menu-item > a {
  border-right: 1px solid #17a47d;
}
ul.ruby-menu.ruby-vertical.ruby-menu-dividers > li.ruby-active-menu-item > a {
  border-bottom: 1px solid #17a47d;
}
ul.ruby-menu > li > ul,
ul.ruby-menu > li > ul > li > ul,
ul.ruby-menu > li > ul > li > ul > li > ul {
  position: absolute;
  box-shadow: 0 8px 24px -5px #ccc;
  border-top: 4px solid #436fe8;
  /* background-color: #ddd; */
  background-color: #436fe8;
}
ul.ruby-menu > li > ul > li > a,
ul.ruby-menu > li > ul > li > ul > li > a,
ul.ruby-menu > li > ul > li > ul > li > ul > li > a {
  display: block;
  width: 175px;
  line-height: 33px;
  padding: 0 0 0 9px;
  background: #436fe8;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  font-family: arial;
  text-transform: capitalize;
}
ul.ruby-menu > li > ul > li:hover > a,
ul.ruby-menu > li > ul > li > a:hover,
ul.ruby-menu > li > ul > li > ul > li:hover > a,
ul.ruby-menu > li > ul > li > ul > li > a:hover,
ul.ruby-menu > li > ul > li > ul > li > ul > li:hover > a,
ul.ruby-menu > li > ul > li > ul > li > ul > li > a:hover {
  /* background: #efefef; */background-color: #ffffff2e;
}
ul.ruby-menu > li.ruby-menu-right > ul {
  right: 0;
}
ul.ruby-menu > li > ul > li > ul,
ul.ruby-menu > li > ul > li > ul > li > ul {
  left: 175px;
  top: -4px;
  z-index: 100;
}
ul.ruby-menu > li > ul > li.ruby-open-to-left > ul,
ul.ruby-menu > li > ul > li > ul > li.ruby-open-to-left > ul {
  left: auto;
  right: 170px;
}
ul.ruby-menu > li.ruby-menu-mega-blog > div,
ul.ruby-menu > li.ruby-menu-mega-shop > div,
ul.ruby-menu > li.ruby-menu-mega > div {
  position: absolute;
  width: 100%;
  height: auto;
  top: 50px;
  left: 0;
  background: #fff;
  border-top: 4px solid #19ad86;
  overflow: hidden;
  -webkit-box-shadow: 0 8px 24px -5px #ccc;
  -moz-box-shadow: 0 8px 24px -5px #ccc;
  box-shadow: 0 8px 24px -5px #ccc;
}
ul.ruby-menu > li.ruby-menu-mega-blog > div > ul.ruby-menu-mega-blog-nav {
  position: relative;
  width: 200px;
  height: 100%;
  left: 0;
  top: 0;
  background: #efefef;
}
ul.ruby-menu
  > li.ruby-menu-mega-blog
  > div
  > ul.ruby-menu-mega-blog-nav
  > li
  > a {
  display: block;
  line-height: 40px;
  padding: 0 15px;
  background: #efefef;
  color: #666;
  text-decoration: none;
}
ul.ruby-menu
  > li.ruby-menu-mega-blog
  > div
  > ul.ruby-menu-mega-blog-nav
  > li.ruby-active-menu-item
  > a {
  background: #e6e6e6;
}
ul.ruby-menu
  > li.ruby-menu-mega-blog
  > div
  > ul.ruby-menu-mega-blog-nav
  > li
  > div {
  position: absolute;
  width: 780px;
  height: auto;
  min-height: 100%;
  left: 200px;
  top: 0;
  background: #fff;
}
ul.ruby-menu
  > li.ruby-menu-mega-blog
  > div
  > ul.ruby-menu-mega-blog-nav
  > li:hover
  > a {
  background: #ddd;
}
ul.ruby-menu > li.ruby-menu-mega-shop > div {
  text-align: center;
  z-index: 999999;
}
ul.ruby-menu > li.ruby-menu-mega-shop > div > ul {
  width: 100%;
  background: #efefef;
  margin: 0 auto;
  padding: 0;
  border-bottom: 1px solid #ddd;
  font-size: 0;
  z-index: 100;
}
ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li {
  display: inline-block;
  margin: 0;
}
ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a {
  padding: 20px 15px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  font-stretch: expanded;
  color: #666;
  position: relative;
  text-decoration: none;
  display: inline-block;
}
span.ruby-c-category a,
span.ruby-c-date a,
span.ruby-c-title a,
ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li.ruby-active-menu-item > a,
ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li:hover > a,
ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:hover {
  text-decoration: none;
}
ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > div {
  position: absolute;
  width: 100%;
  left: 0;
  top: auto;
  margin-top: 1px;
  text-align: left;
  background: #fff;
  z-index: -1;
}
div.ruby-grid,
div.ruby-grid-lined {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
}
div.ruby-grid > div.ruby-row {
  position: relative;
  width: 100%;
  height: auto;
}
div.ruby-grid > div.ruby-row div.ruby-row [class^="ruby-col"] {
  margin-top: 16px;
}
div.ruby-grid.ruby-grid-lined > div.ruby-row:after {
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background: -webkit-linear-gradient(
    left,
    rgba(204, 204, 204, 0) 0,
    rgba(204, 204, 204, 0.48) 24%,
    #ccc 50%,
    rgba(204, 204, 204, 0.42) 79%,
    rgba(204, 204, 204, 0) 100%
  );
}
div.ruby-grid.ruby-grid-lined > div.ruby-row:last-child:after {
  height: 0;
}
div.ruby-grid > div.ruby-row [class^="ruby-col"] {
  position: relative;
  float: left;
  height: auto;
}
div.ruby-grid > div.ruby-row > [class^="ruby-col"] {
  padding: 16px;
}
div.ruby-grid > div.ruby-row > [class^="ruby-col"] [class^="ruby-col"] {
  padding-left: 16px;
}
div.ruby-grid > div.ruby-row div.ruby-col-1 {
  width: 8.33%;
}
div.ruby-grid > div.ruby-row div.ruby-col-2 {
  width: 16.66%;
}
div.ruby-grid > div.ruby-row div.ruby-col-3 {
  width: 25%;
}
div.ruby-grid > div.ruby-row div.ruby-col-4 {
  width: 33.33%;
}
div.ruby-grid > div.ruby-row div.ruby-col-5 {
  width: 41.66%;
}
div.ruby-grid > div.ruby-row div.ruby-col-7 {
  width: 58.33%;
}
div.ruby-grid > div.ruby-row div.ruby-col-8 {
  width: 66.66%;
}
div.ruby-grid > div.ruby-row div.ruby-col-10 {
  width: 83.33%;
}
div.ruby-grid > div.ruby-row div.ruby-col-11 {
  width: 91.66%;
}
div.ruby-grid.ruby-grid-lined > div.ruby-row > div[class^="ruby-col"]:after {
  content: "";
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  right: 0;
  background: -webkit-linear-gradient(
    top,
    rgba(204, 204, 204, 0) 0,
    rgba(204, 204, 204, 0.48) 24%,
    #ccc 50%,
    rgba(204, 204, 204, 0.42) 79%,
    rgba(204, 204, 204, 0) 100%
  );
}
div.ruby-grid.ruby-grid-lined
  > div.ruby-row
  > div[class^="ruby-col"]:last-child:after {
  width: 0;
}
h3.ruby-list-heading {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  margin: 0;
}
ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > div ul,
ul.ruby-menu > li.ruby-menu-mega > div ul {
  margin: 10px 0 0;
}
ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > div ul > li,
ul.ruby-menu > li.ruby-menu-mega > div ul li {
  position: relative;
  font-size: 14px;
  line-height: 28px;
  display: block;
  width: 100%;
}
ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > div ul > li > a,
ul.ruby-menu > li.ruby-menu-mega > div ul li > a {
  text-decoration: none;
  color: #555;
  display: inline-block;
}
ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > div ul > li > a:hover,
ul.ruby-menu > li.ruby-menu-mega > div ul li > a:hover {
  color: #222;
  text-decoration: underline;
}
ul.ruby-menu > li.ruby-menu-mega > div ul.ruby-list-with-images li {
  height: 50px;
}
ul.ruby-menu
  > li.ruby-menu-mega
  > div
  ul.ruby-list-with-images
  li
  span.ruby-list-desc {
  font-size: 11px;
  display: block;
  position: absolute;
  top: 16px;
  left: 72px;
  color: #999;
}
ul.ruby-menu > li.ruby-menu-mega > div ul li > a > img {
  float: left;
  width: 60px;
  margin-right: 12px;
}
span.ruby-c-category,
span.ruby-c-date,
span.ruby-c-title {
  position: relative;
  display: block;
}
span.ruby-c-title a:hover {
  color: #444;
  text-decoration: underline;
}
span.ruby-c-date,
span.ruby-c-date a {
  font-size: 11px;
  color: #444;
}
span.ruby-c-category a:hover,
span.ruby-c-date a:hover {
  color: #19ad86;
}
div.ruby-c-inline span.ruby-c-category,
div.ruby-c-inline span.ruby-c-date {
  position: absolute;
  display: inline-block;
}
div.ruby-c-inline span.ruby-c-date {
  float: right;
  right: 0;
}
div.ruby-c-inline span.ruby-c-category,
div.ruby-c-inline span.ruby-c-category a {
  margin: 0;
}
span.ruby-c-content {
  display: block;
  position: relative;
  top: 7px;
  font-size: 12px;
  line-height: 18px;
  text-align: justify;
}
.ruby-wrapper .visible-xs {
  visibility: hidden;
  position: absolute;
}
@media (min-width: 768px) {
  div.ruby-wrapper.ruby-menu-transparent,
  div.ruby-wrapper.ruby-menu-transparent
    > ul.ruby-menu
    > li.ruby-active-menu-item
    > a,
  div.ruby-wrapper.ruby-menu-transparent
    > ul.ruby-menu
    > li.ruby-menu-social.ruby-menu-search:hover
    > a,
  div.ruby-wrapper.ruby-menu-transparent
    > ul.ruby-menu
    > li.ruby-menu-social.ruby-menu-search
    > a:hover {
    background: 0 0;
  }
  div.ruby-wrapper.ruby-menu-transparent
    > ul.ruby-menu.ruby-menu-dividers
    > li
    > a,
  div.ruby-wrapper.ruby-menu-transparent
    > ul.ruby-menu
    > li.ruby-menu-social
    > a {
    border: none;
  }
  div.ruby-wrapper.ruby-menu-transparent
    > ul.ruby-menu.ruby-menu-dividers
    > li:hover
    > a,
  div.ruby-wrapper.ruby-menu-transparent
    > ul.ruby-menu.ruby-menu-dividers
    > li
    > a:hover,
  div.ruby-wrapper.ruby-menu-transparent > ul.ruby-menu > li:hover > a,
  div.ruby-wrapper.ruby-menu-transparent > ul.ruby-menu > li > a:hover {
    background: 0 0;
    color: #e2e2e2;
  }
  div.ruby-wrapper.ruby-menu-full-width {
    width: 100%;
  }
  div.ruby-wrapper.ruby-menu-full-width > ul.ruby-menu {
    width: 980px;
    margin: 0 auto;
  }
  ul.ruby-menu > li.ruby-menu-mega-blog > div,
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li
    > div,
  ul.ruby-menu > li.ruby-menu-mega-shop > div,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > div,
  ul.ruby-menu > li.ruby-menu-mega > div,
  ul.ruby-menu > li > ul,
  ul.ruby-menu > li > ul > li > ul,
  ul.ruby-menu > li > ul > li > ul > li > ul {
    visibility: hidden;
    opacity: 0;
  }
  ul.ruby-menu > li.ruby-menu-mega-blog:hover > div,
  ul.ruby-menu
    > li.ruby-menu-mega-blog:hover
    > div
    > ul.ruby-menu-mega-blog-nav
    > li.ruby-active-menu-item
    > div,
  ul.ruby-menu > li.ruby-menu-mega-shop:hover > div,
  ul.ruby-menu
    > li.ruby-menu-mega-shop:hover
    > div
    > ul
    > li.ruby-active-menu-item
    > div,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li:hover > div,
  ul.ruby-menu > li.ruby-menu-mega:hover > div,
  ul.ruby-menu > li:hover > ul,
  ul.ruby-menu > li > ul > li:hover > ul,
  ul.ruby-menu > li > ul > li > ul > li:hover > ul {
    visibility: visible;
    opacity: 1;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li:hover
    > div {
    visibility: visible;
    opacity: 1;
    z-index: 101;
  }
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:after,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:before {
    height: 1px;
    position: absolute;
    content: "";
    background-color: #19ad86;
    width: 0;
  }
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:before {
    top: 10px;
    left: 5%;
  }
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:after {
    bottom: 10px;
    right: 5%;
  }
  .snip1217 .current a,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:hover {
    color: #333;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-shop
    > div
    > ul
    > li.ruby-active-menu-item
    > a:after,
  ul.ruby-menu
    > li.ruby-menu-mega-shop
    > div
    > ul
    > li.ruby-active-menu-item
    > a:before,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li:hover > a:after,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li:hover > a:before,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:hover:after,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:hover:before {
    width: 90%;
  }
  ul.ruby-menu > li > a:after,
  ul.ruby-menu > li > ul > li > a:after,
  ul.ruby-menu > li > ul > li > ul > li > a:after,
  ul.ruby-menu > li > ul > li > ul > li > ul > li > a:after {
    font-family: FontAwesome;
    text-decoration: none;
    content: "";
    background-image: url("../../public/assest/photos/arrow-down.png");
    background-size: 100px 100px;
    position: absolute;
    width: 10px;
    height: 10px;
    margin: 10px;
  }
  ul.ruby-menu > li:hover > a:after,
  ul.ruby-menu > li > ul > li.ruby-open-to-left:hover > a:after,
  ul.ruby-menu > li > ul > li:hover > a:after,
  ul.ruby-menu > li > ul > li > ul > li.ruby-open-to-left:hover > a:after,
  ul.ruby-menu > li > ul > li > ul > li:hover > a:after,
  ul.ruby-menu
    > li
    > ul
    > li
    > ul
    > li
    > ul
    > li.ruby-open-to-left:hover
    > a:after,
  ul.ruby-menu > li > ul > li > ul > li > ul > li:hover > a:after {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  ul.ruby-menu > li > ul > li.ruby-open-to-left > a:after,
  ul.ruby-menu > li > ul > li > ul > li.ruby-open-to-left > a:after,
  ul.ruby-menu > li > ul > li > ul > li > ul > li.ruby-open-to-left > a:after {
    content: "\f104";
    right: auto;
    left: 10px;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li.ruby-active-menu-item
    > a:after,
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li:hover
    > a:after {
    content: "";
    position: absolute;
    right: 0;
    margin-top: 13px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 8px 6px 0;
    border-color: transparent #fff transparent transparent;
  }
}
@media (max-width: 767px) {
  div.ruby-wrapper .hidden-xs {
    visibility: hidden;
    display: none;
  }
  div.ruby-wrapper {
    position: absolute;
    width: 100%;
    top: 0;
    background: #111;
  }
  .ruby-wrapper .visible-xs {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
  div.ruby-wrapper > ul {
    position: absolute;
    top: 50px;
    width: 100%;
  }
  ul.ruby-menu > li,
  ul.ruby-menu > li.ruby-menu-right {
    float: none;
  }
  ul.ruby-menu > li > a {
    padding: 0 65px;
    background: #19ad86;
  }
  ul.ruby-menu > li > a:only-child {
    padding: 0 65px;
  }
  ul.ruby-menu > li.ruby-active-menu-item > a:hover,
  ul.ruby-menu > li:hover > a,
  ul.ruby-menu > li > a:hover {
    background: #127e61;
  }
  ul.ruby-menu > li.ruby-active-menu-item > a {
    background: #159572;
  }
  ul.ruby-menu > li.ruby-menu-social > a > span {
    display: block;
  }
  ul.ruby-menu > li > ul,
  ul.ruby-menu > li > ul > li > ul,
  ul.ruby-menu > li > ul > li > ul > li > ul {
    position: relative;
    width: 100%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-top: none;
  }
  ul.ruby-menu > li,
  ul.ruby-menu > li.ruby-menu-mega,
  ul.ruby-menu > li.ruby-menu-mega-blog,
  ul.ruby-menu > li.ruby-menu-mega-blog > div > ul.ruby-menu-mega-blog-nav > li,
  ul.ruby-menu > li.ruby-menu-mega-shop,
  ul.ruby-menu > li > ul > li,
  ul.ruby-menu > li > ul > li > ul > li,
  ul.ruby-menu > li > ul > li > ul > li > ul > li {
    position: relative;
  }
  ul.ruby-menu > li > ul > li > a,
  ul.ruby-menu > li > ul > li > ul > li > a,
  ul.ruby-menu > li > ul > li > ul > li > ul > li > a {
    width: 100%;
    line-height: 50px;
    padding: 0 80px;
    background: #d1d1d1;
  }
  ul.ruby-menu > li > ul > li:hover > a,
  ul.ruby-menu > li > ul > li > a:hover,
  ul.ruby-menu > li > ul > li > ul > li:hover > a,
  ul.ruby-menu > li > ul > li > ul > li > a:hover,
  ul.ruby-menu > li > ul > li > ul > li > ul > li:hover > a,
  ul.ruby-menu > li > ul > li > ul > li > ul > li > a:hover {
    background: #d9d9d9;
    color: #111;
  }
  ul.ruby-menu > li > ul > li > ul,
  ul.ruby-menu > li > ul > li > ul > li > ul {
    left: auto;
    top: auto;
    z-index: auto;
  }
  ul.ruby-menu > li > ul > li.ruby-open-to-left > ul,
  ul.ruby-menu > li > ul > li > ul > li.ruby-open-to-left > ul {
    left: auto;
    right: auto;
  }
  ul.ruby-menu > li > ul > li > ul > li > a {
    background: #e5e5e5;
    padding: 0 95px;
  }
  ul.ruby-menu > li > ul > li > ul > li:hover > a,
  ul.ruby-menu > li > ul > li > ul > li > a:hover {
    background: #e1e1e1;
  }
  ul.ruby-menu > li > ul > li > ul > li > ul > li > a {
    background: #efefef;
    padding: 0 110px;
  }
  ul.ruby-menu > li > ul > li > ul > li > ul > li:hover > a,
  ul.ruby-menu > li > ul > li > ul > li > ul > li > a:hover {
    background: #fff;
  }
  ul.ruby-menu > li.ruby-menu-social > a {
    border: none;
    width: 100%;
  }
  ul.ruby-menu > li.ruby-menu-mega-blog > div,
  ul.ruby-menu > li.ruby-menu-mega-shop > div,
  ul.ruby-menu > li.ruby-menu-mega > div {
    position: relative;
    top: auto;
    left: auto;
    border-top: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  ul.ruby-menu > li.ruby-menu-mega-blog > div > ul.ruby-menu-mega-blog-nav {
    width: 100%;
    height: auto;
    left: auto;
    top: auto;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li
    > a {
    line-height: 50px;
    padding: 0 75px;
    background: #d1d1d1;
    color: #222;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li.ruby-active-menu-item
    > a {
    background: #bcbcbc;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li
    > div {
    position: relative;
    width: 100%;
    min-height: auto;
    left: auto;
    top: auto;
    z-index: auto;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li:hover
    > a,
  ul.ruby-menu
    > li.ruby-menu-mega-shop
    > div
    > ul
    > li.ruby-active-menu-item
    > a,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li:hover > a,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:hover {
    background: #d9d9d9;
  }
  ul.ruby-menu > li.ruby-menu-mega-shop > div {
    position: relative;
    text-align: left;
  }
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul {
    position: relative;
    border: none;
    font-size: 14px;
    margin-bottom: -1px;
  }
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li {
    position: relative;
    display: block;
    margin-top: -1px;
  }
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a {
    padding: 0 80px;
    line-height: 50px;
    max-height: 50px;
    text-transform: none;
    font-weight: 400;
    background: #d1d1d1;
    color: #222;
    border: none;
    display: block;
  }
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > div {
    position: relative;
    top: 0;
    z-index: 9999;
    margin: 0;
  }
  ul.ruby-menu > li.ruby-menu-social img {
    display: inline-table;
    position: absolute;
    top: 15px;
  }
  ul.ruby-menu > li.ruby-menu-social .fa {
    position: absolute;
    top: 17px;
  }
  ul.ruby-menu > li.ruby-menu-social span {
    line-height: 50px;
    padding: 0 25px;
  }
  span.ruby-dropdown-toggle {
    position: absolute;
    width: 50px;
    height: 50px;
    max-width: 50px;
    max-height: 50px;
    left: 0;
    top: 0;
    padding: 10px 17px;
    z-index: 1000000;
    cursor: pointer;
  }
  span.ruby-dropdown-toggle:after {
    font-family: FontAwesome;
    text-decoration: none;
    content: "\f107";
    color: #fff;
    font-size: 25px;
    right: 0;
  }
  span.ruby-dropdown-toggle.ruby-dropdown-toggle-rotate {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li
    > a:before,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:before,
  ul.ruby-menu > li > a:before,
  ul.ruby-menu > li > ul > li > a:before,
  ul.ruby-menu > li > ul > li > ul > li > a:before,
  ul.ruby-menu > li > ul > li > ul > li > ul > li > a:before {
    content: "";
    width: 50px;
    height: 50px;
    background: #333;
    position: absolute;
    left: 0;
    top: 0;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li
    > a:before,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a:before,
  ul.ruby-menu > li > ul > li > a:before {
    background: #555;
  }
  ul.ruby-menu > li > ul > li > ul > li > a:before {
    background: #777;
  }
  ul.ruby-menu > li > ul > li > ul > li > ul > li > a:before {
    background: #999;
  }
  ul.ruby-menu,
  ul.ruby-menu > li.ruby-menu-mega-blog > div,
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li
    > div,
  ul.ruby-menu > li.ruby-menu-mega-shop > div,
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > div,
  ul.ruby-menu > li.ruby-menu-mega > div,
  ul.ruby-menu > li > ul,
  ul.ruby-menu > li > ul > li > ul,
  ul.ruby-menu > li > ul > li > ul > li > ul {
    max-height: 0;
    transform: scaleY(0);
    overflow: hidden;
  }
  ul.ruby-menu.ruby-mobile-sublevel-show,
  ul.ruby-menu > li.ruby-menu-mega-blog > div.ruby-mobile-sublevel-show,
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li
    > div.ruby-mobile-sublevel-show,
  ul.ruby-menu > li.ruby-menu-mega-shop > div.ruby-mobile-sublevel-show,
  ul.ruby-menu
    > li.ruby-menu-mega-shop
    > div
    > ul
    > li
    > div.ruby-mobile-sublevel-show,
  ul.ruby-menu > li.ruby-menu-mega > div.ruby-mobile-sublevel-show,
  ul.ruby-menu > li > ul.ruby-mobile-sublevel-show,
  ul.ruby-menu > li > ul > li > ul.ruby-mobile-sublevel-show,
  ul.ruby-menu > li > ul > li > ul > li > ul.ruby-mobile-sublevel-show {
    max-height: 20000px;
    transform: scaleY(1);
  }
  div.ruby-grid > div.ruby-row div.ruby-row [class^="ruby-col"],
  div.ruby-grid > div.ruby-row div.ruby-row:first-of-type [class^="ruby-col"] {
    margin-top: 0;
  }
  div.ruby-grid.ruby-grid-lined > div.ruby-row:after {
    height: 0;
  }
  div.ruby-grid > div.ruby-row > [class^="ruby-col"] {
    padding: 0;
  }
  div.ruby-grid > div.ruby-row > [class^="ruby-col"] [class^="ruby-col"],
  div.ruby-grid
    > div.ruby-row
    > [class^="ruby-col"]
    [class^="ruby-col"]:first-of-type {
    padding-left: 0;
  }
  div.ruby-grid > div.ruby-row div.ruby-col-1,
  div.ruby-grid > div.ruby-row div.ruby-col-10,
  div.ruby-grid > div.ruby-row div.ruby-col-11,
  div.ruby-grid > div.ruby-row div.ruby-col-12,
  div.ruby-grid > div.ruby-row div.ruby-col-2,
  div.ruby-grid > div.ruby-row div.ruby-col-3,
  div.ruby-grid > div.ruby-row div.ruby-col-4,
  div.ruby-grid > div.ruby-row div.ruby-col-5,
  div.ruby-grid > div.ruby-row div.ruby-col-6,
  div.ruby-grid > div.ruby-row div.ruby-col-7,
  div.ruby-grid > div.ruby-row div.ruby-col-8,
  div.ruby-grid > div.ruby-row div.ruby-col-9 {
    width: 100%;
    padding: 20px 30px;
  }
  div.ruby-grid.ruby-grid-lined > div.ruby-row > div[class^="ruby-col"]:after {
    width: 0;
  }
  div.ruby-grid img {
    position: relative;
    max-width: 100%;
    height: auto;
    display: block;
  }
  div.ruby-grid > div.ruby-row::after {
    content: "";
    display: table;
    clear: both;
  }
  .c-hamburger {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 50px;
    height: 50px;
    font-size: 0;
    text-indent: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
  }
  .c-hamburger:focus {
    outline: 0;
  }
  .c-hamburger span {
    display: block;
    position: absolute;
    top: 24px;
    left: 8px;
    right: 8px;
    height: 3px;
    background: #fff;
  }
  .c-hamburger span::after,
  .c-hamburger span::before {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #fff;
    content: "";
  }
  .c-hamburger span::before {
    top: -10px;
  }
  .c-hamburger span::after {
    bottom: -10px;
  }
  .c-hamburger--htx {
    background-color: #19ad86;
  }
  .c-hamburger--htx span {
    -webkit-transition: background 0 0.3s;
    transition: background 0 0.3s;
  }
  .c-hamburger--htx span::after,
  .c-hamburger--htx span::before {
    -webkit-transition-duration: 0.3s, 0.3s;
    transition-duration: 0.3s, 0.3s;
    -webkit-transition-delay: 0.3s, 0;
    transition-delay: 0.3s, 0;
  }
  .c-hamburger--htx span::before {
    -webkit-transition-property: top, -webkit-transform;
    transition-property: top, transform;
  }
  .c-hamburger--htx span::after {
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
  }
  .c-hamburger--htx.is-active {
    background-color: #333;
  }
  .c-hamburger--htx.is-active span {
    background: 0 0;
  }
  .c-hamburger--htx.is-active span::before {
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .c-hamburger--htx.is-active span::after {
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .c-hamburger--htx.is-active span::after,
  .c-hamburger--htx.is-active span::before {
    -webkit-transition-delay: 0s, 0.3s;
    transition-delay: 0s, 0.3s;
  }
  ul.ruby-menu.ruby-menu-dividers > li,
  ul.ruby-menu.ruby-menu-dividers > li.ruby-menu-social > a,
  ul.ruby-menu.ruby-menu-dividers > li:hover > a,
  ul.ruby-menu.ruby-menu-dividers > li > a,
  ul.ruby-menu.ruby-menu-dividers > li > a:hover {
    border: none;
  }
  div.ruby-wrapper.ruby-menu-transparent
    > ul.ruby-menu
    > li.ruby-active-menu-item
    > a {
    padding-left: 45px;
  }
  ul.ruby-menu > li.ruby-menu-social.ruby-menu-search > a {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .ruby-wrapper .hidden-md,
  span.ruby-c-date {
    display: none;
    visibility: hidden;
  }
  div.ruby-wrapper {
    width: 760px;
  }
  ul.ruby-menu > li.ruby-menu-mega-blog > div > ul.ruby-menu-mega-blog-nav {
    width: 170px;
  }
  ul.ruby-menu
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li
    > div {
    width: 590px;
    left: 170px;
  }
  ul.ruby-menu.ruby-vertical > li.ruby-menu-mega-blog > div,
  ul.ruby-menu.ruby-vertical > li.ruby-menu-mega-shop > div,
  ul.ruby-menu.ruby-vertical > li.ruby-menu-mega > div {
    width: 560px;
  }
  ul.ruby-menu.ruby-vertical
    > li.ruby-menu-mega-blog
    > div
    > ul.ruby-menu-mega-blog-nav
    > li
    > div {
    width: 360px;
  }
  span.ruby-c-date {
    opacity: 0;
  }
  div.ruby-grid > div.ruby-row div.ruby-col-3-md {
    width: 25%;
  }
  div.ruby-grid > div.ruby-row div.ruby-col-4-md {
    width: 33.33%;
  }
  div.ruby-grid > div.ruby-row div.ruby-col-6-md {
    width: 50%;
  }
  div.ruby-grid > div.ruby-row div.ruby-col-12-md {
    width: 100%;
  }
  div.ruby-grid.ruby-grid-lined > div.ruby-row:after,
  div.ruby-grid.ruby-grid-lined > div.ruby-row > div[class^="ruby-col"]:after {
    background: 0 0;
  }
  ul.ruby-menu > li.ruby-menu-mega-shop > div > ul > li > a {
    padding: 20px 5px;
    font-size: 12px;
    font-stretch: condensed;
    color: #666;
  }
}
.arrow-div-img,
.arrow-div-img2,
.arrow-div-img3 {
  position: absolute;
  color: #fff;
  width: 15px;
  rotate: -90deg;
  margin: 3px 9px;
}
.arrow-div,
.arrow-div2,
.arrow-div3 {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.arrow-div2:hover .arrow-div-img2,
.arrow-div3:hover .arrow-div-img3,
.arrow-div:hover .arrow-div-img {
  rotate: -270deg;
  transition: 0.6s ease-in-out;
}
.micro-yes-height {
  height: 25px;
}
.error {
  color: #ff5722;
  font-size: 13px;
  padding-left: 13px;
  text-transform: lowercase;
}
.popup-section {
  background-image: url("../../public/assest/photos/back-pattern.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 12px 20px !important;
}
.popup-section-disclaimer {
  background-image: url("../../public/assest/photos/background-square.png");
  background-repeat: repeat;
  /* background-size: 100% 100%; */
  padding: 12px 20px !important;
}
.popup-section-title {
  background-image: url("../../public/assest/photos/background-square.png");
  background-repeat: repeat;
  padding: 12px 20px !important;
  background-color: #436fe8;
}
.popup-section-icon {
  color: #fff !important;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.terms-fadeInUp {
  animation: 1s ease-in-out fadeInUp;
}
.google-content-h5 {
  line-height: 25px;
  color: #666;
  text-align: left;
  font-size: 14px;
  margin: 30px 20px;
  font-family: arial;
}
.cart-notes {
  color: #000000c2;
  font-family: arial;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 27px;
}
.cart-notes > span {
  color: #3f51b5;
  font-size: 14px;
  font-family: arial-bold;
}
.contact-google {
  font-size: 14px;
  padding: 11px 0;
  text-align: center;
  text-transform: capitalize;
  line-height: 82px;
  color: #6c757d;
  font-family: arial;
}
.disclaimer-div {
  margin: 20px 40px;
}
.disclaimer-div > p {
  font-size: 15px;
  font-family: "arial";
  color: #666;
  line-height: 23px;
}
.disclaimer-div > h4 {
  font-size: 15px;
  line-height: 29px;
  font-family: "arial";
  color: #666;
}
.disclaimer-div > h3 {
  font-size: 16px;
  font-family: "arial-bold";
  color: #436fe8;
  line-height: 42px;
  text-transform: uppercase;
}
.disclaimer-div > span {
  font-size: 16px;
  color: #333;
  font-family: "arial";
  line-height: 68px;
  font-weight: 600;
}
.panel-title > button.collapsed::after {
  float: right !important;
  content: "\002B";
}
.panel-title > button::after {
  float: right !important;
  font-family: FontAwesome;
  content: "\2212";
  font-size: 25px;
  padding-right: 5px;
  color: #ffc107;
}
.panel-title > button:hover,
.panel-title > button:active,
.panel-title > button:focus {
  text-decoration: none;
}

.xx{height: 20px;}
