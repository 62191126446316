@media (min-width: 765px) and (max-width: 820px) {
  ul.ruby-menu > li > ul,
  ul.ruby-menu > li > ul > li > ul,
  ul.ruby-menu > li > ul > li > ul > li > ul {
    visibility: hidden;
    opacity: 0;
  }
}
@media (min-width: 980px) and (max-width: 1044px) {
  .nav-link.active > a,
  .nav-link > a {
    font-family: arial;
    font-size: 10px;
    padding: 28px 0;
  }
  .nav-link > a {
    color: var(--bg-white);
    font-weight: 500;
  }
  .nav-link.active > a {
    color: #fed501;
  }
  .nav-link-img {
    display: none;
    width: 14px;
    height: 55px;
  }
}
@media (min-width: 1045px) and (max-width: 1100px) {
  .menu-inner3 {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: none;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
  }
  .total-cart > div > div > select {
    width: 80px;
    padding: 5px;
    margin-right: 10px;
    background-color: #f8f9fa;
    box-shadow: 0 0 6px 0 #dee2e6;
    font-family: arial;
    font-size: 17px;
    border: 1px solid #e9ecef;
  }
}
@media (min-width: 1101px) and (max-width: 1399px) {
  .nav-link.active > a,
  .nav-link > a {
    font-size: 12px;
    /* font-family: arial; */font-family: 'Opens',sans-serif;
  }
  .menu-inner3 {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: none;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
  }
  .h-max {
    height: 95vh;
    max-width: 650px;
    margin: 0 auto;
  }
  .total-cart > div > div > select {
    width: 80px;
    padding: 5px;
    margin-right: 10px;
    background-color: #f8f9fa;
    box-shadow: 0 0 6px 0 #dee2e6;
    font-family: arial;
    font-size: 17px;
    border: 1px solid #e9ecef;
  }
  .social-banner-div {
    position: absolute;
    top: 27%;
    text-align: start;
    left: 32px;
  }
  .total-cart > div > div > span {
    width: 39px;
    box-shadow: 0 0 7px 0 #e9ecef;
    font-family: arial;
    border: 1px solid #dee2e6;
    font-size: 15px;
    margin: 0 1px;
    color: #000;
    padding: 9px 14px;
    background-color: #f8f9fa;
  }
  .nav-link.active > a {
    color: #fed501;
    padding: 28px 0;
  }
  .nav-link > a {
    color: var(--bg-white);
    font-weight: 500;
    padding: 28px 0;
  }
  .branding-banner-new-div {
    position: absolute;
    top: 6%;
    text-align: start;
    left: 11%;
  }
  .branding-cunsulting-banner-new-div > h3 {
    font-size: 12px;
    line-height: 14px;
    margin: 12px 1px;
    font-family: arial;
  }
  .branding-comminication-banner-new-div > h1 {
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    color: #6c757d;
    min-height: 98px;
    line-height: 44px;
    font-family: arial-bold;
  }
  .security-testimonial-slider-h1 {
    text-align: left;
    color: var(--primary-bg-color);
    font-family: arial-bold;
    font-size: 25px;
    padding: 27px 35px;
  }
  .security-testimonial-slider-text {
    text-align: left;
    color: #003b77;
    font-family: arial;
    font-size: 16px;
    padding: 0 35px;
  }
  .email-banner-div > h5 {
    font-family: arial-bold;
    line-height: 29px;
    font-size: 22px;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .email-banner-div > h2 {
    font-family: arial;
    font-size: 15px;
    color: #003b77;
    line-height: 25px;
  }
  .email-banner-div1 > h1 {
    font-family: arial-bold;
    line-height: 42px;
    font-size: 33px;
    color: #fff;
    margin-bottom: 20px;
  }
  .email-banner-div1 > h2 {
    font-family: arial;
    font-size: 18px;
    color: #fff;
    line-height: 13px;
  }
  .b2b-banner-div > h1 {
    font-family: arial-bold;
    font-size: 36px;
    color: #000;
    margin-bottom: 20px;
  }
}
@media (min-width: 1400px) and (max-width: 1800px) {
  .menu-inner3 {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: none;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
  }
}
@media (min-width: 1601px) and (max-width: 1800px) {
  .menu-inner3 {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: none;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
  }
  .background-blue-imgs {
    width: 100%;
    height: 17vh;
    text-align: center;
  }
  .slider-div2 {
    height: 240px;
  }
  .slider-img2 {
    font-size: 14px;
    color: #fff;
    line-height: 20px;
    font-family: arial-bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .slider-div {
    height: 250px;
    background-color: #fff;
    position: relative;
    outline: 0;
  }
}
@media (min-width: 1801px) and (max-width: 2400px) {
  .organic-marketing-banner-new-div > div > .p2,
  .organic-marketing-banner-new-div > div > p {
    background-color: #f8f9fa;
    text-align: start;
    border-radius: 5px;
    font-family: arial;
    margin-bottom: 20px;
  }
  .menu-inner3 {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: none;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
  }
  .slider-div2 {
    height: 240px;
  }
  .slider-img2 {
    font-size: 14px;
    color: #fff;
    line-height: 20px;
    font-family: arial-bold;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  .slider-div {
    height: 250px;
    background-color: #fff;
    position: relative;
    outline: 0;
  }
  .background-blue-imgs {
    width: 100%;
    height: 16vh;
    text-align: center;
  }
  .organic-marketing-banner-new-div > img {
    position: relative;
    top: 0;
    right: 0;
    height: 680px;
  }
  .organic-marketing-banner-new-div > div > p {
    font-size: 20px;
    width: 250px;
    padding: 20px 15px;
  }
  .organic-marketing-banner-new-div > div > .p2 {
    font-size: 21px;
    width: 350px;
    padding: 20px 23px;
  }
  .h-max {
    height: 65vh;
    max-width: 650px;
    margin: 0 auto;
  }
}
@media (min-width: 100px) and (max-width: 766px) {
  .teams-heading-div1 > h5,
  .tearm-condition-div h4 {
    text-transform: uppercase;
    font-family: arial-bold;
  }
  .ads-batch-div1,
  .branding-why-text1,
  .domain-name-div,
  .nav-tab-text,
  .ourteam-what-we-do-text,
  .question-creative-div,
  .social-question-text {
    align-self: center;
    align-self: center;
  }
  .total-cart > div > div > button,
  .total-cart > div > div > span {
    box-shadow: 0 0 7px 0 #e9ecef;
    background-color: #f8f9fa;
  }
  .overflow-div {
    display: flex;
    height: auto;
    padding: 20px 0;
    justify-content: flex-start;
    overflow-x: auto;
  }
  .branding-why-text > h2 {
    font-size: 11px;
    padding-bottom: 8px;
    font-family: arial;
    padding-left: 60px;
    font-weight: 500;
    line-height: 17px;
    color: #fff;
  }
  .meet-our-festive-moment3 {
    font-size: 15px;
    float: right;
    font-family: arial-bold;
    color: #fff;
    text-decoration: underline;
    padding: 0;
  }
  .footer-back2 {
    background-image: linear-gradient(to top, #0080ff2d, #ffffffe0);
  }
  .contact-us-form-mobile {
    margin: 0;
  }
  .teams-link {
    font-family: arial;
    font-size: 13px;
    text-transform: uppercase;
  }
  .teams-heading-div1 {
    text-align: center;
    background-image: url("../../public/assest/photos/termsandcondi-mobile.jpg");
    background-size: 100% 100%;
    height: 540px;
  }
  .tearm-condition-div p {
    font-family: arial;
    font-size: 14px;
    color: #175089;
    line-height: 23px;
  }
  .disclaimer-div {
    margin: 0px 10px;
  }
  .disclaimer-div > span {
    font-size: 14px;
    color: #333;
    font-family: "arial";
    line-height: 42px;
    font-weight: 600;
  }
  .disclaimer-div > h4 {
    font-size: 15px;
    line-height: 26px;
    font-family: "arial";
    color: #666;
  }
  .disclaimer-div > h3 {
    font-size: 14px;
    font-family: "arial-bold";
    color: #436fe8;
    line-height: 39px;
    text-transform: uppercase;
  }
  .disclaimer-div > p {
    font-size: 14px;
    font-family: "arial";
    color: #666;
    line-height: 23px;
  }
  .teams-div2 p {
    font-family: arial;
    font-size: 14px;
    line-height: 23px;
    padding-top: 1px;
    padding-left: 0;
  }
  .tearm-condition-div div {
    font-family: arial;
    font-size: 16px;
    padding-left: 14px;
    padding-bottom: 8px;
    position: relative;
  }
  .tearm-condition-div h4 {
    padding-top: 22px;
    padding-bottom: 4px;
    line-height: 22px;
    color: #2451cc;
    font-size: 15px;
  }
  .teams-heading-div1 > h5 {
    color: #436fe8;
    padding-top: 323px;
    line-height: 63px;
    font-size: 46px;
    text-shadow: 0 4px #007bff3b;
  }
  .tearm-condition-div h1 {
    padding: 40px 0;
    font-family: arial-bold;
    font-size: 31px;
    color: #0062cc;
  }
  .enquire-button span,
  .enquire-button span:hover {
    padding: 5px 11px;
    text-transform: uppercase;
    text-decoration: none;
  }
  .card-header {
    padding: 8px;
  }
  .why-choose-us-texting > h2 {
    color: #fff;
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 0;
    position: relative;
    font-family: arial;
  }
  .card-body-span {
    border-top: 1px solid #ccc;
    font-size: 14px;
    font-family: arial;
    padding: 19px 7px;
    line-height: 26px;
    margin: 0 20px;
  }
  .career-img-banner {
    height: 550px;
  }
  .career-banner-div > h3 {
    font-size: 13px;
    line-height: 27px;
    margin: 22px 1px;
    color: #fff;
    font-family: arial;
  }
  .enquire-button span {
    transition: color 0.2s ease-in-out;
    color: #fff;
    background-color: var(--primary-bg-color);
    border-radius: 3px;
    font-size: 13px;
    border: 1px solid var(--primary-color);
  }
  .enquire-button span:hover {
    transition: 0.5s ease-out;
    color: var(--primary-color);
    background: linear-gradient(to left, #fff 50%, #436fed 50%) right;
    background-size: 200%;
    border-radius: 3px;
    border: 1px solid #436fed;
  }
  .enquire-button {
    position: fixed;
    border-radius: 0;
    right: 0;
    bottom: 50%;
    z-index: 400;
    transform: translateY(40px);
    rotate: -90deg;
  }
  .sign-section {
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .header1 {
    transform: translateY(-26%) !important;
    position: fixed;
    transition: 1s;
    border-bottom: 1px solid #ccc;
    min-width: 100%;
    background-color: #fff;
    z-index: 9;
    padding-bottom: 0;
  }
  .header1:hover {
    transform: none;
  }
  .menu-inner {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: block;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
  }
  .footer-div-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 100px;
    background-color: #000;
    align-items: center;
    font-size: 13px;
    display: flex;
    color: #fed501;
    justify-content: space-around;
    border-radius: 10px;
    font-weight: 800;
  }
  .footer-div-buttom {
    background-color: #fed501;
    color: #000;
    font-size: 9px;
    font-weight: 600;
    padding: 0 10px;
    border-radius: 5px;
  }
  .search-box-form .login-inp {
    width: 100%;
    height: 30px;
    border-radius: 25px;
    background: 0 0;
    border: 1px solid var(--primary-color);
    padding-left: 27px;
    font-size: 12px;
    padding-bottom: 0;
    margin-top: 1%;
  }
  .drop-new-span {
    color: #37474f;
    text-transform: capitalize;
    font-family: arial;
    font-size: 10px;
    font-weight: 100;
    text-align: center;
  }
  .addtocard-head,
  .sign-up-head {
    font-size: 12px;
    font-family: arial;
  }
  .main li {
    list-style: none;
    background: #f8f9fa;
    position: relative;
    width: 70px;
    text-align: start;
    left: -12px;
    color: #436fe8;
    padding: 2px 3px;
    box-shadow: 0 0 2px 0 #adb5bd;
    font-size: 23px;
    line-height: 15px;
    -webkit-transition: 0.5s ease-in-out;
  }
  .hov32:hover .hov23 {
    position: absolute;
    color: #000;
    top: 4px;
    font-size: 11px;
    display: block;
    background: #fff;
    left: 70px;
    width: 69px;
  }
  .hov34:hover .hov24,
  .hov35:hover .hov25 {
    position: absolute;
    color: #000;
    background: #fff;
    left: 69px;
    transform: translateY(6px);
    display: block;
  }
  .sign-up-head {
    color: #fff;
    border-radius: 4px;
    text-transform: capitalize;
    padding: 2px 16px;
    margin: 1px 7px;
    background-color: #436fe8;
    border: 1px solid #436fe8;
  }
  .addtocard-head {
    padding: 4px 13px;
    color: #0430ab;
  }
  .Client-testinomial-buttom,
  .pt-button {
    font-family: var(--title-fonts);
    line-height: 2;
    vertical-align: middle;
    overflow: hidden;
  }
  .profile-div {
    position: absolute;
    text-align: center;
    top: 24px;
    background-color: #000;
    right: 0;
    visibility: hidden;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    z-index: 3;
  }
  .hov34:hover .hov24 {
    top: -2px;
    width: 85px;
  }
  .hov35:hover .hov25 {
    top: 25px;
    width: 91px;
  }
  .search-box-form > .login-btn {
    height: 26px;
    line-height: 1;
    color: var(--primary-color) !important;
    padding: 0;
    border-radius: 15px;
    font-size: 10px;
    letter-spacing: 1px;
    position: relative;
    top: 0;
    left: 38px;
    background: 0 0;
  }
  .search-div {
    margin-bottom: 1px;
    display: block;
  }
  .icon-top-ul > li {
    border-right: 1px solid #ccc;
    padding: 0 4px;
    display: none;
  }
  .icon-top-ul {
    display: flex;
    justify-content: flex-end;
    margin: 2px 0;
    height: 30px;
  }
  .cart-count {
    background-color: red;
    color: #fff;
    box-shadow: 1px 1px 1px 0 #6c757d;
    padding: 3px 6px;
    position: absolute;
    font-size: 9px;
    top: -7%;
    border-radius: 50px;
  }
  .second-menu-ul > li > a {
    display: flex;
    line-height: 13px;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 8px;
    padding: 6px 0;
  }
  .second-menu-ul > li > a:only-child {
    padding: 5px 15px;
  }
  .Client-testinomial-buttom {
    position: relative;
    width: auto;
    background: 0 0/200% auto #ffc107;
    color: var(--bg-white);
    font-size: 15px;
    font-weight: 700;
    padding: 7px 26px;
    border: none;
    display: inline-block;
    border-radius: 5px;
    margin-top: 28px;
    margin-bottom: 25px;
  }
  .btn-link,
  .btn-link:hover {
    color: var(--bg-black);
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
  }
  .client-test-div {
    height: 0;
  }
  .section-margin {
    padding-top: 0;
    padding-bottom: 0;
  }
  .btn-link:hover {
    border: 1px solid transparent;
  }
  .slider-heading {
    text-align: center;
    color: #436fe8;
    font-size: 25px;
    font-weight: 700;
    position: relative;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .grank-title,
  .text-align-div {
    text-align: left;
  }
  .slider-heading::after {
    content: "";
    position: absolute;
    width: 68px;
    text-align: center;
    top: 134%;
    right: 40%;
    border-radius: 20px;
    height: 4px;
    background-color: #fed501;
  }
  .elementor-icon-list-img {
    width: 100%;
    margin-top: 10%;
  }
  .pt-button {
    position: relative;
    width: auto;
    background: var(--primary-color);
    background-size: 200% auto;
    color: var(--bg-white);
    font-size: 16px;
    padding: 7px 26px;
    border: none;
    display: inline-block;
    border-radius: 5px;
    margin-top: 19px;
  }
  .pt-footer-social ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    float: none;
  }
  .pt-footer-social ul li a {
    background: var(--bg-white);
    color: var(--primary-color);
    width: 30px;
    height: 31px;
    line-height: 53px;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
  }
  footer#pt-footer .pt-copyright-footer .pt-copyright a {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }
  .grank-title {
    line-height: 28px;
    font-size: 16px;
    margin-bottom: 20px;
  }
  #pt-footer .pt-footer-style-1 .pt-footer-logo {
    height: 68px;
    margin-bottom: 13px;
    margin-top: 36px;
    width: 193px;
    border-radius: 5px;
  }
  .nav-link2,
  .nav-link2.active {
    border: none;
    background-repeat: no-repeat;
    background-color: #436fe8;
    width: 100%;
    font-weight: 700;
  }
  .case-padding-none,
  .footer-white-bg-padding {
    padding: 0 20px;
  }
  footer#pt-footer .widget .footer-title {
    margin-top: 23px;
    padding: 0;
    font-size: 21px;
    position: relative;
    margin-bottom: 0;
    line-height: 43px;
  }
  footer#pt-footer .pt-footer-style-1 .widget ul li a,
  footer#pt-footer .pt-footer-style-1 .widget ul.menu li a {
    color: var(--bg-white);
    line-height: 16px;
    font-size: 14px;
  }
  .pt-contact {
    font-size: 14px;
    line-height: 21px;
  }
  footer#pt-footer .pt-copyright-footer .pt-copyright {
    text-align: center;
    padding: 5px 0;
    display: inline-block;
    width: 100%;
  }
  #order1 {
    order: 2;
    order: 1;
  }
  #order2 {
    order: 1;
    order: 2;
  }
  .nav-link2.active {
    background-image: none;
    background-size: 107% 100%;
    font-size: 9px;
    height: 48px;
    color: #fed501;
  }
  .nav-link2 {
    background-size: 100% 100%;
    font-size: 9px;
    height: 47px;
    color: #fff;
    text-align: center;
  }
  .main-slider-custome-button-next,
  .main-slider-custome-button-prev {
    background-color: #f8f9faa3;
    color: #0062cc;
    bottom: 35%;
    border-radius: 3px;
    cursor: pointer;
    padding: 5px;
    height: 37px;
    position: absolute;
    display: block;
  }
  .nav-item2 {
    width: 61px;
    text-align: center;
  }
  .banner-new-div > h3 {
    font-size: 9px;
    line-height: 28px;
    margin: 0 1px;
  }
  .banner-new-div > h3 > a {
    background-color: #000;
    padding: 4px 18px;
    border-radius: 4px;
  }
  .banner-new-div > h2 {
    font-size: 13px;
    line-height: 18px;
    margin: 4px 0;
  }
  .banner-new-div > h4 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 29px;
    font-family: arial-bold;
    max-width: 238px;
    min-height: 67px;
  }
  .banner-new-div2 > h4 {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    max-width: 237px;
    min-height: 83px;
    line-height: 38px;
    color: #fff;
    font-family: arial-bold;
  }
  .banner-new-div2 > h2 {
    font-size: 13px;
    line-height: 21px;
    margin: 0;
    color: #fff;
    font-family: arial-bold;
  }
  .banner-new-div2 > h3 {
    font-size: 11px;
    line-height: 26px;
    margin: 11px 1px;
    font-family: arial;
  }
  .banner-new-div2 > h3 > a {
    background-color: #000;
    padding: 9px 29px;
    border-radius: 4px;
    background: linear-gradient(to left, #fed501 50%, #fff 50%) right;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .banner-new-div2 {
    position: absolute;
    top: 17%;
    text-align: start;
    left: 30px;
  }
  .main-slider-custome-button-prev {
    width: 33px;
    right: 24%;
    z-index: 2;
    text-align: center;
  }
  .main-slider-custome-button-next {
    width: 35px;
    right: 10%;
    text-align: center;
  }
  .slider-heading-top {
    position: absolute;
    text-align: center;
    left: 36%;
    bottom: 8%;
    color: #436fe8;
    font-size: 19px;
    font-weight: 700;
  }
  .slider-heading-top::after {
    content: "";
    position: absolute;
    width: 53px;
    text-align: center;
    top: 122%;
    left: 27%;
    border-radius: 20px;
    height: 3px;
    background-color: #fed501;
  }
  .slider-div > .slider-img-absolute1 {
    width: 100%;
    padding: 0;
    height: 100%;
  }
  .selecter-div {
    border: 1px solid #436fe8;
    border-radius: 18px;
    color: #436fe8;
    width: 100%;
    margin: 8px 0;
    padding: 3px 12px;
  }
  .testinomial-section-name10::before,
  .testinomial-section-name11::before,
  .testinomial-section-name12::before,
  .testinomial-section-name13::before,
  .testinomial-section-name2::before,
  .testinomial-section-name3::before,
  .testinomial-section-name4::before,
  .testinomial-section-name5::before,
  .testinomial-section-name6::before,
  .testinomial-section-name7::before,
  .testinomial-section-name8::before,
  .testinomial-section-name9::before,
  .testinomial-section-name::before {
    left: 5px;
    width: 53px;
    height: 50px;
    border-radius: 32px;
    content: "";
    background-size: 100% 100%;
    background-repeat: no-repeat;
    top: 4px;
  }
  .selecter-section > h3 {
    font-size: 16px;
    font-weight: 700;
    color: #436fe8;
  }
  .desktop-none,
  .domain-cart,
  .flex-cart-div,
  .selecter-section {
    display: block;
  }
  .banner-new-div {
    position: absolute;
    top: 11%;
    left: 17px;
    text-align: start;
  }
  .elementor-icon-list-text2,
  .elementor-icon-list-text3,
  .slider-heading1 {
    font-family: arial-bold;
    position: relative;
  }
  .elementor-icon-list-item {
    margin-bottom: 12px;
    margin-left: 29px;
  }
  .nav-tab-text {
    padding: 0;
  }
  .pt-button2,
  .pt-button3 {
    margin: 20px;
  }
  .elementor-icon-list-img2 {
    width: 101%;
    float: left;
  }
  .testinomial-section-img {
    width: 73%;
    height: auto;
    margin-left: 42px;
    text-align: center;
    animation: 1s shakeX;
  }
  .second-menu {
    background-color: #436fe8;
    padding: 0;
    display: block;
  }
  .dno {
    padding: 4px 0;
    display: flex;
  }
  .icon-top-ul > li > a > img {
    height: 22px;
  }
  .search-box-form {
    width: 131%;
    display: flex;
    margin-left: -47px;
  }
  .elementor-icon-list-text3 {
    color: #495057;
    font-size: 14px;
    padding-bottom: 8px;
    line-height: 25px;
  }
  .elementor-icon-list-text2 {
    font-size: 14px;
    color: var(--primary-color);
    padding-bottom: 16px;
    line-height: 23px;
  }
  .elementor-icon-list-text4 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    font-family: arial-bold;
    padding-bottom: 20px;
  }
  .slider-heading1 {
    text-align: center;
    color: #436fe8;
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 0;
  }
  .why-choose-us-img {
    width: 106%;
    margin-bottom: 20px;
  }
  .why-choose-us-texting {
    position: absolute;
    top: 18%;
    transform: translate(0, -50%);
    left: 5%;
    padding: 28px;
  }
  .why-choose-us-texting > p {
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: -18px;
    position: relative;
    font-family: arial;
  }
  .ourteam-what-we-do-text > .typewriter > h3 {
    font-family: montessat;
    color: #fff;
    font-size: 60px;
    position: relative;
    line-height: 77px;
    text-shadow: 5px 3px #343434;
  }
  .ourteam-what-we-do-text > .typewriter > h3 > span {
    display: block;
    font-size: 107px;
    line-height: 90px;
  }
  .ourteam-what-we-do-text > .typewriter > h3 > .do {
    display: block;
    font-size: 120px;
    line-height: 96px;
  }
  .methodo-texting > p {
    font-size: 13px;
    font-family: arial;
    line-height: 20px;
    color: #6c757d;
  }
  .methodo-texting > h3 {
    font-size: 16px;
    color: #436fe8;
    font-family: arial-bold;
    margin: 12px 0;
    position: relative;
  }
  .methodo-texting > h3::before {
    content: "";
    position: absolute;
    top: 25%;
    left: -6.2%;
    width: 10px;
    height: 10px;
    background-color: var(--primary-bg-color);
    border-radius: 20px;
  }
  .methodo-img-div {
    display: grid;
    padding: 29px 0;
  }
  #level,
  .background-color,
  .background-color4,
  .background-color8,
  .branding-icon1,
  .branding-icon3,
  .branding-icon7,
  .breadcrumbs,
  .mobile-none,
  .organic-marketing-banner-new-div > .div2-p2,
  .organic-marketing-banner-new-div > div,
  .testimg:after {
    display: none;
  }
  .testimonial-slider-text {
    text-align: left;
    color: #fff;
    font-family: arial;
    font-size: 13px;
    padding: 0 15px;
  }
  .client-testimonial-texture {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0;
  }
  .slider-heading2 {
    text-align: left;
    font-family: arial;
    color: #436fe8;
    font-size: 25px;
    font-weight: 700;
    position: relative;
    margin-bottom: 25px;
    margin-top: -5px;
  }
  .slider-heading-text {
    position: relative;
    font-size: 13px;
    font-family: arial-bold;
    margin-bottom: 50px;
    color: #6c757d;
  }
  .testinomial-section-name > h2 {
    font-size: 13px;
    font-family: arial;
    color: #6c757de6;
  }
  .testinomial-section-name > h3 {
    font-size: 14px;
    font-family: arial;
    margin-bottom: 10px;
  }
  .methodo-texting {
    position: relative;
    padding: 20px 18px;
  }
  .ourteam-what-we-do-text {
    position: relative;
    text-align: center;
    margin-bottom: 38px;
    margin-top: 38px;
  }
  .slider-div-team > div {
    background-color: #f8f9fa;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 9px 0 #dee2e6;
    min-height: 486px;
    border-radius: 15px;
  }
  .testimonial-section-margin {
    margin-top: 0;
    margin-bottom: 40px;
    position: relative;
    background-image: none;
    background-size: 100% 100%;
    padding-top: 0;
    padding-bottom: 50px;
    background-color: #436fe8;
  }
  .testimonial-comma {
    width: 0;
  }
  .slider-custome-button-next,
  .slider-custome-button-prev {
    background-color: #f8f9faa3;
    color: #0062cc;
    width: 40px;
    bottom: -7%;
    border-radius: 3px;
    display: block;
    padding: 5px;
    height: 40px;
    text-align: center;
    position: absolute;
    cursor: pointer;
  }
  .slider-custome-button-prev {
    left: 10%;
    z-index: 2;
  }
  .slider-custome-button-next {
    left: 25%;
  }
  .testinomial-section-name,
  .testinomial-section-name10,
  .testinomial-section-name11,
  .testinomial-section-name12,
  .testinomial-section-name13,
  .testinomial-section-name14,
  .testinomial-section-name15,
  .testinomial-section-name16,
  .testinomial-section-name17,
  .testinomial-section-name2,
  .testinomial-section-name3,
  .testinomial-section-name4,
  .testinomial-section-name5,
  .testinomial-section-name6,
  .testinomial-section-name7,
  .testinomial-section-name8,
  .testinomial-section-name9 {
    border-radius: 40px;
    background-color: #fed501;
    padding: 9px 31px 9px 31px;
    transform: translate(-8%, -42%);
    float: right;
    transition: 1s;
  }
  /* .testinomial-section-name::before {
    position: absolute;
    background-image: url("../../public/assest/photos/review-logo/techmech.png");
  }
  .testinomial-section-name2::before {
    position: absolute;
    background-image: url("../../public/assest/photos/review-logo/ameenji.png");
  }
  .testinomial-section-name3::before {
    position: absolute;
    background-image: url("../../public/assest/photos/background-grey.jpeg");
  }
  .testinomial-section-name4::before {
    position: absolute;
    background-image: url("../../public/assest/photos/review-logo/shantiniketan.png");
  } */
  .testinomial-section-name2 > h3,
  .testinomial-section-name4 > h3,
  .testinomial-section-name5 > h3 {
    font-size: 13px;
    font-family: arial;
    margin-bottom: 5px;
  }
  /* .testinomial-section-name5::before {
    position: absolute;
    background-image: url("../../public/assest/photos/review-logo/aqua-biotech.png");
  }
  .testinomial-section-name6::before {
    position: absolute;
    background-image: url("../../public/assest/photos/review-logo/snvp-school.png");
  }
  .testinomial-section-name7::before {
    position: absolute;
    background-image: url("../../public/assest/photos/review-logo/ace-cons.png");
  }
  .testinomial-section-name8::before {
    position: absolute;
    background-image: url("../../public/assest/photos/review-logo/esennarlogo.png");
  }
  .testinomial-section-name9::before {
    position: absolute;
    background-image: url("../../public/assest/photos/review-logo/candeur-landmark.png");
  }
  .testinomial-section-name10::before {
    position: absolute;
    background-image: url("../../public/assest/photos/review-logo/ecolife_logo.png");
  }
  .testinomial-section-name11::before {
    position: absolute;
    background-image: url("../../public/assest/photos/review-logo/bharadwaj-packaging.jpg");
  }
  .testinomial-section-name12::before {
    position: absolute;
    background-image: url("../../public/assest/photos/review-logo/rrstone.png");
  }
  .testinomial-section-name13::before {
    position: absolute;
    background-image: url("../../public/assest/photos/review-logo/tsg-audio-nnovations.png");
  }
  .testinomial-section-name14::before {
    position: absolute;
    top: 4px;
    left: 0;
    width: 83px;
    height: 50px;
    border-radius: 32px;
    content: "";
    background-image: url("../../public/assest/photos/client-icon/dirak.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  } */
  .testinomial-section-name15::before,
  .testinomial-section-name16::before,
  .testinomial-section-name17::before {
    top: 4px;
    left: 5px;
    width: 53px;
    height: 50px;
    border-radius: 32px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    content: "";
  }
  /* .testinomial-section-name15::before {
    background-image: url("../../public/assest/photos/review-logo/ravoos.png");
  }
  .testinomial-section-name16::before {
    background-image: url("../../public/assest/photos/review-logo/rajapushpa.png");
  }
  .testinomial-section-name17::before {
    background-image: url("../../public/assest/photos/review-logo/primex-plastics.jpg");
  } */
  .action-white-div-text {
    width: 100%;
    font-family: arial;
    height: 111px;
    background-color: #fff;
    background-image: url("../../public/assest/photos/white-action-back.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    align-items: center;
    font-size: 16px;
    display: flex;
    color: var(--primary-bg-color);
    justify-content: space-around;
    border-radius: 10px;
    font-weight: 800;
  }
  .action-white-div-buttom {
    background-color: var(--primary-bg-color);
    color: #fff;
    font-family: arial;
    font-size: 10px;
    font-weight: 600;
    padding: 5px 23px;
    border-radius: 5px;
    background: linear-gradient(to left, #436fe8 50%, #fff 50%) right;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .action-yellow-div-text,
  .branding-process-div,
  .branding-why-div,
  .branding-why-img::after,
  .branding-why-img::before,
  .slider-div2 {
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .branding-consulting-banner > h4 {
    position: absolute;
    bottom: 5%;
    left: 50%;
    font-size: 7px;
    letter-spacing: 0;
    line-height: 12px;
    width: 1500px;
    color: #fff;
    font-family: arial;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .branding-consulting-banner > h5,
  .branding-consulting-banner > h5 > p {
    color: #fed501;
    text-align: center;
    font-family: arial;
    font-weight: 700;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  .branding-consulting-banner > h5 {
    bottom: 22%;
    left: 27%;
    font-size: 18px;
  }
  .branding-consulting-banner > h5 > p {
    bottom: 23%;
    left: 50%;
    font-size: 12px;
    line-height: 24px;
  }
  .branding-cunsulting-banner-new-div {
    position: absolute;
    top: 70%;
    text-align: start;
    left: 8%;
  }
  .branding-cunsulting-banner-new-div > h3 {
    font-size: 13px;
    line-height: 6px;
    margin: 15px 1px;
    font-family: arial;
  }
  .branding-cunsulting-banner-new-div > h3 > a {
    padding: 6px 18px;
    border-radius: 23px;
    background: linear-gradient(to left, #fff 50%, #fff 50%) right;
    color: #000;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .branding-cunsulting-banner-new-div > h2 {
    font-size: 14px;
    color: #e9ecef;
    line-height: 33px;
    font-family: arial-bold;
  }
  .branding-cunsulting-banner-new-div > h1 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    max-width: 200px;
    color: #3f51b5;
    min-height: 43px;
    line-height: 26px;
    font-family: arial-bold;
  }
  .waviy-sec {
    position: relative;
    -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
    font-size: 31px;
  }
  .branding-consulting-process-text {
    text-align: center;
    padding: 0 25px;
  }
  .branding-consulting-process-text > h4 {
    color: #37474f;
    font-family: arial-bold;
    font-size: 25px;
    padding-top: 0;
  }
  .branding-consulting-process-text > h2 {
    color: #37474f;
    font-family: arial-bold;
    font-size: 30px;
    padding-bottom: 5px;
  }
  .branding-consulting-process-text > p {
    font-size: 16px;text-align: left;
    padding-bottom: 26px;
    font-family: arial;
    font-weight: 500;
    line-height: 26px;
    color: #436fe8;
  }
  .branding-quick-link,
  .branding-quick-link > p {
    font-family: arial-bold;
    color: #303e45;
    text-align: center;
  }
  .branding-quick-link > p {
    font-size: 20px;
    margin-bottom: 23px;
  }
  .branding-quick-link {
    font-size: 33px;
    margin-top: 70px;
  }
  .branding-process-div {
    background-color: #e9ecef;
    height: auto;
  }
  .branding-consulting-what-heading {
    padding: 20px 25px;
  }
  .branding-consulting-what-text {
    overflow-y: scroll;
    direction: rtl;
    height: 400px;
    margin-left: 10px;
    padding: 19px 26px;
  }
  .branding-consulting-what-text > h2,
  .branding-consulting-what-text > p {
    font-size: 15px;
    font-family: arial;
    margin-bottom: 28px;
    position: relative;
    line-height: 21px;
  }
  .branding-consulting-what-text > h2::before,
  .branding-consulting-what-text > p::before {
    content: "";
    width: 7px;
    height: 7px;
    background-color: #0062cc;
    top: 5%;
    border-radius: 24px;
    position: absolute;
    left: -5%;
  }
  .testinomial-heading::after {
    border-radius: 10px;
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 5px;
    background-color: #fed501;
  }
  .testinomial-heading {
    padding-bottom: 12px;
    color: #fff;
    font-family: arial-bold;
    font-size: 28px;
    padding-top: 20px;
    text-align: center;
    position: relative;
  }
  .slider-div2 {
    height: 255px;
    background-image: url("../../public/assest/photos/background-blue-slider.png");
    position: relative;
    border-radius: 17px;
    text-align: center;
    border-right: 4px solid #fff;
    outline: 0;
  }
  .slider-img2 {
    font-size: 14px;
    color: #fff;
    line-height: 20px;
    font-family: arial-bold;
    margin-top: 110px;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 5%;
    text-transform: uppercase;
  }
  .branding-comminication-banner-new-div > h1 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: #6c757d;
    min-height: 20px;
    line-height: 21px;
    font-family: arial-bold;
  }
  .branding-comminication-banner-new-div > h2 {
    font-size: 11px;
    color: #6c757d;
    line-height: 37px;
    font-family: arial-bold;
  }
  .branding-comminication-banner-new-div > h3 > a {
    padding: 8px 28px;
    border-radius: 23px;
    background: linear-gradient(to left, #fff 50%, #fff 50%) right;
    color: #000;
    background-size: 200%;
    font-size: 12px;
    transition: 0.5s ease-out;
    border: 2px solid #ffc107;
  }
  .branding-banner-new-div > h3 > a,
  .social-banner-div > h3 > a {
    background: linear-gradient(to left, #fed501 50%, #fff 50%) right;
  }
  .branding-comminication-banner-new-div > h3 {
    font-size: 16px;
    line-height: 26px;
    margin: 0 1px;
    font-family: arial;
  }
  .branding-why-img::before {
    content: "";
    position: absolute;
    background-image: url("../../public/assest/photos/branding/branding-yellow-back.png");
    top: -10%;
    width: 306px;
    height: 197px;
    left: 8%;
  }
  .branding-why-img {
    width: 80%;
  }
  .branding-why-img::after {
    content: "";
    position: absolute;
    background-image: url("../../public/assest/photos/branding/branding-yellow-path.png");
    bottom: -10%;
    left: 0.5%;
    width: 50px;
    height: 50px;
  }
  .branding-why-text > h4 {
    color: #fed501;
    font-family: arial-bold;
    padding-bottom: 16px;
    padding-top: 25px;
    font-size: 23px;
  }
  .branding-why-text > p {
    font-size: 13px;
    padding-bottom: 7px;
    font-family: arial;
    padding-left: 0;
    font-weight: 500;
    line-height: 20px;
    color: #fff;
  }
  .branding-banner-new-div > h4 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    max-width: 414px;
    color: #436fe8;
    min-height: 57px;
    line-height: 31px;
    font-family: arial-bold;
  }
  .branding-banner-new-div > h3 > a {
    padding: 9px 28px;
    border-radius: 23px;
    color: #000;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .branding-banner-new-div > h3 {
    font-size: 12px;
    line-height: 0;
    margin: 0;
    font-family: arial;
  }
  .branding-banner-new-div {
    position: absolute;
    top: 83%;
    text-align: start;
    left: 7%;
  }
  .branding-process-text > h4 {
    color: #37474f;
    font-family: arial-bold;
    font-size: 23px;
    padding-top: 0;
  }
  .branding-process-text > h2 {
    color: #37474f;
    font-family: arial-bold;
    font-size: 41px;
    padding-bottom: 20px;
  }
  .branding-process-text > p {
    font-size: 16px;
    padding-bottom: 26px;
    font-family: arial;
    font-weight: 500;
    line-height: 26px;
    color: #436fe8;
  }
  .branding-service-text1 {
    margin-bottom: 56px;
    background-color: #436fe8;
    height: auto;
    padding-bottom: 20px;
    display: block;
  }
  .flip-card {
    background-color: transparent;
    width: 261px;
    height: 450px;
    text-align: center;
    transform: translate(13%, 5%);
    margin-bottom: 20px;
    position: relative;
    perspective: 1000px;
  }
  .flip-card-front3 > img {
    width: 88%;
    margin-top: 17px;
    border-radius: 0 50px;
  }
  .branding-service-text2 > h4 {
    color: #436fe8;
    padding-top: 24px;
    font-size: 25px;
    font-family: arial-bold;
  }
  .branding-service-text2 > h2 {
    color: #436fe8;
    padding-bottom: 13px;
    font-size: 26px;
    font-family: arial-bold;
  }
  .flip-card-back > h3 {
    font-size: 16px;
    font-family: arial;
    text-align: center;
    font-weight: 600;
    line-height: 30px;
    padding: 0 20px;
    letter-spacing: 1px;
    color: #495057;
  }
  .branding-why-text1,
  .question-creative-div {
    text-align: center;
  }
  .branding-why-div {
    background-image: url("../../public/assest/photos/branding/branding-why-banner.png");
    padding: 24px 0;
  }
  .action-black-div-buttom,
  .action-yellow-div-buttom {
    padding: 5px 23px;
    transition: 0.5s ease-out;
    font-family: arial;
  }
  .action-yellow-div-text {
    width: 100%;
    font-family: arial;
    height: 137px;
    background-image: url("../../public/assest/photos/yellow-action-back.png");
    align-items: center;
    font-size: 16px;
    display: flex;
    color: #000;
    justify-content: space-around;
    border-radius: 10px;
    font-weight: 800;
  }
  .action-yellow-div-buttom {
    background-color: #fed501;
    color: #fed501;
    font-size: 10px;
    font-weight: 600;
    border-radius: 5px;
    background: linear-gradient(to left, #000 50%, #fed501 50%) right;
    background-size: 200%;
  }
  #level {
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
  }
  .action-black-div-text {
    width: 100%;padding: 20px 20px;
    font-family: arial;
    height: 145px;
    background-color: #000;
    background-image: url("../../public/assest/photos/contact-us.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    align-items: center;
    font-size: 14px;
    display: block;text-align: center;
    color: #fed501;
    justify-content: space-around;
    border-radius: 10px;
    font-weight: 800;
  }
  .action-black-div-buttom {
    background-color: #fed501;margin-top: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    background: linear-gradient(to left, #ffc107 50%, #000 50%) right;
    background-size: 200%;
  }
  .branding-consulting-what-heading > h4 {font-size: 25px;line-height: 35px;}
  .branding-comminication-banner-new-div {
    position: absolute;
    top: 76%;
    text-align: end;
    right: 8%;
  }
  .performance-subdiv > div > p {
    font-size: 12px;
    font-family: arial;
    line-height: 18px;
    margin-top: 13px;
  }
  .performance-subdiv > div > h4 {
    font-size: 11px;
    font-family: arial-bold;
  }
  .performance-banner-div > h1 {
    position: relative;
    font-size: 4vh;
    line-height: 39px;
    color: #495057;
    -webkit-text-stroke: 0.3vw transparent;
    text-transform: uppercase;
  }
  .performance-banner-div > h2 {
    font-size: 19px;
    color: #000;
    line-height: 65px;
    font-family: arial-bold;
  }
  .performance-banner-div {
    position: absolute;
    top: 16%;
    text-align: start;
    left: 6%;
  }
  .performance-subdiv {
    position: relative;
    margin-bottom: 0;
  }
  .hid-box,
  .performance-footer-div-text {
    position: absolute;
    color: #6c757d;
    font-family: arial;
  }
  .performance-div2 {
    padding: 21px 20px;
  }
  .performance-div2 > h4 {
    color: #436fe8;
    font-family: arial-bold;
    margin-bottom: 15px;
    font-size: 25px;line-height: 38px;
  }
  .performance-div2-img {
    padding-top: 45px;
  }
  .performance-why-cho-div {
    background-color: #fff;
    padding: 40px;
    text-align: center;
    margin: 20px;
    border-radius: 20px;
  }
  .performance-why-cho-div:hover {
    background-color: #fed501;
    transition: 1s;
  }
  .performance-why-cho-div > div > h3 {
    font-size: 19px;
    font-family: arial-bold;
    padding-bottom: 10px;
  }
  .performance-why-cho-div > div > p,
  .slider-div77-text > h2,
  .slider-div77-text > p {
    font-size: 15px;
    font-family: arial;
  }
  .h-max {
    height: auto;
    max-width: 650px;
    margin: 0 auto;
    display: none;
  }
  .slider-div77-text > h2 {
    text-align: center;
    margin: 10px 0;
  }
  .slider-div77-text > p {
    text-align: center;
    margin: 30px 0;
  }
  .performance-subdiv > div {
    position: absolute;
    top: 0;
    transform: translate(0, -89%);
    left: 5%;
    background-color: #e9ecef;
    width: 90%;
    border-radius: 25px;
    height: 100%;
    box-shadow: 0 4px 8px 0 #adb5bd;
    min-height: 188px;
    padding: 18px 10px;
  }
  .performance-div2 > p {
    font-size: 16px;
    line-height: 28px;
    font-family: arial;
    margin-bottom: 20px;
    color: #444;
  }
  .slider-div7 {
    background-color: #f8f9fa;
    margin: 23px 13px;
    padding: 62px 29px;
    height: 100%;
    min-height: 350px;
    width: 92%;
    border-radius: 20px;
    box-shadow: 0 0 17px 0 #adb5bd;
    text-align: center;
    cursor: pointer;
  }
  .slider-div77-text > h4 {
    color: #436fe8;
    text-align: center;
    font-size: 20px;
    font-family: arial-bold;
    margin-top: 59px;
    margin-bottom: 0;
  }
  .hid-box {
    top: 100%;
    transition: 0.3s ease-out;
    background-image: url("../../public/assest/photos/creative-content/creative-icon-back-white.png");
    background-size: 102% 54%;
    padding: 50px 7px;
    background-repeat: no-repeat;
    height: 100%;
    font-size: 11px !important;
  }
  .performance-footer-div-text {
    transform: translate(-50%, -50%);
    width: 80%;
    top: 40%;
    left: 50%;
    padding: 35px;
    height: 150px;
    background-color: #f8f9fa;
    display: grid;
    align-items: center;
    border: 1px solid #436fe8;
    justify-content: space-around;
    border-radius: 20px;
  }
  .performance-footer-div-text > h4 {
    font-size: 15px;
    text-align: center;
    font-family: arial;
  }
  .searchArea input {
    position: relative;
    border: 0;
    z-index: 2;
    font-size: 13px;
    padding: 1px 16px;
    width: 200px;
    height: 26px;
  }
  .searchArea .inputDiv,
  .searchArea button {
    height: 26px;
    box-sizing: border-box;
    margin: 0;
  }
  .searchArea button {
    float: right;
    margin-left: -25px;
    border: 1px solid #436fe8;
    background-color: #436fe8;
    font-size: 12px;
    padding: 0 16px;
    border-radius: 25px;
    color: #fff;
    font-family: arial-bold;
    position: relative;
    z-index: 4;
  }
  .performance-sem-subdiv > div,
  .performance-smm-subdiv > div {
    left: 5%;
    background-color: #e9ecef;
    border-radius: 25px;
    height: 100%;
  }
  .performance-sem-subdiv > div {
    position: absolute;
    top: 5%;
    transform: translate(0, -127%);
    box-shadow: 0 5px 7px 0 #ccc;
    width: 90%;
    text-align: center;
    min-height: 113px;
    padding: 19px 0;
  }
  .performance-sem-banner-div > h1 {
    position: relative;
    font-size: 23px;
    color: #fff;
    text-align: end;
    font-family: arial-bold;
    -webkit-text-stroke: 0.3vw transparent;
  }
  .performance-sem-subdiv > div > img {
    width: 50%;
  }
  .ads-batch-div2 > img {
    width: 38%;
    padding-top: 34px;
  }
  .performance-sem-subdiv {
    position: relative;
    margin-bottom: 0;
    margin-top: 9px;
  }
  .performance-sem-div2 > img {
    width: 100%;
    margin-bottom: 20px;
  }
  .ads-batch-div1 > h4 {
    font-size: 26px;
    font-family: arial-bold;
    margin-top: 0;
    text-align: center;
    margin-bottom: 0;
    padding: 20px 0;
  }
  .thankyou-div h2 {
    font-size: 17px;
    line-height: 28px;
    margin-bottom: 20px;
    font-family: arial;
  }
  .thankyou-div h1 {
    color: #163277;
    font-size: 20px;
    line-height: 38px;
    padding: 10px 0;
    text-transform: capitalize;
  }
  .ads-batch-div1 > p {
    font-size: 16px;
    font-family: arial;
  }
  .ads-batch {
    background-color: #fed501;
    background-image: none;
  }
  .ads-batch-div1 {
    text-align: left;
  }
  .ads-batch-div2 {
    text-align: center;
    padding: 3px;
  }
  .ads-batch-div2 > h4 {
    font-size: 14px;
    font-family: arial-bold;
    margin-top: 4px;
    margin-bottom: 17px;
  }
  .ads-batch-div2 > h5 {
    font-size: 12px;
    font-family: arial-bold;
    margin-top: 4px;
    margin-bottom: 6px;
  }
  .branding-sem-consulting-what-text {
    overflow-y: scroll;
    direction: rtl;
    height: 550px;
    margin-left: 0;
    padding: 0 23px;
  }
  .b2b-message,
  .b2b-message-div,
  .box,
  .box4 {
    overflow: hidden;
  }
  .org-mart-what-we-do,
  .org-mart-what-we-do2 {
    height: 800px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .branding-sem-consulting-what-heading {
    padding: 20px;
    text-align: center;
  }
  .performance-sem-banner-div {
    position: absolute;
    top: 62%;
    text-align: start;
    right: 5%;
  }
  .performance-smm-banner-div {
    position: absolute;
    top: 58%;
    text-align: end;
    left: 79%;
    transform: translate(-50%, -50%);
  }
  .performance-smm-banner-div > h1 {
    position: relative;
    font-size: 4vh;
    color: #f8f9fa;
    text-shadow: 1px;
    text-align: end;
    font-family: arial-bold;
    -webkit-text-stroke: 0.3vw transparent;
  }
  .performance-smm-subdiv > div {
    position: absolute;
    top: 8%;
    transform: translate(0, -128%);
    width: 90%;
    text-align: center;
    box-shadow: 0 7px 6px 0 #ccc;
    min-height: 135px;
    padding: 12px 0;
  }
  .branding-sem-consulting-what-text > h2,
  .branding-sem-consulting-what-text > p {
    font-size: 16px;
    font-family: arial;
    margin-bottom: 24px;
    padding-left: 10px;
    line-height: 23px;
    position: relative;
  }
  .performance-smm-subdiv > div > h4 {
    font-size: 11px;
    text-align: center;
    font-family: arial-bold;
    margin-top: 9px;
  }
  .performance-smm-subdiv {
    position: relative;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .branding-sem-consulting-what-text > h2 {
    color: #fff;
  }
  .organic-marketing-banner-new-div > img {
    position: relative;
    top: 0;
    right: 0;
    margin-top: -2px;
    height: 189px;
  }
  .hov-drop {
    position: relative;
    width: auto;
    font-size: 13px;
    font-family: Helvetica;
    margin-left: 0;
    font-weight: 700;
    padding: 0 20px;
    margin-top: 14px;
    border-radius: 27px;
    border: 2px solid #436fe8;
    text-align: left;
    line-height: 40px;
    list-style: none;
    z-index: 2;
  }
  .al-video-div {
    position: absolute;
    top: 68%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
  .main-drop li {
    list-style: none;
    background: #fff;
    position: relative;
    width: 294px;
    text-align: start;
    left: 0;
    color: #436fe8;
    padding: 7px;
    box-shadow: 0 0 1px 0 #436fe8;
    font-size: 22px;
    line-height: 18px;
    -webkit-transition: 0.5s ease-in-out;
  }
  .organic-marketing-banner-new-div > h1 {
    position: absolute;
    font-size: 20px;
    top: 9%;
    line-height: 27px;
    left: 34px;
    color: #343a40;
    -webkit-text-stroke: 0.3vw transparent;
    text-transform: uppercase;
  }
  .organic-marketing-banner-new-div > h2 {
    font-size: 13px;
    top: 27%;
    left: 34px;
    position: absolute;
    color: #37474f;
    line-height: 56px;
    font-family: arial-bold;
  }
  .organic-marketing-banner-new-div > h3 > a {
    padding: 1px 21px;
    position: absolute;
    top: 48%;
    left: 31px;
    font-size: 9px;
    border-radius: 23px;
    background: linear-gradient(to left, #436fe8 50%, #fff 50%) right;
    color: #fff;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .question-div > h4 {
    color: #436fe8;
    font-size: 23px;
    margin-bottom: 50px;
    font-family: arial-bold;
    position: relative;
  }
  .question-div > h4::after {
    position: absolute;
    content: "";
    bottom: -41%;
    left: 0;
    background-color: #fed501;
    border-radius: 10px;
    width: 86px;
    height: 4px;
  }
  .question-div > h2 {
    font-size: 14px;
    font-family: arial-bold;
    line-height: 23px;
    padding-right: 0;
    color: #6c757d;
  }
  .videos-text > p {
    font-size: 8px;
    font-family: arial;
    line-height: 14px;
    color: #fff;
  }
  .videos-text > h4 {
    color: #fed501;
    font-family: arial-bold;
    margin-bottom: 7px;
    font-size: 15px;
  }
  .organic-market-service-div {
    background-image: url("../../public/assest/photos/background-blue-slider.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 279px;
    position: relative;
    z-index: 1;
  }
  .organic-market-service-div > img {
    position: absolute;
    top: 50%;
    width: 23%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .org-mark-sem-text > h4,
  .organic-market-service-text {
    font-family: arial-bold;
    position: relative;
    color: #436fe8;
    text-align: center;
  }
  .organic-market-service-text {
    font-size: 17px;
    line-height: 25px;
  }
  .organic-market-service-text::before {
    z-index: 0;
    border-radius: 20px;
    content: "";
    position: absolute;
    left: 0;
    border: 2px solid #ffc107;
    width: 100%;
    height: 301px;
  }
  .organic-market-service-section {
    padding: 2px 0 0;
  }
  .org-mark-sem-text > h4 {
    margin-top: 40px;
    font-size: 25px;
    margin-bottom: 36px;
  }
  .org-mark-sem-text > h2,
  .org-mark-sem-text > p {
    text-align: center;
    font-size: 16px;
    color: #436fe8;
    margin: -1px 0;
    font-family: arial;
  }
  .org-mart-what-we-do2 > p,
  .org-mart-what-we-do > p {
    margin-bottom: 32px;
    color: #fff;
    position: relative;
  }
  .org-mart-what-we-do {
    background-image: url("../../public/assest/photos/organicmarketing/org-mart-what-we-img.png");
    padding: 0 14px;
  }
  .org-mart-what-we-do > h4 {
    font-size: 25px;
    font-family: arial-bold;
    padding: 38px 0;
    position: relative;
    color: #fff;
  }
  .org-mart-what-we-do > h2 {
    font-size: 20px;
    font-family: arial;
    color: #fff;
    line-height: 29px;
    padding-bottom: 41px;
  }
  .org-mart-what-we-do > p {
    font-size: 16px;
    font-family: arial;
    line-height: 20px;
    padding: 0 20px;
  }
  .org-mart-what-we-do2 {
    padding: 0 3px;
  }
  .box,
  .org-sem-slider {
    height: 100%;
    border-radius: 20px;
    box-shadow: 0 0 17px 0 #adb5bd;
    cursor: pointer;
  }
  .org-mart-what-we-do2 > h4 {
    font-size: 17px;line-height: 26px;text-transform: capitalize;
    font-family: arial;
    padding: 20px 0;
    position: relative;
    color: #fff;
  }
  .org-mart-what-sect {height: 800px;}
  .org-mart-what-we-do2 > h2 {
    font-size: 16px;
    font-family: arial;
    color: #fff;
    line-height: 22px;
    padding-bottom: 41px;
  }
  .org-mart-what-we-do2 > p {
    font-size: 14px;
    font-family: arial;
    line-height: 19px;
    padding: 0 20px;
  }
  .creative-marketing-banner-div {
    position: absolute;
    top: 70%;
    text-align: center;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 100%;
  }
  .creative-marketing-banner-div > h1 {
    font-family: arial-bold;
    font-size: 27px;
    color: #000;
    margin-bottom: 6px;
  }
  .creative-marketing-banner-div > h2 {
    font-family: arial-bold;
    font-size: 15px;
    color: #000;
  }
  .creative-marketing-banner-div > h3 {
    font-size: 16px;
    line-height: 26px;
    margin: 24px 1px;
    font-family: arial;
  }
  .cart-img-div {
    text-align: center;
    width: 0;
    margin-right: 0;
    background-color: #f8f9fa;
    padding-top: 40px;
  }
  .creative-marketing-banner-div > h3 > a {
    padding: 13px 33px;
    border-radius: 23px;
    background: linear-gradient(to left, #fff 50%, #fff 50%) right;
    color: #000;
    font-size: 15px;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .creative-header {
    text-align: center;
    text-transform: uppercase;
    margin: 23px 0;
    line-height: 31px;
    font-family: arial-bold;
    color: #436fe8;
    font-size: 23px;
  }
  .creative-header2 {
    text-align: center;
    text-transform: uppercase;
    margin: 0 22px;
    line-height: 26px;
    font-family: arial;
    color: #6c757d;
    font-size: 11px;
  }
  .box {
    background-color: var(--primary-bg-color);
    margin: 9px 12px;
    padding: 30px 0;
    text-align: center;
    min-height: 359px;
    position: relative;
    width: 92%;
  }
  .box > .hid-box2 > h4 {
    color: #fff;
    font-size: 14px;
    font-family: arial-bold;
    margin-top: 20px;
    line-height: 20px;
  }
  .domain-slider-div > h4 {
    font-size: 20px;
    font-family: arial-bold;
  }
  .creative-banner-div {
    position: absolute;
    top: 70%;
    text-align: center;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50px);
  }
  .creative-banner-div > h1 {
    font-family: arial-bold;
    font-size: 28px;
    color: #fff;
    margin-bottom: 11px;
  }
  .creative-banner-div > h2 {
    font-family: arial-bold;
    font-size: 17px;
    color: #fff;
  }
  .creative-banner-div > h3 {
    font-size: 16px;
    line-height: 26px;
    margin: 20px 1px;
    font-family: arial;
  }
  .creative-blue-div > h4,
  .creative-bulb-banner-div > h2,
  .creative-bulb-banner-div > h4,
  .question-creative-div > h4 {
    text-transform: uppercase;
    font-family: arial-bold;
  }
  .creative-banner-div > h3 > a {
    padding: 9px 38px;
    border-radius: 23px;
    background: linear-gradient(to left, #fff 50%, #fff 50%) right;
    color: #000;
    font-size: 14px;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .creative-header23,
  .creative-header24 {
    text-align: center;
    text-transform: uppercase;
    margin: 16px 14px;
    line-height: 29px;
    font-family: arial;
    color: #6c757d;
    font-size: 12px;
  }
  .creative-bulb-banner-div {
    /* position: absolute; */position: relative;
    top: -100px;min-height: 230px;
    text-align: end;
    right: 32px;
  }
  .creative-bulb-banner-div p{width: 90%;}
  .creative-bulb-banner-div > h4 {
    font-size: 18px;
    color: #436fe8;
    line-height: 36px;
  }
  .creative-bulb-banner-div > h2 {
    font-size: 18px;
    line-height: 26px;
    color: #495057;
  }
  .creative-blue-div > h4 {
    color: #fff;
    text-align: start;
    padding-top: 37px;
    margin-bottom: 15px;
    line-height: 34px;
    font-size: 24px;
    padding-left: 0;
    position: relative;
  }
  .org-sem-slider {
    background-color: #e9ecef;
    margin: 20px 7px;
    padding: 30px 12px;
    min-height: 329px;
    width: 97%;
    text-align: center;
  }
  .question-creative-div > h4 {
    color: #436fe8;
    font-size: 28px;
    margin-bottom: 7px;
    position: relative;
  }
  .question-creative-div > h2 {
    font-size: 15px;
    font-family: arial;
    line-height: 30px;
    padding-right: 15px;
    color: #6c757d;
  }
  .social-banner-img {
    position: absolute;
    top: 0;
    right: 16px;
    width: 45%;
  }
  .social-banner-div,
  .web-develop-banner-div {
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .social-banner-div {
    position: absolute;
    top: 86%;
    text-align: center;
    border-bottom: 1px solid #436fe8;
  }
  .social-banner-div > h1 {
    font-family: arial-bold;
    font-size: 34px;
    color: #436fe8;
    margin-bottom: 5px;
  }
  .social-banner-div > h2 {
    font-family: arial;
    font-size: 18px;
    color: #436fe8;
  }
  .social-banner-div > h3 > a {
    padding: 10px 25px;
    border-radius: 6px;
    color: #000;
    font-size: 13px;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .social-banner-div > h3 {
    font-size: 19px;
    line-height: 26px;
    margin: 26px 1px;
    font-family: arial;
  }
  .social-header {
    text-align: center;
    text-transform: capitalize;
    margin: 19px 0;
    line-height: 29px;
    font-family: arial-bold;
    color: #436fe8;
    font-size: 22px;
  }
  .social-question-div {
    background-color: var(--primary-bg-color);
    border-top-right-radius: 65px;
    border-bottom-right-radius: 65px;
    box-shadow: 7px 0 12px 0 #6c757d;
    height: 450px;
    position: relative;
    margin-top: 23px;
    z-index: 3;
  }
  .social-question-text {
    background-image: url("../../public/assest/photos/social-media/grey-back.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0 21px;
    z-index: 1;
  }
  .social-question-header {
    text-align: left;
    text-transform: capitalize;
    margin-top: 62px;
    line-height: 41px;
    font-family: arial-bold;
    color: #436fe8;
    font-size: 33px;
  }
  .social-header2,
  .social-question-header2 {
    text-transform: capitalize;
    font-family: arial;
    color: #6c757d;
  }
  .social-question-header2 {
    text-align: left;
    margin: 13px 0 75px;
    line-height: 28px;
    font-size: 15px;
  }
  .social-header2 {
    text-align: center;
    line-height: 22px;
    margin-bottom: 24px;
    font-size: 12px;
  }
  .web-develop-banner-div {
    position: absolute;
    top: 87%;
    text-align: center;
  }
  .web-develop-banner-div > h1 {
    font-family: arial-bold;
    font-size: 34px;
    color: #436fe8;
    margin-bottom: 16px;
  }
  .web-develop-banner-div > h2 {
    font-family: arial;
    font-size: 16px;
    color: #436fe8;
  }
  .web-develop-banner-div > h3 {
    font-size: 16px;
    line-height: 26px;
    margin: 22px 1px;
    font-family: arial;
  }
  .web-dev-action-yellow-div-text > p > span {
    font-size: 10px;
    font-family: arial-bold;
  }
  .web-develop-banner-div > h3 > a {
    padding: 13px 28px;
    border-radius: 6px;
    background: linear-gradient(to left, #fed501 50%, #fff 50%) right;
    color: #000;
    font-size: 12px;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .web-blue-div1 {
    text-align: center;
    background-color: #fed501;
    padding: 57px 30px;
    width: 92%;
    border-radius: 15px;
    min-height: 323px;
    box-shadow: 0 0 9px 0 #6c757d;
  }
  .web-blue-div-white,
  .web-blue-div2 {
    text-align: center;
    padding: 57px 31px;
    border-radius: 15px;
    position: relative;
    left: 0;
    min-height: 350px;
    box-shadow: 0 0 9px 0 #6c757d;
  }
  .web-blue-div-white {
    background-color: #fff;
  }
  .web-blue-div2 {
    background-color: #fed501;
  }
  .web-dev-action-yellow-div-text {
    width: 100%;
    font-family: arial;
    height: 150px;
    background-color: #fed501;
    align-items: center;
    font-size: 16px;
    display: flex;
    color: #000;
    padding: 20px;
    justify-content: space-around;
    border-radius: 10px;
    font-weight: 800;
  }
  .web-dev-action-yellow-div-buttom {
    background-color: #fed501;
    color: #fff;
    font-family: arial;
    font-size: 8px;
    letter-spacing: 2px;
    padding: 4px 26px;
    text-transform: uppercase;
    border-radius: 5px;
    background: linear-gradient(to left, #436fe8 50%, #fff 50%) right;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .web-dev-blue-heading,
  .web-develop-header {
    position: relative;
    font-family: arial-bold;
    text-align: center;
  }
  .domain-extention-FAQs-section,
  .domain-plan-section {
    background-repeat: no-repeat;
  }
  .web-develop-header {
    text-transform: capitalize;
    margin: 40px 0;
    line-height: 63px;
    color: #495057;
    font-size: 25px;
  }
  .web-dev-blue-heading {
    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    padding: 0 0 44px;
  }
  .domain-extention-FAQs-section {
    background-size: 100% 100%;
    padding: 80px 0;
  }
  .domain-banner-div {
    position: absolute;
    top: 84%;
    text-align: center;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 100%;
  }
  .titleh2 > h2,
  .titleh2w2 > h2 {
    position: relative;
    font-weight: 600;
  }
  .domain-banner-div > h4 {
    font-size: 18px;
    color: #436fe8;
    font-family: arial;
    line-height: 54px;
  }
  .domain-banner-div > h1 {
    font-size: 30px;
    color: #436fe8 !important;
    font-family: arial-bold;
    line-height: 3px;
  }
  .domain-banner-div > h5 {
    font-size: 13px;
    color: #436fe8;
    font-family: arial;
    line-height: 52px;
  }
  .searchArea2 {
    max-width: 313px;
    float: inherit;
    margin-left: 32px;
  }
  .domain-second-div {
    background-color: #fff;
    align-items: center;
    height: 260px;
    justify-content: space-around;
    display: flex;
    border-radius: 20px;
    margin: 12px 4px;
    padding: 20px 21px;
  }
  .domain-second-div > div > h3 {
    font-size: 19px;
    font-family: arial-bold;
    margin: 14px 0;
  }
  .domain-second-div > div > h4 {
    font-size: 12px;
    margin: 15px 0;
    font-family: arial;
    line-height: 19px;
  }
  .domain-second-heading > h1 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 25px;
    font-family: arial-bold;
    color: #fff;
    margin-top: 32px;
  }
  .domain-plan-section {
    background-size: 100% 100%;
    padding: 20px 0;
  }
  .domain-slider-heading {
    text-align: center;
    font-size: 24px;
    margin: 13px 0;
    color: #436fe8;
    font-family: arial-bold;
  }
  .domain-extention-slider-heading {
    text-align: center;
    font-size: 24px;
    margin: 17px 0;
    color: #fff;
    font-family: arial-bold;
  }
  .domain-extention-slider-passage {
    text-align: center;
    font-size: 16px;
    margin: 0 0 30px;
    color: #fff;
    font-family: arial;
  }
  .domain-extention-plan-div {
    margin: 20px;
    height: 496px;
    text-align: center;
    border-right: 1px solid #ccc;
    box-shadow: 0 0 3px 0 #adb5bd;
    padding: 24px;
    background-color: #fff;
  }
  .extension-div2 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .extension-div1 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .yellow-section-heading {
    text-align: center;
    font-family: arial-bold;
    font-size: 21px;
    padding-top: 48px;
  }
  .yellow-section-div {
    padding: 40px;
    background-color: #f7e276;
    margin: 9px 0;
    z-index: 0;
    border-radius: 15px;
  }
  .FAQs-heading-section h4 {
    color: #fff;
    text-align: center;
    font-size: 20px;
    padding: 40px 0;
    font-family: arial-bold;
  }
  .yellow-transfer-section-div {
    text-align: center;
    background-color: #fff;
    padding: 0 50px;
    border-radius: 25px;
    margin: 20px 0;
    height: 289px;
  }
  .yellow-transfer-section-div > h4 {
    font-size: 17px;
    font-family: arial-bold;
    padding-bottom: 20px;
  }
  .domain-name-div {
    background-color: #f2f2f2;
    text-align: center;
    margin: 5px;
    box-shadow: 13px 9px 18px 0 #ccc;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 15px;
    padding-top: 10px;
    height: 233px;
  }
  .op,
  .op2 {
    opacity: 0.5;
    transition: 0.3s;
    height: 100%;
  }
  .titleh2w h2 {
    color: #fff;
    padding: 24px 0;
    font-family: arial-bold;
    font-size: 25px;
    text-align: center;
    letter-spacing: 0;
    font-weight: 400;
  }
  .exp-hosting {
    padding: 20px 0 0;
    text-align: center;
  }
  .hosting-banner h3 a {
    padding: 13px 57px;
    border-radius: 23px;
    color: #fff;
    transition: 0.5s ease-out;
    background: linear-gradient(to left, #436fe8 50%, #fed501 50%) right;
    background-size: 200%;
  }
  .hosting-banner h3 {
    font-size: 16px;
    line-height: 26px;
    margin: 19px 1px;
    font-family: arial;
    letter-spacing: 0.5px;
  }
  .exp-hosting p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 29px;
  }
  .exp-hosting h1 {
    font-size: 20px;
    line-height: 33px;
    margin-bottom: 10px;
    font-family: arial-bold;
  }
  .explore-plans h2 {
    margin-right: -1px;
    font-size: 12px;
    font-weight: 400;
    font-family: arial;
  }
  .explore-plans h3 {
    font-size: 10px;
    line-height: 26px;
    margin: 20px 8px;
    font-family: arial;
    letter-spacing: 0.5px;
  }
  .content-box h3 {
    color: #444;
    text-transform: uppercase;
    font-size: 17px;
    margin-bottom: 10px;
    line-height: 24px;
    font-weight: 600;
    transition: 0.3s ease-in;
  }
  .bestprice-sec {
    background-color: var(--primary-bg-color);
    padding: 21px 0;
    background-repeat: round;
  }
  .email-second-section,
  .email-table-section,
  .hid-box4,
  .meet-our-core-team-section,
  .meet-our-core-team-section2,
  .popup-section,
  .price-box,
  .security-section-margin,
  .wildcard-lock-section {
    background-repeat: no-repeat;
  }
  .price-box {
    background-image: url("../../public/assest/photos/hosting/40.png");
    background-size: 100% 100%;
    padding: 60px 70px;
    margin: 20px 0;
  }
  .pt-comparison-table .cell-tag-bottom {
    position: absolute;
    left: 9%;
    top: 90%;
  }
  .mt60 {
    margin-top: 12px;
  }
  .features-sec2 {
    padding: 23px 0;
    position: relative;
    background-position: bottom;
  }
  .op {
    min-height: 250px;
    padding: 31px 0;
  }
  .shared-title-p {
    text-align: center;
    font-size: 15px;
    line-height: 26px;
    font-family: arial;
    margin: 39px 11px;
  }
  .titleh2w2 > h2 {
    text-align: center;
    font-family: arial;
    display: block;
    font-size: 27px;
    color: #fff;
  }
  .op2 {
    min-height: 438px;
    padding: 29px 0;
  }
  .titleh2 > h2 {
    text-align: center;
    font-family: arial;
    font-size: 23px;
    display: block;
    color: #436fe8;
  }
  .security-banner-div > h1,
  .security-circle-heading {
    font-family: arial-bold;
    font-size: 25px;
    color: var(--primary-bg-color);
  }
  .number > div,
  .quality-cart,
  .total-cart {
    display: flex;
  }
  .security-banner-div {
    position: absolute;
    top: 55%;
    text-align: start;
    left: 24px;
  }
  .security-banner-div > h1 {
    line-height: 31px;
    margin-bottom: 11px;
  }
  .security-banner-div > h2 {
    font-family: arial;
    font-size: 15px;
    color: #003b77;
    line-height: 25px;
  }
  .security-banner-div > h3 {
    font-size: 16px;
    line-height: 26px;
    margin: 30px 1px;
    font-family: arial;
  }
  .security-banner-div > h3 > a {
    padding: 17px 52px;
    border-radius: 6px;
    background: linear-gradient(to left, #fed501 50%, #436fe8 50%) right;
    color: #000;
    font-size: 15px;
    box-shadow: 0 0 9px 0 #adb5bd;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .security-circle-heading {
    text-align: center;
    padding: 20px 0;
  }
  .security-circle-div {
    text-align: center;
    background-color: #e9ecef;
    height: 350px;
    border-radius: 200px;
    margin: 15px 0;
    box-shadow: 0 0 9px 0 #ccc;
    padding: 24px;
  }
  .security-blue-div > p {
    color: #fff;
    font-family: arial;
    padding: 0 10px;
    font-size: 17px;
  }
  .security-blue-div > h4 {
    font-size: 26px;
    padding: 20px 0;
    font-family: arial-bold;
    color: #ffff;
  }
  .table-section {
    padding: 20px;
  }
  .security-section-margin {
    background-size: 100% 100%;
    background-color: rgb(0 123 255 / 9%);
  }
  .security-slider-heading {
    font-family: arial-bold;
    font-size: 24px;
    padding: 0 33px;
    margin-top: 20px;
  }
  .security-testimonial-slider-h1 {
    text-align: left;
    color: var(--primary-bg-color);
    font-family: arial-bold;
    font-size: 18px;
    padding: 19px 36px;
  }
  .security-testimonial-slider-text {
    text-align: left;
    color: #003b77;
    font-family: arial;
    font-size: 17px;
    padding: 0 35px;
  }
  .security-standard-blue-div {
    text-align: center;
    padding: 10px 20px;
  }
  .standard-step1,
  .standard-step2,
  .standard-step3,
  .standard-step4 {
    background-color: rgb(167 210 255 / 50%);
    margin-bottom: 12px;
    font-size: 15px;
    border-left: 10px solid #436fe8;
    text-align: end;
    position: relative;
    left: 0;
    width: 100%;
    float: right;
  }
  .security-standard-blue-div > h4 {
    font-size: 25px;
    color: #436fe8;
    font-family: arial-bold;
    padding: 20px 0;
  }
  .security-standard-blue-div > p {
    font-size: 16px;
    font-family: arial;
    line-height: 28px;
  }
  .standard-step1 {
    font-family: arial;
    height: 131px;
    padding: 29px 26px 0 0;
  }
  .standard-step2 {
    padding: 16px 25px 23px 23px;
    font-family: arial;
    height: 139px;
  }
  .standard-step3 {
    font-family: arial;
    height: 175px;
    padding: 9px 36px 23px 23px;
  }
  .standard-step4 {
    font-family: arial;
    height: 110px;
    padding: 10px 30px 23px 23px;
  }
  .standard-step1 > h1,
  .standard-step2 > h1,
  .standard-step3 > h1,
  .standard-step4 > h1 {
    color: #436fe8;
    font-size: 14px;
    font-family: arial-bold;
    padding-bottom: 7px;
    text-transform: capitalize;
  }
  .standard-step-img > img {
    width: 100%;
    padding: 20px 0;
  }
  .wildcard-lock-text {
    text-align: left;
    color: #003b77;
    font-family: arial;
    font-size: 16px;
    line-height: 24px;
    padding: 35px;
  }
  .email-banner-div,
  .security-org-banner-div,
  .total-cart > div > h2 {
    text-align: start;
    position: absolute;
  }
  .wildcard-lock-heading {
    font-family: arial-bold;
    font-size: 18px;
    padding: 0 33px;
    line-height: 27px;
    margin-top: 80px;
  }
  .wildcard-lock-section {
    background-image: url("../../public/assest/photos/security/back-blue-square.png");
    background-size: 100% 100%;
    padding: 0;
  }
  .b2b-testimonial-section-margin,
  .email-second-section,
  .email-table-section,
  .meet-our-core-team-section {
    background-size: 100% 100%;
  }
  .security-org-banner-div {
    top: 57%;
    left: 17px;
  }
  .security-org-banner-div > h1 {
    font-family: arial-bold;
    line-height: 32px;
    font-size: 25px;
    color: var(--primary-bg-color);
    margin-bottom: 20px;
  }
  .security-org-banner-div > h2 {
    font-family: arial;
    font-size: 16px;
    color: #003b77;
    line-height: 15px;
  }
  .b2b-banner-div > h1,
  .b2c-banner-div > h1,
  .email-banner-div > h1,
  .number span,
  .total-cart > div > h1,
  .total-cart > div > h2 {
    font-family: arial-bold;
  }
  .email-banner-div {
    top: 60%;
    left: 64px;
  }
  .email-banner-div > h1 {
    line-height: 18px;
    font-size: 13px;
    color: #000;
    margin-bottom: 5px;
  }
  .b2b-banner-div > h1 {
    font-size: 34px;
    color: #fff;
    margin-bottom: 20px;
  }
  .b2c-banner-div > h1 {
    font-size: 29px;
    line-height: 41px;
    text-transform: uppercase;
    color: #fff;
  }
  .blog-images-section2 > h4 {
    color: #0062cc;
    font-family: arial-bold;
    line-height: 70px;
    font-size: 14px;
  }
  .number > div {
    justify-content: center;
  }
  .number span {
    font-size: 39px;
    margin-bottom: 39px;
    color: #fed501;
    display: block;
  }
  .total-cart > div > h2 {
    font-size: 19px;
    right: 13px;
    bottom: 13%;
    color: #09757a;
  }
  .total-cart-select {
    width: 135px;
    padding: 5px;
    text-align: center;
    height: 34px;
    margin-left: 0;
    margin-top: 10px;
    background-color: #fff;
    box-shadow: 0 0 0 0 #dee2e6;
    font-family: arial;
    font-size: 15px;
    color: #666;
    border: 1px solid #495057;
  }
  .button-dis {
    position: absolute;
    cursor: pointer;
    color: #000;
    border-radius: 5px;
    right: 15px;
    top: 5%;
  }
  .total-cart > div > h1 {
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
    line-height: 21px;
  }
  .total-cart > div > div > span {
    width: 81px;
    font-family: arial;
    border: 1px solid #dee2e6;
    font-size: 15px;
    margin: 0 7px;
    color: #000;
    padding: 11px 14px;
  }
  .total-cart > div > img {
    padding: 6px 4px;
    display: none;
    visibility: hidden;
  }
  .total-cart > div > div > button {
    border-radius: 44px;
    width: 40px;
    border: 1px solid #dee2e6;
    font-size: 24px;
    color: #a7a7a7;
  }
  .b2b-banner-div > h3 > a,
  .b2c-banner-div > h3 > a {
    border-radius: 6px;
    transition: 0.5s ease-out;
  }
  .total-payment p {
    font-family: arial;
    padding: 12px 0;
    font-size: 12px;
  }
  .total-cart-header h1 {
    color: #074b94;
    font-family: arial-bold;
    font-size: 20px;
    text-align: center;
    text-transform: capitalize;
  }
  .total-cart {
    padding: 27px 11px;
    position: relative;
    background-color: #fff;
    margin: 16px 0;
    box-shadow: 0 0 3px 0 #ccc;
    border-radius: 10px;
  }
  .your-cart-container {
    padding: 0 31px;
  }
  .number {
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
  }
  .b2b-banner-div > .b2b-1 {
    font-family: arial;
    font-size: 16px;
    line-height: 37px;
    color: #fff;
    margin-bottom: 10px;
  }
  .email-banner-div > h5 {
    font-family: arial-bold;
    line-height: 20px;
    font-size: 19px;
    color: #000;
    margin-bottom: 10px;
  }
  .email-banner-div1 > h2,
  .email-banner-div > h2 {
    font-family: arial;
    font-size: 13px;
    color: #003b77;
    line-height: 19px;
  }
  .email-banner-div1 > h3,
  .email-banner-div > h3 {
    font-size: 15px;
    line-height: 26px;
    margin: 18px 1px;
    font-family: arial;
  }
  .email-banner-div1 > h3 > a,
  .email-banner-div > h3 > a {
    padding: 10px 34px;
    border-radius: 6px;
    background: linear-gradient(to left, #fed501 50%, #436fe8 50%) right;
    color: #000;
    font-size: 14px;
    background-size: 200%;
    box-shadow: 0 0 9px 0 #adb5bd;
    transition: 0.5s ease-out;
  }
  .email-banner-div1 {
    position: absolute;
    top: 75%;
    text-align: start;
    left: 64px;
  }
  .email-banner-div1 > h1 {
    font-family: arial-bold;
    line-height: 25px;
    font-size: 22px;
    color: #000;
    margin-bottom: 10px;
  }
  .email-second-section {
    background-color: var(--primary-bg-color);
    padding-bottom: 60px;
    padding-left: 0;
    padding-right: 0;
  }
  .email-second-heading {
    font-family: arial-bold;
    color: #fff;
    font-size: 21px;
    padding: 17px 50px;
  }
  .email-second-main-div {
    background-color: #fff;
    box-shadow: 0 0 10px 0 #6c757d;
    padding: 21px;
    margin: 1px 20px;
    border-radius: 44px 0 44px 44px;
  }
  .email-table-section {
    background-color: #fff;
    padding-top: 80px;
    padding-bottom: 100px;
  }
  .email-ani-email-heading {
    color: #fed501;
    font-size: 20px;
    line-height: 29px;
    padding: 0 37px;
    font-family: arial-bold;
  }
  .email-ani-email-h1 {
    color: #fff;
    font-size: 15px;
    font-family: arial-bold;
    padding: 25px 36px;
  }
  .email-ani-email-text {
    color: #fff;
    font-size: 15px;
    font-family: arial;
    padding: 0 36px;
    line-height: 26px;
  }
  .security-slider-custome-button-next {
    background-color: #f8f9faa3;
    color: #0062cc;
    width: 40px;
    bottom: 28%;
    position: absolute;
    border-radius: 3px;
    cursor: pointer;
    display: block;
    left: 20%;
    padding: 5px;
    height: 40px;
    text-align: center;
  }
  .b2b-slider-img5 {
    font-size: 15px;
    font-family: arial;
    margin: 20px 0;
    color: #000;
  }
  .b2b-section-second img {
    width: 59px;
    margin: 0 auto;
  }
  .b2b-banner-div > h3 > a {
    padding: 9px 18px;
    background: linear-gradient(to left, #fed501 50%, #fff 50%) right;
    color: #000;
    font-size: 16px;
    background-size: 200%;
  }
  .b2b-banner-div > h3 {
    font-size: 14px;
    line-height: 0;
    margin: 28px 1px;
    font-family: arial;
  }
  .b2b-banner-div > h4 {
    font-family: arial;
    font-size: 14px;
    line-height: 23px;
    color: #fff;
    margin-bottom: 20px;
  }
  .b2b-banner-div > h2,
  .b2c-banner-div > h2 {
    line-height: 34px;
    color: #fff;
    text-transform: uppercase;
  }
  .b2b-pt {
    padding-top: 20px;
  }
  .b2b-banner-div > h2 {
    font-family: arial-bold;
    font-size: 23px;
  }
  .b2b-banner-div {
    position: absolute;
    top: 69%;
    text-align: end;
    right: 24px;
  }
  .b2b-message-h2 {
    font-size: 29px;
    font-family: arial-bold;
    text-transform: uppercase;
    color: #436fe8;
  }
  .b2b-message-div {
    color: #333;
    font-family: tahoma;
    font-size: 3rem;
    font-weight: 100;
    line-height: 67.5px;
    text-transform: uppercase;
    white-space: nowrap;
    position: relative;
    width: 550px;
  }
  .b2b-message {
    color: #fed501;
    display: block;
    font-weight: 400;
    font-size: 33px;
    position: absolute;
    animation: 5s ease-in-out infinite openclose;
  }
  .b2b-message-p {
    font-size: 17px;
    font-family: arial;
    margin-bottom: 20px;
  }
  .b2b-testinomial-section-name,
  .b2b-testinomial-section-name10,
  .b2b-testinomial-section-name11,
  .b2b-testinomial-section-name12,
  .b2b-testinomial-section-name13,
  .b2b-testinomial-section-name14,
  .b2b-testinomial-section-name15,
  .b2b-testinomial-section-name16,
  .b2b-testinomial-section-name17,
  .b2b-testinomial-section-name2,
  .b2b-testinomial-section-name3,
  .b2b-testinomial-section-name4,
  .b2b-testinomial-section-name5,
  .b2b-testinomial-section-name6,
  .b2b-testinomial-section-name7,
  .b2b-testinomial-section-name8,
  .b2b-testinomial-section-name9 {
    border-radius: 5px;
    background-color: #000;
    padding: 20px 31px 20px 87px;
    transform: translate(-2%, -49%);
    float: right;
    transition: 1s;
  }
  .b2b-testinomial-section-name2 > h3,
  .b2b-testinomial-section-name4 > h3,
  .b2b-testinomial-section-name5 > h3 {
    font-size: 13px;
    font-family: arial;
    color: #fff;
    margin-bottom: 3px;
  }
  .b2b-testimonial-section-margin {
    margin-top: 0;
    margin-bottom: 40px;
    position: relative;
    padding-top: 80px;
    background-color: #fed501;
    padding-bottom: 100px;
  }
  .b2b-testinomial-heading {
    padding-bottom: 20px;
    color: #000;
    font-family: arial-bold;
    font-size: 30px;
    text-align: center;
    position: relative;
  }
  .b2b-testimonial-slider-text {
    text-align: left;
    color: #000;
    font-family: arial;
    line-height: 25px;
    font-size: 13px;
    padding: 0 35px;
  }
  .b2c-banner-div > h2 {
    font-family: arial-bold;
    font-size: 24px;
  }
  .b2c-banner-div {
    position: absolute;
    top: 66%;
    text-align: start;
    left: 23px;
  }
  .b2c-banner-div > h3 > a {
    padding: 12px 33px;
    background: linear-gradient(to left, #0b76b4 50%, #fff 50%) right;
    color: #fff;
    font-size: 18px;
    background-size: 200%;
  }
  .blog-images-section {
    padding: 21px 15px;
  }
  .blog-images-section > h5 {
    color: #6c757d;
    font-family: arial;
    line-height: 18px;
    font-size: 13px;
  }
  .blog-images-section > span > h2 {
    font-size: 13px;
    padding: 8px 24px;
    font-family: arial-bold;
    margin: 20px 0;
    width: 138px;
    border-radius: 15px;
    border: 2px solid #adb5bd;
  }
  .blog-section {
    padding: 30px 0;
    border-bottom: 34px solid #fed501;
  }
  .blog-banner-div > h2 {
    font-family: arial-bold;
    font-size: 34px;
    line-height: 32px;
    padding-bottom: 0;
    border-bottom: 3px solid #fed501;
    border-radius: 5px;
    color: #000;
    position: relative;
  }
  .case-sub-banner-div2 > h2,
  .case-sub-banner-div > h2 {
    font-family: arial-bold;
    font-size: 13px;
    padding-bottom: 21px;
    border-radius: 5px;
  }
  .blog-banner-div {
    position: absolute;
    top: 24%;
    text-align: end;
    left: 50px;
  }
  .case-sub-banner-div > h2 {
    line-height: 62px;
    position: relative;
  }
  .case-sub-banner-div > h2::before {
    content: "";
    position: absolute;
    top: 30%;
    left: -19px;
    width: 11px;
    border-radius: 50%;
    height: 11px;
    background-color: var(--primary-bg-color);
  }
  .case-sub-banner-div2 > h2::before,
  .what-we-offer-div p::before {
    content: "";
    top: 31%;
    width: 11px;
    border-radius: 50%;
    height: 11px;
    background-color: var(--primary-bg-color);
  }
  .case-sub-banner-div2 > h2 {
    line-height: 24px;
    position: relative;
  }
  .case-sub-banner-div2 > h2::before {
    position: absolute;
    left: -19px;
  }
  .section2-web-develop-passage {
    text-align: center;
    margin: 40px 0 40px 20px;
    line-height: 32px;
    font-family: arial;
    color: #495057;
    font-size: 15px;
    position: relative;
  }
  .case-studies-div > a > h4 {
    text-transform: capitalize;
    color: #436fe8;
    padding-top: 10px;
    font-size: 21px;
    padding-left: 20px;
  }
  .what-we-offer-div2 p {
    font-size: 25px;
    font-family: arial-bold;
    text-align: center;
    padding: 17px 0;
    position: relative;
  }
  .case-banner-div {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    display: none;
    left: 50%;
  }
  .section2-web-develop-header {
    text-align: center;
    text-transform: capitalize;
    margin: 40px 0;
    line-height: 45px;
    font-family: arial-bold;
    color: #495057;
    font-size: 27px;
    position: relative;
  }
  .section2-web-develop-header::before {
    content: "";
    position: absolute;
    top: 110%;
    transform: translate(-50%, -50%);
    left: 50%;
    background-color: var(--primary-bg-color);
    width: 72px;
    border-radius: 25px;
    height: 4px;
  }
  .sub-banner-div > h1 {
    font-family: arial-bold;
    font-size: 69px;
  }
  .sub-banner-div > h2 {
    margin: auto;
    padding-left: 20px;
    font-size: 26px;
    font-family: arial-bold;
  }
  .what-we-offer-div {
    padding: 25px 21px;
    text-align: left;
  }
  .what-we-offer-div p {
    font-size: 15px;
    font-family: arial-bold;
    padding: 9px 15px;
    position: relative;
  }
  .result-img > img {
    padding: 50px 0;
    height: auto;
  }
  .result-text h1 {
    width: 100%;
    margin: auto;
    font-size: 14px;
    line-height: 26px;
    padding: 20px 0;
    font-family: arial-bold;
  }
  .result-text-section {
    padding: 20px 0;
    border-bottom: 20px solid #fed501;
  }
  .what-we-offer-div p::before {
    position: absolute;
    left: -5px;
  }
  .case-sub-banner-div {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 90%;
    left: 32%;
  }
  .blog-images-section > div > img {
    width: 100%;
    transition: 0.6s linear;
    height: auto;
    overflow: hidden;
  }
  .blog-images-section > h4 {
    padding: 20px 0;
  }
  .ani-girl-img3 {
    position: absolute;
    top: 92%;
    left: 2px;
    width: 102px;
    animation: 3s ease-out infinite moveUp;
  }
  .ani-girl-img2 {
    position: absolute;
    top: 0;
    width: 109px;
    left: 0;
    animation: 2s ease-out infinite moveUp2;
  }
  .ani-girl-img1 {
    position: absolute;
    top: -349px;
    width: 112px;
    left: -9px;
    animation: 4s ease-out infinite moveUp3;
  }
  .meet-our-core-team-section {
    background-color: #436fe8;
    background-color: var(--primary-bg-color);
    height: auto;
  }
  .festive-img-div img {
    width: 100%;
    top: 0;
    box-shadow: 0 0 10px 0 #efefef;
    left: 0;
    position: relative;
    border-radius: 5px;
  }
  .festive-img-div2 img {
    width: 100%;
    height: 163px;
    object-fit: cover;
    float: left;
    border: 3px solid #efefef;
    bottom: 0;
    margin-top: 2px;
    padding: 0;
    left: 0;
    position: relative;
    border-radius: 6px;
  }
  .festive-img-div3 img,
  .festive-img-div4 img {
    border: 3px solid #efefef;
    left: 0;
    object-fit: cover;
    width: 100%;
    position: relative;
  }
  .festive-img-div3 img {
    height: 154px;
    box-shadow: 0 0 10px 0 #6c757d;
    bottom: 0;
    border-radius: 5px;
    margin: 2px 0;
    float: right;
  }
  .festive-img-div4 img {
    height: 169px;
    margin-bottom: 20px;
    float: left;
    top: 0;
    border-radius: 7px;
  }
  .padding-none {
    padding: 0 15px;
  }
  .gallery-block-section {
    padding: 26px 0;
  }
  .meet-our-core-team-section3 {
    background-image: url("../../public/assest/photos/our-team/background-blue.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: auto;
  }
  .meet-our-festive-moment > h2 {
    font-family: arial-bold;
    color: #fff;
    padding: 0;
    font-size: 20px;
  }
  .meet-our-festive-moment2 > a > span {
    font-size: 17px;
    float: left;
    font-family: arial-bold;
    color: #000;
    text-decoration: underline;
    padding: 20px 0;
  }
  .meet-our-festive-moment > a > span {
    font-size: 13px;
    float: left;
    font-family: arial-bold;
    color: #fff;
    text-decoration: underline;
    padding-bottom: 20px;
  }
  .meet-our-festive-moment2 > h2 {
    font-family: arial-bold;
    color: #000;
    padding: 5px 0;
    font-size: 19px;
  }
  .meet-our-festive-moment > h3 {
    font-family: arial;
    color: #fff;
    padding: 8px 0;
    line-height: 21px;
    font-size: 13px;
  }
  .meet-our-festive-moment2 > h3 {
    font-family: arial;
    color: #000;
    padding: 5px 0;
    line-height: 23px;
    font-size: 14px;
  }
  .festive-img-div {
    width: 100%;
    margin-top: 2px;
    height: 327px;
    position: relative;
    margin-bottom: 1px;
    float: right;
    border: 3px solid #efefef;
    border-radius: 7px;
  }
  .gallery-block-img3 {
    height: 491px;
    padding: 2px 0;
    object-fit: cover;
  }
  .gallery-block-img {
    height: 165px;
    padding: 5px 0;
    object-fit: cover;
  }
  .gallery-block-img2 {
    height: 332px;
    padding: 4px 0;
    object-fit: cover;
  }
  .meet-our-core-team-section2 {
    background-image: none;
    background-size: 100% 100%;
    background-color: #fed501;
    height: auto;
  }
  .meet-our-team-banner-div > h2 {
    color: var(--primary-bg-color);
    font-family: arial-bold;
    font-size: 14px;
    border-radius: 0;
  }
  .meet-our-team-banner-div > h3 {
    font-size: 13px;
    line-height: 16px;
    margin: 0 1px;
    color: #6c757d;
    font-family: arial;
    border-radius: 0;
    padding: 10px;
  }
  .contact-us-section {
    background-color: rgba(0, 123, 255, 0.25);
    padding: 34px 0;
  }
  .contact-us-contain {
    background-color: #fff;
    border-bottom-right-radius: 49px;
    border-top-right-radius: 70px;
    padding: 5px 0;
  }
  .contact-us-form h1 {
    font-size: 23px;
    color: #fff;
    text-align: center;
    padding: 12px 0;
    font-family: arial-bold;
  }
  .contact-us-form p,
  .contact-us-form-mobile p {
    font-size: 14px;
    color: #495057;
    line-height: 23px;
    padding: 20px 0;
    text-align: center;
    font-family: arial;
  }
  .contact-us-form-mobile h1 {
    font-size: 27px;
    color: #436fe8;
    text-align: center;
    padding: 0;
    font-family: arial-bold;
  }
  .popup-section {
    background-image: url("../../public/assest/photos/back-pattern.png");
    background-size: cover;
    padding: 12px 20px !important;
  }
  .contact-address p {
    color: #6c757d;
    padding: 21px 19px;
    font-family: arial;
    line-height: 28px;
    font-size: 14px;
  }
  .box4 {
    background-color: var(--primary-bg-color);
    margin: 20px 0;
    text-align: center;
    min-height: 350px;
    position: relative;
    width: 100%;
    height: 350px;
    border-radius: 24px;
    cursor: pointer;
  }
  .hid-box4 {
    top: 100%;
    position: absolute;
    transition: 0.3s ease-out;
    background-image: url("../../public/assest/photos/gallery/asset.png");
    background-size: 104% 51%;
    width: 100%;
    height: 100%;
    padding-top: 111px;
    font-family: arial-bold;
    color: #fff;
    font-size: 22px;
  }
  .waviy span,
  .waviy2 span {
    position: relative;
    display: inline-block;
    font-size: 25px;
    color: #436fe8;
    text-transform: uppercase;
    animation: 2s infinite flip;
    animation-delay: calc(0.2s * var(--i));
  }
  .career-dont-see-div h4 {
    font-size: 27px;
    font-family: arial-bold;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .festive-img-div img,
  .festive-img-div2 img,
  .festive-img-div3 img,
  .festive-img-div4 img {
    object-fit: cover;
    position: relative;
  }
  .meet-our-core-team-section,
  .meet-our-core-team-section2,
  .meet-our-core-team-section3 {
    background-repeat: no-repeat;
    height: 450px;
    background-size: 100% 100%;
  }
  .domain-name-div,
  .nav-tab-text {
    align-self: center;
    align-self: center;
  }
  .background-color8,
  .branding-icon1,
  .breadcrumbs {
    display: none;
  }
  .branding-banner-new-div > h1 {
    font-size: 11px;
    padding-top: 10px;
    color: #fff;
    font-family: arial-bold;
  }
  .festive-img-div3 img {
    box-shadow: 0 0 10px 0 #6c757d;
    top: -28%;
  }
  .festive-img-div2 img {
    width: 100%;
    height: auto;
    float: left;
    border: 3px solid #efefef;
    margin-top: 2px;
    padding: 0;
    right: 44px;
    border-radius: 6px;
  }
  .teams-link {
    font-family: arial;
    font-size: 10px;
  }
  footer#pt-footer .pt-copyright-footer .pt-copyright a {
    color: #fff;
    font-family: arial;
    font-size: 12px;
  }
  .festive-img-div4 img {
    width: 87%;
    height: auto;
    border: 3px solid #efefef;
    margin-bottom: 20px;
    float: left;
    left: -27px;
    top: 16%;
    border-radius: 7px;
  }
  .festive-img-div img {
    width: 100%;
    height: 153px;
    float: right;
    border-radius: 7px;
  }
  .meet-our-festive-moment2 > h3 {
    font-family: arial;
    color: #000;
    padding: 20px 0;
    line-height: 30px;
    font-size: 18px;
  }
  .meet-our-festive-moment2 > h2 {
    font-family: arial-bold;
    color: #000;
    padding: 0;
    font-size: 23px;
  }
  .meet-our-festive-moment > h2 {
    font-family: arial-bold;
    color: #fff;
    padding: 0;
    font-size: 24px;
  }
  .meet-our-festive-moment > h3 {
    font-family: arial;
    color: #fff;
    padding: 20px 0;
    line-height: 29px;
    font-size: 17px;
  }
  .contact-us-form {
    margin: 20px 40px;
    text-align: center;
  }
  .meet-our-core-team-section {
    background-image: url("../../public/assest/photos/our-team/meet-our-team-banner-white.png");
  }
  .padding-none {
    padding: 0;
  }
  .meet-our-core-team-section2 {
    background-image: url("../../public/assest/photos/our-team/meet-our-team-banner-yellow.png");
  }
  .meet-our-core-team-section3 {
    background-image: url("../../public/assest/photos/our-team/background-blue.png");
  }
  .header1,
  .header1:hover {
    transform: translateY(-29%);
  }
  .search-box-form {
    width: 100%;
    display: flex;
    margin-left: 0;
  }
  .search-box-form .login-inp {
    width: 84%;
    height: 30px;
    border-radius: 25px;
    background: 0 0;
    border: 1px solid var(--primary-color);
    padding-left: 37px;
    font-size: 12px;
    font-family: arial;
    padding-bottom: 0;
  }
  .banner-new-div > h1 {
    font-family: arial-bold;
    line-height: 18px;
    font-size: 13px;
  }
  .icon-top-ul > li > a > img {
    height: 21px;
  }
  .icon-top-ul > li {
    border-right: 1px solid #ccc;
    padding: 0 1px;
  }
  ul.ruby-menu > li > a {
    display: block;
    line-height: 25px;
    padding: 0 25px 0 15px;
    color: #fff;
    text-decoration: none;
    border-right: 1px solid #ccc;
    font-size: 10px;
    font-family: arial;
    text-transform: uppercase;
  }
  .banner-new-div2 > h4 {
    font-size: 29px;
    font-weight: 700;
    text-transform: uppercase;
    max-width: 418px;
    min-height: 95px;
    line-height: 46px;
    color: #fff;
    font-family: arial-bold;
  }
  .banner-new-div2 > h2 {
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    color: #fff;
    font-family: arial-bold;
  }
  .login-sign-div {
    margin-left: 5px;
    display: flex;
    background-color: #094bff52;
    padding: 2px 0;
    border-radius: 5px;
  }
  .sign-up-head {
    font-size: 11px;
    font-family: arial;
    color: #fff;
    border-radius: 4px;
    text-transform: capitalize;
    padding: 3px 10px;
    margin: 1px 6px;
    background-color: #436fe8;
    border: 1px solid #436fe8;
  }
  .cart-count {
    background-color: red;
    color: #fff;
    box-shadow: 1px 1px 1px 0 #6c757d;
    padding: 2px 4px;
    position: absolute;
    font-size: 10px;
    top: -14%;
    border-radius: 50px;
  }
  .addtocard-head {
    font-size: 12px;
    padding: 4px 12px;
    font-family: arial;
    color: #0430ab;
  }
  .banner-new-div {
    position: absolute;
    top: 5%;
    text-align: end;
    right: 6%;
  }
  .banner-new-div > h4 {
    font-size: 26px;
    font-weight: 700;
    text-transform: uppercase;
    max-width: 266px;
    min-height: 90px;
    line-height: 40px;
    font-family: arial-bold;
  }
  .banner-new-div > h2 {
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    font-family: arial-bold;
  }
  .banner-new-div > h3 {
    font-size: 13px;
    line-height: 26px;
    margin: 17px 1px;
    font-family: arial;
  }
  .main-slider-custome-button-prev {
    background-color: #f8f9faa3;
    color: #0062cc;
    width: 40px;
    bottom: 45%;
    position: absolute;
    display: block;
    border-radius: 3px;
    right: 16%;
    height: 40px;
    padding: 5px;
    z-index: 2;
    text-align: center;
    cursor: pointer;
  }
  .box4,
  .slider-heading {
    text-align: center;
    position: relative;
  }
  .nav-item2 {
    width: 143px;
    text-align: center;
  }
  .nav-link2,
  .nav-link2.active {
    border: none;
    background-repeat: no-repeat;
    background-color: #436fe8;
    width: 100%;
  }
  .nav-link2.active {
    background-image: url("../../public/assest/photos/yellow-back-img3.png");
    background-size: 107% 100%;
    font-size: 13px;
    font-family: arial-bold;
    height: 38px;
    color: #000;
  }
  .slider-heading {
    color: #436fe8;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 75px;
    font-family: arial-bold;
    margin-top: 50px;
  }
  .testinomial-section-name4 > h3 {
    font-size: 13px;
    font-family: arial;
    margin-bottom: 3px;
  }
  .hid-box4,
  .methodo-texting > h3,
  .nav-link2 {
    font-family: arial-bold;
  }
  .nav-link2 {
    background-size: 100% 100%;
    font-size: 13px;
    height: 50px;
    color: #fff;
    text-align: center;
  }
  .box4,
  .methodo-texting > h3::before {
    background-color: var(--primary-bg-color);
    border-radius: 20px;
  }
  .box4 {
    margin: 20px 12px;
    padding: 0;
    min-height: 349px;
    width: 97%;
    overflow: hidden;
    height: 293px;
    cursor: pointer;
  }
  .action-white-div-text,
  .hid-box4 {
    width: 100%;
    background-repeat: no-repeat;
  }
  .hid-box4 {
    top: 100%;
    position: absolute;
    transition: 0.3s ease-out;
    background-image: url("../../public/assest/photos/gallery/asset.png");
    background-size: 104% 51%;
    height: 100%;
    padding-top: 106px;
    color: #fff;
    font-size: 20px;
  }
  .nav-tab-text {
    padding: 27px 58px;
  }
  .elementor-icon-list-text4 {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    font-family: arial-bold;
    padding-bottom: 26px;
  }
  .elementor-icon-list-text3 {
    color: #495057;
    font-size: 14px;
    padding-bottom: 20px;
    font-family: arial;
    line-height: 17px;
    position: relative;
  }
  .ourteam-what-we-do-text > .typewriter > h3 {
    font-family: montessat;
    color: #fff;
    font-size: 60px;
    position: relative;
    line-height: 41px;
    text-shadow: 5px 3px #343434;
  }
  .ourteam-what-we-do-text > .typewriter > h3 > span {
    display: block;
    font-size: 111px;
    line-height: 126px;
  }
  .ourteam-what-we-do-text > .typewriter > h3 > .do {
    display: block;
    font-size: 121px;
    line-height: 90px;
  }
  .methodo-texting {
    position: relative;
    padding: 30px 21px;
  }
  .methodo-texting > h3 {
    font-size: 15px;
    color: #436fe8;
    margin: 4px 2px;
    position: relative;
  }
  .methodo-texting > h3::before {
    content: "";
    position: absolute;
    top: 25%;
    left: -6.2%;
    width: 10px;
    height: 10px;
  }
  .methodo-texting > p {
    font-size: 11px;
    font-family: arial;
    line-height: 18px;
    color: #6c757d;
  }
  .why-choose-us-texting > h2::before {
    content: "";
    width: 4px;
    height: 100%;
    border-radius: 20px;
    top: 0;
    left: -7%;
    position: absolute;
    background-color: #ffc107;
  }
  .why-choose-us-texting > h2 {
    color: #fff;
    font-size: 11px;
    line-height: 17px;
    margin-bottom: 31px;
    position: relative;
    font-family: arial;
  }
  .action-white-div-text {
    font-family: arial;
    height: 153px;
    background-color: #fff;
    background-image: url("../../public/assest/photos/white-action-back.png");
    background-size: 100% 100%;
    align-items: center;
    font-size: 19px;
    display: flex;
    color: var(--primary-bg-color);
    justify-content: space-around;
    border-radius: 10px;
    font-weight: 800;
  }
  .testinomial-heading::after {
    border-radius: 10px;
    content: "";
    position: absolute;
    top: 37%;
    left: 6%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 5px;
    background-color: #fed501;
  }
  .testinomial-heading {
    padding-bottom: 80px;
    color: #fff;
    font-family: arial-bold;
    font-size: 24px;
    text-align: left;
    position: relative;
  }
  .testimonial-slider-text {
    text-align: left;
    color: #fff;
    font-family: serif;
    font-size: 16px;
    padding: 27px 35px;
  }
  .testimg:after {
    position: absolute;
    content: "";
    top: -15px;
    left: 25px;
    width: 280px;
    height: 374px;
    border-radius: 5px;
    background: 0 0;
    border: 2px solid #fed501;
    z-index: -1;
  }
  .testinomial-section-name10::before,
  .testinomial-section-name11::before,
  .testinomial-section-name12::before,
  .testinomial-section-name13::before,
  .testinomial-section-name14::before,
  .testinomial-section-name15::before,
  .testinomial-section-name16::before,
  .testinomial-section-name17::before,
  .testinomial-section-name2::before,
  .testinomial-section-name3::before,
  .testinomial-section-name4::before,
  .testinomial-section-name5::before,
  .testinomial-section-name6::before,
  .testinomial-section-name7::before,
  .testinomial-section-name8::before,
  .testinomial-section-name9::before,
  .testinomial-section-name::before {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 47px;
    height: 39px;
    background-size: contain;
    content: "";
  }
  .testinomial-section-name {
    border-radius: 5px;
    background-color: #fed501;
    padding: 15px 33px 20px 74px;
    transform: translate(-14%, 0);
    float: right;
    transition: 1s;
  }
  .testinomial-section-name2 > h3,
  .testinomial-section-name > h3 {
    font-size: 10px;
    font-family: arial;
    margin-bottom: 3px;
  }
  .testinomial-section-name2 > h2,
  .testinomial-section-name > h2 {
    font-size: 11px;
    font-family: arial;
    color: #6c757de6;
  }
  .testinomial-section-name::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/techmech.png");
    background-repeat: no-repeat;
  }
  .testinomial-section-name2::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/ameenji.png");
    background-repeat: no-repeat;
  }
  .testinomial-section-name3::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/background-grey.jpeg");
    background-repeat: no-repeat;
  }
  .testinomial-section-name4::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/shantiniketan.png");
    background-repeat: no-repeat;
  }
  .testinomial-section-name5::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/aqua-biotech.png");
    background-repeat: no-repeat;
  }
  .testinomial-section-name6::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/snvp-school.png");
    background-repeat: no-repeat;
  }
  .testinomial-section-name7::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/ace-cons.png");
    background-repeat: no-repeat;
  }
  .testinomial-section-name8::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/esennarlogo.png");
    background-repeat: no-repeat;
  }
  .testinomial-section-name9::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/candeur-landmark.png");
    background-repeat: no-repeat;
  }
  .testinomial-section-name10::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/ecolife_logo.png");
    background-repeat: no-repeat;
  }
  .testinomial-section-name11::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/bharadwaj-packaging.jpg");
    background-repeat: no-repeat;
  }
  .testinomial-section-name12::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/rrstone.png");
    background-repeat: no-repeat;
  }
  .testinomial-section-name13::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/tsg-audio-nnovations.png");
    background-repeat: no-repeat;
  }
  .testinomial-section-name14::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/client-icon/dirak.png");
    background-repeat: no-repeat;
  }
  .testinomial-section-name15::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/ravoos.png");
    background-repeat: no-repeat;
  }
  .testinomial-section-name16::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/rajapushpa.png");
    background-repeat: no-repeat;
  }
  .testinomial-section-name17::before {
    border-radius: 5px;
    background-image: url("../../public/assest/photos/review-logo/primex-plastics.jpg");
    background-repeat: no-repeat;
  }
  .testinomial-section-name10,
  .testinomial-section-name11,
  .testinomial-section-name12,
  .testinomial-section-name13,
  .testinomial-section-name14,
  .testinomial-section-name15,
  .testinomial-section-name16,
  .testinomial-section-name17,
  .testinomial-section-name2,
  .testinomial-section-name3,
  .testinomial-section-name4,
  .testinomial-section-name5,
  .testinomial-section-name6,
  .testinomial-section-name7,
  .testinomial-section-name8,
  .testinomial-section-name9 {
    border-radius: 5px;
    background-color: #fed501;
    padding: 15px 33px 20px 74px;
    transform: translate(-10%, -14%);
    float: right;
    transition: 1s;
  }
  .slider-custome-button-next {
    background-color: #f8f9faa3;
  }
  .testinomial-section-img {
    width: 89%;
    height: auto;
    border-radius: 5px;
    text-align: center;
    margin-top: 18px;
    position: relative;
  }
  .background-blue-imgs {
    width: 100%;
    height: 8vh;
    text-align: center;
  }
  .slider-heading2 {
    text-align: left;
    font-family: arial;
    color: #436fe8;
    font-size: 21px;
    font-weight: 700;
    position: relative;
    margin-bottom: 9px;
    margin-top: 50px;
  }
  .slider-heading-text {
    position: relative;
    font-size: 11px;
    font-family: arial-bold;
    color: #6c757d;
  }
  .footer-div-text {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 80%;
    top: 40%;
    left: 50%;
    font-family: arial;
    height: 165px;
    background-color: #000;
    background-image: url("../../public/assest/photos/contact-us.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    align-items: center;
    font-size: 23px;
    display: flex;
    color: #fed501;
    justify-content: space-around;
    border-radius: 10px;
    font-weight: 800;
  }
  .hov-drop {
    position: relative;
    width: 204px;
    font-size: 13px;
    font-family: Helvetica;
    margin-left: 20px;
    font-weight: 700;
    padding: 0 20px;
    border-radius: 27px;
    border: 2px solid #436fe8;
    text-align: left;
    line-height: 34px;
    list-style: none;
    z-index: 2;
  }
  .action-black-div-text,
  .action-yellow-div-text {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    align-items: center;
    display: flex;
    border-radius: 10px;
  }
  .slider-heading-top {
    position: absolute;
    text-align: center;
    left: 43%;
    font-family: arial;
    bottom: 9%;
    color: #436fe8;
    font-size: 19px;
    font-weight: 700;
  }
  .selecter-section > h3 {
    font-size: 24px;
    font-weight: 700;
    color: #436fe8;
    font-family: arial;
  }
  footer#pt-footer .pt-footer-style-1 .widget ul li a span {
    margin-left: 8px;
    font-size: 13px;
  }
  .pt-contact > li > span {
    margin-left: 3px;
    font-family: arial;
    line-height: 22px;
    font-size: 12px;
  }
  footer#pt-footer .pt-footer-style-1 .widget ul li a,
  footer#pt-footer .pt-footer-style-1 .widget ul.menu li a {
    color: var(--bg-white);
    line-height: 21px;
    font-family: arial;
  }
  .grank-title {
    line-height: 25px;
    margin-bottom: 38px;
    font-size: 13px;
    font-family: arial;
  }
  #pt-footer .pt-footer-style-1 .pt-footer-logo {
    height: 66px;
    margin-bottom: 9px;
    margin-top: 12px;
    width: 189px;
    border-radius: 5px;
  }
  .branding-banner-new-div > h4 {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    max-width: 286px;
    color: #e9ecef;
    min-height: 86px;
    line-height: 32px;
    font-family: arial-bold;
  }
  .branding-why-img::before {
    content: "";
    position: absolute;
    background-image: url("../../public/assest/photos/branding/branding-yellow-back.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    top: -10%;
    width: 352px;
    height: 272px;
    left: 8%;
  }
  .branding-banner-new-div > h3 {
    font-size: 10px;
    line-height: 25px;
    margin: 10px 1px;
    font-family: arial;
  }
  .branding-why-text > h2 {
    font-size: 14px;
    padding-bottom: 26px;
    font-family: arial;
    padding-left: 26px;
    font-weight: 500;
    line-height: 22px;
    color: #fff;
  }
  .branding-why-text > h4 {
    color: #fed501;
    font-family: arial-bold;
    padding-top: 55px;
    padding-bottom: 31px;
    font-size: 26px;
  }
  .branding-banner-new-div {
    position: absolute;
    top: 8%;
    text-align: start;
    left: 11%;
  }
  .branding-process-text > p {
    font-size: 16px;
    padding-bottom: 26px;
    font-family: arial;
    font-weight: 500;
    padding-right: 50px;
    line-height: 30px;
    color: #436fe8;
  }
  .action-yellow-div-text {
    width: 100%;
    font-family: arial;
    height: 163px;
    background-image: url("../../public/assest/photos/yellow-action-back.png");
    font-size: 27px;
    color: #000;
    justify-content: space-around;
    font-weight: 800;
  }
  .branding-service1 {
    width: 224px;
  }
  .flip-card {
    background-color: transparent;
    width: 222px;
    height: 365px;
    transform: translateY(-20%);
    perspective: 1000px;
  }
  .rho1,
  .rho3 {
    transform: translateX(-50%) rotate(45deg);
  }
  .flip-card-back > h3 {
    font-size: 10px;
    font-family: arial;
    text-align: center;
    font-weight: 600;
    line-height: 19px;
    padding: 0 20px;
    letter-spacing: 1px;
    color: #495057;
  }
  .branding-cunsulting-banner-new-div > h1 {
    position: relative;
    font-size: 2vh;
    color: #252839;
    -webkit-text-stroke: 0.3vw #383d52;
    text-transform: uppercase;
  }
  .branding-cunsulting-banner-new-div > h2 {
    font-size: 11px;
    color: #6c757d;
    line-height: 56px;
    font-family: arial-bold;
  }
  .branding-cunsulting-banner-new-div > h3 {
    font-size: 11px;
    line-height: 26px;
    margin: 3px 1px;
    font-family: arial;
  }
  .branding-consulting-process-text {
    text-align: end;
    padding-right: 10px;
  }
  .branding-consulting-process-text > h4 {
    color: #37474f;
    font-family: arial-bold;
    font-size: 23px;
    padding-top: 90px;
  }
  .branding-consulting-process-text > h2 {
    color: #37474f;
    font-family: arial-bold;
    font-size: 31px;
    padding-bottom: 20px;
  }
  .branding-consulting-process-text > p {
    font-size: 13px;
    padding-left: 36px;
    padding-bottom: 16px;
    font-family: arial;
    font-weight: 500;
    line-height: 20px;
    color: #436fe8;
  }
  .action-black-div-text {
    width: 100%;
    font-family: arial;
    height: 154px;
    background-color: #000;
    background-image: url("../../public/assest/photos/contact-us.png");
    font-size: 26px;
    color: #fed501;
    justify-content: space-around;
    font-weight: 800;
  }
  .branding-consulting-what-text > p {
    font-size: 13px;
    font-family: arial;
    margin-bottom: 9px;
    position: relative;
    line-height: 23px;
  }
  .branding-consulting-what-text {
    overflow-y: scroll;
    direction: rtl;
    height: 313px;
    margin-left: 4px;
    margin-bottom: 54px;
    padding: 20px 29px;
  }
  .branding-consulting-what-heading {
    padding: 19px 30px;
  }
  .branding-consulting-what-heading > h4 {
    color: #436fe8;
    font-size: 29px;
    font-family: arial-bold;
  }
  .branding-consulting-what-heading > h2 {
    color: #436fe8;
    font-size: 38px;
    font-family: arial-bold;
    text-transform: capitalize;
  }
  .branding-comminication-banner-new-div > h1 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    color: #6c757d;
    min-height: 67px;
    line-height: 37px;
    font-family: arial-bold;
  }
  .branding-comminication-banner-new-div > h2 {
    font-size: 15px;
    color: #6c757d;
    line-height: 39px;
    font-family: arial-bold;
  }
  .branding-comminication-banner-new-div > h3 {
    font-size: 9px;
    line-height: 26px;
    margin: 18px 1px;
    font-family: arial;
  }
  .performance-banner-div > h1 {
    position: relative;
    font-size: 3vh;
    color: #495057;
    line-height: 45px;
    -webkit-text-stroke: 0.3vw transparent;
    text-transform: uppercase;
  }
  .performance-subdiv > div > h4 {
    font-size: 20px;
    font-family: arial-bold;
  }
  .performance-subdiv > div > p {
    font-size: 13px;
    font-family: arial;
    line-height: 22px;
    margin-top: 10px;
  }
  .performance-div2 > h4 {
    color: #436fe8;
    font-family: arial-bold;
    margin-bottom: 20px;
    line-height: 34px;
    font-size: 24px;
  }
  .performance-div2 {
    padding: 50px 32px;
  }
  .performance-div2 > p {
    font-size: 13px;
    line-height: 21px;
    font-family: arial-bold;
    margin-bottom: 20px;
    color: #6c757d;
  }
  .rho3 {
    bottom: 13%;
    left: 49%;
  }
  .rho1 {
    top: 14%;
    left: 48%;
  }
  .performance-footer-div-text {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 70%;
    top: 40%;
    left: 50%;
    font-family: arial;
    padding: 40px;
    height: 200px;
    background-color: #f8f9fa;
    display: grid;
    align-items: center;
    border: 1px solid #436fe8;
    color: #6c757d;
    justify-content: space-around;
    border-radius: 20px;
  }
  .performance-smm-banner-div {
    position: absolute;
    top: 25%;
    text-align: start;
    left: 191px;
    transform: translate(-50%, -50%);
  }
  .performance-sem-banner-div > h1 {
    position: relative;
    font-size: 3vh;
    color: #000;
    text-align: end;
    font-family: arial-bold;
    -webkit-text-stroke: 0.3vw transparent;
  }
  .performance-sem-div2 > img {
    width: 101%;
  }
  .ads-batch-div1 > h4 {
    font-size: 25px;
    font-family: arial-bold;
    padding: 40px 0;
  }
  .ads-batch-div1 > p {
    font-size: 15px;
    font-family: arial;
  }
  .ads-batch-div2 > h4 {
    font-size: 14px;
    font-family: arial-bold;
    margin-top: 14px;
    margin-bottom: 30px;
  }
  .branding-sem-consulting-what-text {
    overflow-y: scroll;
    direction: rtl;
    height: 342px;
    margin-left: 0;
    margin-bottom: 40px;
    padding: 19px 30px;
  }
  .branding-sem-consulting-what-heading {
    padding: 40px 20px;
  }
  .branding-sem-consulting-what-heading > h4 {
    color: #fed501;
    font-size: 26px;
    font-family: arial-bold;
  }
  .branding-sem-consulting-what-heading > h2 {
    color: #fed501;
    font-size: 42px;
    font-family: arial-bold;
    text-transform: capitalize;
  }
  .performance-sem-subdiv > div {
    position: absolute;
    top: 29%;
    transform: translate(-50%, -50%);
    left: 50%;
    background-color: #e9ecef;
    width: 77%;
    text-align: center;
    border-radius: 25px;
    height: 100%;
    min-height: 172px;
    padding: 17px 40px;
  }
  .performance-smm-banner-div > h1 {
    position: relative;
    font-size: 3vh;
    color: #f8f9fa;
    text-shadow: 1px;
    text-align: start;
    font-family: arial-bold;
    -webkit-text-stroke: 0.3vw transparent;
  }
  .smm-ads-batch-div2 {
    text-align: center;
    background-color: #f8f9fa;
    margin: 10px;
    border-radius: 15px;
    min-height: 350px;
  }
  .smm-ads-batch-div2 > p {
    font-size: 11px;
    line-height: 24px;
    background: #fff;
    min-height: 250px;
    color: #000;
    border-radius: 12px;
    font-family: arial;
    padding: 15px 20px;
    margin: 0 20px 10px;
  }
  .smm-ads-batch-div2 > h4 {
    font-family: arial-bold;
    font-size: 13px;
    padding: 22px 0;
    text-transform: capitalize;
  }
  .organic-marketing-banner-new-div > img {
    position: relative;
    top: 0;
    right: 0;
    height: 300px;
  }
  .organic-marketing-banner-new-div > h1 {
    position: absolute;
    font-size: 2vh;
    top: 11%;
    line-height: 30px;
    left: 52px;
    color: #495057;
    -webkit-text-stroke: 0.3vw transparent;
    text-transform: uppercase;
  }
  .domain-banner-div > h1 {
    font-size: 37px;
    color: #fed501;
    font-family: arial-bold;
    line-height: 46px;
  }
  .social-banner-div {
    position: absolute;
    top: 27%;
    text-align: start;
    left: 21px;
  }
  .organic-marketing-banner-new-div > h2 {
    font-size: 14px;
    top: 28%;
    left: 50px;
    position: absolute;
    color: #37474f;
    line-height: 56px;
    font-family: arial-bold;
  }
  .organic-marketing-banner-new-div > h3 {
    font-size: 12px;
    line-height: 7px;
    margin: 29px 1px;
    font-family: arial;
  }
  .organic-marketing-banner-new-div > h3 > a {
    padding: 11px 40px;
    position: absolute;
    top: 45%;
    left: 50px;
    border-radius: 23px;
    background: linear-gradient(to left, #436fe8 50%, #fff 50%) right;
    color: #fff;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .organic-marketing-banner-new-div > div > .p2,
  .organic-marketing-banner-new-div > div > p {
    background-color: #fff;
    text-align: start;
    font-family: arial;
    margin-bottom: 20px;
    font-size: 11px;
  }
  .organic-marketing-banner-new-div > .div2-p2 {
    position: absolute;
    bottom: 29%;
    right: 12px;
  }
  .organic-marketing-banner-new-div > div > p {
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 150px;
    padding: 20px 15px;
    box-shadow: 0 0 10px 0 #6c757d;
  }
  .organic-marketing-banner-new-div > div > .p2 {
    border-radius: 5px;
    width: 194px;
    padding: 20px 23px;
  }
  .question-div > h4 {
    color: #436fe8;
    font-size: 22px;
    margin-bottom: 50px;
    font-family: arial-bold;
    position: relative;
  }
  .question-div > h2 {
    font-size: 14px;
    font-family: arial-bold;
    line-height: 30px;
    padding-right: 50px;
    color: #6c757d;
  }
  .videos-text > h4 {
    color: #fed501;
    font-family: arial-bold;
    margin-bottom: 17px;
    font-size: 20px;
  }
  .videos-text > p {
    font-size: 12px;
    font-family: arial;
    line-height: 23px;
    color: #fff;
  }
  .organic-market-service-div {
    background-image: url("../../public/assest/photos/background-blue-slider.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 197px;
    position: relative;
    z-index: 1;
  }
  .org-mart-what-sect,
  .org-mart-what-we-do {
    background-image: url("../../public/assest/photos/organicmarketing/org-mart-what-we-img.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .organic-market-service-text::before {
    z-index: 0;
    border-radius: 20px;
    content: "";
    position: absolute;
    top: -98%;
    left: 0;
    border: 2px solid #ffc107;
    width: 100%;
    height: 308px;
  }
  .organic-market-service-text {
    text-align: center;
    font-size: 11px;
    font-family: arial;
    padding: 1px 20px;
    color: #436fe8;
    min-height: 130px;
    line-height: 24px;
    position: relative;
  }
  .organic-market-service-div > h4 {
    position: absolute;
    top: 80%;
    font-family: arial-bold;
    color: #fff;
    font-size: 12px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .org-mart-what-we-do {
    height: 600px;
    padding: 6px 50px;
  }
  .org-mart-what-we-do > h4 {
    font-size: 29px;
    font-family: arial-bold;
    padding: 33px 0;
    position: relative;
    color: #fff;
  }
  .org-mart-what-we-do > h2 {
    font-size: 13px;
    font-family: arial;
    color: #fff;
    line-height: 23px;
    padding-bottom: 41px;
  }
  .org-mart-what-we-do > p {
    font-size: 15px;
    font-family: arial;
    line-height: 25px;
    margin-bottom: 21px;
    color: #fff;
    position: relative;
    padding: 0 20px;
  }
  .org-mart-what-we-do2 {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 500px;
    padding: 0 10px;
  }
  .org-mart-what-we-do2 > h4 {
    font-size: 33px;
    font-family: arial-bold;
    padding: 50px 0;
    position: relative;
    color: #fff;
  }
  .org-mart-what-we-do2 > h2 {
    font-size: 14px;
    font-family: arial;
    color: #fff;
    line-height: 29px;
    padding-bottom: 41px;
  }
  .org-mart-what-we-do2 > p {
    font-size: 14px;
    font-family: arial;
    line-height: 24px;
    margin-bottom: 19px;
    color: #fff;
    position: relative;
    padding: 0 20px;
  }
  .org-mart-what-sect {
    height: 600px;
  }
  .creative-banner-div > h1 {
    font-family: arial-bold;
    font-size: 23px;
    color: #fff;
    margin-bottom: 20px;
  }
  .creative-banner-div > h3 {
    font-size: 11px;
    line-height: 22px;
    margin: 29px 1px;
    font-family: arial;
  }
  .creative-blue-div > h4,
  .creative-bulb-banner-div > h2,
  .creative-bulb-banner-div > h4 {
    font-family: arial-bold;
    text-transform: uppercase;
  }
  .creative-bulb-banner-div > h4 {
    font-size: 35px;
    color: #436fe8;
    line-height: 85px;
  }
  .creative-bulb-banner-div > h2 {
    font-size: 32px;
    line-height: 42px;
    color: #495057;
  }
  .creative-blue-div > h4 {
    color: #fff;
    text-align: start;
    padding-top: 60px;
    margin-bottom: 60px;
    line-height: 51px;
    font-size: 27px;
    padding-left: 120px;
    position: relative;
  }
  .question-creative-div > h2 {
    font-size: 15px;
    font-family: arial;
    line-height: 28px;
    padding-right: 150px;
    color: #6c757d;
  }
  .question-creative-div > h4 {
    color: #436fe8;
    font-size: 31px;
    margin-bottom: 9px;
    text-transform: uppercase;
    font-family: arial-bold;
    position: relative;
  }
  .creative-flip-card-front3 > h4 {
    font-family: arial-bold;
    font-size: 16px;
    margin-top: 37px;
    line-height: 23px;
    bottom: 40px;
    transform: translateX(-50%);
    left: 50%;
    text-transform: capitalize;
    position: absolute;
    color: #fff;
  }
  .creative-flip-card {
    background-color: transparent;
    width: 100%;
    height: 274px;
    perspective: 1000px;
  }
  .creative-marketing-banner-div > h1 {
    font-family: arial-bold;
    font-size: 25px;
    color: #000;
    margin-bottom: 20px;
  }
  .creative-marketing-banner-div > h3 {
    font-size: 10px;
    line-height: 26px;
    margin: 15px 1px;
    font-family: arial;
  }
  .creative-marketing-banner-div > h2 {
    font-family: arial-bold;
    font-size: 16px;
    color: #000;
  }
  .creative-header {
    text-align: center;
    text-transform: uppercase;
    margin: 33px 0;
    line-height: 38px;
    font-family: arial-bold;
    color: #436fe8;
    font-size: 29px;
  }
  .contact-google,
  .creative-header2 {
    color: #6c757d;
    font-family: arial;
    text-align: center;
    text-transform: capitalize;
  }
  .creative-header2 {
    margin: 13px 0;
    line-height: 36px;
    font-size: 14px;
  }
  .social-banner-div > h1 {
    font-family: arial-bold;
    font-size: 25px;
    color: #fff;
    margin-bottom: 18px;
  }
  .social-banner-div > h3 > a {
    padding: 10px 26px;
    border-radius: 6px;
    background: linear-gradient(to left, #fed501 50%, #fff 50%) right;
    color: #000;
    font-size: 11px;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .social-banner-img {
    position: absolute;
    top: 3%;
    height: 316px;
    right: 18px;
  }
  .contact-google {
    font-size: 10px;
    padding: 11px 0;
    line-height: 43px;
  }
  .email-sub-logo-div2 > h5,
  .social-header,
  .social-question-header {
    font-family: arial-bold;
    color: #436fe8;
  }
  .email-sub-logo-div2 > h5 {
    background-color: #436fe824;
    font-size: 13px;
    text-transform: uppercase;
    text-align: center;
    padding: 20px 0;
    border-radius: 9px;
    box-shadow: 0 0 2px 1px #436fe84f;
  }
  .social-header {
    text-align: center;
    text-transform: capitalize;
    margin: 22px 0;
    line-height: 32px;
    font-size: 29px;
  }
  .social-question-header {
    text-align: left;
    text-transform: capitalize;
    margin: 19px 0;
    line-height: 35px;
    font-size: 24px;
  }
  .social-question-header2 {
    text-align: left;
    text-transform: capitalize;
    margin: 42px 0;
    line-height: 27px;
    font-family: arial;
    color: #6c757d;
    font-size: 13px;
  }
  .social-question-div {
    background-color: var(--primary-bg-color);
    border-top-right-radius: 65px;
    border-bottom-right-radius: 65px;
    box-shadow: 7px 0 12px 0 #6c757d;
    height: 750px;
    position: relative;
    z-index: 3;
  }
  .social-banner-div > h2,
  .web-develop-banner-div > h2 {
    font-family: arial;
    font-size: 19px;
    color: #fff;
  }
  .web-develop-banner-div > h1 {
    font-family: arial-bold;
    font-size: 37px;
    color: #fff;
    margin-bottom: 20px;
  }
  .waviy2 span {
    position: relative;
    display: inline-block;
    font-size: 33px;
    color: #fff;
    text-transform: uppercase;
    animation: 2s infinite flip;
    animation-delay: calc(0.2s * var(--i));
  }
  .web-develop-banner-div > h3 > a {
    padding: 10px 30px;
    border-radius: 6px;
    background: linear-gradient(to left, #fed501 50%, #fff 50%) right;
    color: #000;
    font-size: 10px;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .web-develop-banner-div > h3 {
    font-size: 16px;
    line-height: 26px;
    margin: 18px 1px;
    font-family: arial;
  }
  .section2-web-develop-header {
    text-align: right;
    text-transform: capitalize;
    margin: 40px 0;
    line-height: 30px;
    font-family: arial-bold;
    color: #495057;
    font-size: 21px;
    position: relative;
  }
  .section2-web-develop-passage {
    text-align: right;
    margin: 40px 0 40px 20px;
    line-height: 25px;
    font-family: arial;
    color: #495057;
    font-size: 14px;
    position: relative;
  }
  .web-blue-div-white > h4 {
    font-size: 16px;
    line-height: 23px;
    padding: 20px 0;
    font-family: arial-bold;
  }
  .web-blue-div1 > h4,
  .web-blue-div2 > h4 {
    line-height: 31px;
    font-family: arial-bold;
    padding: 20px 0;
  }
  .web-blue-div2 > h4 {
    font-size: 16px;
  }
  .web-blue-div1 > h4 {
    font-size: 18px;
  }
  .web-blue-div-white > p,
  .web-blue-div1 > p,
  .web-blue-div2 > p {
    font-size: 14px;
    font-family: arial;
  }
  .web-dev-blue-heading {
    text-align: center;
    color: #fff;
    font-size: 25px;
    text-transform: uppercase;
    padding: 40px 0 80px;
    position: relative;
    font-family: arial-bold;
  }
  .web-dev-action-yellow-div-text {
    width: 100%;
    font-family: arial;
    height: 193px;
    background-color: #fed501;
    align-items: center;
    font-size: 25px;
    display: flex;
    padding: 20px;
    color: #000;
    justify-content: space-around;
    border-radius: 10px;
    font-weight: 800;
  }
  .web-dev-action-yellow-div-buttom {
    background-color: #fed501;
    color: #fff;
    font-family: arial;
    font-size: 7px;
    letter-spacing: 2px;
    padding: 10px 37px;
    text-transform: uppercase;
    border-radius: 5px;
    background: linear-gradient(to left, #436fe8 50%, #fff 50%) right;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .domain-plan-div,
  .price-box {
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .web-dev-action-yellow-div-text > p > span {
    font-size: 13px;
    font-family: arial-bold;
  }
  .domain-banner-div > h3 {
    font-size: 29px;
    color: #fed501;
    font-family: arial-bold;
    line-height: 24px;
  }
  .domain-banner-div > h4 {
    font-size: 23px;
    color: #fff;
    font-family: arial;
    line-height: 53px;
  }
  .domain-banner-div > h5 {
    font-size: 13px;
    color: #fff;
    font-family: arial;
    line-height: 38px;
  }
  .domain-second-div {
    background-color: #fff;
    align-items: center;
    height: auto;
    justify-content: space-around;
    display: flex;
    border-radius: 20px;
    margin: 33px 0;
    padding: 20px 21px;
  }
  .domain-second-div > div > img {
    width: 72px;
    padding: 0;
  }
  .domain-second-div > div > h3 {
    font-size: 17px;
    font-family: arial-bold;
    margin: 14px 0;
  }
  .domain-second-div > div > h4 {
    font-size: 10px;
    margin: 0 6px;
    font-family: arial;
    line-height: 18px;
  }
  .exp-hosting h2 {
    font-family: arial-bold;
    margin-bottom: 0;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 29px;
  }
  .domain-second-heading > h4 {
    text-align: center;
    font-size: 21px;
    margin-bottom: -7px;
    font-family: arial-bold;
    color: #fff;
    margin-top: 37px;
  }
  .domain-slider-div > h4 {
    font-size: 15px;
    padding: 20px 0;
    font-family: arial-bold;
  }
  .domain-slider-div > p {
    font-size: 13px;
    padding: 20px 0;
    line-height: 20px;
    font-family: arial;
  }
  .domain-slider-heading {
    text-align: center;
    font-size: 27px;
    margin: 40px 0;
    color: #436fe8;
    font-family: arial-bold;
  }
  .domain-plan-div {
    background-image: url("../../public/assest/photos/domain/domain-plan-back-img.png");
    height: 450px;
    text-align: center;
    padding: 13px;
  }
  .domain-plan-div > h2 {
    font-size: 16px;
    font-family: arial-bold;
    padding: 33px 0;
    text-transform: capitalize;
  }
  .domain-plan-div > h4 {
    color: #436fe8;
    font-family: arial;
    font-size: 18px;
    font-weight: 700;
  }
  .domain-plan-div > p {
    font-size: 12px;
    color: #495057;
    font-family: arial;
    padding-top: 67px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .domain-plan-div > h3 > span {
    background-color: #fed501;
    font-family: arial-bold;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 10px 20px;
    box-shadow: 2px 3px 7px 0 #6c757d;
    border-radius: 5px;
  }
  .email-banner-div > h3 > a,
  .security-banner-div > h3 > a {
    padding: 16px 40px;
    background: linear-gradient(to left, #fed501 50%, #436fe8 50%) right;
  }
  .domain-extention-plan-div {
    height: 500px;
    text-align: center;
    box-shadow: 0 0 3px 0 #adb5bd;
    padding: 9px;
    background-color: #fff;
  }
  .yellow-section-heading {
    text-align: center;
    font-family: arial-bold;
    font-size: 23px;
    padding-top: 71px;
  }
  .yellow-transfer-section-div > img {
    height: 74px;
    margin: 15px 0;
  }
  .yellow-transfer-section-div > h4 {
    font-size: 14px;
    font-family: arial-bold;
    padding-bottom: 20px;
  }
  .domain-name-div > h4,
  .domain-name-div > p {
    font-size: 13px;
    line-height: 22px;
    padding: 8px 0;
  }
  .yellow-transfer-section-div > p {
    font-size: 13px;
    font-family: arial;
  }
  .domain-name-div > h4 {
    font-family: arial-bold;
  }
  .domain-name-div > p {
    font-family: arial;
  }
  .domain-name-div {
    background-color: #f2f2f2;
    text-align: center;
    margin: 5px;
    box-shadow: 13px 9px 18px 0 #ccc;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 15px;
    padding-top: 28px;
    height: 261px;
  }
  .exp-hosting h1 {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 10px;
    font-weight: 400;
    font-family: arial;
  }
  .exp-hosting p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 19px;
  }
  .explore-plans h3,
  .hosting-banner h3 {
    line-height: 26px;
    font-family: arial;
    letter-spacing: 0.5px;
  }
  .hosting-banner h3 {
    font-size: 11px;
    margin: 11px 1px;
  }
  .exp-hosting {
    padding: 31px 0 0 44px;
  }
  .explore-plans h2 {
    margin-right: 50px;
    font-size: 18px;
    font-weight: 400;
    font-family: arial;
  }
  .explore-plans h3 {
    font-size: 10px;
    margin: 20px 0;
  }
  .price-box {
    background-image: url("../../public/assest/photos/hosting/40.png");
    padding: 40px 21px;
  }
  .pt-pricebox-info {
    padding-top: 50px;
    padding-left: 6px;
  }
  .price-box .pt-price-title {
    text-transform: uppercase;
    font-size: 11px;
    color: #555;
    line-height: 21px;
    font-weight: 600;
  }
  .price-box .price {
    color: #436fe8;
    font-size: 25px;
    text-align: right;
    padding-right: 25px;
  }
  .price-box .pt-price-duration {
    text-align: right;
    padding-right: 25px;
    color: #555;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 600;
  }
  .pt-list-info li {
    font-size: 10px;
    line-height: 22px;
    color: #555;
  }
  .table-p {
    padding: 32px 0;
    text-align: center;
    margin-top: -11px;
    color: #fff;
    font-size: 24px;
  }
  .table-add-to-cart-div > h3 {
    color: #000;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 8px;
    font-family: arial-bold;
    background-color: #fed501;
    text-align: center;
    width: 73%;
    padding: 9px 0;
    margin: auto;
  }
  .email-sub-logo-div h6,
  .email-sub-logo-div p {
    text-transform: capitalize;
  }
  .table-cell-tag2 {
    border-radius: 18px;
    text-align: center;
    padding: 16px 0;
    color: #6c757d;
    font-family: arial-bold;
    border: 1px solid #ccc;
    font-size: 11px;
    background-color: #f8f9fa;
  }
  .waviy-sec {
    position: relative;
    -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
    font-size: 33px;
  }
  .slider-div6 p {
    color: #000;
    padding: 12px 4px;
    font-size: 14px;
    line-height: 26px;
  }
  .slider-img5 {
    font-size: 18px;
    font-family: arial-bold;
    margin: 20px 0;
    color: #000;
  }
  .op2 {
    opacity: 0.5;
    transition: 0.3s;
    height: 97%;
    min-height: 402px;
    padding: 50px 0;
  }
  .features-sec2 {
    padding: 28px 0;
    position: relative;
    background-position: bottom;
  }
  .security-banner-div {
    position: absolute;
    top: 27%;
    text-align: start;
    left: 56px;
  }
  .security-banner-div > h3 > a {
    border-radius: 6px;
    color: #000;
    font-size: 12px;
    box-shadow: 0 0 9px 0 #adb5bd;
    background-size: 200%;
    transition: 0.5s ease-out;
  }
  .security-circle-heading {
    text-align: center;
    font-size: 24px;
    font-family: arial-bold;
    padding: 20px 0;
    color: var(--primary-bg-color);
  }
  .security-circle-div {
    text-align: center;
    background-color: #e9ecef;
    height: 350px;
    border-radius: 0;
    margin: 50px 0;
    box-shadow: 0 0 9px 0 #ccc;
    padding: 24px;
  }
  .security-circle-div > p {
    font-size: 14px;
    font-family: arial;
    padding: 11px 0;
    color: #495057;
  }
  .security-banner-div > h1 {
    font-family: arial-bold;
    line-height: 34px;
    font-size: 28px;
    color: var(--primary-bg-color);
    margin-bottom: 20px;
  }
  .security-banner-div > h2 {
    font-family: arial;
    font-size: 15px;
    color: #003b77;
    line-height: 23px;
  }
  .security-blue-div > p {
    color: #fff;
    font-family: arial;
    padding: 0 150px;
    font-size: 17px;
  }
  .table-cell-tagsecu {
    border-top-right-radius: 10px;
    color: #000;
    position: relative;
    font-size: 9px;
    width: 94%;
    border-top-left-radius: 10px;
    letter-spacing: 1px;
    padding: 10px 0;
    font-weight: 500;
    margin: auto;
    background: #fed501;
    text-align: center;
  }
  .secu-table-text,
  .table-add-to-cart-div > h4 {
    text-align: center;
    background-color: #f8f9fa;
    font-family: arial-bold;
  }
  .table-add-to-cart-div > h4 {
    border-radius: 18px;
    padding: 13px 0;
    color: #436fe8;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  .email-banner-div1 > h3 > a,
  .email-banner-div > h3 > a {
    border-radius: 6px;
    box-shadow: 0 0 9px 0 #adb5bd;
    transition: 0.5s ease-out;
  }
  .secu-table-text {
    border-radius: 18px;
    padding: 5px 0;
    color: #6c757d;
    font-size: 10px;
  }
  .security-testimonial-slider-h1 {
    text-align: left;
    color: var(--primary-bg-color);
    font-family: arial-bold;
    font-size: 19px;
    padding: 27px 35px;
  }
  .security-testimonial-slider-text {
    text-align: left;
    color: #003b77;
    font-family: arial;
    font-size: 17px;
    padding: 0 35px;
  }
  .security-slider-custome-button-next {
    background-color: #f8f9faa3;
    color: #0062cc;
    width: 40px;
    bottom: 28%;
    position: absolute;
    border-radius: 3px;
    cursor: pointer;
    display: block;
    left: 19%;
    padding: 5px;
    height: 40px;
    text-align: center;
  }
  .standard-step1,
  .standard-step2,
  .standard-step3,
  .standard-step4 {
    background-color: rgb(167 210 255 / 50%);
    margin-bottom: 12px;
    font-family: arial;
    border-left: 10px solid #436fe8;
    text-align: end;
    position: relative;
    width: 100%;
    float: right;
  }
  .standard-step1 {
    font-size: 15px;
    height: auto;
    padding: 29px 59px 23px 23px;
  }
  .standard-step2 {
    font-size: 15px;
    height: auto;
    padding: 14px 62px 23px 23px;
  }
  .standard-step3 {
    font-size: 17px;
    height: auto;
    padding: 29px 59px 23px 23px;
  }
  .standard-step4 {
    font-size: 15px;
    height: 110px;
    padding: 29px 51px 23px 23px;
  }
  .standard-step-img > img {
    width: 100%;
    display: none;
  }
  .wildcard-lock-heading {
    font-family: arial-bold;
    font-size: 23px;
    padding: 0 33px;
    line-height: 32px;
    margin-top: 80px;
  }
  .wildcard-lock-text {
    text-align: left;
    color: #003b77;
    font-family: arial;
    font-size: 16px;
    line-height: 23px;
    padding: 19px 35px;
  }
  .email-banner-div,
  .email-banner-div1,
  .security-org-banner-div {
    position: absolute;
    text-align: start;
  }
  .security-org-banner-div > h1 {
    font-family: arial-bold;
    line-height: 36px;
    font-size: 28px;
    color: var(--primary-bg-color);
    margin-bottom: 20px;
  }
  .email-banner-div > h5 {
    font-family: arial-bold;
    line-height: 29px;
    font-size: 22px;
    color: #000;
    margin-bottom: 7px;
  }
  .b2b-banner-div > h1,
  .email-banner-div1 > h1,
  .email-banner-div > h1 {
    margin-bottom: 20px;
    font-family: arial-bold;
  }
  .security-org-banner-div {
    top: 27%;
    left: 23px;
  }
  .email-banner-div {
    top: 12%;
    left: 48px;
  }
  .email-banner-div > h1 {
    line-height: 31px;
    font-size: 17px;
    color: #000;
  }
  .email-banner-div1 > h1 {
    line-height: 32px;
    font-size: 24px;
    color: #fff;
  }
  .number span {
    font-size: 26px;
    color: #fed501;
    font-family: arial-bold;
    display: block;
  }
  .email-sub-logo-div p,
  .email-sub-logo-div2 h4,
  .email-sub-logo-div4 p,
  .overflow-div {
    display: flex;
    display: flex;
  }
  .b2b-banner-div > h1 {
    font-size: 28px;
    color: #fff;
  }
  .overflow-div {
    height: auto;
    padding: 20px 0;
    justify-content: start;
    overflow-x: auto;
  }
  .email-banner-div > h2 {
    font-family: arial;
    font-size: 11px;
    color: #003b77;
    line-height: 19px;
  }
  .email-banner-div > h3 {
    font-size: 10px;
    line-height: 26px;
    margin: 29px 1px;
    font-family: arial;
  }
  .performance-subdiv > div {
    position: absolute;
    top: 15%;
    transform: translate(-50%, -50%);
    left: 50%;
    background-color: #e9ecef;
    width: 90%;
    border-radius: 25px;
    height: 100%;
    min-height: 203px;
    padding: 40px 22px;
  }
  .email-banner-div > h3 > a {
    color: #000;
    font-size: 10px;
    background-size: 200%;
  }
  .email-second-main-div {
    background-color: #fff;
    box-shadow: 0 0 10px 0 #6c757d;
    padding: 1px;
    margin: 18px 0;
    border-radius: 44px 0 44px 44px;
  }
  .email-second-div > div > h4 {
    color: #436fe8;
    padding: 1px 7px;
    font-family: arial-bold;
    font-size: 12px;
  }
  .email-second-div > div > p {
    line-height: 15px;
    color: #495057;
    padding: 3px 0;
    font-family: arial;
    font-size: 13px;
  }
  .email-second-main-div > p {
    font-size: 14px;
    font-family: arial;
    line-height: 26px;
    color: #495057;
    padding: 36px 21px;
  }
  .email-second-heading {
    font-family: arial-bold;
    color: #fff;
    font-size: 21px;
    padding: 0 16px;
  }
  .email-second-div2 > div > h4 {
    color: #436fe8;
    padding: 11px 0;
    font-family: arial-bold;
    font-size: 16px;
  }
  .email-ani-email-text {
    color: #fff;
    font-size: 17px;
    font-family: arial;
    padding: 13px 36px;
    line-height: 28px;
  }
  .email-ani-email-h1 {
    color: #fff;
    font-size: 20px;
    font-family: arial-bold;
    padding: 5px 36px;
  }
  .email-ani-email-heading {
    color: #fed501;
    font-size: 21px;
    padding: 16px 37px;
    font-family: arial-bold;
  }
  .email-banner-div1 {
    top: 25%;
    left: 37px;
  }
  .email-banner-div1 > h4 {
    font-family: arial-bold;
    line-height: 26px;
    font-size: 25px;
    color: #fff;
    margin-bottom: 20px;
  }
  .email-banner-div1 > h2 {
    font-family: arial;
    font-size: 13px;
    color: #fff;
    line-height: 3px;
  }
  .email-banner-div1 > h3 > a {
    padding: 12px 27px;
    background: linear-gradient(to left, #fed501 50%, #436fe8 50%) right;
    color: #000;
    font-size: 12px;
    background-size: 200%;
  }
  .slider-img9 {
    font-size: 18px;
    font-weight: 400;
    margin: 10px 0;
    color: #000;
  }
  .slider-div9 p {
    color: #000;
    padding: 0 40px;
    font-size: 14px;
    line-height: 23px;
  }
  .email-sub-logo-div h5 {
    font-size: 15px;
    font-family: arial-bold;
    padding: 20px 0;
  }
  .email-sub-logo-div h4 span {
    font-size: 13px;
    padding: 0 5px;
    text-transform: uppercase;
    color: #ccc;
  }
  .email-sub-logo-div h2 {
    color: #6c757d;
    padding: 15px 0;
    font-size: 15px;
    line-height: 18px;
  }
  .email-sub-logo-div h6 {
    background: #fff;
    width: 131px;
    font-size: 15px;
    border-radius: 5px;
    margin: auto auto 8px;
    box-shadow: 0 0 6px 0 #ccc;
    padding: 11px 6px;
    font-family: arial;
    color: #436fe8;
    cursor: pointer;
  }
  .your-cart-container {
    padding: 0 32px;
  }
  .festive-img-div {
    width: 74%;
    height: auto;
    position: relative;
    margin-bottom: 20px;
    float: right;
    border: 3px solid #efefef;
    margin-top: 0;
    border-radius: 7px;
  }
  .meet-our-team-banner-div > h2 {
    color: #436fe8;
    font-family: arial-bold;
    font-size: 34px;
    padding: 10px 30px;
    text-transform: uppercase;
    border-radius: 10px;
    background-color: #ffffff85;
    text-shadow: 3px 4px #fff;
  }
  .email-sub-logo-div p {
    font-size: 11px;
    padding: 5px 0;
    text-align: left;
    text-transform: capitalize;
    line-height: 19px;
    display: flex;
    color: #6c757d;
    font-family: arial;
  }
  .email-sub-logo-div > p > img {
    height: 21px;
    padding: 5px;
  }
  .email-sub-logo-div h4 {
    font-size: 21px;
    color: #436fe8;
    padding: 20px 0;
    font-family: arial-bold;
  }
  .email-sub-logo-div {
    text-align: center;
    height: 697px;
    padding: 0 10px;
    border: 3px solid #fff;
  }
  .email-sub-logo-div4 h6 {
    border-radius: 0;
    border: 2px solid #0062cc;
    cursor: pointer;
    font-family: arial-bold;
    font-size: 16px;
    background-color: #bedbfb5c;
    color: #0062cc;
    margin: auto auto 7px;
    padding: 9px 3px;
    text-transform: capitalize;
    width: 126px;
  }
  .email-sub-logo-div2 h4 {
    background-color: #fff;
    color: #436fe8;
    font-size: 20px;
    font-family: arial-bold;
    padding: 4px 0;
    text-align: center;
    text-transform: capitalize;
  }
  .email-small-button,
  .email-sub-logo-div4 p {
    text-transform: capitalize;
    font-family: arial;
  }
  .email-sub-logo-div4 h2 {
    color: #607d8b;
    font-size: 13px;
    padding-bottom: 15px;
  }
  .micro-yes-height {
    height: 16px;
  }
  .email-small-button {
    background: #00bcd4;
    font-size: 8px;
    border-radius: 22px;
    padding: 12px 6px;
    color: #fff;
    margin: 12px 13px;
    cursor: pointer;
  }
  .email-sub-logo-div4 p {
    font-size: 10px;
    padding: 6px 0;
    text-align: left;
    line-height: 17px;
    color: #6c757d;
  }
  .blog-banner-div > h2,
  .case-banner-div > h2 {
    padding-bottom: 21px;
    border-bottom: 6px solid #fed501;
    border-radius: 5px;
    color: #000;
    position: relative;
  }
  .email-sub-logo-div4 h4 span {
    font-size: 9px;
    padding: 8px 4px;
    text-transform: lowercase;
    color: #607d8b;
    font-family: cursive;
  }
  .case-banner-div > h2 {
    font-family: arial-bold;
    font-size: 37px;
    line-height: 8px;
  }
  .blog-images-section > div > img {
    width: 100%;
    transition: 0.6s linear;
    height: auto;
    overflow: hidden;
  }
  .b2b-banner-div > h3 > a,
  .b2c-banner-div > h3 > a {
    border-radius: 6px;
    transition: 0.5s ease-out;
  }
  .blog-images-section {
    padding: 40px 13px;
  }
  .case-studies-div > a > h4 {
    text-transform: capitalize;
    color: #436fe8;
    font-size: 31px;
    padding-left: 20px;
  }
  .blog-banner-div > h2 {
    font-family: arial-bold;
    font-size: 43px;
    line-height: 15px;
  }
  .blog-images-section2 > div {
    width: 100%;
    margin-bottom: 25px;
    margin-top: 37px;
    border-radius: 7px;
    box-shadow: 0 0 10px 0 #ccc;
    overflow: hidden;
  }
  .career-banner-div > h2 {
    color: #fff;
    font-family: arial-bold;
    font-size: 26px;
  }
  .career-banner-div > h3 {
    font-size: 14px;
    line-height: 23px;
    margin: 22px 1px;
    color: #fff;
    font-family: arial;
  }
  .career-yellow-div {
    position: absolute;
    left: 97px;
    bottom: 0;
    transform: translate(50%, 50%);
  }
  .career-yellow-div > h2 {
    font-size: 18px;
    line-height: 39px;
    text-align: center;
    padding: 9px 0;
    border-radius: 50px;
    box-shadow: 0 0 3px 0 #adb5bd;
    margin: 22px 1px;
    background-color: #fed501;
    width: 302px;
    color: #495057;
    font-family: arial;
  }
  .career-at-g-rank-div > div {
    margin: 13px 0;
    padding: 9px 11px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 #ccc;
    border-left: 20px solid #fed501;
  }
  .career-at-g-rank-div > div > h4 {
    font-size: 19px;
    padding: 13px 0;
    font-family: arial-bold;
  }
  .career-at-g-rank-div > div > h2 {
    font-size: 13px;
    padding: 0;
    font-family: arial;
  }
  .career-dont-see-div h4 {
    font-size: 33px;
    font-family: arial-bold;
  }
  .b2b-banner-div > h2,
  .b2c-banner-div > h1 {
    font-family: arial-bold;
    font-size: 19px;
    line-height: 28px;
    text-transform: uppercase;
    color: #000;
  }
  .b2b-banner-div > h3 > a {
    padding: 13px 32px;
    background: linear-gradient(to left, #fed501 50%, #fff 50%) right;
    color: #000;
    font-size: 12px;
    background-size: 200%;
  }
  .b2c-banner-div > h3 > a {
    padding: 11px 27px;
    background: linear-gradient(to left, #0b76b4 50%, #fff 50%) right;
    color: #fff;
    font-size: 11px;
    background-size: 200%;
  }
  .b2c-banner-div > h3 {
    font-size: 16px;
    line-height: 26px;
    margin: 13px 1px;
    font-family: arial;
  }
  .b2b-message-h2 {
    font-size: 37px;
    font-family: arial-bold;
    text-transform: uppercase;
    color: #436fe8;
  }
  .b2b-testinomial-heading {
    padding-bottom: 45px;
    color: #000;
    font-family: arial-bold;
    font-size: 35px;
    text-align: left;
    position: relative;
  }
  .b2b-testimonial-slider-text {
    text-align: left;
    color: #000;
    font-family: arial;
    line-height: 24px;
    font-size: 14px;
    padding: 27px 35px;
  }
  .slider-custome-button-next,
  .slider-custome-button-prev {
    background-color: #f8f9faa3;
    color: #0062cc;
    width: 40px;
    bottom: 0;
    position: absolute;
    border-radius: 3px;
    cursor: pointer;
    display: block;
    padding: 5px;
    height: 40px;
    text-align: center;
  }
  .slider-custome-button-prev {
    left: 5%;
    z-index: 2;
  }
  .slider-custome-button-next {
    left: 13%;
  }
  .b2b-testinomial-section-name,
  .b2b-testinomial-section-name10,
  .b2b-testinomial-section-name11,
  .b2b-testinomial-section-name12,
  .b2b-testinomial-section-name13,
  .b2b-testinomial-section-name14,
  .b2b-testinomial-section-name15,
  .b2b-testinomial-section-name16,
  .b2b-testinomial-section-name17,
  .b2b-testinomial-section-name2,
  .b2b-testinomial-section-name3,
  .b2b-testinomial-section-name4,
  .b2b-testinomial-section-name5,
  .b2b-testinomial-section-name6,
  .b2b-testinomial-section-name7,
  .b2b-testinomial-section-name8,
  .b2b-testinomial-section-name9 {
    border-radius: 5px;
    background-color: #000;
    padding: 20px 31px 20px 87px;
    transform: translate(-11%, -14%);
    float: right;
    transition: 1s;
  }
  .b2b-banner-div > .b2b-1 {
    font-family: arial;
    font-size: 18px;
    line-height: 34px;
    color: #000;
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1044px) {
  .breadcrumbs,
  .menu-inner3 {
    display: none;
  }
  .menu-inner3 {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
  }
}
@media (min-width: 1024px) and (max-width: 1100px) {
  .box,
  .nav-item2 {
    text-align: center;
  }
  .box,
  .nav-item {
    position: relative;
  }
  .breadcrumbs {
    display: none;
  }
  .nav-link > a {
    color: var(--bg-white);
    font-size: 8px;
    font-weight: 500;
  }
  .nav-link.active > a {
    color: #ffc107;
    font-size: 8px;
  }
  .nav-item {
    max-width: 100%;
    padding: 0;
  }
  .first-tabs > li > a {
    color: var(--primary-bg-color);
    border-right: 1px solid var(--primary-bg-color);
    padding: 0 32px;
    font-weight: 500;
    font-size: 10px;
  }
  .organic-marketing-banner-new-div > div > .p2,
  .organic-marketing-banner-new-div > div > p {
    background-color: #f8f9fa;
    text-align: start;
    border-radius: 5px;
    font-family: arial;
    font-size: 15px;
    margin-bottom: 20px;
  }
  .nav-item2 {
    width: 154px;
  }
  .nav-link2,
  .nav-link2.active {
    border: none;
    background-repeat: no-repeat;
    font-size: 12px;
    height: 34px;
    background-color: #436fe8;
    width: 100%;
    font-family: arial-bold;
  }
  .nav-link-img {
    width: 38px;
    height: 70px;
  }
  .organic-marketing-banner-new-div > img {
    position: relative;
    top: 0;
    right: 0;
    height: 394px;
  }
  .organic-marketing-banner-new-div > div > p {
    width: 200px;
    padding: 20px 15px;
  }
  .organic-marketing-banner-new-div > div > .p2 {
    width: 250px;
    padding: 20px 23px;
  }
  .organic-marketing-banner-new-div > .div2-p2 {
    position: absolute;
    bottom: 30%;
    right: 12px;
  }
  .box {
    background-color: var(--primary-bg-color);
    margin: 20px 60px;
    padding: 30px 0;
    min-height: 329px;
    width: 75%;
    overflow: hidden;
    height: 350px;
    border-radius: 20px;
    box-shadow: 0 0 17px 0 #adb5bd;
    cursor: pointer;
  }
  .social-banner-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 48%;
  }
  .social-banner-div {
    position: absolute;
    top: 27%;
    text-align: start;
    left: 36px;
  }
  .social-question-div > img {
    position: absolute;
    top: 34%;
    left: 50px;
    transform: translate(-50%, -50%);
    width: 82%;
  }
}
@media (min-width: 1101px) and (max-width: 1299px) {
  .box,
  .nav-item2 {
    text-align: center;
  }
  .nav-link > a {
    color: var(--bg-white);
    font-size: 9px;
    font-weight: 500;
  }
  .nav-link.active > a {
    color: #ffc107;
    font-size: 11px;
  }
  .first-tabs > li > a {
    color: var(--primary-bg-color);
    border-right: 1px solid var(--primary-bg-color);
    padding: 0 32px;
    font-weight: 500;
    font-size: 12px;
  }
  .organic-marketing-banner-new-div > div > .p2,
  .organic-marketing-banner-new-div > div > p {
    background-color: #f8f9fa;
    text-align: start;
    border-radius: 5px;
    font-family: arial;
    font-size: 15px;
    margin-bottom: 20px;
  }
  .nav-item2 {
    width: 190px;
  }
  .nav-link2,
  .nav-link2.active {
    border: none;
    background-repeat: no-repeat;
    font-size: 14px;
    height: 50px;
    background-color: #436fe8;
    width: 100%;
    font-family: arial-bold;
  }
  .nav-link-img {
    width: 39px;
    height: 70px;
  }
  .slider-div,
  .slider-div2 {
    height: 169px;
  }
  .slider-img2 {
    font-size: 12px;
    color: #fff;
    line-height: 18px;
    font-family: arial-bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .background-blue-imgs {
    width: 100%;
    height: 25vh;
    text-align: center;
  }
  .h-max {
    height: 95vh;
    max-width: 650px;
    margin: 0 auto;
  }
  .organic-marketing-banner-new-div > img {
    position: relative;
    top: 0;
    right: 0;
    height: 450px;
  }
  .organic-marketing-banner-new-div > div > p {
    width: 200px;
    padding: 20px 15px;
  }
  .organic-marketing-banner-new-div > div > .p2 {
    width: 250px;
    padding: 20px 23px;
  }
  .organic-marketing-banner-new-div > .div2-p2 {
    position: absolute;
    bottom: 26%;
    right: 12px;
  }
  .box {
    background-color: var(--primary-bg-color);
    margin: 20px 60px;
    padding: 30px 0;
    min-height: 400px;
    position: relative;
    width: 75%;
    overflow: hidden;
    height: 400px;
    border-radius: 20px;
    box-shadow: 0 0 17px 0 #adb5bd;
    cursor: pointer;
  }
  .social-banner-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 49%;
  }
}
